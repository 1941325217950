import {getTranslations} from "../session/localstorage/getTranslations.js";
import {createCard} from "./createCard";
import {getColumnsPerEntity} from "./getColumnsPerEntity";
import {getPrimaryKeyName} from "./getPrimaryKeyName";

export const convertRailroadTasksToKanbanCards = async ({tasks}) => {
  const translations = getTranslations();

  // Helper function to get columns for each entity
  const getColumnsForEntities = async (tasks) => {
    const uniqueTableNames = [...new Set(tasks.map((task) => task.TableName))];
    const columnsPerEntityArray = await Promise.all(
      uniqueTableNames.map(async (tableName) => {
        return await getColumnsPerEntity({
          task: {TableName: tableName},
          columnsPerEntity: {},
        });
      }),
    );

    // Convert array of column entities to an object
    const columnsPerEntity = columnsPerEntityArray.reduce((acc, entity) => {
      return {...acc, ...entity};
    }, {});

    return columnsPerEntity;
  };

  // Helper function to process each task
  const processTask = async (task, columnsPerEntity, translations) => {
    const primaryKeyName = getPrimaryKeyName({
      columnsPerEntity,
      tableName: task.TableName,
    });
    return createCard({task, primaryKeyName, translations});
  };

  // Get columns for all entities
  const columnsPerEntity = await getColumnsForEntities(tasks);

  // Process each task
  return await Promise.all(
    tasks.map((task) => processTask(task, columnsPerEntity, translations)),
  );
};
