<template>
  <div class="flex w-100 lg:flex-row flex-col">
    <div class="mr-auto flex">
      <div
        class="fake-button button button-menu no-hover no-action mr-auto h-fit"
      >
        <span>{{ rows.length }}</span>
      </div>
      <div class="flex ml-2">
        <datagrid-pagination
          :page="page"
          :count="rows.length"
          :page-size="pageSize"
          @page-change="$emit('page-change', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DatagridPagination from "@/components/datagrid/DatagridPagination.vue";

export default {
  name: "DatagridReturnItemFooter",
  components: {
    DatagridPagination,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    loadingPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
};
</script>
