<template>
  <td class="border" :rowspan="rowspan" :colspan="1">
    <component
      :key="action.name"
      :is="getComponent(action)"
      :row="value"
      :columns="columns"
      :value="value"
      @action="onAction"
    ></component>
  </td>
</template>

<script>
export default {
  name: "DatagridRowAction",
  props: {
    value: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    rowspan: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    onAction(action) {
      this.$emit("action", action);
    },
    getComponent(action) {
      return action.component;
    },
  },
};
</script>

<style>
td {
  cursor: pointer;
}
</style>
