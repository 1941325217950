import {sortColumns} from "../columns/sortColumns.js";

export function processReturnItemServiceColumn({
  columns,
  rows,
  page,
  pageSize,
}) {
  if (!rows) return columns;

  let newColumns = [...columns];
  const start = (page - 1) * pageSize;
  const end = page * pageSize;
  let visibleRows = rows.slice(start, end);

  const pageHasRowWithService = visibleRows.find(
    (row) => row?.ServiceBeforeReturnType?.Value !== 0,
  );
  const pageHasServiceColumn = newColumns.find(
    (column) => column.Name === "PerformServiceBeforeReturn",
  );

  if (pageHasServiceColumn && !pageHasRowWithService) {
    newColumns = newColumns.filter(
      (column) => column.Name !== "PerformServiceBeforeReturn",
    );
  }

  if (!pageHasServiceColumn && pageHasRowWithService) {
    newColumns = [
      ...newColumns,
      {
        Name: "PerformServiceBeforeReturn",
        Title:
          '<i class="fas fa-tools text-md"></i> <div class="inline text-xl">?</div>',
        Editor: "Checkbox",
        Ranking: 75,
        IsVisible: true,
        IsRequired: true,
        IsVirtual: true,
        IsReadOnly: false,
      },
    ];
  }

  return sortColumns(newColumns);
}
