var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row no-gutters"},[(_vm.settings.SimpleInterface && !_vm.settings.HideFormSections)?_c('div',{staticClass:"col-xs-12 col-md-12 col-lg-3 col-xl-3 pb-4 pb-lg-0"},[_c('div',{staticClass:"font-semibold text-[18.7px] m-2 p-0"},[_vm._v(" "+_vm._s(_vm.section.Title)+" ")]),_c('div',{staticClass:"text-[14.3px] m-2 p-0"},[_vm._v(_vm._s(_vm.section.Description))])]):_vm._e(),_c('div',{class:{
      'col-xs-12 col-md-12 col-lg-12 col-xl-12':
        _vm.settings.ShowActionBar && _vm.settings.HideFormSections,
      'col-xs-12 col-md-12 col-lg-9 col-xl-9': !(
        _vm.settings.ShowActionBar && _vm.settings.HideFormSections
      ),
    }},[(!_vm.settings.HideFormSections && !_vm.settings.SimpleInterface)?_c('div',{staticClass:"ml-3"},[(_vm.section.Title !== '' && _vm.section.Description !== '')?_c('div',[_c('div',{staticClass:"font-semibold text-[18.7px] m-2 p-0"},[_vm._v(" "+_vm._s(_vm.section.Title)+" ")]),_c('div',{staticClass:"text-[14.3px] m-2 p-0"},[_vm._v(_vm._s(_vm.section.Description))])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"row"},[_vm._l((_vm.sortedSectionColumns),function(column){return [_c('div',{key:column.Name,class:_vm.getColumnWidthClassFromColumn(column)},[_c('form-field-col',{key:column.Name,attrs:{"field-name":column.Name,"form-fields":_vm.formFields},on:{"input":_vm.handleInput,"field-change":function($event){return _vm.$emit('field-change', $event)}}})],1),(column.IsVisible)?_c('div',{key:`${column.Name}-offset`,class:_vm.getColumnOffsetClassFromColumn(column)}):_vm._e()]})],2)]),(!_vm.settings.HideFormSections)?_c('div',{staticClass:"col-12"},[_c('hr',{staticClass:"w-100 mt-4 mb-4 pb-0 mb-md-5"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }