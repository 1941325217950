import {api} from "../../api";
import store from "../../../../state/store";

export const getInvoice = async ({invoiceId}) => {
  return await api.get(`/v2/invoices/${invoiceId}`, {
    windowId: global.session.activeWindow.id,
    params: {
      WarehouseID: store.state.activeWarehouse,
    },
  });
};
