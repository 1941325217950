<template>
  <div class="flex w-100 lg:flex-row flex-col">
    <div class="mr-auto flex">
      <div
        class="fake-button button button-menu no-hover no-action mr-auto h-fit"
      >
        <span>{{ count }}</span>
      </div>
      <div class="flex ml-2">
        <datagrid-pagination
          :page="page"
          :count="count"
          :page-size="pageSize"
          @page-change="$emit('page-change', $event)"
        />
      </div>
      <div
        v-if="activeRow"
        class="grow h-[30px] flex items-center mx-3 font-bold justify-center"
      >
        <span
          >#{{ activeRow.Ranking.Value }} | {{ activeRow.ItemID.Value }} -
          {{ activeRow.ItemDescription.Value }}</span
        >
      </div>
    </div>

    <datagrid-footer-weight-sum
      v-if="rowsHaveWeight"
      :total-weights="totalWeights"
    />
    <datagrid-order-item-footer-price-sum
      v-if="defaultCurrencyId !== currency"
      class="mr-2"
      :rows="nonVirtualRows"
      :total-paid="totalPaid"
      :extra-total-values="extraTotalValues"
      :use-rate="false"
      :title="defaultCurrencyId"
    />
    <datagrid-order-item-footer-price-sum
      :rows="nonVirtualRows"
      :total-paid="totalPaid"
      :extra-total-values="extraTotalValues"
      :title="currency"
    />
  </div>
</template>

<script>
import DatagridOrderItemFooterPriceSum from "@/components/datagrid/datagridFooters/DatagridOrderItemFooterPriceSum";
import DatagridFooterWeightSum from "./DatagridFooterWeightSum.vue";
import DatagridPagination from "@/components/datagrid/DatagridPagination.vue";

export default {
  name: "DatagridOrderItemFooter",
  components: {
    DatagridOrderItemFooterPriceSum,
    DatagridFooterWeightSum,
    DatagridPagination,
  },
  inject: ["rateCallback"],
  props: {
    rows: {
      type: Array,
      required: true,
    },

    activeRow: {
      type: Object,
      required: false,
      default: null,
    },
    loadingPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    totalWeights: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    totalPaid: {
      type: Number,
      required: false,
      default: 0,
    },
    extraTotalValues: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    page: {
      type: Number,
      required: true,
    },
    currency: {
      type: [String, null],
      required: false,
      default: null,
    },
    defaultCurrencyId: {
      type: String,
      required: true,
    },
  },
  computed: {
    nonVirtualRows() {
      return this.rows.filter((row) => !row.rowMeta?.virtual);
    },
    rowsHaveWeight() {
      return !!(
        this.totalWeights?.ItemsWeight > 0 ||
        this.totalWeights?.ShippingWeight > 0
      );
    },
    count() {
      return this.rows.filter((row) => !row.rowMeta?.compositionItem).length;
    },
    rate() {
      if (!this.rows.length) return 1;
      return this.rateCallback ? this.rateCallback(this.rows[0]) : 1;
    },
  },
};
</script>

<style scoped></style>
