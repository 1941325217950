import {detectName} from ".";

import * as textRenderers from "./textRenderers";

export function getTextRenderer({cell, column}) {
  const componentName = detectName({cell, column});

  return textRenderers[componentName];
}

export function getCellText({cell, column}) {
  const value = cell?.Value;
  const textRenderer = getTextRenderer({cell, column, value});

  if (textRenderer) {
    return textRenderer({cell, column, value});
  }

  return cell.Value?.toString();
}
