<template>
  <div class="bg-gray-100 text-right p-2" style="min-width: 300px">
    <div v-if="title" class="text-left font-semibold pb-2">{{ title }}:</div>
    <div class="flex justify-between">
      <div class="font-semibold">
        {{ translations["Rental.OrderItem-MultiEdit-TotalExcVat"] }}:
      </div>
      <div>{{ formatPrice(totalExcludingVat) }}</div>
    </div>
    <div class="flex justify-between">
      <div class="font-semibold">
        {{ translations["Rental.OrderItem-MultiEdit-Vat"] }}:
      </div>
      <div>{{ formatPrice(totalVat) }}</div>
    </div>
    <div class="flex justify-between">
      <div class="font-semibold">
        {{ translations["Rental.OrderItem-MultiEdit-TotalIncVat"] }}:
      </div>
      <div>{{ formatPrice(totalIncludingVat) }}</div>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import {formatPrice} from "@/util/formatting/formatPrice";
import {sumValues} from "@/util/math/sumValues";

export default {
  name: "DatagridInvoiceItemFooterPriceSum",
  inject: ["rateCallback", "translations"],
  props: {
    title: {
      type: String,
      default: "",
    },
    rows: {
      type: Array,
      required: true,
    },
    totalPaid: {
      type: Number,
      required: false,
      default: 0,
    },
    useRate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    totalExcludingVat() {
      return sumValues(
        this.rows.map((x) =>
          this.convertToLocalCurrency(x.TotalExcVAT.Value, x),
        ),
      );
    },
    totalVat() {
      return sumValues(
        this.rows.map((x) => this.convertToLocalCurrency(x.TotalVAT.Value, x)),
      );
    },
    totalIncludingVat() {
      return new Decimal(this.totalExcludingVat).plus(this.totalVat).toNumber();
    },
  },
  methods: {
    formatPrice,
    convertToLocalCurrency(num, row) {
      let rate = 1;

      if (this.useRate) {
        rate = this.rateCallback(row);
      }
      return new Decimal(num || 0).times(rate).toNumber();
    },
  },
};
</script>

<style scoped></style>
