export function convertKeyValueRowToCellRow(row) {
  const convertedValues = row;
  for (const value in convertedValues) {
    if (row[value]?.hasOwnProperty("Value")) continue;
    convertedValues[value] = {
      Value: row[value],
      Name: value,
    };
  }
  return convertedValues;
}
