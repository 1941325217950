<template>
  <div class="flex w-100 lg:flex-row flex-col">
    <div class="mr-auto flex">
      <div
        class="fake-button button button-menu no-hover no-action mr-auto h-fit"
      >
        <span>{{ count }}</span>
      </div>
      <div class="flex ml-2">
        <datagrid-pagination
          :page="page"
          :count="count"
          :page-size="pageSize"
          @page-change="$emit('page-change', $event)"
        />
      </div>
    </div>
    <datagrid-invoice-item-footer-price-sum
      v-if="defaultCurrencyId !== currency"
      class="mr-2"
      :rows="nonVirtualRows"
      :total-paid="totalPaid"
      :use-rate="false"
      :title="defaultCurrencyId"
    />
    <datagrid-invoice-item-footer-price-sum
      :rows="nonVirtualRows"
      :total-paid="totalPaid"
      :title="currency"
    />
  </div>
</template>

<script>
import DatagridInvoiceItemFooterPriceSum from "@/components/datagrid/datagridFooters/DatagridInvoiceItemFooterPriceSum";
import DatagridPagination from "@/components/datagrid/DatagridPagination.vue";

export default {
  name: "DatagridQuotationItemFooter",
  components: {
    DatagridInvoiceItemFooterPriceSum,
    DatagridPagination,
  },
  inject: ["rateCallback"],
  props: {
    rows: {
      type: Array,
      required: true,
    },
    activeRow: {
      type: Object,
      required: false,
      default: null,
    },
    loadingPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    totalPaid: {
      type: Number,
      required: false,
      default: 0,
    },
    page: {
      type: Number,
      required: true,
    },
    currency: {
      type: [String, null],
      required: false,
      default: null,
    },
    defaultCurrencyId: {
      type: String,
      required: true,
    },
  },
  computed: {
    nonVirtualRows() {
      return this.rows.filter((row) => !row.rowMeta?.virtual);
    },
    count() {
      return this.rows.filter((row) => !row.rowMeta?.compositionItem).length;
    },
  },
};
</script>

<style scoped></style>
