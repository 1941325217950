<template>
  <div
    class="col-md-12 sidebar-wrapper row justify-end"
    :class="hideRailRoad ? 'col-lg-2' : 'col-lg-4'"
  >
    <div
      v-if="!hideRailRoad"
      class="flex-grow-0 flex-shrink-0 w-full lg:max-w-[55%] xl:2xl:max-w-[50%] lg:pr-[7.5px] xl:pr-[12px] 2xl:pr-[16px]"
    >
      <form-sidebar-railroad
        :guides="railroadGuides"
        :r-window="rWindow"
        class="sticky top-0 pb-5"
      />
    </div>
    <div
      class="flex-grow-0 flex-shrink-0 w-full xl:px-[10px] 2xl:px-[15px]"
      :class="{
        'lg:max-w-[45%] xl:2xl:max-w-[50%]':
          !hideRailRoad && (!hideFormSections || hideFormSections),
      }"
    >
      <r-row class="sidebar mx-0">
        <r-col
          v-if="sidebarFields.length"
          :cols="12"
          class="flex-col lg:pl-[9px] lg:pr-0 xl:px-0 2xl:px-[15px]"
        >
          <field
            v-for="field in sidebarFields"
            :key="field.Name"
            :field="field"
            :fields="fields"
            @field-change="$emit('field-change', $event)"
          />
        </r-col>
        <r-col :cols="12" class="lg:ml-3 xl:ml-2 2xl:ml-0">
          <sidebar-checklist v-show="guides.length" :guides="checklistGuides" />
        </r-col>
      </r-row>
    </div>
  </div>
</template>

<script>
import {fetchSidebarData} from "../../../functions/form/r-form/fetchSidebarData.js";
import {getSidebarFields} from "../../../functions/form/r-form/getSidebarFields.js";
import {getSettings} from "../../../util/getSettings.js";
import FormSidebarRailroad from "./FormSidebarRailroad.vue";
import SidebarChecklist from "./FormSidebarChecklist.vue";
import Field from "../../../../views/content/fields/Field.vue";
import RRow from "../../elements/RRow.vue";
import RCol from "../../elements/RCol.vue";

export default {
  name: "FormSidebar",
  components: {
    FormSidebarRailroad,
    SidebarChecklist,
    Field,
    RRow,
    RCol,
  },
  props: {
    windowId: {
      type: String,
      required: true,
    },
    rWindow: {
      type: Object,
      required: true,
    },
    windowData: {
      type: Object,
      default: () => ({}),
    },
    metadata: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      guides: [],
      railroadGuides: [],
      hideRailRoad: getSettings()?.HideRailRoad,
      hideFormSections: getSettings()?.HideFormSections,
    };
  },
  computed: {
    checklistGuides() {
      return this.guides.filter((guide) => guide.TutorialStep === false);
    },
    columns() {
      return this.windowData.Columns;
    },
    row() {
      if (!this.windowData.Rows) return {};
      return this.windowData.Rows[0];
    },
    sidebarFields() {
      return getSidebarFields({
        columns: this.columns,
        row: this.row,
        metadata: this.metadata,
      });
    },
    fields() {
      return this.getFieldsFromRow({row: this.row, columns: this.columns});
    },
  },
  created() {
    global.eventBus.on("afterRender", this.fetchData);
    this.fetchData();
  },
  destroyed() {
    global.eventBus.off("afterRender", this.fetchData);
  },
  methods: {
    getFieldsFromRow({row, columns}) {
      const fields = [];

      if (!row) return [];

      for (const index in row) {
        fields.push({...row[index], ...columns[index]});
      }
      fields.sort((a, b) => a.Ranking - b.Ranking);
      return fields;
    },
    async fetchData() {
      const {criteria, subject, prefix} = this.rWindow;
      const {guides, railroadGuides} = await fetchSidebarData({
        subject,
        criteria,
        prefix,
      });
      this.guides = guides;
      this.railroadGuides = railroadGuides;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  border-left: 2px solid #eeeeee;
  position: sticky;
  top: 0px;
}
</style>
