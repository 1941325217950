//Quotation
import {rowsProcessVirtualRowAmountChange} from "@/functions/datagrid/quotation-item/rows/rowsProcessVirtualRowAmountChange";
import {processPopupPriceCalculations} from "@/functions/datagrid/order-item/row/processPopupPriceCalculations";
import {rowsAmountChangeWrapper} from "@/functions/datagrid/quotation-item/rows/change-event-wrappers/rowsAmountChangeWrapper";
import {rowAmountChangeWrapper} from "@/functions/datagrid/quotation-item/row/change-event-wrappers/rowAmountChangeWrapper";
import {handleItemIdChange} from "@/functions/datagrid/quotation-item/row/handleItemIdChange";
import {processRowPrices} from "@/functions/datagrid/quotation-item/row/processRowPrices";
import {processItemType} from "@/functions/datagrid/quotation-item/row/processItemType";

//Generic
import {processUseSinglePeriodPriceChange} from "@/functions/datagrid/quotation-item/processUseSinglePeriodPriceChange";
import {fetchPeriodBasedExpectedEndDate} from "@/functions/datagrid/quotation-item/row/fetchPeriodBasedExpectedEndDate";
import {rowsVirtualResumTotalExcVat} from "@/functions/datagrid/order-item/rows/rowsVirtualResumTotalExcVat";
import {handleItemDescriptionChange} from "@/functions/datagrid/order-item/row/handleItemDescriptionChange";
import {processPeriodColumnChange} from "@/functions/datagrid/order-item/row/processPeriodColumnChange";
import {processPeriodPriceChange} from "@/functions/datagrid/order-item/row/processPeriodPriceChange";
import {processSerialIdChange} from "@/functions/datagrid/order-item/row/processSerialIdChange";
import {handleVATCodeIDChange} from "@/functions/datagrid/row/handleVATCodeIDChange";
import {handleRankingChange} from "@/functions/datagrid/row/handleRankingChange";
import {setAndForceMinDates} from "@/functions/datagrid/order-item/row/setAndForceMinDates";
import {processPeriodPrice} from "@/functions/datagrid/order-item/row/processPeriodPrice";
import {handleAmountChange} from "../row/handleAmountChange";
import {processTotalExcVat} from "@/functions/datagrid/order-item/row/processTotalExcVat";
import {fetchCounterPrice} from "@/functions/datagrid/order-item/row/fetchCounterPrice";
import {processSalesRow} from "@/functions/datagrid/quotation-item/row/processSalesRow";
import {processFreeRow} from "@/functions/datagrid/quotation-item/row/processFreeRow";
import {processSerialRow} from "../row/processSerialRow.js";

export function processColumnsMetaData({columns}) {
  const newColumns = columns.slice();
  newColumns.forEach((newColumn) => {
    newColumn.processValueChangeFunctions = [];
    newColumn.processValueChangeFunctionsForRows = [];

    if (newColumn.Name === "VATCodeID") {
      newColumn.processValueChangeFunctions.push(handleVATCodeIDChange);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "ItemID") {
      newColumn.processValueChangeFunctions.push(handleItemIdChange);
      newColumn.processValueChangeFunctions.push(processSerialRow);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.openRef = true;
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "Ranking") {
      newColumn.processValueChangeFunctions.push(handleRankingChange);
    }

    if (newColumn.Name === "DateTimeExpectedStart") {
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(
        fetchPeriodBasedExpectedEndDate,
      );
    }

    if (newColumn.Name === "DateTimeExpectedEnd") {
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "ItemDescription") {
      newColumn.processValueChangeFunctions.push(handleItemDescriptionChange);
    }

    if (newColumn.Name === "Amount") {
      newColumn.processValueChangeFunctions.push(handleAmountChange);
      newColumn.processValueChangeFunctions.push(rowAmountChangeWrapper);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsAmountChangeWrapper,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsProcessVirtualRowAmountChange,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "SerialID") {
      newColumn.processValueChangeFunctions.push(processSerialIdChange);
      newColumn.processValueChangeFunctions.push(rowAmountChangeWrapper);
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "Discount") {
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "DateTimeBusinessStart") {
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "DateTimeBusinessEnd") {
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "ExpectedCounter") {
      newColumn.processValueChangeFunctions.push(fetchCounterPrice);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "Price") {
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "ItemType") {
      newColumn.processValueChangeFunctions.push(processFreeRow);
      newColumn.processValueChangeFunctions.push(processItemType);
      newColumn.processValueChangeFunctions.push(processSalesRow);
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "Period") {
      newColumn.processValueChangeFunctions.push(processPeriodPrice);
      newColumn.processValueChangeFunctions.push(processPeriodColumnChange);
      newColumn.processValueChangeFunctions.push(
        processUseSinglePeriodPriceChange,
      );
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(
        fetchPeriodBasedExpectedEndDate,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "PeriodPrice") {
      newColumn.processValueChangeFunctions.push(processPeriodPriceChange);
      newColumn.processValueChangeFunctions.push(processPeriodColumnChange);
      newColumn.processValueChangeFunctions.push(
        processUseSinglePeriodPriceChange,
      );
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "PeriodAmount") {
      newColumn.processValueChangeFunctions.push(
        processUseSinglePeriodPriceChange,
      );
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(processPeriodColumnChange);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);

      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(
        fetchPeriodBasedExpectedEndDate,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }
  });
  return newColumns;
}
