import {parseNumber} from "../../../util/parsing/parseNumber.js";

export const handleNumberFieldChange = ({value, field, vueComponent}) => {
  const valueStrippedOfAlphaCharactersAndSymbolsExceptDotAndComma =
    value.replace(/[^\d.,-]/g, "");

  let parsedValue = parseNumber({
    value: valueStrippedOfAlphaCharactersAndSymbolsExceptDotAndComma,
  });

  // In case the value to display stays the same, force a re-render because otherwise the input won't update
  vueComponent.$forceUpdate();

  if (Number.isNaN(parsedValue) || value === "")
    return vueComponent.$emit("input", null);

  if (field.MaxNumber && parsedValue > field.MaxNumber)
    parsedValue = field.MaxNumber;
  if (field.MinNumber && parsedValue < field.MinNumber)
    parsedValue = field.MinNumber;

  vueComponent.$emit("input", parsedValue);
};
