<template>
  <div>
    <v-table
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :table-rows-wrapper-component="tableRowsWrapperComponent"
      :loading="showLoadAnimation"
      :client-side-filtering="false"
      :search-values="searchValues"
      :disable-search="true"
      :max-rows="maxRows"
      @search="handleSearch"
      @remove-search="handleRemoveSearch"
      @filter-change="handleFilterChange"
    />
  </div>
</template>

<script>
import VTable from "./VTable.vue";
import {getColumns} from "../../functions/datagrid/columns/getColumns";
import {sortColumns} from "../../functions/datagrid/columns/sortColumns";
import {convertKeyValueRowsToCellRows} from "../../functions/datagrid/rows/convertKeyValueRowsToCellRows";
import {setRowsMetaData} from "../../functions/table/core-rail-road-task/setRowsMetaData";
import {fetchRailRoadTasks} from "../../functions/table/core-rail-road-task/fetchRailRoadTasks";
import {processColumnMetadata} from "../../functions/table/task-list/processColumnMetadata";
import {generateTaskListFilters} from "../../functions/table/task-list/generateTaskListFilters";
import {sortRowsByColumn} from "../../functions/datagrid/rows/sortRowsByColumn";
import {getUserId} from "../../functions/session/getUserId.js";
import {getLocalStorageDataByKey} from "../../functions/session/localstorage/getLocalStorageDataByKey.js";
import {setLocalStorageKey} from "../../functions/session/localstorage/setLocalsStorageKey.js";
import {getActiveWarehouse} from "../../util/getActiveWarehouse.js";

export default {
  name: "TableTaskList",
  components: {
    VTable,
  },
  data() {
    return {
      rows: [],
      columns: [],
      filters: [],
      searchValues: [],
      loading: {},
      maxRows: 250,
      tableRowsWrapperComponent: () => import("./TableRowsWrapperTasks.vue"),
    };
  },
  computed: {
    showLoadAnimation() {
      return Object.values(this.loading).some((l) => l === true);
    },
  },
  async created() {
    this.loading.initial = true;
    let columns = await getColumns({
      table: "Core.RailRoadTask",
      prefix: "Multi",
    });
    columns = processColumnMetadata({columns});

    let rows = await this.fetchAndProcessRows();

    rows = sortRowsByColumn({
      column: columns.find((column) => column.Name === "DateTimeExecuteEnd"),
      rows,
    });

    columns = sortColumns(columns);
    const restoredFilters = this.getStoredFilters();

    this.filters = generateTaskListFilters({
      columns,
      rows,
      filters: restoredFilters ?? [],
      initialValues: restoredFilters === null,
    });

    this.rows = rows;
    this.columns = columns;
    this.loading.initial = false;
  },
  methods: {
    saveFilters({filters}) {
      const userId = getUserId();
      const key = `table-task-filters-${userId}`;

      setLocalStorageKey({key, data: filters});
    },
    getStoredFilters() {
      const userId = getUserId();
      const key = `table-task-filters-${userId}`;
      return getLocalStorageDataByKey({key});
    },
    async customReset() {
      this.rows = await this.fetchAndProcessRows();
    },
    async fetchAndProcessRows() {
      const parsedDateFilters = this.getAndParseActiveDateFilters({
        filters: this.filters,
      });
      const parsedListFilters = this.getAndParseListFilters({
        filters: this.filters,
      });

      const orderBy = "DateTimeExecuteEnd";

      const filters = {
        ...parsedListFilters,
        ...parsedDateFilters,
        Warehouse: getActiveWarehouse(),
      };

      this.loading = {...this.loading, [JSON.stringify(filters)]: true};

      const {tasks} = await fetchRailRoadTasks({
        filters,
        orderBy,
        sortReverse: true,
        size: 250,
      });

      let rows = convertKeyValueRowsToCellRows(tasks);
      rows = setRowsMetaData({rows});

      this.loading = {...this.loading, [JSON.stringify(filters)]: false};

      return rows;
    },

    getAndParseListFilters({filters}) {
      let parsedFilters = {};

      for (const filter of filters) {
        if (filter.Type === "List" && filter.Selected.length) {
          parsedFilters[filter.Target] = filter.Selected.join(",");
        }
      }

      return parsedFilters;
    },
    getAndParseActiveDateFilters({filters}) {
      const dateFilters = filters.filter((f) => f.Type === "Date");
      const parsedFilters = {};

      for (const filter of dateFilters) {
        if (filter.Selected) {
          const {key, value} = this.processFilterValue({filters, filter});
          parsedFilters[key] = value;
        }
      }

      return parsedFilters;
    },
    processFilterValue({filters, filter}) {
      const bothDateFieldsAreSelected =
        filters.filter(
          (f) => f.Type === "Date" && f.Selected !== null && f.Selected !== [],
        ).length === 2;

      if (bothDateFieldsAreSelected && filter.Target === "DateTimeExecuteEnd") {
        return {
          key: `DateTimeExecuteStart <=`,
          value: filter.Selected,
        };
      }

      if (
        bothDateFieldsAreSelected &&
        filter.Target === "DateTimeExecuteStart"
      ) {
        return {
          key: `DateTimeExecuteEnd >=`,
          value: filter.Selected,
        };
      }

      return {
        key: `${filter.Target} ${filter.Suffix}`,
        value: filter.Selected,
      };
    },
    handleSearch(search) {
      if (this.searchValues.includes(search)) {
        return;
      }
      this.searchValues.push(search);
    },
    handleRemoveSearch(search) {
      this.searchValues = this.searchValues.filter((s) => s !== search);
    },
    async handleFilterChange({filter, value}) {
      let newFilters = this.filters.slice();

      newFilters = newFilters.map((f) => {
        if (f.Target === filter.Target) {
          f.Selected = value;
        }
        return f;
      });

      this.filters = [...newFilters];
      this.saveFilters({filters: newFilters});
      this.rows = await this.fetchAndProcessRows();
    },
  },
};
</script>
