import {notify} from "../../../util/notify";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";

export const notifyUserAboutFirstRowMissingRequiredValue = ({
  rows,
  columns,
  missingCellRowsObject,
}) => {
  // Get the UUID of the first row with missing values
  const firstRowUUID = Object.keys(missingCellRowsObject)[0];

  // Find the index of the first row with the missing values
  const rowNumber = rows.findIndex(
    (row) => row.ClientSideUUID.Value === firstRowUUID,
  );

  // Exit function if a matching row was not found
  if (rowNumber === -1) {
    return;
  }

  // Get the first missing value's cell name
  const firstCell = missingCellRowsObject[firstRowUUID][0];

  // Find the corresponding column to get the cell's title
  const firstCellTitle = columns.find(
    (column) => column.Name === firstCell,
  ).Title;

  let translatedMessage = getTranslations().EnterMissingRowValue.replace(
    "{firstCellTitle}",
    `'${firstCellTitle}'`,
  );

  notify({
    message: `${translatedMessage} ${rowNumber + 1}`,
    type: "error",
  });
};
