<template>
  <div ref="item-scanner">
    <v-select
      :placeholder="translations['ItemScanner-AddANewItem']"
      :value="selectedValue"
      :options="options"
      :dropdown-should-open="dropdownShouldOpen"
      :filter-by="filterBy"
      :label="label"
      @option:selected="handleSelect"
      @open="handleSearch"
      @search="handleSearch"
    >
      <div slot="no-options">{{ translations.NoRowsFound }}</div>
      <template #spinner="{loading}">
        <i v-if="loading" class="fas fa-spinner fa-spin text-2xl"></i>
      </template>

      <template #option="option">
        <slot name="select-option" :option="option" :search-value="searchValue">
          {{ option }}
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script>
import {cloneDeep, isEqual, uniqWith} from "lodash";
import vSelect from "vue-select";

export default {
  name: "DatagridScanner",
  components: {
    vSelect,
  },
  inject: ["translations"],
  props: {
    fetchDataFunction: {
      type: Function,
      required: false,
      default: () => {},
    },
    params: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    filterBy: {
      type: Function,
      required: false,
      default: () => true,
    },
    providedOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: "label",
    },
    mergeOptions: {
      type: Boolean,
      required: false,
      default: false,
    },
    settings: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      options: [],
      selectedValue: null,
      searchValue: null,
      queuedSearchValue: null,
    };
  },
  catch(error) {
    console.error("Error occurred during search:", error);
    this.$emit("error", error);
  },
  methods: {
    async handleSearch(searchValue) {
      const input = this.$refs["item-scanner"].querySelector(".vs__search");
      this.$emit("search", searchValue);
      // wait 100 ms for input to be rendered
      setTimeout(() => {
        input.focus();
      }, 100);
      if (this.providedOptions.length) {
        this.options = this.providedOptions;
        return;
      }
      this.queuedSearchValue = searchValue;
      this.searchValue = searchValue;

      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        while (this.queuedSearchValue !== null) {
          const valueToSearch = this.queuedSearchValue;
          this.queuedSearchValue = null;

          const fetchArguments = this.params;

          if (valueToSearch) fetchArguments.search = valueToSearch;
          const newOptions = await this.fetchDataFunction({
            params: fetchArguments,
          });
          let mergedOptions = cloneDeep([...this.options, ...newOptions]);

          if (this.mergeOptions)
            this.options = uniqWith(mergedOptions, isEqual).slice(0, 500);

          if (!this.mergeOptions) this.options = newOptions;

          this.selectedValue = null;
        }
      } catch (error) {
        console.error("Error occurred during search:", error);
      } finally {
        this.loading = false;
      }
    },
    dropdownShouldOpen({open, search}) {
      if (this.settings.FetchScannerItemsByClicking && open) return true;
      return search;
    },
    resetAndFocusSelectBox() {
      this.selectedValue = null;

      // find input in scanElement and focus it
      const input = this.$refs["item-scanner"].querySelector(".vs__search");
      // wait 100 ms for input to be rendered
      setTimeout(() => {
        input.focus();
      }, 100);
    },
    handleSelect(option) {
      const scanValueObject = option;

      if (!option.value) {
        scanValueObject.value = option.label ?? option;
      }

      this.$emit("select", scanValueObject);
      this.resetAndFocusSelectBox();
    },
  },
};
</script>
