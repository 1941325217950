import {getTranslations} from "@/functions/session/getTranslations";
import {notify} from "@/util/notify";

export function setRowDateTimeDesiredDelivery({
  row,
  differenceInDays,
  expectedDeliveryDays,
  itemExpectedDeliveryDays,
}) {
  const translations = getTranslations();
  const {DateTimeDesiredDelivery, DateTimeOrdered} = row;

  if (differenceInDays < 0) {
    notify({message: translations.DeliveryBeforeOrderDate, type: "warning"});
    return {
      ...row,
      DateTimeDesiredDelivery: {
        Value: DateTimeOrdered.Value,
        Icon: "fa-exclamation-triangle",
        IconColor: "warning",
        IconMessage: translations.DeliveryBeforeOrderDate,
      },
    };
  }

  //If expectedDeliveryDays comes from the Item
  if (
    differenceInDays < expectedDeliveryDays &&
    expectedDeliveryDays >= 1 &&
    itemExpectedDeliveryDays
  ) {
    return {
      ...row,
      DateTimeDesiredDelivery: {
        Value: DateTimeDesiredDelivery.Value,
        Icon: "fa-exclamation-triangle",
        IconColor: "warning",
        IconMessage: translations.DeliveryBeforeExpectedItemDeliveryDate,
      },
    };
  }

  //If expectedDeliveryDays comes from the Supplier
  if (
    differenceInDays < expectedDeliveryDays &&
    expectedDeliveryDays >= 1 &&
    !itemExpectedDeliveryDays
  ) {
    return {
      ...row,
      DateTimeDesiredDelivery: {
        Value: DateTimeDesiredDelivery.Value,
        Icon: "fa-exclamation-triangle",
        IconColor: "warning",
        //TODO: Check translation
        IconMessage: translations.DeliveryBeforeExpectedSupplierDeliveryDate,
      },
    };
  }

  if (differenceInDays > 0 && !expectedDeliveryDays) {
    return {
      ...row,
      DateTimeDesiredDelivery: {
        Value: DateTimeDesiredDelivery.Value,
        Icon: null,
        IconColor: null,
        IconMessage: null,
      },
    };
  }

  if (differenceInDays >= expectedDeliveryDays) {
    return {
      ...row,
      DateTimeDesiredDelivery: {
        Value: DateTimeDesiredDelivery.Value,
        Icon: null,
        IconColor: null,
        IconMessage: null,
      },
    };
  }
}
