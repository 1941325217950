import {getCustomerDimensionObjects} from "@/services/v2/customers/dimensions/getCustomerDimensionObjects";

export async function fetchDimensionObjects({customerId}) {
  const dimensionCollection = await getCustomerDimensionObjects({
    customerId,
    IsObjectNode: true,
  });

  return dimensionCollection.Collection;
}
