import {handleItemTypeSalesChangeOnPeriodSelection} from "./handleItemTypeSalesChangeOnPeriodSelection.js";
import Decimal from "decimal.js";

export function handleItemTypeSalesRowChange({newRow, oldRow}) {
  if (
    oldRow &&
    (oldRow.ItemType.Value?.Key === "Sales" ||
      oldRow.ItemType.Value === "Sales")
  ) {
    newRow.DateTimeExpectedStart.IsReadOnly = false;
    newRow.DateTimeBusinessStart.IsReadOnly = false;
    newRow.TotalExcVAT.Value = new Decimal(0).toNumber();
    newRow.TotalIncVAT.Value = new Decimal(0).toNumber();
    newRow.TotalVAT.Value = new Decimal(0).toNumber();

    newRow.ItemType = {
      ...newRow.ItemType,
      Value: {
        Description: newRow.ItemType.Text,
        Key: newRow.ItemType.Value,
      },
    };
    newRow.Price = {
      ...newRow.Price,
      Value: 0,
      IsReadOnly: true,
    };
    newRow.DateTimeExpectedEnd = {
      ...newRow.DateTimeExpectedEnd,
      Value: newRow.DateTimeExpectedEnd.OldValue,
      IsReadOnly: false,
    };

    if (newRow?.OrderID) {
      newRow.LockPrice.IsReadOnly = false;
      newRow.LockPrice.Value = false;
      newRow.CalculatedPrice.Value = 0;
    }

    newRow = handleItemTypeSalesChangeOnPeriodSelection({row: newRow});
  }

  return newRow;
}
