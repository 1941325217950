import clone from "clone";
import Combobox from "../interface/combobox.class";
import customParseFormat from "dayjs/plugin/customParseFormat";

const dayjs = require("dayjs");

dayjs.extend(customParseFormat);

/** Server data formatter
 * @property {Object} parsers - Parsers, convert data from server to be used by client
 * @property {Object} serializers - Serializers, format data to be used by server
 */
class Formatter {
  /**
   * @property {function} DateTime - dayjs -> "DD-MM-YYYY HH:mm"
   * @property {function} Date - dayjs -> "DD-MM-YYYY"
   * @property {function} Money - $0.00, with variable precision based on col.NumericScale(default:2)
   * @property {function} Decimal - number with variable precision based on col.NumericScale(default:3)
   * @property {function} Int - Integer
   * @property {function} String - String
   * @property {function} Text - String with no html support
   * @property {function} Password - String
   */
  parsers = {
    DateTime: (s) => {
      if (!s) return null;
      if (s.startsWith("/Date(")) {
        const unixMilisecondsTimeStamp = s.split(/[\(\)]/)[1];
        const unixTimeStamp = unixMilisecondsTimeStamp.substring(
          0,
          unixMilisecondsTimeStamp.length - 3,
        );

        return dayjs.unix(unixTimeStamp).format("DD-MM-YYYY HH:mm");
      }
      return s ? dayjs(s).format("DD-MM-YYYY HH:mm") : null;
    },
    Date: (s) => {
      if (!s) return null;
      if (typeof s === "string" && s.startsWith("/Date(")) {
        const unixMilisecondsTimeStamp = s.split(/[\(\)]/)[1];
        const unixTimeStamp = unixMilisecondsTimeStamp.substring(
          0,
          unixMilisecondsTimeStamp.length - 3,
        );

        return dayjs.unix(unixTimeStamp).format("DD-MM-YYYY");
      }
      return s ? dayjs(s).format("DD-MM-YYYY") : null;
    },
    Money: (s, col) =>
      s != null ? (Number(s) || 0).toFixed(col.NumericScale || 2) : null,
    Decimal: (s, col) =>
      s != null ? (Number(s) || 0).toFixed(col.NumericScale || 3) : null,
    Int: (s) => (s != null ? Number((Number(s) || 0).toFixed()) : null),
    String: (s) => (s != null && String(s)) || null,
    Text: (s) => (s != null && String(s)) || null,
    Password: (s) => (s != null && String(s)) || null,
    StringifiedObject: (s) =>
      (s != null && s != "" && JSON.stringify(s)) || null,
  };

  /**
   * @property {function} DateTime - dayjs -> "DD-MM-YYYY HH:mm" -> "YYYY-MM-DD HH:mm:ss"
   * @property {function} Date - dayjs -> "DD-MM-YYYY" -> "YYYY-MM-DD"
   * @property {function} Money - $0.00, with variable precision based on col.NumericScale(default:2)
   * @property {function} Decimal - number with variable precision based on col.NumericScale(default:3)
   * @property {function} Int - Integer
   * @property {function} String - String
   * @property {function} Text - String with no html support
   * @property {function} Password - String
   */
  serializers = {
    DateTime: (s) =>
      s
        ? this.parseDate(s, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss")
        : null,
    Date: (s) =>
      s ? this.parseDate(s, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
    Money: (s, col) => {
      if (s == null || s === "") return null;

      // If it's already a number, no need for string replacement
      const value =
        typeof s === "number" ? s : Number(s.replace(",", ".")) || 0;

      return Number(value.toFixed(col.NumericScale || 2));
    },
    Decimal: (s, col) =>
      s != null && s != ""
        ? Number((Number(s) || 0).toFixed(col.NumericScale || 3))
        : null,
    Int: (s) => (s != null ? Number((Number(s) || 0).toFixed()) : null),
    String: (s) => (s != null && String(s)) || null,
    Text: (s) => (s != null && String(s)) || null,
    Password: (s) => (s != null && String(s)) || null,
    Object: (s) => (s != null && s != "" && this.tryParse(s)) || null,
  };

  tryParse(value) {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }

  /**
   * Converts Unix timestamp in miliseconds to one in seconds, dayjs does not directly support the miliseconds variant (momentjs did)
   * @param {string} string
   * @returns  Unix timestamp
   */
  unixMilisecondsToUnix(string) {
    const unixMilisecondsTimeStamp = string.split(/[\(\)]/)[1];
    const unixTimeStamp = unixMilisecondsTimeStamp.substring(
      0,
      unixMilisecondsTimeStamp.length - 3,
    );

    return unixTimeStamp;
  }

  /**
   * Parse Date
   * @param {string} str - Date string
   * @param {string} humanNotation - Notation
   * @returns {Object} dayjs date object
   * @private
   */
  parseDate(str, humanNotation) {
    // If server send unix timestamp with encapsulated in /Date(VAL)
    let humanForm = dayjs(str, humanNotation);

    if (humanForm.isValid()) {
      return humanForm;
    } else if (humanNotation == "DD-MM-YYYY HH:mm") {
      humanForm = dayjs(str, "DD-MM-YYYY");

      if (humanForm.isValid()) {
        return humanForm;
      }
    }

    return dayjs(str);
  }

  /**
   * Add a datepart to the given data
   * @param {string} datepart - The part of date to which dateAdd adds an integer number. For example 'days'
   * @param {int} number - An expression that can resolve to an int that dateAdd adds to a datepart of date.
   * @param {string} date - string containing a date
   * @returns {string} formatted datetime object
   */
  dateAdd(datepart, number, date) {
    let newDate = dayjs(date).add(number, datepart);

    return newDate;
  }

  /**
   * Mutates a date- or datetime-object by setting it to the start of a unit of time.
   * @param {string} datepart - The part of date to which startOf will mutate
   * @param {string} date - string containing a date or datetime
   * @returns {string} formatted datetime object
   */
  startOf(datepart, date) {
    let newDate = dayjs(date).startOf(datepart);

    return newDate;
  }

  /**
   * Mutates a date- or datetime-object by setting it to the end of a unit of time.
   * @param {string} datepart - The part of date to which endOf will mutate
   * @param {string} date - string containing a date or datetime
   * @returns {string} formatted datetime object
   */
  endOf(datepart, date) {
    let newDate = dayjs(date).endOf(datepart);

    return newDate;
  }

  /**
   * Join all the keys from an object into a string seperated by a comma
   * Result is sorted
   * @param {} obj - object containing keys (properties)
   */
  joinKeys(obj) {
    return this.join(
      Object.keys(obj)
        .map(function (k) {
          return k;
        })
        .sort(),
    );
  }

  /**
   * Join all the values from an object into a string seperated by a comma
   * Result is sorted
   * @param {} obj - object containing keys (properties) with values
   */
  joinValues(obj) {
    return this.join(
      Object.keys(obj)
        .map(function (k) {
          return obj[k];
        })
        .sort(),
    );
  }

  /**
   * Join all the descriptions from an object into a string seperated by a comma
   * Result is sorted
   * @param {} obj - object containing keys (properties) with values
   */
  joinDescription(obj) {
    return this.join(
      Object.keys(obj)
        .map(function (k) {
          return obj[k].description;
        })
        .sort(),
    );
  }

  /**
   * Join an array to a string seperated by a comma
   * @param {*} arr - array to be seperated
   */
  join(arr) {
    return arr
      .join(", ")
      .trim()
      .replace(/(^,)|(,$)/g, "")
      .trim();
  }

  /**
   * Returns true if val is a json object
   * @param {} val - Value to parse
   */
  isJson(val) {
    val = typeof val !== "string" ? JSON.stringify(val) : val;

    try {
      val = JSON.parse(val);
    } catch (e) {
      return false;
    }

    if (typeof val === "object" && val !== null) {
      return true;
    }

    return false;
  }

  /**
   * Parse value
   * @param {Object} col - Column
   * @param {*} val - Value
   * @returns {*} Parsed value
   */
  parseValue(col, val) {
    let fn = this.parsers[this.getParserType(col)];

    return fn ? fn(val, col) : val;
  }

  getParserType(col) {
    if (col.Editor === "upload") return "StringifiedObject";
    return col.Type;
  }

  /**
   * Serialize value
   * @param {Object} col - Column
   * @param {*} val - Value
   * @returns {*} Serialized value
   */
  serializeValue(col, val) {
    let fn = this.serializers[col.Type];
    return fn ? fn(val, col) : val;
  }

  /**
   * Apply metadata to the column
   * @param {Object} column - Column values
   * @param {Object} rowMetadata - Metadata values
   * @returns {Object} combined output
   */
  applyMetadata(column, rowMetadata) {
    let output = {};

    for (let columnAttributeName in column) {
      output[columnAttributeName] = column[columnAttributeName];
    }

    let rowWideMetadata = rowMetadata["*"] || {};

    for (let columnAttributeName in rowWideMetadata) {
      output[columnAttributeName] = rowWideMetadata[columnAttributeName];
    }

    let columnMetadata = rowMetadata[column.Name] || {};

    for (let columnAttributeName in columnMetadata) {
      output[columnAttributeName] = columnMetadata[columnAttributeName];
    }

    output.BaseType = column.Type;

    return output;
  }

  /**
   * Apply metadata on row
   * @param {Object} columns - Columns (format {columname: column})
   * @param {Object} rowMetadata - Metadata values for row
   * @returns {Object} metadata for row
   */
  applyMetadataRow(columns, rowMetadata) {
    let output = {};

    for (let columnName in columns) {
      output[columnName] = this.applyMetadata(columns[columnName], rowMetadata);
    }

    return output;
  }

  /**
   * Calculate pagination data
   * @param {string} currentPageStr - Current page string
   * @param {number} numPages - Number of pages
   * @param {number} maxItems - Max items per page
   * @returns {Object} Pagination data
   * @private
   */
  paginate(currentPageStr, numPages, maxItems = 5) {
    let currentPage = Math.min(Number(currentPageStr), numPages + 1);

    let half = Math.floor(maxItems / 2);
    let mostLeft = Math.max(currentPage - half, 1);
    let mostRight = Math.min(mostLeft + maxItems - 1, numPages);
    let sequence = [];

    for (let i = mostLeft; i <= mostRight; i++) {
      sequence.push({
        current: i === currentPage,
        page: i,
      });
    }

    return {
      first: currentPage > 1 ? 1 : false,
      prev: currentPage > 1 ? currentPage - 1 : false,
      current: currentPage,
      sequence: sequence,
      next: currentPage < numPages ? currentPage + 1 : false,
      last: currentPage < numPages ? numPages : false,
    };
  }

  /**
   * Big ol' messy formatting of server data
   * @param {Window} window - Window
   * @param {Object} data - Server output
   * @returns {Object} Formatted data
   * @private
   */
  formdata(window, data) {
    data.serverData = data.Data;
    let copiedColumns = clone(data.Data.Columns);
    let columns = Object.keys(copiedColumns)
      .map((x) => copiedColumns[x])
      .sort((a, b) => a.Ranking - b.Ranking)
      .map((info, index) => {
        info.OrderType =
          data.Request.Data && data.Request.Data.SortOn === info.Name
            ? data.Request.Data.SortReverse
              ? "DESC"
              : "ASC"
            : null;
        info.Index = index;
        info.window = window;
        info.getDropdownText = (val) =>
          (
            (info.Dropdown &&
              info.Dropdown.Items &&
              info.Dropdown.Items.filter((x) => x.Value == val).pop()) ||
            {}
          ).Text;
        return info;
      })
      .filter(Boolean);

    let rows = (data.Data.Rows || []).map((row, rowIndex) => {
      let rowMetadata =
        (data.Data.Metadata && data.Data.Metadata[rowIndex]) || {};

      return columns
        .map((x) => row[x.Name])
        .map((colValue, colIndex) => {
          let fullMetaColumn = this.applyMetadata(
            columns[colIndex],
            rowMetadata,
          );

          let cell = {
            Column: fullMetaColumn,
            SerializedValue: colValue,
            Value: this.parseValue(fullMetaColumn, colValue),
            Initial: null,
            Type: null,
            IsDirty: data.Prefix === "New",
            NewValue:
              data.Prefix === "New"
                ? this.parseValue(fullMetaColumn, colValue)
                : null,
            Combobox: null,
          };

          if (cell.Column.Dropdown) {
            cell.Initial = {
              Description: cell.Column.Description,
              Value: cell.Value,
            };

            // Remove open-row if open-row was set for a row with a value where a matching description was not found in the related table
            if (
              cell.Value &&
              !cell.Column.Description &&
              cell.Column.CustomClass
            ) {
              cell.Column.CustomClass = cell.Column.CustomClass.replace(
                "open-row",
                "",
              );
            }
          }

          if (cell.Column.Dropdown) {
            cell.Combobox = Combobox.new(cell, null);
          }

          cell.NewValue = cell.Initial ? cell.Initial.Value : cell.Value;

          if (cell.NewValue === "") cell.NewValue = null;

          return cell;
        });
    });

    data.FullTable = {
      Columns: columns,
      Rows: rows,
    };

    data.Table = {
      Columns: columns.filter((c) => c && c.IsVisible),
      Rows: rows.map((r) => r.filter((c) => c.Column.IsVisible)),
      identifiedRows: [], // Added so row cells can be referenced by their column for detail(form) views TODO: generate this array in DataCore backend
    };

    if (data.Table.Rows[0]) {
      for (const row of data.Table.Rows[0]) {
        if (row.Column.Name !== undefined) {
          data.Table.identifiedRows[row.Column.Name] = row;
        }
      }
    }

    if (window.output.Request && window.output.Request.Prefix == "New") {
      data.FullTable.ColumnsToSelect = data.FullTable.Columns.filter(
        (c) => c && c.Ranking > 0 && !c.IsRequired,
      );
    } else {
      data.FullTable.ColumnsToSelect = data.FullTable.Columns.filter(
        (c) => c && c.Ranking > 0,
      );
    }

    data.Totals =
      data.Data &&
      data.Data.Totals &&
      Object.keys(data.Data.Totals)
        .map((x) => data.Data.Totals[x])
        .sort((a, b) => a.Ranking - b.Ranking)
        .filter((x) => x.Ranking >= 0);

    if (
      data.Data &&
      data.Data.Totals &&
      data.Data.Totals.RowCount &&
      data.Request &&
      data.Request.Data &&
      data.Request.Data.PageSize
    ) {
      let numPages = Math.ceil(
        data.Data.Totals.RowCount.Value / data.Request.Data.PageSize,
      );

      if (numPages > 1) {
        data.pagination = this.paginate(
          data.Request.Data.PageNumber || 1,
          numPages,
        );
      }
    }

    data.selectAll = false;
    data.selected = [];

    /* data.Options.OpenRow is enumaration: Default, Disabled and Always
      - Default: Only open row in multi and non-bulkedit-mode
      - Disabled: Never open a row
      - Always: On click of a row (attr data-row-index) always open a row (ignore prefix and mode)
    */
    if (data.Options.OpenRow === null) {
      data.Options.OpenRow = data.Request.Prefix == "Multi" ? 1 : 0;
    }

    if (data.Options.DisableOpenRow) {
      data.Options.OpenRow = 0;
    }

    if (data.Data.Sections) {
      for (const section in data.Data.Sections) {
        data.Data.Sections[section].Columns = Object.values(
          data.Data.Sections[section].Columns,
        ).sort((column, newColumn) =>
          column.Ranking > newColumn.Ranking ? 1 : -1,
        );
      }
    }

    return data;
  }

  /**
   * Converts a unix timestamp in miliseconds to a date string YYYY-MM-DD
   * @param {string} unixTimeStamp
   * @returns {string} Date string in YYYY-MM-DD format
   */
  unixMilisecondsToDateString(unix) {
    if (!unix) return null;
    if (unix.startsWith("/Date(")) {
      const unixMilisecondsTimeStamp = unix.split(/[\(\)]/)[1];
      const unixTimeStamp = unixMilisecondsTimeStamp.substring(
        0,
        unixMilisecondsTimeStamp.length - 3,
      );

      return dayjs.unix(unixTimeStamp).format("YYYY-MM-DD");
    }
  }

  /**
   * Converts a unix timestamp in miliseconds to a date string YYYY-MM-DD
   * @param {string} unixTimeStamp
   * @returns {Date} Date string in YYYY-MM-DD HH:MM format
   */
  unixMilisecondsToDateObject(unix) {
    if (!unix) return null;
    if (unix.startsWith("/Date(")) {
      const unixMilisecondsTimeStamp = unix.split(/[\(\)]/)[1];
      const unixTimeStamp = unixMilisecondsTimeStamp.substring(
        0,
        unixMilisecondsTimeStamp.length - 3,
      );

      return dayjs.unix(unixTimeStamp).toDate();
    }
  }

  /**
   * Format window
   * @param {Window} window - window object
   * @returns {void}
   */
  format(window) {
    if (window.output === null || typeof window.output != "object") {
      return;
    }

    if (
      window.output.Data &&
      window.output.Data.Columns &&
      window.output.Data.Rows
    ) {
      window.output = this.formdata(window, window.output);
    }
  }
}

/** @ignore */
export default new Formatter();
