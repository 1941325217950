export const cancelSerialAmountPickedChangeAboveOne = ({row, oldRow}) => {
  if (row.UseSerials.Value !== true) {
    return row;
  }
  if (row.PickedQuantity.Value > 1) {
    return {
      ...row,
      PickedQuantity: {
        ...row.PickedQuantity,
        Value: oldRow.PickedQuantity.Value,
      },
    };
  }
  return row;
};
