export function setClosedRowPropsReadOnly({row}) {
  if (row.Status.Value === "Closed") {
    const newRow = {...row};
    // Loop over every property in row and set IsReadOnly to true
    for (const property in row) {
      row[property].IsReadOnly = true;
    }
    return newRow;
  }
  return row;
}
