import {applyPercentage} from "./applyPercentage";

export const getPriceForPriceCondition = ({priceCondition, currentPrice}) => {
  if (priceCondition.PriceType === "Percentage") {
    return applyPercentage({
      value: currentPrice,
      percentage: priceCondition.Percentage,
    });
  }
  if (priceCondition.PriceType === "Fixed") {
    return priceCondition.Price;
  }
  return null;
};
