<template>
  <div class="mb-2">
    <excel-import-wizard
      v-if="button.ActionName === 'ExcelImportDialogue'"
      :key="button.ActionName"
      :ref="button.ActionName"
      :title="button.Title"
      :icon="button.Icon"
      :config="button.Options"
      :action="button.ActionName"
      :full-table-name="subject"
      :disabled="loading"
      :block="true"
    />
    <action-setup-two-factor-q-r
      v-else-if="button.ActionName === 'CreateNewQr'"
      :key="button.ActionName"
      :ref="button.ActionName"
      :title="button.Title"
      :icon="button.Icon"
      :action="button.ActionName"
      :full-table-name="subject"
      :disabled="loading"
      :block="true"
    />
    <r-button
      v-else-if="button.IsVueAction"
      :key="button.ActionName"
      :ref="button.ActionName"
      :disabled="loading"
      :action="button.ActionName"
      class="w-full whitespace-pre-line"
      :variant="variant"
      @click="handleClick"
      ><div class="float-left" v-html="button.Icon" />
      {{ title }}
    </r-button>
    <r-button
      v-else-if="
        (button.GlobalActions && button.GlobalActions.length) ||
        (button.ComponentActions && button.ComponentActions.length) ||
        button.JsFunctionPayload
      "
      :key="button.ActionName"
      :ref="button.ActionName"
      class="w-full whitespace-pre-line"
      :variant="variant"
      @click="$emit('process-actions', button)"
    >
      <div class="float-left" v-html="button.Icon" />
      {{ title }}
    </r-button>
    <r-button
      v-else-if="button.ActionName === 'SaveAction'"
      :key="button.ActionName"
      :ref="button.ActionName"
      :data-window-target="button.Target || subWindowId || window.id"
      :disabled="loading"
      :action="button.ActionName"
      class="w-full whitespace-pre-line"
      :variant="variant"
      ><div class="float-left" v-html="button.Icon" />
      {{ title }}
    </r-button>
    <r-button
      v-else
      :key="button.ActionName"
      :ref="button.ActionName"
      :data-window-event="button.Event"
      :data-window-target="button.Target || subWindowId || window.id"
      :disabled="loading"
      :action="button.ActionName"
      class="w-full whitespace-pre-line"
      :variant="variant"
      ><div class="float-left" v-html="button.Icon" />
      {{ title }}
    </r-button>
  </div>
</template>

<script>
import {debounce} from "lodash";
import ActionSetupTwoFactorQR from "./actions/ActionSetupTwoFactorQR.vue";
import ExcelImportWizard from "../../elements/ExcelImportWizard.vue";
import RButton from "../../elements/RButton.vue";
import {v4 as uuidv4} from "uuid";

export default {
  name: "ActionBarAction",
  components: {ActionSetupTwoFactorQR, ExcelImportWizard, RButton},
  inject: ["translations"],
  props: {
    button: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    window: {
      type: Object,
      default: null,
    },
    subWindowId: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    windowId: {
      type: String,
      default: null,
    },
    activeWindowId: {
      type: String,
      default: null,
    },
    activeSubWindowId: {
      type: String,
      default: null,
    },
  },
  computed: {
    providedTranslations() {
      return this.translations.translations;
    },
    title() {
      return (
        this.button.Title ??
        this.providedTranslations[this.button.TitleTranslationKey]
      );
    },
    variant() {
      return this.button.DisplayType ?? "secondary";
    },
  },
  methods: {
    handleClick: debounce(
      function () {
        this.$emit("new-job", {
          id: uuidv4(),
          actionName:
            this.button.ActionName.charAt(0).toLowerCase() +
            this.button.ActionName.slice(1),
          dateTimeCreated: new Date(),
          windowId: this.activeSubWindowId ?? this.activeWindowId,
          context: {
            button: this.button,
          },
        });
      },
      1000,
      {leading: true, trailing: false},
    ),
  },
};
</script>
