import {openWindow} from "@/services/admin/webservices/corewebservices/openWindow";
import store from "@/../state/store";

export async function valueClickOpenRef({cell, column}) {
  const subject = column.Dropdown.TableName;
  const criteria = [{[column.Name]: cell.Value.Key || cell.Value}];

  const request = {
    Subject: subject,
    Criteria: criteria,
    Prefix: "Single",
  };
  store.commit("setLoading", true);
  const result = await openWindow({request});

  await global.session.activeWindow.insertWindow(
    result,
    false,
    false,
    false,
    false,
  );
  store.commit("setLoading", false);
}
