import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

/**
 * Converts a unix timestamp in miliseconds to a date or datetime string based on the current setting
 * @param {string} unixTimeStamp
 * @returns {string} Date string in YYYY-MM-DD format or Date time string in YYYY-MM-DD HH:mm format
 */
export function unixMilisecondsToDayjs(unix) {
  if (!unix) return null;
  if (unix.startsWith("/Date(")) {
    const unixMilisecondsTimeStamp = unix.split(/[\(\)]/)[1];
    const unixTimeStamp = unixMilisecondsTimeStamp.substring(
      0,
      unixMilisecondsTimeStamp.length - 3,
    );

    return dayjs.unix(unixTimeStamp);
  }
}
