import {api} from "@/services/api";

export default async function getOrderItemsConditionStockPrediction({
  orderItems,
  orderId,
}) {
  return await api.post(
    `/v2/rental/order/${orderId}/_stock-prediction/conditions`,
    orderItems,
  );
}
