import {mergeValueOrValueObject} from "./mergeValueOrValueObject";
import {cloneDeep} from "lodash";
import {processValueChanges} from "./processValueChanges";
import {processRowsChanges} from "./processRowsChanges";

export const processFieldUpdate = async ({
  row,
  fieldName,
  value,
  columns,
  rows,
  processContext,
  cell,
}) => {
  const rowUUID = row.ClientSideUUID.Value;
  const index = rows.findIndex((x) => x.ClientSideUUID.Value === rowUUID);

  const oldRow = cloneDeep(rows[index]);
  let newRows = cloneDeep(rows);

  let newRow = {
    ...newRows[index],
    [fieldName]: mergeValueOrValueObject({
      oldValue: newRows[index][fieldName],
      newValue: value,
      cell,
    }),
  };

  const column = columns.find((x) => x.Name === fieldName) ?? {};

  newRows = newRows.map((currentRow, currentIndex) =>
    currentIndex === index ? newRow : currentRow,
  );

  try {
    newRows = await processValueChanges({
      rows: newRows,
      index,
      column,
      oldRow,
      cell,
      context: processContext,
    });
  } catch (error) {
    console.error("Error during processing value changes:", error);
  }

  try {
    newRows = await processRowsChanges({
      rows: newRows,
      row: newRow ?? row,
      column,
      context: processContext,
    });
  } catch (error) {
    console.error("Error during processing rows changes:", error);
  }

  return newRows;
};
