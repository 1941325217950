<template>
  <div class="tree-select-wrapper h-full">
    <tree-select
      ref="r-tree-select"
      class="r-tree-select h-full"
      :value="value"
      :options="options"
      :disabled="readonly"
      :loading="loading"
      :multiple="true"
      :placeholder="placeholder"
      :no-results-text="translations.NoRowsFound"
      :no-options-text="translations.NoDataFound"
      :no-children-text="translations.NoDataFound"
      @input="handleInput($event)"
      @open="$emit('open')"
    >
      <label
        slot="value-label"
        slot-scope="{node}"
        :class="'mb-0'"
        :title="node.label"
      >
        {{ node.label.substring(0, 8) + "..." }}
      </label>
    </tree-select>
  </div>
</template>

<script>
import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import TreeSelect from "vue-treeselect/dist/vue-treeselect.cjs.js";
import {debounce} from "lodash";
import "vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "RTreeSelect",
  components: {TreeSelect},
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    inputId: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    translations() {
      return getTranslations();
    },
  },
  mounted() {
    const inputElement = this.$refs["r-tree-select"].$el.querySelector("input");
    if (inputElement) inputElement.id = this.inputId;
  },
  methods: {
    handleInput: debounce(function (value) {
      this.$emit("input", value);
    }, 100),
  },
};
</script>

<style lang="scss">
.r-tree-select .vue-treeselect__control {
  height: 100% !important;
}

.tree-select-wrapper {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.vue-treeselect--disabled {
  .vue-treeselect__control {
    background-color: #eeeeee;
    cursor: not-allowed;
  }
  .vue-treeselect__control-arrow {
    display: none;
  }
}

.vue-treeselect__control-arrow-container {
  height: 30.5px;
}

.vue-treeselect__multi-value-item-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.r-tree-select .vue-treeselect__value-container {
  overflow-x: scroll;
  overflow-y: visible;
  caret-color: transparent;
  align-items: center;
}

.r-tree-select .vue-treeselect__multi-value {
  margin: 0.1rem 0 !important;
}

.vue-treeselect__multi-value-label {
  max-width: 100px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
