import {openReturnItemScanbox} from "./openReturnItemScanbox.js";
import $ from "jquery";

export function resetReturnItemScanbox({vueComponent}) {
  if (vueComponent) vueComponent.formFields.Scan.Value = "";

  const scanboxElement = $('[name="Scan"] input');

  scanboxElement.value = "";
  scanboxElement.blur();

  openReturnItemScanbox();
}
