<template>
  <div class="w-100">
    <date-range-picker
      ref="picker"
      v-model="dateRange"
      class="float-right"
      :locale-data="dataFormat"
      :date-format="dateFormat"
      :show-dropdowns="true"
      :time-picker="true"
      :time-picker24-hour="true"
      :time-picker-increment="1"
      :auto-apply="false"
      :ranges="false"
      :single-date-picker="true"
      opens="right"
      @update="handleChange"
      @change-month="updateClosingDays($event)"
    >
    </date-range-picker>

    <date-field
      :date="exposedStartDate"
      :show-time="true"
      :original-value="value"
      :readonly="readonly"
      :required="isRequired"
      vue-input
      :random-i-d="randomID"
      :disabled-dates="disabledDates"
      @change="updateStartDate($event)"
    >
      <r-button
        v-if="readonly != 'true'"
        class="z-10 ml-[-35px]"
        variant="link"
        @click="togglePicker()"
        ><i class="fas fa-calendar-alt text-dark"></i></r-button
    ></date-field>

    <input
      :id="randomID"
      v-model="exposedStartDate"
      vue-input
      :name="name"
      vue-date-time-field
      hidden
    />
  </div>
</template>

<script>
import {parseDateTime} from "../../util/parsing/parseDateTime.js";
import DateRangePicker from "../calendar/DateRangePicker.vue";

import dayjs from "dayjs";
import generateRandomID from "../../util/generateRandomID";
import dateField from "./dateField.vue";
import RButton from "./RButton.vue";

import {getColumnMetaDataFromWindowId} from "../../functions/window/getColumnMetaDataFromWindowId";
import {nonActionAxiosInstance} from "../../services/nonActionAxiosInstance";

export default {
  name: "DateTime",
  components: {"date-range-picker": DateRangePicker, dateField, RButton},
  props: {
    value: {
      type: String,
      required: true,
    },
    today: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    postback: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterOnOpeningsDay: {
      type: String,
      required: false,
      default: "false",
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: String,
      required: false,
    },
    windowId: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dateRange: {
        startDate: "",
        endDate: "",
      },
      exposedStartDate: "",
      disabledDates: [],
      checkedDates: [],
      metadata: {},
    };
  },
  computed: {
    startDate: function () {
      if (this.dateRange.startDate) {
        return dayjs(this.dateRange.startDate).format("DD-MM-YYYY HH:mm");
      }
      return "";
    },
    isRequired: function () {
      return this.required ?? this.metadata.IsRequired;
    },
    randomID: function () {
      return generateRandomID(8);
    },
    translations: function () {
      return this.$store.state.translations;
    },
    dataFormat: function () {
      return {
        direction: "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        changed: false,
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: [
          this.translations["calendar-sun"],
          this.translations["calendar-mon"],
          this.translations["calendar-tue"],
          this.translations["calendar-wed"],
          this.translations["calendar-thu"],
          this.translations["calendar-fri"],
          this.translations["calendar-sat"],
        ],
        monthNames: [
          this.translations["calendar-jan"],
          this.translations["calendar-feb"],
          this.translations["calendar-mar"],
          this.translations["calendar-apr"],
          this.translations["calendar-may"],
          this.translations["calendar-jun"],
          this.translations["calendar-jul"],
          this.translations["calendar-aug"],
          this.translations["calendar-sep"],
          this.translations["calendar-oct"],
          this.translations["calendar-nov"],
          this.translations["calendar-dec"],
        ],
        firstDay: 0,
      };
    },
  },
  watch: {
    async startDate() {
      this.$nextTick(() => {
        if (this.changed) {
          this.processChange();

          let $this = this;
          $.when($(document).find(`#${this.randomID}`).trigger("change")).done(
            function () {
              if ($this.postback == true) {
                $this.runPostback();
              }
              (
                global.session.activeWindow?.sub?.window ??
                global.session.activeWindow
              ).dirty = true;
              return;
            },
          );
        }
        this.changed = true;
      }, this);
    },
  },
  created() {
    let parsedDate = parseDateTime({dateString: this.value});

    if (parsedDate) {
      this.dateRange.startDate = dayjs(parsedDate).format("YYYY-MM-DD HH:mm");
      this.updateClosingDays(parsedDate);
    } else {
      this.updateClosingDays();
    }

    if (dayjs(parsedDate).format("DD-MM-YYYY") !== "Invalid Date") {
      this.exposedStartDate = dayjs(parsedDate).format("DD-MM-YYYY HH:mm");
    }

    this.metadata = getColumnMetaDataFromWindowId({
      windowId: this.windowId,
      columnName: this.name,
      session: global.session,
    });
  },
  methods: {
    handleChange({startDate}) {
      this.changed = true;

      if (dayjs(startDate).format("DD-MM-YYYY HH:mm") !== "Invalid Date") {
        this.exposedStartDate = dayjs(startDate).format("DD-MM-YYYY HH:mm");
      }
    },
    updateStartDate(val) {
      this.changed = true;
      if (val === "") {
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
        this.exposedStartDate = null;
      }

      this.dateRange.startDate = dayjs(val).toDate();
      this.dateRange.endDate = dayjs(val).toDate();

      if (
        dayjs(this.dateRange.startDate).format("DD-MM-YYYY HH:mm") !==
        "Invalid Date"
      ) {
        this.exposedStartDate = null;
        this.exposedStartDate = dayjs(this.dateRange.startDate).format(
          "DD-MM-YYYY HH:mm",
        );
      }
    },
    togglePicker() {
      this.$refs.picker.openPicker();
    },
    processChange() {
      const values = {};
      const tableRow =
        this.$store.state.window.output?.FullTable?.Rows[0] ??
        this.$store.state.window.output.Sub?.Table?.Rows[0];

      if (tableRow) {
        for (let rowcell of tableRow) {
          values[rowcell.Column.Name] = rowcell.NewValue;

          if (rowcell.Column.Name === this.name) {
            rowcell.NewValue = this.startDate;
          }
        }
      }

      values[this.name] = this.startDate;

      return values;
    },
    runPostback() {
      const values = this.processChange();

      Promise.resolve(global.session.activeWindow.postbackValues(values));
    },
    dateFormat(classes, date) {
      if (this.disabledDates.length > 0) {
        for (const disabledDate of this.disabledDates) {
          if (dayjs(disabledDate).isSame(dayjs(date), "day")) {
            classes.disabled = true;
          }
        }
      }
      return classes;
    },
    updateClosingDays: async function (date) {
      if (this.filterOnOpeningsDay === "false") {
        return;
      }

      if (!date) {
        date = new Date();
      }

      if (this.checkedDates.includes(dayjs(date).format("YYYY-MM-DD"))) {
        return;
      }

      const start = dayjs(date).subtract("2", "month").format("YYYY-MM-DD");
      const end = dayjs(date).add("2", "month").format("YYYY-MM-DD");

      const disabledDates = await nonActionAxiosInstance.get(
        "/api/v2/closingdays",
        {
          params: {
            warehouse: this.$store.state.activeWarehouse,
            dateFrom: start,
            dateTo: end,
          },
        },
      );

      this.disabledDates = this.disabledDates.concat(disabledDates.data);

      this.checkedDates.push(dayjs(date).format("YYYY-MM-DD"));
    },
  },
};
</script>

<style>
.vue-daterange-picker {
  border: 1px solid #ced4da;
  border-radius: 3px;
  height: 31.5px;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.09) !important;
}

.reportrange-text {
  border: none !important;
}
.reportrange-text[data-v-00277188] {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;

  width: 100%;
}
</style>
