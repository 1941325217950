<template>
  <div class="flex flex-row">
    <div
      v-for="searchFilter in searchValues"
      :key="searchFilter"
      class="filter"
      @click="$emit('remove-search', searchFilter)"
    >
      <span class="close material-icons" style="font-size: inherit"
        >&#xe5cd;</span
      >
      <span>{{ searchFilter }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormFilterBarFilterSearchValues",
  props: {
    searchValues: {
      type: Array,
      required: true,
    },
  },
};
</script>
