export const setRowSerialOrderedQuantityToInt = ({row}) => {
  if (row.UseSerials.Value !== true) {
    return row;
  }
  return {
    ...row,
    PickedQuantity: {
      ...row.PickedQuantity,
      Editor: "Int",
    },
  };
};
