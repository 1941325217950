export function convertDatagridRowsToDatagridScanRows({rows}) {
  let convertedRowsArray = [];

  for (const row of rows) {
    delete row.Description;
    delete row.UseSerials;

    convertedRowsArray.push({
      Criteria: {
        StockTransferItemID: row.StockTransferItemID,
      },
      Values: {...row},
      Delete: row.Delete,
    });
  }

  return convertedRowsArray;
}
