import {setCorrectActiveTab} from "./setCorrectActiveTab";
import {upsertWindow} from "./upsertWindow";

export const handleSubWindowFocus = ({vueInstance, window}) => {
  if (!vueInstance.rWindows[window.id]) {
    upsertWindow({vueInstance, window});
  }
  setCorrectActiveTab({vueInstance});
  if (!window.parent?.id) {
    console.error("Parent window id is not set while a sub has been focussed");
    return;
  }

  vueInstance.rWindows[window.parent?.id] = {
    ...vueInstance.rWindows[window.parent.id],
    subWindowId: window.id,
  };
};
