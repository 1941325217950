import {api} from "@/services/api";
import store from "../../../../state/store";

export async function getItem(itemId) {
  const result = await api.get(`/v2/items/${itemId}`, {
    windowId: global.session.activeWindow.id,
    params: {
      WarehouseID: store.state.activeWarehouse,
    },
  });

  return result.data;
}
