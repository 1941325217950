import canReturn from "../../../services/return-item/canReturn.js";
import {notify} from "../../../util/notify.js";
import {alertPopup} from "../../../interface/alertPopup/alertPopup.js";
import {getTranslations} from "../../session/localstorage/getTranslations.js";

export async function validateReturnItemRowCheck({
  ignoreReturnConfirmation,
  row,
}) {
  if (row.Checked?.Value === true) return true;

  const rowHasReturnConfirmation =
    row.ReturnConfirmation?.Value !== undefined
      ? row.ReturnConfirmation.Value !== null
      : row.ReturnConfirmation !== null;

  const rowHasReturnWarning =
    row.ReturnWarning?.Value !== undefined
      ? row.ReturnWarning.Value !== null
      : row.ReturnWarning !== null;

  if (rowHasReturnConfirmation && !ignoreReturnConfirmation) {
    const translations = getTranslations();
    const confirm = await alertPopup({
      text: row.ReturnConfirmation?.Value ?? row.ReturnConfirmation,
      icon: "warning",
      dangerMode: true,
      buttons: [translations.Cancel],
      size: "xl",
    });

    if (!confirm) return false;
  }

  await canReturn(row.OrderItemID.Value);

  if (rowHasReturnWarning)
    notify({
      message: row.ReturnWarning?.Value ?? row.ReturnWarning,
      type: "warning",
    });

  return true;
}
