import {fetchVisStockData} from "../../services/v2/screen/fetchVisStockData";

export const getVisStockData = async ({
  categoryId,
  warehouseId,
  orderId,
  startDate,
  periodQuantity,
}) => {
  const {data} = await fetchVisStockData({
    categoryId,
    warehouseId,
    orderId,
    startDate,
    periodQuantity,
  });

  return data;
};
