import {getRunningResetWindowCount} from "./getRunningResetWindowCount";

export const waitForRunningProcesses = async ({resetId, windowId}) => {
  while (getRunningResetWindowCount({windowId}) > 0) {
    await new Promise((resolve) => setTimeout(resolve, 100));

    if (!global.runningProcesses.find((process) => process.resetId === resetId))
      break;
  }
};
