export const getColumnWidthClassFromColumn = (column) => {
  const MAX_WIDTH = 12;
  const DEFAULT_WIDTH = column.IsVisible ? 4 : MAX_WIDTH;

  const parseWidth = (width) => {
    const parsedValue = parseInt(width);
    return !isNaN(parsedValue) && parsedValue > 0 && column.IsVisible
      ? Math.min(parsedValue, MAX_WIDTH)
      : DEFAULT_WIDTH;
  };

  const formatColumnClass = (width) => `col-md-${width}`;

  return formatColumnClass(parseWidth(column?.ColumnWidth));
};
