export const handleNewRow = ({row, setPickedQuantityMax}) => {
  let pickedQuantity = 0;

  if (row.StatusCode.Value === "150") {
    pickedQuantity = 1;
  }

  return {
    ...row,
    Quantity: {Value: 1},
    OrderedQuantity: {Value: 1},
    PickedQuantity: {
      Value: setPickedQuantityMax ? 1 : pickedQuantity,
      IsReadOnly: row.UseSerials.Value ? true : false,
    },
  };
};
