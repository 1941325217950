import {api} from "@/services/api";


export default async function getOrderItemsStockPrediction({
  orderItems,
  orderId,
}) {
  const result = await api.post(
    `/v2/rental/order/${orderId}/_stock-prediction`,
    orderItems,
  );

  return result.data;
}
