import {api} from "@/services/api";
import {translateGetOrderItems} from "./translateGetOrderItems.js";

export default async function getOrderItems({orderId, params}) {
  const result = await api.get(`/v2/orders/${orderId}/items`, {
    params: {...params},
  });

  return translateGetOrderItems(result.data);
}
