<template>
  <div
    :title="resource.Description"
    class="rounded-full text-gray-700 text-xs font-bold text-center flex items-center justify-center uppercase border-white border"
    :class="classes"
    :style="`background-color: ${
      resource.Color
    }; color:${getContrastingTextColorByHex(resource.Color)};`"
  >
    <div class="pt-[1px]">
      {{ resource.ShortCode }}
    </div>
  </div>
</template>

<script>
import getContrastingTextColorByHex from "../../util/getContrastingTextColorByHex.js";

export default {
  name: "KanbanCardResource",
  props: {
    resource: {
      type: Object,
      required: true,
    },
    tableStyle: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSubTask: {
      type: Boolean,
      required: false,
    },
    laneColor: {
      type: String,
      required: false,
    },
  },
  computed: {
    classes() {
      if (this.isSubTask === true) {
        return {
          "-mr-1 w-6 h-6": true,
        };
      }
      return {
        "-ml-1.5 w-8 h-8": true,
      };
    },
  },
  methods: {
    getContrastingTextColorByHex,
  },
};
</script>

<style scoped></style>
