export function handleVATCodeIDChange({row}) {
  const vatPercentage = parseFloat(
    row.VATCodeID.SelectedOption?.Attributes.Percentage,
  );

  return {
    ...row,
    VATPercentage: {
      ...row.VATPercentage,
      Value: vatPercentage,
    },
  };
}
