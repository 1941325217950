import {notify} from "../../../../util/notify";
import {getTranslations} from "../../../session/getTranslations";
import {togglePickedQuantityBasedOnSerialScannedStatus} from "../row/togglePickedQuantityBasedOnSerialScannedStatus";
const translations = getTranslations();

export const findRowAndUpdate = ({rows, ItemID, SerialID}) => {
  // Find index based on ClientSideUUID.Value
  const rowIndex = rows.findIndex(
    (row) => row.ItemID.Value === ItemID && !row.SerialID.Value,
  );

  if (rowIndex === -1) {
    if (!SerialID) {
      notify({
        message: translations.NotifyUnableToFindItemInPicklist.replace(
          "{ItemID}",
          ItemID,
        ),
        type: "warning",
      });
    } else {
      notify({
        message: translations.NotifyAllSerialsForItemScannedPicklist.replace(
          "{ItemID}",
          ItemID,
        ),
        type: "warning",
      });
    }
    return rows; // Return original rows if no matching row is found
  }

  const row = rows[rowIndex];
  let newRow = {...row}; // Clone the original row to avoid mutations

  if (SerialID) {
    newRow.SerialID.Value = SerialID;

    if (newRow.PickedQuantity.Value < newRow.OrderedQuantity.Value) {
      newRow.PickedQuantity.Value += 1;
      newRow.StatusCode.Value = 150;
    }

    notify({message: `Serial ${SerialID} scanned`, type: "success"});
  } else {
    if (newRow.PickedQuantity.Value < newRow.OrderedQuantity.Value) {
      newRow.PickedQuantity.Value += 1;
      notify({message: `Item ${ItemID} scanned`, type: "success"});
    } else {
      notify({
        message: `Item ${ItemID} already fully picked`,
        type: "warning",
      });
    }
  }

  newRow = togglePickedQuantityBasedOnSerialScannedStatus({row: newRow});

  // Construct a new array with the modified row
  return [...rows.slice(0, rowIndex), newRow, ...rows.slice(rowIndex + 1)];
};
