export function formatNumber(value, round) {
  if (isNaN(value)) value = value.replace("€ ", "");

  return prettyPrintNumber(Math.round(parseFloat(value)), round);
}
export function prettyPrintNumber(value, round) {
  var suffixes = ["", "k", "m", "b", "t", "qua", "qui"];
  var suffixNum = 0;
  var shortValue = parseFloat(value);

  if (round) {
    suffixNum = Math.floor((("" + value).length - 1) / 6);

    shortValue = parseFloat(
      Math.round(suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value),
    );
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
  }

  if (shortValue?.toString()) {
    shortValue = shortValue.toLocaleString("nl");
  }

  return `${shortValue} ${suffixes[suffixNum]}`;
}
