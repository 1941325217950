import {uniqBy} from "lodash";
import {getDatagridGroupFilterChildren} from "./getDatagridGroupFilterChildren.js";

export const getDatagridGroupFilterOptions = ({
  rows,
  groupNodeColumnName,
  childNodeColumnName,
}) => {
  return uniqBy(
    rows.map((row) => {
      const groupNodeColumnValue = row[groupNodeColumnName].Value;

      return {
        id: groupNodeColumnValue,
        label: groupNodeColumnValue,
        children: getDatagridGroupFilterChildren({
          rows,
          groupNodeColumnName,
          groupNodeColumnValue,
          childNodeColumnName,
        }),
      };
    }),
    "id",
  );
};
