import {rowsProcessPopupStockPredictions} from "@/functions/datagrid/order-item/rows/rowsProcessPopupStockPredictions";
import {loadRowDataForPageProcessVisibleRows} from "./loadRowDataForPageProcessVisibleRows.js";

export async function loadRowDataForPage({rows, page, pageSize, order}) {
  try {
    const start = (page - 1) * pageSize;
    const end = page * pageSize;

    const modifiedRows = rowsProcessPopupStockPredictions({rows: rows.slice()});

    let visibleRows = modifiedRows
      .filter((row) => !row.rowMeta?.compositionItem)
      .slice(start, end);

    let pageRows = visibleRows.slice();
    for (let row of visibleRows) {
      if (row.rowMeta?.virtual) {
        const composition = row.Composition.Value;
        const compositionChildRows = rows.filter(
          (row) =>
            row.Composition.Value === composition && !row.rowMeta.virtual,
        );

        pageRows.push(...compositionChildRows);
      }
    }

    pageRows = await loadRowDataForPageProcessVisibleRows({
      rows: pageRows,
      order,
    });

    const result = {};
    const originalRows = rows.reduce(
      (acc, row) => ({...acc, [row.ClientSideUUID.Value]: row}),
      {},
    );

    for (let row of pageRows) {
      const originalRow = originalRows[row.ClientSideUUID.Value];
      const modifiedProperties = {};

      for (let key in row) {
        if (JSON.stringify(row[key]) !== JSON.stringify(originalRow[key])) {
          modifiedProperties[key] = row[key];
        }
      }

      if (Object.keys(modifiedProperties).length > 0) {
        result[row.ClientSideUUID.Value] = modifiedProperties;
      }
    }

    return result;
  } catch (e) {
    console.log(e);
  }
}
