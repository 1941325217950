import {getInboundItem} from "../../../../../services/inbound/getInboundItem";
import {v4 as uuidv4} from "uuid";

export const addPriceConditionItemRow = async ({
  priceCondition,
  rows,
  row,
  getRowsWrapper,
}) => {
  const itemIdToAdd = priceCondition.AddItemID?.Key;
  if (!itemIdToAdd) {
    return {
      rows,
      priceConditionOrderItemIDs: [],
    };
  }
  const itemResponse = await getInboundItem({itemId: itemIdToAdd});

  const newOrderItemID = uuidv4();

  const rowToAdd = {
    ...itemResponse.data,
    OrderItemID: newOrderItemID,
    Amount: priceCondition.AmountType === "Follow" ? row.Amount?.Value ?? 1 : 1,
    PriceConditionID: priceCondition.PriceConditionID,
  };

  // when the ItemID is not set the PriceCondition is generic so don't create a relation
  if (priceCondition.ItemID) {
    rowToAdd.RelatedOrderItemID = row.OrderItemID.Value;
  }
  const updatedRows = await getRowsWrapper({
    scannedRow: rowToAdd,
    rows,
  });

  return {
    rows: updatedRows,
    priceConditionOrderItemIDs: [newOrderItemID],
  };
};
