import {getCostAccountWithoutPriceConditions} from "./getCostAccountWithoutPriceConditions.js";
import {getItemPriceWithoutPriceConditions} from "./getItemPriceWithoutPriceConditions";
import {setValuesForRowInRows} from "../setValuesForRowInRows";

export const removePriceConditionsFromRow = ({
  row,
  rows,
  changedColumnName,
  inboundItems,
  priceConditions,
}) => {
  const costAccount = getCostAccountWithoutPriceConditions({
    row,
    inboundItems,
    priceConditions,
  });

  const valuesToUpdate = {CostAccountID: costAccount};

  let itemPrice;

  // TODO: Reintroduce Itemtype on Inbound OrderItems
  //   if (row.ItemType.Value === "RentalPeriod") {
  //   itemPrice = row.HirePricePerDay?.Value ?? 0;
  // } else {
  itemPrice = getItemPriceWithoutPriceConditions({
    row,
    changedColumnName,
    inboundItems,
    priceConditions,
  });
  // }

  valuesToUpdate.PurchasePricePerUnit = itemPrice;

  const modifiedRows = setValuesForRowInRows({
    rows,
    row,
    values: valuesToUpdate,
  });

  return {
    itemPrice,
    modifiedRows,
  };
};
