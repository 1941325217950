<template>
  <form class="table-form stick-div ml-1 mr-1 mt-1">
    <form-filter-bar
      :filters="filters"
      :search-values="searchValues"
      :title="translations['Filters']"
      icon="fa-filter"
      :default-collapsed="true"
      :collapse-filter="true"
      :disable-search="disableSearch"
      @remove-search="$emit('remove-search', $event)"
      @search="$emit('search', $event)"
      @filter-change="$emit('filter-change', $event)"
    />
  </form>
</template>
<script>
import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import FormFilterBar from "../form/filters/FormFilterBar.vue";
const translations = getTranslations();
export default {
  name: "TableForm",
  components: {
    FormFilterBar,
  },
  props: {
    filterIcon: {
      type: String,
      default: "fa-filter",
    },
    searchValues: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      translations: translations,
    };
  },
};
</script>
