import {calculateRowTotalIncVat} from "../../row/calculateRowTotalIncVat";
import {calculateRowTotalVat} from "../../row/calculateRowTotalVat";
import {getPrice} from "@/services/v2/rental/quotation/getPrice";
import dayjs from "dayjs";

export async function getRowPrices({quotation, rowValues}) {
  const {DateTimeBusinessStart, DateTimeBusinessEnd} = rowValues;
  const convertedRowValues = {...rowValues};

  if (convertedRowValues.Composition === "") {
    convertedRowValues.Composition = null;
  }

  const startDate = dayjs(DateTimeBusinessStart).format("YYYY-MM-DDTHH:mm:ss");
  const endDate = dayjs(DateTimeBusinessEnd).format("YYYY-MM-DDTHH:mm:ss");

  const priceResult = await getPrice({
    startDate,
    endDate,
    itemId: rowValues.ItemID,
    quotationItemID: rowValues.QuotationItemID,
    customerId: quotation.CustomerID?.Key,
    transactionTypeId: quotation.TransactionTypeID?.Key,
    contractId: quotation.ContractID?.Key,
  });

  const price = priceResult?.PriceNett;
  const totalExcVat = price * rowValues.Amount;
  const totalIncVat = calculateRowTotalIncVat({price, vatPercentage: priceResult?.VATPercentage, amount: rowValues.Amount});
  const totalVat = calculateRowTotalVat({totalIncVat, totalExcVat});

  return {
    Price: price,
    TotalExcVAT: totalExcVat,
    TotalVAT: totalVat,
    TotalIncVAT: totalIncVat,
    VATPercentage: priceResult.VATPercentage ?? 21,
    PriceCalcReason: priceResult.PriceCalcReason,
    PriceProjection: {
      ...rowValues.PriceProjection,
      PriceCalculation: priceResult.PriceCalculation ?? [],
    },
  };
}
