import dayjs from "dayjs";

export const setItemPickerDialogStartDate = ({order}) => {
  if (
    !order.DateTimeExpectedEnd ||
    dayjs(order.DateTimeExpectedStart).isBefore(dayjs(), "day") ||
    dayjs(order.DateTimeExpectedStart).isSame(dayjs(), "day")
  ) {
    return order.DateTimeExpectedStart;
  }

  return dayjs(order.DateTimeExpectedStart).subtract(1, "months");
};
