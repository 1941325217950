import {processJob} from "./processJob";

export const handleJobExecution = async ({job, vueInstance}) => {
  if (
    vueInstance[job.actionName] &&
    typeof vueInstance[job.actionName] === "function"
  ) {
    await processJob({job, vueInstance});
  }
};
