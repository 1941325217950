<template>
  <div class="login cover v-center">
    <div class="container">
      <div class="box pull-center">
        <form @submit.prevent="submit($event)">
          <div class="logo">
            <span
              :class="[
                'logo-icon',
                'infodatek-icon',
                'infodatek-icon-' + (session.product || '').toLowerCase(),
              ]"
            ></span
            ><br />
            <span class="logo-text">{{ session.product }}</span>
          </div>
          <div class="form-element">
            <select
              name="language"
              autofocus
              @change="session.switchLanguage($event.target.value)"
            >
              <option
                v-for="lang in session.languages"
                :key="lang.ID"
                :value="lang.ID"
                :selected="lang.ID == session.language"
              >
                {{ lang.Name }}
              </option>
            </select>
          </div>

          <div v-if="error" class="error-box">
            {{ error }}
          </div>

          <div>
            <div class="form-element">
              <input
                v-model="username"
                type="text"
                name="username"
                required
                autocomplete="username"
                :placeholder="session.translations.UserName"
              />
            </div>

            <div class="form-element with-side">
              <div
                :class="{
                  'show-password': true,
                  'material-icons': true,
                }"
              >
                &#xe8f4;
              </div>
              <input
                v-model="password"
                type="password"
                name="password"
                autocomplete="current-password"
                required
                :placeholder="translations.Password"
              />
            </div>

            <div class="form-element">
              <button
                name="login"
                class="button button-default login-button"
                type="submit"
              >
                {{ translations.LogIn }}
              </button>
            </div>
            <div v-if="welcomeInfo.SSOConfigured" class="form-element">
              <a
                name="logingooglesso"
                class="button button-default login-button"
                type="submit"
                :href="welcomeInfo.SSOUrl"
              >
                {{ welcomeInfo.SSOTitle }}
              </a>
            </div>
            <div class="form-element">
              <button
                class="button button-menu password-lost-button"
                @click="showRequestResetPassword"
              >
                {{ translations.ForgotPassword }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import postMembershipLogin from "../../services/authentication/postMembershipLogin.js";
import getAnonymousWelcomeInfo from "../../services/authentication/getAnonymousWelcomeInfo.js";

export default {
  name: "LoginPage",
  props: {
    session: {
      type: Object,
      default: () => {},
    },
    initialErrorMessage: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      translations: this.session.translations,
      username: null,
      password: null,
      error: null,
      welcomeInfo: {
        SSOConfigured: false,
        SSOTitle: null,
      },
    };
  },
  created: async function () {
    this.$data.error = this.$props.initialErrorMessage;
    await this.loadWelcomeInfo();

    const urlParams = new URLSearchParams(location.search);
    const loginMessageResourceId = urlParams.get("LoginMessage");

    if (loginMessageResourceId)
      this.error = this.translations[loginMessageResourceId];
  },
  methods: {
    async loadWelcomeInfo() {
      const response = await getAnonymousWelcomeInfo();
      this.$data.welcomeInfo = response.data;
    },
    showRequestResetPassword() {
      this.$emit("showComponent", "loginRequestResetPassword");
    },
    async submit() {
      this.$data.error = null;

      let result = await postMembershipLogin({
        product: "RentMagic",
        username: this.$data.username,
        password: this.$data.password,
      });

      if (result.data.Token?.Token_type == "Login") {
        this.$emit("setLoginToken", result.data.Token.Access_token);
      }

      switch (result.data.Status) {
        case "Success":
          this.$emit("setBearerToken", result.data.Token);
          this.$emit("showComponent", "loginSessionList");
          break;
        case "MFASetUpRequired":
          this.$emit("showComponent", "loginTwoFactorSetup");
          break;
        case "MFACodeRequired":
          this.$emit("showComponent", "loginMFA");
          break;
        default:
          this.$data.error = result.data.Error;
      }
    },
  },
};
</script>
