import store from "../../../state/store";
import Window from "@/model/window";

export const insertWindow = async (output) => {
  let window = new Window(global.session, null);
  window.input = output.Request;
  await window.process(output);
  window.loading = false;
  window.focus();
  await window.render();
  store.commit("updateWindow");

  return window;
};
