import {getDraftTotals} from "@/services/v2/rental/orderitem/getDraftTotals";
import {createPricesUpdateHookRequestBody} from "./rows/rows-process-webhook/createPricesUpdateHookRequestBody";
import {hasDataHookEvent} from "../../../hooks/hasDataHookEvent";

export const getExtraTotalValues = async ({rows, order, dataWebHooks}) => {
  if (
    !hasDataHookEvent({
      dataWebHooks,
      eventId: "rental.orderitem.totals.updated.draft",
    })
  ) {
    return {};
  }
  const context = createPricesUpdateHookRequestBody({context: order, rows});
  const {data} = await getDraftTotals({event: "processTotals", context});

  return data;
};
