const findLastIndex = (str, pattern) => str.lastIndexOf(pattern);

const replaceAt = ({originalString, startIndex, endIndex, replacement}) =>
  originalString.slice(0, startIndex) +
  replacement +
  originalString.slice(endIndex);

export const replaceCompositionValue = ({value, count}) => {
  const pattern = "(0)";
  const lastIndex = findLastIndex(value, pattern);

  if (lastIndex !== -1) {
    return replaceAt({
      originalString: value,
      startIndex: lastIndex,
      endIndex: lastIndex + pattern.length,
      replacement: `(${count + 1})`,
    });
  }

  return value; // Return the original value if "(0)" is not found
};
