import {handleItemIdChange} from "@/functions/datagrid/stock-transfer-item/handleItemIdChange";
import {handleSerialIdChange} from "@/functions/datagrid/stock-transfer-item/handleSerialIdChange";
import {handleStatusChange} from "@/functions/datagrid/stock-transfer-item/handleStatusChange";
import {handleAmountChange} from "../row/handleAmountChange.js";

export function processColumnsMetaData({columns}) {
  const newColumns = columns.slice();
  newColumns.forEach((newColumn) => {
    newColumn.processValueChangeFunctions = [];
    newColumn.processValueChangeFunctionsForRows = [];

    if (newColumn.Name === "SerialID") {
      newColumn.processValueChangeFunctions.push(handleSerialIdChange);
    }

    if (newColumn.Name === "Amount") {
      newColumn.processValueChangeFunctions.push(handleAmountChange);
    }

    if (newColumn.Name === "ItemID") {
      newColumn.processValueChangeFunctions.push(handleItemIdChange);
      newColumn.openRef = true;
    }

    if (newColumn.Name === "Status") {
      newColumn.processValueChangeFunctions.push(handleStatusChange);
      newColumn.openRef = true;
    }
  });

  return newColumns;
}
