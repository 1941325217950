import Decimal from "decimal.js";

export function compositionGroupGetCalculatedPriceSum({row, rows}) {
  return Decimal(
    rows.reduce((acc, currentRow) => {
      if (currentRow.Composition.Value === row.Composition.Value) {
        return new Decimal(parseFloat(acc)).add(
          new Decimal(parseFloat(currentRow.CalculatedPrice?.Value)),
        );
      }
      return acc;
    }, 0),
  ).toNumber();
}
