export const setCustomRowPropertiesFromCustomPropertyObject = ({row}) => {
  const modifiedRow = {...row};

  for (const property in modifiedRow.CustomFields.Value) {
    if (property.startsWith("CUST_")) {
      modifiedRow[property] = {
        ...modifiedRow[property],
        Value: modifiedRow.CustomFields.Value[property],
      };
    }
  }

  return modifiedRow;
};
