export const getItemPriceWithoutPriceConditions = ({
  row,
  inboundItems,
  changedColumnName,
  priceConditions,
}) => {
  const itemID = row.ItemID.Value;
  if (changedColumnName !== "PurchasePricePerUnit" && itemID) {
    const hasPricePriceConditions = priceConditions.some(
      (priceCondition) =>
        (!priceCondition.ItemID?.Key ||
          priceCondition.ItemID?.Key === itemID) &&
        ["Percentage", "Fixed"].includes(priceCondition.PriceType),
    );

    // only reset the PurchasePricePerUnit when there are price conditions that could've modified it. To prevent resetting user changes when all price conditions are irrelevant
    if (hasPricePriceConditions) {
      const item = inboundItems.find(
        (inboundItem) => inboundItem.ItemID === itemID,
      );
      if (item?.UnitPrice) {
        return item.UnitPrice;
      }
    }
  }

  return row.PurchasePricePerUnit?.Value ?? 0;
};
