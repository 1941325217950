export const toggleFieldsLoading = ({
  vueComponent,
  windowData,
  windowId,
  value,
}) => {
  for (let column in windowData.Columns)
    windowData.Columns[column].IsLoading = value;

  vueComponent.$emit("data-change", {newData: windowData, windowId});
};
