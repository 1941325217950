import React from "react";

export function availabilityListStatus({status, translations}) {
  const trClass = status.highlight ? "font-bold" : "";
  const tdClass = `text-right text-${status.key.toLowerCase()}`;

  return (
    <tr className={trClass} key={status.key}>
      <td>{translations["AvailabilityList_" + status.key]}</td>
      <td className={tdClass}>{status.value}</td>
    </tr>
  );
}
