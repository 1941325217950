<template>
  <div class="px-1">{{ translations[displayValue] ?? displayValue }}</div>
</template>

<script>
import {renderAsText as badgeSelectRenderAsText} from "./TableCellBadgeSelect.vue";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";

export const renderAsText = badgeSelectRenderAsText;

export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: "",
    },
    cell: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayValue() {
      return badgeSelectRenderAsText(this);
    },
    translations() {
      return getTranslations();
    },
  },
};
</script>

<style scoped>
.date-cell {
  height: 29px;
  display: flex;
  align-items: center;
}
td {
  white-space: nowrap;
  line-height: 30px;
}
div {
  border: none;
  line-height: 26px;
  background-color: transparent;
}
</style>
