import {nonActionAxiosInstance} from "../nonActionAxiosInstance.js";
import store from "../../../state/store.js";

export async function getLocations({params}) {
  return await nonActionAxiosInstance.get(`/api/v2/warehouse/location`, {
    params: {
      ...params,
      WarehouseID: store.state.activeWarehouse,
    },
  });
}
