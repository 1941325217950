import React from "react";

export function detailButton({dataItem, subject}) {
  if (subject == "Rental.virtual_ItemAvailability") {
    return (
      <button
        style={{marginLeft: "2px"}}
        className="btn btn-sm btn-secondary"
        data-item-description={dataItem.item.description}
        data-item-id={dataItem.item.itemID}
        data-function={"openItemDetailOverview"}
      >
        <i className="fas fa-search" style={{pointerEvents: "none"}}></i>
      </button>
    );
  }
}
