import {setReturnItemFormFieldsFromOrder} from "./setReturnItemFormFieldsFromOrder.js";
import {handleValueFieldChange} from "./form-handlers/handleValueFieldChange.js";
import {openReturnItemScanbox} from "./openReturnItemScanbox.js";

export async function handleReturnWindowOpenedFromOrder({
  vueComponent,
  criteria,
}) {
  const mockValueFieldEvent = {
    Value: criteria.Order,
    predefinedType: "Order",
  };

  await handleValueFieldChange({
    event: mockValueFieldEvent,
    vueComponent,
  });

  setReturnItemFormFieldsFromOrder({vueComponent, criteria});

  if (vueComponent.OnlyShowScan) {
    vueComponent.formFields.Scan.IsReadOnly = false;
    delete vueComponent.formFields.Type;
    delete vueComponent.formFields.Value;
  }

  setTimeout(() => openReturnItemScanbox(), 320);
}
