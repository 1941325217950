import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {processTotalExcVat} from "../../order-item/row/processTotalExcVat.js";
import {getItem} from "@/services/v2/items/getItem";
import Decimal from "decimal.js";

export async function setSalesPrice({row}) {
  let newRow = {...row};

  const itemId = getRawValueFromDropdownValue(newRow.ItemID.Value);
  const item = await getItem(itemId);

  const salesPrice = item.SalesPrice;
  const discount = new Decimal(parseFloat(row.Discount?.Value ?? 0)).toNumber();

  if (salesPrice && discount) {
    newRow.Price.Value = salesPrice;
  }

  newRow.Price.Value = item.SalesPrice;
  newRow.Price.OldValue = row.Price.Value;

  newRow = processTotalExcVat({row: newRow});

  return newRow;
}
