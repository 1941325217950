<template>
  <div
    class="flex checkbox"
    :class="{
      '!cursor-not-allowed': value.IsReadOnly || column.IsReadOnly,
    }"
  >
    <label
      class="switch"
      ref="checkbox"
      :class="{
        readonly: value.IsReadOnly || column.IsReadOnly,
      }"
    >
      <input
        :name="column.Name"
        type="checkbox"
        :disabled="column.IsReadOnly"
        :checked="value.Value"
        @change="handleChange($event.target.checked)"
      />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleChange(newValue) {
      this.$emit("input", {
        ...this.value,
        Value: newValue,
      });
    },
  },
};
</script>

<style scoped>
td {
  white-space: nowrap;
}
.checkbox {
  height: 31px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.readonly {
  pointer-events: none;
}
.readonly-cursor {
  cursor: not-allowed !important;
}
</style>
