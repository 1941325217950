export function setReturnItemFormFieldsWhenValueChanged({
  newFormFields,
  event,
}) {
  return {
    ...newFormFields,
    Type: {
      ...newFormFields.Type,
      IsReadOnly: true,
    },
    Value: {
      ...newFormFields.Value,
      IsReadOnly: false,
      Value: event.Value,
    },
    Scan: {
      ...newFormFields.Scan,
      IsReadOnly: false,
    },
  };
}
