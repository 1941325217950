<template>
  <div class="tab">
    <div class="relative inline-flex align-middle mr-2">
      <r-button
        :key="tabShortcut.MenuID"
        variant="secondary"
        size="sm"
        class="ml-0 bg-[#e5e5e5] border-solid border-[1px] border-[#e5e5e5] w-auto"
        :class="{
          'button-secondary': true,
          'fade-in': true,
        }"
        :data-menu-id="tabShortcut.MenuID"
        data-window-target="active"
        ><i class="fas fa-external-link"></i> {{ tabShortcut.Text }}</r-button
      >
    </div>
  </div>
</template>

<script>
import RButton from "../../elements/RButton.vue";

export default {
  components: {
    RButton,
  },
  props: {
    tabShortcut: {
      required: true,
      type: Object,
    },
  },
};
</script>
