export const processRowStatusClosed = ({row, closedStatus}) => {
  let modifiedRow = {...row};

  if (modifiedRow.Status.Value === closedStatus) {
    return {
      ...modifiedRow,
      Amount: {
        ...modifiedRow.Amount,
        IsReadOnly: true,
      },
      Ranking: {
        ...modifiedRow.Ranking,
        IsReadOnly: true,
      },
      ItemID: {
        ...modifiedRow.ItemID,
        IsReadOnly: true,
      },
      ItemDescription: {
        ...modifiedRow.ItemDescription,
        IsReadOnly: true,
      },
      TotalExcVAT: {
        ...modifiedRow.TotalExcVAT,
        IsReadOnly: true,
      },
      ItemType: {
        ...modifiedRow.ItemType,
        IsReadOnly: true,
      },
      DateTimeOrdered: {
        ...modifiedRow.DateTimeOrdered,
        IsReadOnly: true,
      },
      PurchasePricePerUnit: {
        ...modifiedRow.PurchasePricePerUnit,
        IsReadOnly: true,
      },
      DateTimeDesiredDelivery: {
        ...modifiedRow.DateTimeDesiredDelivery,
        IsReadOnly: true,
      },
      Notes: {
        ...modifiedRow.Notes,
        IsReadOnly: true,
      },
      // TODO: Reintroduce Itemtype on Inbound OrderItems
      // HirePricePerDay: {
      //     ...modifiedRow.HirePricePerDay,
      //     IsReadOnly: true,
      // },
    };
  }

  return modifiedRow;
};
