<template>
  <div>
    <ul :class="{changingPage}" class="pagination">
      <li
        :disabled="currentPage < 2"
        class="button button-menu mx-1 material-icons"
        @click="changePage(1)"
      >
        
      </li>
      <li
        :disabled="currentPage === 1"
        class="button button-menu mx-1 material-icons"
        :class="{'pointer-events-none': currentPage === 1}"
        @click="changePage(currentPage - 1)"
      >
        
      </li>

      <pagination-page-button
        v-for="index in pageButtons"
        :key="`minus-${index}`"
        :disabled="currentPage - 1 - (pageButtons - index) < 1"
        :current-page="currentPage"
        :page="currentPage - 1 - (pageButtons - index)"
        :page-count="pageCount"
        @changePage="changePage"
      />

      <li class="page current mx-1 cursor-default" disabled>
        <span v-if="!changingPage">{{ currentPage }}</span>
        <span v-else><i class="fas fa-spinner fa-spin"></i></span>
      </li>

      <pagination-page-button
        v-for="index in pageButtons"
        :key="index"
        :current-page="currentPage"
        :page="currentPage + index"
        :page-count="pageCount"
        @changePage="changePage"
      />

      <li
        :disabled="currentPage >= pageCount"
        class="button button-menu material-icons mx-1"
        @click="changePage(currentPage + 1)"
      >
        
      </li>
      <li
        :disabled="currentPage >= pageCount"
        class="button button-menu material-icons mx-1"
        @click="changePage(pageCount)"
      >
        
      </li>
    </ul>
  </div>
</template>

<script>
import PaginationPageButton from "@/components/pagination/PaginationPageButton";

export default {
  name: "Pagination",
  components: {PaginationPageButton},
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    changingPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    pageButtons: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  methods: {
    changePage(page) {
      if (page > this.pageCount || page < 0) return;
      this.$emit("changePage", page);
    },
  },
};
</script>

<style scoped>
.changingPage {
  pointer-events: none;
}

.current {
  cursor: default;
}

.page {
  min-width: 30px;
  text-align: center;
}
</style>
