import {processPeriodColumnChange} from "./row/processPeriodColumnChange.js";
import {rowsProcessPopupPriceCalculations} from "./rows/rowsProcessPopupPriceCalculations.js";
import {rowsProcessPriceDifference} from "./rows/rowsProcessPriceDifference.js";
import {rowsProcessPrices} from "./rows/rowsProcessPrices.js";
import {rowsSetInitialMinDates} from "./rows/rowsSetInitialMinDates.js";
import {rowsStockSerialFetch} from "./rows/rowsStockSerialFetch.js";

export const loadRowDataForPageProcessVisibleRows = async ({rows, order}) => {
  rows = rows.map((row) => {
    return processPeriodColumnChange({row});
  });

  const processedPricesRows = await rowsProcessPrices({
    rows: rows,
    order,
  });
  const stockSerialRows = await rowsStockSerialFetch({
    rows: processedPricesRows,
    order,
  });

  const minDateRows = await rowsSetInitialMinDates({rows: stockSerialRows});
  const popupPriceRows = await rowsProcessPopupPriceCalculations({
    rows: minDateRows,
  });

  return await rowsProcessPriceDifference({
    rows: popupPriceRows,
  });
};
