import {v4 as uuidv4} from "uuid";

export function setRandomOrderItemId({row}) {
  return {
    ...row,
    OrderItemID: {
      ...row.OrderItemID,
      Value: `_${uuidv4()}`,
    },
  };
}
