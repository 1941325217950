import {setCorrectAmountForVirtualCompositionRow} from "../row/setCorrectAmountForVirtualCompositionRow";

export const rowSetCorrectAmountForVirtualCompositionAfterAmountChangeRow = ({
  row,
  rows,
}) => {
  if (row.rowMeta?.virtual) return rows;
  if (!row.Composition?.Value) return rows;
  let modifiedRows = rows.slice();
  const virtualRowIndex = modifiedRows.findIndex(
    (cRow) =>
      cRow.rowMeta?.virtual && cRow.Composition.Value === row.Composition.Value,
  );

  modifiedRows[virtualRowIndex] = setCorrectAmountForVirtualCompositionRow({
    row: modifiedRows[virtualRowIndex],
    rows: modifiedRows,
  });

  return modifiedRows;
};
