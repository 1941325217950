export const clearInvalidLinkedField = ({linkedField, columns, row}) => {
  let newLinkedField = {...linkedField};

  if (linkedField.Dropdown?.Items)
    newLinkedField = {
      ...linkedField,
      Dropdown: {...linkedField.Dropdown, Items: []},
    };

  return {
    newColumns: {...columns, [linkedField.Name]: newLinkedField},
    newRow: {...row, [linkedField.Name]: null},
  };
};
