import {scanPickListItem} from "../../data/scanPickListItem.js";
import {convertCellRowsToKeyValueRows} from "../../rows/convertCellRowsToKeyValueRows.js";
import {scanErrorHandler} from "../scan/scanErrorHandler.js";

export const rowCheckSelectedSerialId = async ({rows, row, oldRow}) => {
  const serialId = row.SerialID?.Value;
  const rowWithOldValue = {...row, SerialID: {Value: oldRow.SerialID?.Value}};

  if (!serialId) return {...row, SerialID: {Value: null}};

  const keyValueBasedRows = convertCellRowsToKeyValueRows({rows});
  const filteredRows = keyValueBasedRows.map((r) => {
    if (row.ClientSideUUID.Value === r.ClientSideUUID) {
      return {...r, SerialID: null, StatusCode: "100"};
    }
    return r;
  });

  const data = await scanPickListItem({
    items: filteredRows,
    search: serialId,
  });

  if (scanErrorHandler({scanData: data})) return rowWithOldValue;

  return row;
};
