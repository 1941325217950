import {canDeleteSingleRow} from "../row/composition-delete/canDeleteSingleRow";

export function rowsSetMetadataForCompositionRows({rows}) {
  const modifiedRows = rows.slice();

  // loop through rows and set rowMeta.childRow = true

  for (const row of modifiedRows) {
    if (
      !row.Composition.Value ||
      row.rowMeta?.compositionItem ||
      (row.Composition.Value && !row.ItemID.Value)
    )
      continue;
    row.rowMeta = {
      ...row.rowMeta,
      compositionItem: true,
      deletable: canDeleteSingleRow({row}),
      classes: {hidden: true, "border-gray-950 border-l-2": true},
      styles: {
        "border-left": "2px solid #b1aeae",
        "border-right": "2px solid #b1aeae",
      },
    };

    row.ItemID = {
      ...row.ItemID,
      IsReadOnly: true,
    };
  }

  return modifiedRows;
}
// Make ranking same as first composition with hashtag
