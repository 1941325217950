import {handleDeliveryAmountChange} from "./handleDeliveryAmountChange";
import {handleCheckboxStateChange} from "./handleCheckboxStateChange";

export function processColumnsMetaData({columns}) {
  const newColumns = columns.slice();
  newColumns.forEach((newColumn) => {
    newColumn.processValueChangeFunctions = [];

    if (newColumn.Name === "DeliveryAmount") {
      newColumn.processValueChangeFunctions.push(handleDeliveryAmountChange);
    }

    if (newColumn.Name === "Checked") {
      newColumn.processValueChangeFunctions.push(handleCheckboxStateChange);
    }
  });

  return newColumns;
}
