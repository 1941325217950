import {getRentalPeriodBasedPricesFromRow} from "./getRentalPeriodBasedPricesFromRow";
import {getSettings} from "@/util/getSettings";

export async function processUseSinglePeriodPrice({
  row,
  startDate,
  endDate,
  periodAmount,
  params,
}) {
  const {PeriodSelection} = getSettings();

  if (
    PeriodSelection === "Default" &&
    !row.HasPeriodPricesForCalculation?.Value
  ) {
    return {...row};
  }

  let prices = [];
  let periodPrices = [];

  switch (PeriodSelection) {
    case "ItemSinglePeriodCalculateEndDateEnforced":
      {
        const serverPeriodPrices = row.PeriodPrices ?? {};
        periodPrices =
          serverPeriodPrices?.Value?.map((periodPrice) => ({
            Period: periodPrice.PeriodID,
            Price: periodPrice.Price,
          })) ?? [];

        if (!periodPrices.length) {
          periodPrices = [
            {
              Period: row.Period.Value,
              Price: 0,
            },
          ];
        }

        prices = await getRentalPeriodBasedPricesFromRow({
          row,
          startDate,
          periodAmount,
          periodPrices,
          params,
        });
      }
      break;
    default:
      prices = await getRentalPeriodBasedPricesFromRow({
        row,
        startDate,
        endDate,
        periodAmount,
        params,
      });
      break;
  }

  const priceCalculation = prices[0]?.PriceCalculation ?? [];
  const priceCalcReason = prices[0]?.PriceCalcReason ?? null;
  const dateTimeValue = prices[0]?.PriceCalculation?.[0]?.EndDate ?? endDate;
  const priceValue = prices[0]?.PriceGross ?? 0;

  return {
    ...row,
    PriceCalcReason: {Value: priceCalcReason},
    DateTimeExpectedEnd:
      PeriodSelection === "AllowItemSinglePeriodPriceCalculation"
        ? {Value: row.DateTimeExpectedEnd?.Value}
        : {Value: dateTimeValue},
    DateTimeBusinessEnd: {Value: dateTimeValue},
    DateTimeRentalEnd: row.DateTimeRentalEnd?.Value
      ? {Value: dateTimeValue}
      : {...row.DateTimeRentalEnd, Value: row.DateTimeRentalEnd?.Value ?? null},
    Price: {Value: priceValue},
    PriceProjection: {
      ...row.PriceProjection,
      Value: {
        ...row.PriceProjection?.Value,
        PriceCalculation: priceCalculation,
      },
    },
  };
}
