import {processReturnItemValueChange} from "../processReturnItemValueChange.js";
import {openReturnItemScanbox} from "../openReturnItemScanbox.js";
import {getReturnItemRows} from "../../../../services/return-item/getReturnItemRows.js";

export async function handleValueFieldChange({event, vueComponent}) {
  const selectionValue = event?.Value;
  let newRows = [];

  const isValueEmpty =
    !event || Array.isArray(selectionValue)
      ? selectionValue.length === 0
      : !selectionValue;

  if (!isValueEmpty) {
    const scanValue = null; //serial number
    const opts = {
      scanValue: null, //serial number
      selectionType:
        event?.predefinedType ?? vueComponent.formFields?.Value?.ReturnType,
      selectionValue,
    };

    newRows = await getReturnItemRows({scanValue, selectionValue, opts});
  }

  if (!Array.isArray(selectionValue)) openReturnItemScanbox();

  vueComponent.loadAndApplyRowDataForPage({rows: newRows});

  vueComponent.formFields = processReturnItemValueChange({
    event: event,
    formFields: vueComponent.formFields,
    rows: vueComponent.rows,
    isValueEmpty,
  });
}
