<template>
  <div class="container-fluid">
    <div v-if="diagramCount > 0 && showDiagrams" class="row no-mar diagrams">
      <template v-for="diagram in diagrams">
        <chart-textual
          v-if="diagram.DiagramType === 'textual'"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
        <chart-pie
          v-if="diagram.DiagramType === 'chart_pie'"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
        <chart-radial-bar
          v-if="diagram.DiagramType === 'chart_radial'"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
        <chart-bar
          v-if="diagram.DiagramType === 'chart_bar'"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
        <chart-timeline
          v-if="diagram.DiagramType === 'chart_timeline'"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {handleJobExecution} from "../../functions/datagrid/actions/handleJobExecution.js";
import chartTextual from "../charts/chartTextual.vue";
import chartPie from "../charts/chartPie.vue";
import chartRadialBar from "../charts/chartRadialBar.vue";
import chartBar from "../charts/chartBar.vue";
import chartTimeline from "../charts/chartTimeline.vue";

export default {
  name: "DiagramDashboard",
  components: {
    chartTextual,
    chartPie,
    chartRadialBar,
    chartBar,
    chartTimeline,
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      diagrams: {},
      showDiagrams: true,
    };
  },
  computed: {
    diagramCount: function () {
      if (this.diagrams === undefined) return 0;
      return this.diagrams?.length ?? 0;
    },
  },
  created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.diagrams = this.getDiagrams();
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    reset(job) {
      try {
        this.showDiagrams = false;
        this.$nextTick(() => {
          this.showDiagrams = true;
        });
      } finally {
        this.$emit("job-completed", job);
      }
    },
    getDiagrams() {
      if (this.$store.state.window.output.Data) {
        this.replaceEndpointWarehouse(
          this.$store.state.window.output.Data.Rows,
        );

        return this.$store.state.window.output.Data.Rows;
      }
      return null;
    },
    replaceEndpointWarehouse(diagrams) {
      for (let i = 0; i < diagrams?.length; i++) {
        if (!diagrams[i].Endpoint) {
          continue;
        }

        diagrams[i].Endpoint = diagrams[i].Endpoint.replace(
          "warehouse=current",
          "Warehouse=" + this.$store.state.activeWarehouse,
        );
      }
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>

<style></style>
