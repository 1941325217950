import dayjs from "dayjs";

export const parseDate = ({dateString}) => {
  if (dayjs(dateString).isValid()) {
    return dayjs(dateString).format("YYYY-MM-DD");
  }
  if (dayjs(dateString, "DD-MM-YYYY").isValid()) {
    return dayjs(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
  }

  return null;
};
