import Decimal from "decimal.js";
import {removeAllButLastDecimalPoint} from "./removeAllButLastDecimalPoint.js";

export function parseNumber({value}) {
  const internationalizedNumberString = value.replaceAll(",", ".");

  try {
    return new Decimal(
      removeAllButLastDecimalPoint(internationalizedNumberString),
    )
      .round()
      .toNumber();
  } catch (error) {
    return 0;
  }
}
