import Decimal from "decimal.js";
import {isRelatedRow} from "../row/isRelatedRow";
import {isSameRow} from "../row/isSameRow";

export const handleTotalPriceChangeForRows =
  ({changedColumnName}) =>
  ({rows, row}) => {
    return rows.map((rowToUpdate) => {
      if (
        !isSameRow({a: row, b: rowToUpdate}) &&
        !isRelatedRow({rowToCheck: rowToUpdate, rowToMatch: row})
      ) {
        return rowToUpdate;
      }

      const vatPercentage = getVatPercentage({row: rowToUpdate});
      const totalExcVAT = getTotalExcVat({changedColumnName, row: rowToUpdate});
      const totalExcVatWithDiscount = getTotalExcVatWithDiscount({
        row: rowToUpdate,
        totalExcVAT,
      });
      const totalVat = getTotalVat({
        changedColumnName,
        row: rowToUpdate,
        totalExcVAT: totalExcVatWithDiscount,
        vatPercentage,
      });
      const totalIncVat = totalExcVatWithDiscount + totalVat;

      const orderItemTotalExcVAT = getOrderItemTotalExcVAT({row: rowToUpdate});
      let invoiceDiscrepancy = 0;
      if (row.OrderItemID?.Value !== null) {
        invoiceDiscrepancy = totalExcVatWithDiscount - orderItemTotalExcVAT;
      }

      return {
        ...rowToUpdate,
        TotalExcVAT: {
          ...rowToUpdate.TotalExcVAT,
          Value: totalExcVatWithDiscount,
        },
        TotalVAT: {
          ...rowToUpdate.TotalVAT,
          Value: totalVat,
        },
        TotalIncVAT: {
          ...rowToUpdate.TotalIncVAT,
          Value: totalIncVat,
        },
        InvoiceDiscrepancy: {
          ...rowToUpdate.InvoiceDiscrepancy,
          Value: invoiceDiscrepancy,
        },
      };
    });
  };

const getTotalExcVat = ({changedColumnName, row}) => {
  if (["TotalExcVAT", "TotalVAT"].includes(changedColumnName)) {
    return new Decimal(row.TotalExcVAT?.Value ?? 0).toNumber();
  }

  return new Decimal(row.PurchasePricePerUnit?.Value ?? row.Price?.Value ?? 0)
    .times(row.Amount?.Value ?? 0)
    .toNumber();
};

const getTotalExcVatWithDiscount = ({row, totalExcVAT}) => {
  if (row.Discount?.Value) {
    return new Decimal(totalExcVAT)
      .dividedBy(100)
      .times(100 + (parseFloat(row.Discount.Value) ?? 0));
  }

  return totalExcVAT;
};

const getTotalVat = ({changedColumnName, row, totalExcVAT, vatPercentage}) => {
  if (changedColumnName === "TotalVAT") {
    return row.TotalVAT.Value;
  }
  if (!totalExcVAT || totalExcVAT === 0) {
    return 0;
  }
  return new Decimal(vatPercentage)
    .dividedBy(100)
    .times(totalExcVAT)
    .toNumber();
};

const getVatPercentage = ({row}) => row.VATPercentage?.Value ?? 0;

const getOrderItemTotalExcVAT = ({row}) => row.OrderItemTotalExcVAT?.Value ?? 0;
