import {alertSerialDateConflict} from "./alertSerialDateConflict.js";

export async function processRowConflicts({rowConflicts}) {
  const processedSerialConflicts = [];

  for (const rowConflict of rowConflicts) {
    const {row, reason} = rowConflict;
    const serialId = row.SerialID.Value;

    if (
      reason === "serialRowHasDateConflict" &&
      !processedSerialConflicts.includes(serialId)
    ) {
      const conflictingRow = rowConflicts.find((x) => {
        return (
          x.row.SerialID.Value === row.SerialID.Value &&
          x.row.ClientSideUUID.Value !== row.ClientSideUUID.Value
        );
      });

      await alertSerialDateConflict({serialId, row, conflictingRow});

      processedSerialConflicts.push(serialId);
    }
  }
}
