<template>
  <div>
    <button
      data-window-event="dispose"
      data-window-target="active"
      class="btn-link text-dark btn-icon mt-1 mr-2"
    >
      <div class="float-left">
        <i class="fas fa-times"></i>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "CanvasDisposeButton",
};
</script>

<style scoped></style>
