export const valuesAreEmptyStringAndFalsyValues = ({value1, value2}) => {
  if (
    ((value1 === "" || value1 === 0) &&
      (value2 === null || value2 === undefined)) ||
    ((value2 === "" || value2 === 0) &&
      (value1 === null || value1 === undefined)) ||
    (value1 === "" && value2 === 0) ||
    (value2 === "" && value1 === 0)
  )
    return true;
};
