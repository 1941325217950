export const updateRowSerialPickedQuantity = ({row}) => {
  if (row.SerialID?.Value) {
    return {
      ...row,
      PickedQuantity: {
        ...row.PickedQuantity,
        Value: 1,
      },
      Status: {
        ...row.Status,
        Value: "Picked",
      },
      StatusCode: {
        ...row.StatusCode,
        Value: 150,
      },
    };
  }
  return {
    ...row,
    PickedQuantity: {
      ...row.PickedQuantity,
      Value: 0,
    },
    Status: {
      ...row.Status,
      Value: "Open",
    },
    StatusCode: {
      ...row.StatusCode,
      Value: 100,
    },
  };
};
