import {processLinkedFormFieldFromCriteria} from "./processLinkedFormFieldFromCriteria.js";

export const processChangedFieldExtraCriteria = async ({
  changedField,
  columns,
  row,
}) => {
  const extraCriteria = changedField.Dropdown.ExtraCriteria;

  for (const criterion in extraCriteria) {
    const linkedField = columns[extraCriteria[criterion]?.FormColumnName];

    if (!linkedField) continue;

    const rowValue = row[linkedField.Name];

    if (rowValue) {
      return await processLinkedFormFieldFromCriteria({
        linkedField,
        columns,
        row,
      });
    }
  }

  return {newRow: row, newColumns: columns};
};
