<template>
  <div class="search-input-container">
    <form @submit.prevent="handleSubmit">
      <input
        class="input ignore-next ignore-reset"
        :placeholder="translation('PlaceholderSearch')"
        :value="searchValue"
        @input="updateSearchValue"
      />
    </form>
  </div>
</template>
<script>
import translation from "../../../util/translation.js";
export default {
  name: "FormFilterSearchBarField",
  data() {
    return {
      searchValue: null,
    };
  },
  methods: {
    translation,
    handleSubmit() {
      if (!this.searchValue) return;
      this.$emit("search", this.searchValue);
      this.searchValue = null;
      this.$emit("input", null);
    },
    updateSearchValue(searchValue) {
      this.searchValue = searchValue.target.value;
      this.$emit("input", searchValue.target.value);
    },
  },
};
</script>
