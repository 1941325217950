import getInboundItems from "@/services/inbound/getInboundItems";
import getInboundOrder from "@/services/inbound/getInboundOrder";

export default async function fetchInboundItemsScannerData({
  params = {},
  search = undefined,
} = {}) {
  const order = (await getInboundOrder({param: params.orderId})).data;
  const result = await getInboundItems({params: {search}});
  const orderSupplierId = order.SupplierID.Key ?? order.SupplierID;

  try {
    const itemArray = result.data.Collection;
    const newItemArray = [];

    const shouldIncludeItem = ({SupplierID}) =>
      SupplierID.Key === orderSupplierId;

    const addMatchingItems = (item) => {
      if (shouldIncludeItem(item)) {
        newItemArray.push(transformItem(item));
      }
    };

    itemArray.forEach(addMatchingItems);

    return newItemArray;
  } catch {
    return [];
  }
}

const transformItem = ({RentalItemID, ...rest}) => ({
  ...rest,
  RentalItemID: RentalItemID?.Description || RentalItemID,
});
