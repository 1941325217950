import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export function serialRowHasDateConflict({row, rows}) {
  const serialRowDateTimeExpectedStart = dayjs(row.DateTimeExpectedStart.Value);
  const serialRowDateTimeExpectedEnd = dayjs(row.DateTimeExpectedEnd.Value);
  const serialRowDateTimeShipped = dayjs(row.DateTimeShipped?.Value);
  const serialRowDateTimeReturned = dayjs(row.DateTimeReturned?.Value);
  const {SerialID, Ranking} = row;

  const serialRowHasDateConflict = Object.keys(rows).some((key) => {
    const currentSerialId = getRawValueFromDropdownValue(SerialID.Value);
    const row = rows[key];
    const serialId = getRawValueFromDropdownValue(row.SerialID.Value);

    if (!serialId || !currentSerialId) return false;
    if (currentSerialId !== serialId) return false;
    if (Ranking.Value === row.Ranking.Value) return false;

    const dateTimeExpectedStart = dayjs(row.DateTimeExpectedStart.Value);
    const dateTimeExpectedEnd = dayjs(row.DateTimeExpectedEnd.Value);
    const dateTimeShipped = dayjs(row.DateTimeShipped?.Value);
    const dateTimeReturned = dayjs(row.DateTimeReturned?.Value);

    const serialRowDateTimeExpectedStartToExpectedEndConflict =
      dateTimeExpectedStart
        .add(1, "minute")
        .isBetween(
          serialRowDateTimeExpectedStart,
          serialRowDateTimeExpectedEnd,
        ) ||
      dateTimeExpectedEnd
        .subtract(1, "minute")
        .isBetween(
          serialRowDateTimeExpectedStart,
          serialRowDateTimeExpectedEnd,
        );

    const serialRowDateTimeShippedToReturnedConflict =
      dateTimeShipped
        .add(1, "minute")
        .isBetween(serialRowDateTimeShipped, serialRowDateTimeReturned) ||
      dateTimeReturned
        .subtract(1, "minute")
        .isBetween(serialRowDateTimeShipped, serialRowDateTimeReturned);

    const datesToCompare = [
      [dateTimeExpectedStart, serialRowDateTimeExpectedStart],
      [dateTimeExpectedStart, serialRowDateTimeShipped],
      [dateTimeExpectedEnd, serialRowDateTimeExpectedEnd],
      [dateTimeExpectedEnd, serialRowDateTimeReturned],
    ];

    const sameDates = datesToCompare.some(([date1, date2]) => {
      return date1.isSame(date2);
    });

    if (sameDates) {
      return true;
    }

    return (
      serialRowDateTimeExpectedStartToExpectedEndConflict ||
      serialRowDateTimeShippedToReturnedConflict
    );
  });

  return serialRowHasDateConflict;
}
