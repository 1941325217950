export function processOrderItemAdvancedColumns(columns) {
  const excludableColumns = ["ExpectedCounter", "CounterPrice"];

  return columns.map((column) => {
    if (excludableColumns.includes(column.Name)) {
      return {
        ...column,
        IsExcludable: true,
      };
    }

    return {
      ...column,
      IsExcludable: false,
    };
  });
}
