import {
  getCellText,
  getTextRenderer,
} from "@/components/table/tableCellTypes/getTextRenderer";

export function generateFilterForColumn({rows, column}) {
  const filter = {
    Type: "List",
    Target: column.Name,
    placeholder: column.Title,
    Selected: [],
    Options: [],
  };

  if (column.DefaultFilter) {
    filter.Selected = [column.DefaultFilter];
  }

  // const textRenderer = getTextRenderer({column});

  // const options = getOptions({rows, column});

  // filter.Options = options;

  return filter;
}

function getOptions({rows, column}) {
  const options = [];

  for (const row of rows) {
    const cell = row[column.Name];

    const option = {
      Key: cell.Value,
      Title: getCellText({cell, column}),
    };

    if (!options.some((o) => o.Key === option.Key)) {
      options.push(option);
    }
  }

  return options;
}
