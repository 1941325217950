<template>
  <span
    :style="{'background-color': color, color: textColor}"
    :class="`badge ${this.badgeClass} py-1 `"
  >
    {{ displayValue }}
  </span>
</template>
<script>
import getTextColor from "@/util/getContrastingTextColorByHex";

export function renderAsText({value}) {
  return value.ShortCode ?? value.Description;
}

export default {
  name: "TableCellMultiSelectorValue",
  props: {
    value: {
      type: Object,
      required: false,
    },
  },
  computed: {
    color() {
      return this.value.Color ?? null;
    },
    textColor() {
      if (this.color) {
        return getTextColor(this.color);
      }
      return "inherit";
    },
    badgeClass() {
      if (!this.color) {
        return "bg-secondary";
      }
      return "";
    },
    displayValue() {
      return renderAsText(this);
    },
  },
};
</script>
