export function normalizeValueKeyRows({rows}) {
  return rows.map((row) => {
    const newRow = {};
    Object.keys(row).forEach((key) => {
      if (row[key]?.Value?.Key) {
        newRow[key] = {
          ...row[key],
          Value: row[key].Value.Key,
        };
      } else {
        newRow[key] = row[key];
      }
    });
    return newRow;
  });
}
