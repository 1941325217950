<template>
  <form class="search-bar">
    <div class="search-box" @keydown.delete="deleteLastSearchValue">
      <div>
        <div
          v-if="collapseFilter"
          class="inline-flex mr-2 mb-1 text-gray-600 hover:text-gray-900 cursor-pointer"
          @click="collapsed = !collapsed"
        >
          <i class="fas m-auto" :class="classes"></i>
          <span v-if="title" class="font-bold px-1">
            ({{ selectedValueCount }})</span
          >{{ title }}
        </div>
        <div
          v-if="!collapsed"
          :class="{
            'absolute p-3 bg-white border z-40 rounded-lg shadow':
              !!collapseFilter,
          }"
        >
          <div v-if="collapseFilter" class="font-bold">
            <i class="fas m-auto" :class="classes"></i>
            {{ title }}
          </div>
          <form-filter-bar-filter-options
            :filters="filters"
            @filter-change="$emit('filter-change', $event)"
          />
          <form-filter-bar-filter-search-values
            :search-values="searchValues"
            @remove-search="$emit('remove-search', $event)"
          />
          <form-filter-search-bar-field
            v-if="!disableSearch"
            @search="$emit('search', $event)"
            @input="searchValue = $event"
          />
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import FormFilterBarFilterSearchValues from "./FormFilterBarFilterSearchValues.vue";
import FormFilterBarFilterOptions from "./FormFilterBarFilterOptions.vue";
import FormFilterSearchBarField from "./FormFilterSearchBarField.vue";
export default {
  name: "FormFilterBar",
  components: {
    FormFilterBarFilterSearchValues,
    FormFilterBarFilterOptions,
    FormFilterSearchBarField,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    searchValues: {
      type: Array,
      default: () => [],
    },
    collapseFilter: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    defaultCollapsed: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      searchValue: "",
      collapsed: this.defaultCollapsed && this.icon !== null,
    };
  },

  computed: {
    classes() {
      return {
        [this.icon]: this.icon !== null,
      };
    },
    selectedValueCount() {
      // loop over each filter and check if Selected is a string count that as one and if array count the length
      return this.filters.reduce((acc, filter) => {
        if (typeof filter.Selected === "string") {
          return acc + 1;
        }
        if (Array.isArray(filter.Selected)) {
          return acc + filter.Selected.length;
        }
        return acc;
      }, 0);
    },
  },
  mounted() {
    if (this.collapseFilter)
      window.addEventListener("click", (event) => {
        if (
          !this.$el.contains(event.target) &&
          event.target.closest("#scrollContainer")
        ) {
          this.collapsed = true;
        }
      });
  },
  destroyed() {
    if (this.collapseFilter)
      window.removeEventListener("click", (event) => {
        if (
          !this.$el.contains(event.target) &&
          event.target.closest("#scrollContainer")
        ) {
          this.collapsed = true;
        }
      });
  },
  methods: {
    deleteLastSearchValue() {
      if (this.searchValue) return;
      if (this.searchValues.length > 0) {
        this.$emit(
          "remove-search",
          this.searchValues[this.searchValues.length - 1],
        );
      }
    },
  },
};
</script>
