import {calculateCalculatedPrice} from "./calculateCalculatedPrice.js";
import {calculatePriceDifference} from "./calculatePriceDifference.js";
import {processRowPrices} from "./processRowPrices.js";
import {processTotalExcVat} from "./processTotalExcVat.js";

export const processSingleRow = async function processSingleRow({row, order}) {
  const processedRow = await processRowPrices({row, order});
  const processedTotalExcVATRow = processTotalExcVat({row: processedRow});

  const calculatedRow = await calculateCalculatedPrice({
    order,
    row: processedTotalExcVATRow,
  });

  return calculatePriceDifference({row: calculatedRow});
};
