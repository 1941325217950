export function handleInvoicingStarted({row}) {
  if (row?.DateTimeRentalStart?.Value) {
    const newRow = {...row};
    newRow.DateTimeBusinessStart.IsReadOnly = true;

    return newRow;
  }

  return row;
}
