import {getRailroadTasks} from "@/services/v2/core/railroad-task/getRailroadTasks";

export async function fetchRailRoadTasks(
  {
    search,
    filters,
    orderBy,
    size,
    sortReverse,
    filterOnFirstOpenTaskForEntity,
  } = {
    search: undefined,
    filters,
    orderBy: undefined,
    size: 2000,
    sortReverse: false,
    filterOnFirstOpenTaskForEntity: false,
  },
) {
  const taskCollection = await getRailroadTasks({
    include: "resource",
    filters,
    search,
    orderBy,
    size,
    sortReverse,
    filterOnFirstOpenTaskForEntity,
  });

  const {Collection, Total, Size, Index} = taskCollection;

  return {tasks: Collection, total: Total, size: Size, index: Index};
}
