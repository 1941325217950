import {cloneDeep} from "lodash";
import templateMapping from "../../interface/templater/templateMapping";

const templateMappings = templateMapping;

export const convertLegacyWindowToRWindow = ({
  id,
  sub,
  output,
  tabs,
  vueComponent,
  actionMapping,
  parent,
  data,
  jobs,
  initialData,
}) => {
  const templateMapping = templateMappings.find(
    (template) =>
      (template.Subject === output?.Request?.Subject &&
        template.Prefix?.includes(output?.Request?.Prefix)) ||
      (template.Prefix?.includes(output?.Request?.Prefix) &&
        template.Subject === "*" &&
        !template.ExcludedSubjects?.includes(output?.Request?.Subject)),
  );
  const getInitialData = ({initialData, output}) => {
    if (initialData && initialData.Rows?.length) {
      return initialData;
    }
    return cloneDeep(output?.Data);
  };

  const subWindowId = sub?.window?.id;
  const parentWindowId = parent?.id;

  const rows = data?.Rows?.length ? data?.Rows : output?.Data?.Rows ?? [];

  return {
    id,
    buttons: output?.Buttons,
    filters: output?.Filters,
    fullTable: output?.FullTable,
    criteria: output?.Request?.Criteria,
    prefix: output?.Request?.Prefix,
    subject: output?.Request?.Subject,
    tabs,
    subWindowId,
    jobs: jobs ?? [],
    parentWindowId,
    request: output.Request,
    data: {
      ...output.Data,
      Rows: rows,
    },
    initialData: getInitialData({initialData, output}),
    actionMapping,
    vueComponent: templateMapping
      ? templateMapping.vueComponent
      : vueComponent ?? null,
  };
};
