async function importDatagridCellType(type) {
  try {
    await import(
      `@/components/datagrid/datagridCellTypes/DatagridCell${type}.vue`
    );
  } catch (err) {
    if (err.code == "MODULE_NOT_FOUND") return;

    throw err;
  }
}

async function sanitizeCell({cell, column, row}) {
  const Component = await importDatagridCellType(column.Editor ?? column.Type);

  if (!Component) return;
  if (!Component.parseServerValue) return;

  cell.Value = Component.parseServerValue(cell.Value, row);
}

export async function sanitizeServerRows({rows, columns}) {
  const jobs = [];

  for (const row of rows) {
    for (const columnName in row) {
      const cell = row[columnName];
      const column = columns.find((column) => column.Name === columnName);

      if (!column) continue;

      jobs.push(sanitizeCell({cell, column, row}));
    }
  }

  await Promise.all(jobs);
}
