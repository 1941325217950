import {fetchDimensionObjects} from "@/functions/datagrid/order-item/dimensions/fetchDimensionObjects";
import {getUniqueDimensionsFromDimensionObjects} from "@/functions/datagrid/order-item/dimensions/getUniqueDimensionsFromDimensionObjects";
import {addDimensionValueWhenMissingToRows} from "@/functions/datagrid/order-item/dimensions/addDimensionValueWhenMissingToRows";

export async function processCustomerDimensions({customerId, columns, rows}) {
  let newRows = rows.slice();
  const dimensionObjects = await fetchDimensionObjects({
    customerId,
  });

  if (!dimensionObjects || dimensionObjects.Error) {
    return {columns, rows: newRows};
  }

  const dimensions = await getUniqueDimensionsFromDimensionObjects({
    dimensionObjects,
  });

  let index = 1;
  for (const dimension of dimensions) {
    const column = {
      Name: `DimensionID-${dimension.DimensionID.Key}`,
      Description: dimension.DimensionID.Description,
      Title: dimension.DimensionID.Description,
      IsAdvancedColumn: true,
      IsVisible: true,
      Ranking: 1000 + index,
      Editor: "Select",
      Value: null,
      ColumnWidth: 1,
    };
    columns.push(column);
    index++;
    newRows = addDimensionValueWhenMissingToRows({rows: newRows, dimension});
  }

  return {columns, rows: newRows};
}
