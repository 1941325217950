export default function locale(lang) {
  if (lang === "nl") {
    return {
      direction: "ltr",
      format: "dd-mm-yyyy",
      separator: " t/m ",
      applyLabel: "Toepassen",
      cancelLabel: "Annuleren",
      weekLabel: "W",
      customRangeLabel: "Aangepaste periode",
      daysOfWeek: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      firstDay: 0,
    };
  }

  if (lang === "de") {
    return {
      direction: "ltr",
      format: "dd.mm.yyyy",
      separator: " bis ",
      applyLabel: "Übernehmen",
      cancelLabel: "Abbrechen",
      weekLabel: "W",
      customRangeLabel: "Benutzerdefiniert",
      daysOfWeek: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      monthNames: [
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
      ],
      firstDay: 1,
    };
  }

  if (lang === "fr") {
    return {
      direction: "ltr",
      format: "dd/mm/yyyy",
      separator: " au ",
      applyLabel: "Appliquer",
      cancelLabel: "Annuler",
      weekLabel: "W",
      customRangeLabel: "Personnalisé",
      daysOfWeek: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      monthNames: [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ],
      firstDay: 1,
    };
  }

  return {
    direction: "ltr",
    format: "dd-mm-yyyy",
    separator: " to ",
    applyLabel: "Apply",
    cancelLabel: "Cancel",
    weekLabel: "W",
    customRangeLabel: "Custom period",
    daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    firstDay: 0,
  };
}
