<template>
  <div class="min-h-[32.5px]">
    <r-tree-select
      ref="tree-select-field"
      :value="value"
      :input-id="fieldName"
      :options="formattedOptions"
      :readonly="readonly"
      :required="required"
      :loading="loading"
      @input="handleInput($event)"
      @open="handleDropdownOpened"
    />
  </div>
</template>

<script>
import {formatTreeSelectOptions} from "../../../functions/fields/treeselect-field/formatTreeSelectOptions.js";
import {newOptionsAreEqual} from "../../../functions/fields/select-field/newOptionsAreEqual.js";
import {getOptions} from "../../../functions/fields/select-field/getOptions.js";
import RTreeSelect from "../../elements/RTreeSelect.vue";

export default {
  name: "MultiSelectField",
  components: {RTreeSelect},
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      loading: false,
    };
  },
  computed: {
    value() {
      return this.field.Value;
    },
    formattedOptions() {
      return formatTreeSelectOptions({options: this.options});
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    readonly() {
      return this.field.IsReadOnly;
    },
    required() {
      return this.field.IsRequired;
    },
  },
  created() {
    this.options = this.field.Dropdown?.Items ?? [];
  },
  async mounted() {
    const componentElement =
      this.$refs["tree-select-field"].$refs["r-tree-select"].$el;
    const isDialogField = !!componentElement.closest(".vue-portal-target");

    if (this.field.Dropdown?.AutoFetchOptions || isDialogField) {
      return await this.loadOptions();
    }
  },
  methods: {
    async handleDropdownOpened() {
      if (!this.readonly) await this.loadOptions();
    },
    async loadOptions(searchValue) {
      const options = await getOptions({
        field: this.field,
        row: this.row,
        vueComponent: this,
        searchValue,
      });

      if (!newOptionsAreEqual({newOptions: options, oldOptions: this.options}))
        this.options = options;

      this.loading = false;
    },
    handleInput(newValue) {
      this.$emit("input", {Value: newValue});
    },
  },
};
</script>

<style></style>
