import {sumValues} from "../../../../../util/math/sumValues";
import Decimal from "decimal.js";

export const getRoundingError = ({rows}) => {
  const totalValue = sumValues(rows.map((row) => row.TotalExcVAT?.Value ?? 0));
  const totalDistributedValue = sumValues(
    rows.map((row) => row.InboundValue?.Value ?? 0),
  );

  return new Decimal(totalValue).minus(totalDistributedValue).toNumber();
};
