export function processColumnTypes({columns}) {
  return columns.map((column) => {
    return {
      ...column,
      Editor:
        column.Editor === "multi-selector" || column.Editor === "multi-select"
          ? "Checkbox"
          : column.Editor === "Select"
          ? "String"
          : column.Editor,
      IsEditorColumn: column.Name === "Checked",
    };
  });
}
