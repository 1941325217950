export const findUniqueCompositionRows = ({rows, targetValue}) =>
  rows
    .filter((row) => row.CompositionID.Value === targetValue)
    .reduce((acc, currentRow) => {
      if (
        !acc.find(
          (item) => item.Composition.Value === currentRow.Composition.Value,
        )
      ) {
        return acc.concat([currentRow]);
      }
      return acc;
    }, []);
