import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {handleItemTypeSalesRowChange} from "../../row/handleItemTypeSalesRowChange.js";

export async function processItemType({row, oldRow}) {
  const itemType = getRawValueFromDropdownValue(row.ItemType.Value);
  let newRow = {...row};

  if (itemType === "Sales") {
    newRow.LockPrice.IsReadOnly = true;
    newRow.LockPrice.Value = true;
    if (newRow.PriceCalcReason) {
      newRow.PriceCalcReason.Value = null;
    }
    if (newRow.PriceProjection) {
      newRow.PriceProjection.Value = null;
    }

    newRow.Price = {
      ...newRow.Price,
      IsReadOnly: false,
      Icon: null,
    };
    newRow.DateTimeExpectedEnd = {
      ...newRow.DateTimeExpectedEnd,
      OldValue: newRow.DateTimeExpectedEnd.Value,
      Value: null,
      IsReadOnly: true,
    };

    newRow.DateTimeBusinessEnd = {
      ...newRow.DateTimeBusinessEnd,
      Value: null,
      IsReadOnly: true,
    };
  } else {
    newRow.Price.IsReadOnly = true;
    newRow.LockPrice.IsReadOnly = false;
  }

  newRow = handleItemTypeSalesRowChange({newRow, oldRow});

  const processOnCounter = ["RentalCounter", "RentalBoth"];
  if (!processOnCounter.includes(itemType)) {
    newRow.ExpectedCounter = {
      ...newRow.ExpectedCounter,
      IsVisible: false,
      IsReadOnly: true,
    };
  }

  return newRow;
}
