export const processRowFullyInvoiced = ({row}) => {
  if (row.IsFullyInvoiced?.Value !== true) return row;
  return {
    ...row,
    Discount: {
      ...row.Discount,
      IsReadOnly: true,
    },
    LockPrice: {
      ...row.LockPrice,
      IsReadOnly: true,
    },
    DateTimeRentalEnd: {
      ...row.DateTimeRentalEnd,
      IsReadOnly: true,
    },
    Period: {
      ...row.Period,
      IsReadOnly: true,
    },
    PeriodAmount: {
      ...row.PeriodAmount,
      IsReadOnly: true,
    },
    PeriodPrice: {
      ...row.PeriodPrice,
      IsReadOnly: true,
    },
  };
};
