export function rowRemoveAllNonExistingColumnProperties({row, columns}) {
  const cleanRow = {...row};
  const columnNameArray = columns.map((column) => column.Name);

  for (const key in row) {
    if (!columnNameArray.includes(key)) {
      delete cleanRow[key];
    }
  }
  return cleanRow;
}
