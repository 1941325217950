import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {processUseSinglePeriodPrice} from "../../row/processUseSinglePeriodPrice";
import {getRowPrices} from "@/functions/datagrid/order-item/row/getRowPrices";
import {getSettings} from "@/util/getSettings";
import {getActiveWarehouse} from "../../../../util/getActiveWarehouse.js";
import {isPriceCalculationDataMissing} from "../../row/isPriceCalculationDataMissing.js";
import {removePricesFromRow} from "../../row/removePricesFromRow.js";

export const processRowPrices = async ({row, order}) => {
  const {PeriodSelection} = getSettings();

  if (row.LockPrice.Value) return {...row};

  const itemType = getRawValueFromDropdownValue(row.ItemType.Value);
  const processOnItemType = ["RentalCounter", "RentalPeriod", "RentalBoth"];
  if (!processOnItemType.includes(itemType)) return {...row};

  const defaultPeriodSelection = PeriodSelection === "Default";
  const ItemSinglePeriodEnforced =
    PeriodSelection === "ItemSinglePeriodEnforced";
  const allowItemSinglePeriodCalculateEndDate =
    PeriodSelection === "ItemSinglePeriodCalculateEndDateEnforced";

  if (
    isPriceCalculationDataMissing({
      row,
      defaultPeriodSelection,
      ItemSinglePeriodEnforced,
      allowItemSinglePeriodCalculateEndDate,
    })
  ) {
    return removePricesFromRow(row);
  }

  const rowValues = {};
  const priceBulkParams = {
    WarehouseID: getActiveWarehouse(),
    CustomerID: getRawValueFromDropdownValue(order.CustomerID),
    OrderID: order.OrderID,
  };

  if (
    (allowItemSinglePeriodCalculateEndDate || ItemSinglePeriodEnforced) &&
    !defaultPeriodSelection &&
    row.Period.Value
  ) {
    const newRow = await processUseSinglePeriodPrice({
      row,
      params: priceBulkParams,
      periodAmount: row.PeriodAmount.Value,
      startDate: row.DateTimeBusinessStart.Value,
      endDate: row.DateTimeRentalEnd?.Value ?? row.DateTimeBusinessEnd.Value,
    });

    return newRow;
  }

  if (!defaultPeriodSelection && row.Period.Value && row.PeriodPrice.Value) {
    const newRow = await processUseSinglePeriodPrice({
      row,
      params: priceBulkParams,
      startDate: row.DateTimeBusinessStart.Value,
      endDate: row.DateTimeRentalEnd?.Value ?? row.DateTimeBusinessEnd.Value,
    });

    return newRow;
  }

  // loop through row object and set rowValues to row
  Object.keys(row).forEach((key) => {
    // check if row[key].Value is object
    if (typeof row[key] === "object") {
      rowValues[key] = row[key].Value;
      if (typeof row[key].Value === "object") {
        rowValues[key] =
          row[key].Value?.Key ?? row[key].Value?.Description ?? null;
      }
    } else {
      rowValues[key] = row[key];
    }
  });

  if (row.HasPeriodPricesForCalculation?.Value) {
    const newRow = await processUseSinglePeriodPrice({
      row,
      params: priceBulkParams,
      periodAmount: row.PeriodAmount?.Value,
      startDate: row.DateTimeBusinessStart.Value,
      endDate: row.DateTimeRentalEnd?.Value ?? row.DateTimeBusinessEnd.Value,
    });

    return newRow;
  }

  const prices = await getRowPrices({
    rowValues,
    order,
  });

  // loop through prices object and set row[key].Value to prices[key]

  Object.keys(prices).forEach((key) => {
    row[key] = {
      ...row[key],
      Value: prices[key],
    };
  });

  return {...row};
};
