<template>
  <div v-if="buttons.length > 0" class="actions mb-4">
    <p class="title">{{ $store.state.translations.Actions }}</p>
    <hr />
    <action-bar-action
      v-for="button in buttons"
      :key="button.ActionName"
      :button="button"
      :window="window"
      :sub-window-id="subWindowid"
      :active-sub-window-id="activeSubWindowId"
      :active-window-id="activeWindowId"
      :window-id="activeWindowId"
      :subject="subject"
      @process-actions="processActions"
      @new-job="$emit('new-job', $event)"
    />
  </div>
</template>
<script>
import processComponentActions from "../../../functions/processComponentActions";
import ActionBarAction from "./ActionBarAction.vue";
import {processActions} from "./util/processActions";

export default {
  components: {
    ActionBarAction,
  },
  props: {
    window: {
      required: true,
      type: Object,
    },
    subWindowid: {
      required: false,
      type: String,
      default: null,
    },
    buttons: {
      required: true,
      type: Array,
    },
    subWindow: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
    activeWindowId: {
      required: false,
      type: String,
      default: null,
    },
    activeSubWindowId: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    subject: function () {
      return (
        this.subWindow?.output?.Data?.Subject ??
        this.window?.output?.Data?.Subject ??
        this.subWindow?.output?.Request?.Subject ??
        this.window?.output?.Request?.Subject
      );
    },
    loading: function () {
      return this.$store.state.loading;
    },
  },
  methods: {
    async processActions(button) {
      return await processActions({
        button,
        vueInstance: this,
        activeWindowId: this.activeWindowId,
        activeSubWindowId: this.activeSubWindowId,
      });
    },
    async processGlobalActions(actions) {
      for (const action of actions) {
        const importedModule = await import(`@/actions/${action.Function}.js`);
        importedModule.default(action.Data);
      }
    },
    processComponentActions,
  },
};
</script>
<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 15.4px;
}
</style>
