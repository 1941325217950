export function processRowDropdownCells({row}) {
  const modifiedRow = {...row};

  for (let key in modifiedRow) {
    if (key === "ItemUnitID") {
      modifiedRow[key] = {
        ...row[key],
        eagerOptionsFetch: true,
      };
    }
  }

  return modifiedRow;
}
