
        
    const runtime = require("../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["src/components/charts/timeline/chartTimelineOrderPopup.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"availabilityList container pt-3\">\n  <div class=\"text-center\">\n    <p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AdminDesktop_Order"), env.opts.autoescape);
output += "\n      <br>\n      <strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"OrderID"), env.opts.autoescape);
output += "</strong>\n    </p>\n  </div>\n\n  <table class=\"table table-sm\">\n    <tbody>\n      <tr>\n        <td>\n          <strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AdminDesktop_Order"), env.opts.autoescape);
output += "</strong>\n        </td>\n        <td></td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"OrderID"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"OrderID"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Customer"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"AddressCompanyName"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"RentalDateTimeExpectedStart"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue((lineno = 26, colno = 55, runtime.callWrap(runtime.memberLookup(((lineno = 26, colno = 19, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "dayjs"), "dayjs", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"DateTimeExpectedStart")]))),"format"), "the return value of (dayjs)[\"format\"]", context, ["DD-MM-YYYY"])), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"BusinessHour"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"BusinessHourStart"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"TransactionType"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"TransactionTypeID")),"Description"), env.opts.autoescape);
output += "\n        </td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Reference"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"Reference"), env.opts.autoescape);
output += "</td>\n      </tr>\n      ";
output += "\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Delivery"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"DeliveryID"), env.opts.autoescape);
output += "\n        </td>\n      </tr>\n      <tr>\n        <td>\n          <strong>Artikelen</strong>\n        </td>\n        <td></td>\n      </tr>\n      <tr>\n        <td>Verhuur</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"AmountOfItemTypeRental"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>Verkoop</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"AmountOfItemTypeSales"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>Vrije</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "order")),"AmountOfItemTypeFree"), env.opts.autoescape);
output += "</td>\n      </tr>\n    </tbody>\n  </table>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("src/components/charts/timeline/chartTimelineOrderPopup.html", ctx);
          }
        
          return nunjucks.render("src/components/charts/timeline/chartTimelineOrderPopup.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["src/components/charts/timeline/chartTimelineOrderPopup.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        