<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <DatagridStandalone
      v-if="loaded && columns.length"
      :rows="rows"
      :columns="columns"
      :window-id="rWindow.id"
      :user-metadata-key="userMetadataKey"
      :page="page"
      :page-size="pageSize"
      @loading="loading = $event"
      @page-change="page = $event"
      @cell-click="handleCellClick($event)"
      @rows-updated="handleRowsUpdated"
      @column-header-change="handleColumnHeaderChange"
    >
      <template #header>
        <datagrid-header-partial-delivery
          :loaded="loaded"
          :page-size="pageSize"
          @page-size-change="handlePageSizeChange"
        />
      </template>
    </DatagridStandalone>
  </div>
</template>

<script>
import {setInitialDatagridDataToWindow} from "../../../functions/datagrid/data/setInitialDatagridDataToWindow.js";
import {convertKeyValueRowsToCellRows} from "../../../functions/datagrid/rows/convertKeyValueRowsToCellRows";
import {confirmDiscardUnsavedChanges} from "../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {processPartialDeliveryRows} from "../../../functions/datagrid/partialdelivery/processPartialDeliveryRows";
import {detectDatagridDataChanges} from "../../../functions/datagrid/detectDatagridDataChanges.js";
import {handleCheckboxStateChange} from "../../../functions/datagrid/partialdelivery/handleCheckboxStateChange.js";
import {convertRowToliRowDataRow} from "../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {setClientSideUUIDToRows} from "../../../functions/datagrid/rows/setClientSideUUIDToRows";
import {assignValuesToRowsProps} from "../../../functions/datagrid/rows/assignValuesToRowsProps";
import {processColumnsMetaData} from "../../../functions/datagrid/partialdelivery/processColumnsMetaData";
import {bundleRowsWithCriteria} from "../../../functions/datagrid/partialdelivery/bundleRowsWithCriteria";
import {getDialogFieldValues} from "../../../functions/dialog/getDialogFieldValues";
import {handleCheckboxCheck} from "../../../functions/datagrid/partialdelivery/handleCheckboxCheck.js";
import {handleJobExecution} from "../../../functions/datagrid/actions/handleJobExecution.js";
import {processColumnTypes} from "../../../functions/datagrid/processColumnTypes.js";
import {checkRowsPrepared} from "../../../functions/datagrid/partialdelivery/checkRowsPrepared.js";
import {toggleRowsChecked} from "../../../functions/datagrid/rows/toggleRowsChecked.js";
import {deliverOrderItems} from "../../../services/partial-delivery/deliverOrderItems.js";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";
import {sortColumns} from "../../../functions/datagrid/columns/sortColumns";
import {getColumns} from "../../../functions/datagrid/columns/getColumns";
import {getUserId} from "../../../functions/session/getUserId";
import {cloneDeep} from "lodash";
import openDialog from "../../../actions/openDialog";
import getOrder from "../../../services/orders/getOrder";
import {notify} from "../../../util/notify.js";
import dayjs from "dayjs";

import DatagridHeaderPartialDelivery from "../datagridHeaders/DatagridHeaderPartialDelivery.vue";
import DatagridStandalone from "../DatagridStandalone.vue";

export default {
  Name: "DatagridPartialDelivery",
  components: {
    DatagridHeaderPartialDelivery,
    DatagridStandalone,
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingPage: false,
      loading: false,
      loaded: false,
      criteria: [],
      columns: {},
      order: {},
      rows: [],
      originalRows: [],
      page: 1,
      pageSize: 100,
      translations: getTranslations(),
    };
  },
  computed: {
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Rental.virtual_PartialDelivery-Multi`;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.criteria = this.rWindow.criteria ?? this.rParentWindow.criteria;
    this.order = await getOrder({
      orderId: this.criteria[0].OrderID,
    });

    let columns = await getColumns({
      table: "Rental.virtual_PartialDelivery",
      primaryKey: "OrderID",
      prefix: "Multi",
    });

    columns = processColumnTypes({columns});
    columns = processColumnsMetaData({columns});
    this.columns = sortColumns(columns);

    let rows = Object.values(this.rWindow.data.Rows);
    rows = convertKeyValueRowsToCellRows(rows);
    rows = setClientSideUUIDToRows({rows});
    rows = processPartialDeliveryRows({rows, translations: this.translations});
    this.rows = rows;
    this.originalRows = cloneDeep(this.rows);

    const columnsObject = this.columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {});

    setInitialDatagridDataToWindow({
      window: this.rWindow,
      columns: columnsObject,
      rows: this.rows,
      vueInstance: this,
    });

    this.loaded = true;
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async save(job) {
      try {
        if (!this.loaded) return false;
        this.$store.state.loading = true;
        document.activeElement.blur();

        while (this.loading || this.loadingPage) {
          await new Promise((r) => setTimeout(r, 0));
        }

        const isAnyRowPrepared = checkRowsPrepared({
          rows: this.rows,
          translations: this.translations,
        });

        if (!isAnyRowPrepared) return false;
        const currentDirtyState = this.rWindow.data.dirty;
        this.$emit("data-change", {
          windowId: this.rWindow.id,
          newData: {
            ...this.rWindow.data,
            dirty: false,
          },
        });

        const valueRows = assignValuesToRowsProps({rows: this.rows});

        const filteredRows = valueRows.filter((row) => row.Checked === true);

        const saveRows = bundleRowsWithCriteria({rows: filteredRows});

        const save = async ({fields}) => {
          const {success} = await deliverOrderItems({
            rows: saveRows,
            order: this.order,
            fields,
          });

          if (!success) {
            this.$emit("data-change", {
              windowId: this.rWindow.id,
              newData: {
                ...this.rWindow.data,
                dirty: currentDirtyState,
              },
            });
            return false;
          }
          notify({
            message: this.translations["SaveSuccessful"],
            type: "success",
          });
        };

        if (
          dayjs(this.getMinSelectedDateTimeExpectedStart()).format(
            "YYYY-MM-DD",
          ) < dayjs().format("YYYY-MM-DD")
        ) {
          this.openDateTimeShippedDialog({save});
        } else {
          await save({});
        }
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);

        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }
        this.$store.state.loading = true;

        let columns = await getColumns({
          table: "Rental.virtual_PartialDelivery",
          primaryKey: "OrderID",
          prefix: "Multi",
        });

        columns = processColumnTypes({columns});
        columns = processColumnsMetaData({columns});
        this.columns = sortColumns(columns);

        let rows = Object.values(this.rWindow.data.Rows);
        rows = convertKeyValueRowsToCellRows(rows);
        rows = setClientSideUUIDToRows({rows});
        rows = processPartialDeliveryRows({
          rows,
          translations: this.translations,
        });
        this.rows = rows;
        this.originalRows = cloneDeep(this.rows);

        const columnsObject = this.columns.reduce((acc, column) => {
          acc[column.Name] = column;
          return acc;
        }, {});

        setInitialDatagridDataToWindow({
          window: this.rWindow,
          columns: columnsObject,
          rows: this.rows,
          vueInstance: this,
        });
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    handleRowsUpdated({rows}) {
      this.rows = rows;
      this.updateWindowData();
    },
    updateWindowData() {
      const columnsObject = this.columns.reduce((acc, column) => {
        acc[column.Name] = column;
        return acc;
      }, {});

      const windowData = {
        ...this.rWindow.data,
        Columns: columnsObject,
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },
    handleCellClick({row, column}) {
      if (
        (column.IsReadOnly || column.Name === "Checked") &&
        column.Name !== "Option"
      ) {
        let newRows = handleCheckboxCheck({
          rows: this.rows,
          row,
        });

        setTimeout(() => {
          this.rows = handleCheckboxStateChange({rows: newRows});
          this.updateWindowData();
        }, 130);
      }
    },
    async handleColumnHeaderChange(event) {
      if (event.column.Name === "Checked") {
        this.rows = toggleRowsChecked({
          rows: this.rows,
          value: event.value.Value,
        });
        this.updateWindowData();
      }
    },
    getMinSelectedDateTimeExpectedStart: function () {
      const selectedRows = this.rows.filter(
        (row) => row.Checked.Value === true,
      );
      if (selectedRows.length === 0) {
        return null;
      }
      return selectedRows.map((oi) => oi.DateTimeExpectedStart.Value).sort()[0];
    },
    openDateTimeShippedDialog({save}) {
      const dialogData = {
        RawTitle: "SelectDateTimeShipped",
        Data: {
          Actions: [
            {
              RawTitle: "Cancel",
              ButtonVariant: "secondary",
              Callback: () => {
                this.$store.commit("dialog/hideDialog");
              },
            },
            {
              RawTitle: "ButtonSend",
              Callback: async (dialogData) => {
                const fields = getDialogFieldValues(dialogData);
                save({fields});
                this.$store.commit("dialog/hideDialog");
              },
            },
          ],
          Fields: [
            {
              Name: "DateTimeShipped",
              RawTitle: "DateTimeShipped",
              Type: "Date",
              Value: dayjs(this.getMinSelectedDateTimeExpectedStart()).format(
                "YYYY-MM-DD",
              ),
              ClearOnChange: ["BusinessHour"],
            },
            {
              Name: "BusinessHour",
              RawTitle: "BusinessHourStart",
              Type: "Select",
              Dropdown: {
                Items: null,
                TableName: "Settings.BusinessHour",
                ColumnName: "BusinessHour",
                ExtraKeys: ["WarehouseID", "DateTimeShipped"],
                ExtraCriteria: [],
                PassthroughValues: null,
                OpenRef: true,
              },
            },
          ],
        },
      };

      if (
        this.rows
          .filter((row) => row.Checked.Value === true)
          .some((oi) => oi.DateTimeRentalStart.Value === null)
      ) {
        dialogData.Data.Fields.push({
          Name: "ChangeRentalStartDate",
          RawTitle: "ChangeRentalStartDate",
          Type: "Boolean",
        });
      }
      openDialog(dialogData);
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    navigateToPage({page}) {
      this.page = page;
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
