<template>
  <div class="login cover v-center">
    <div class="container">
      <div class="box pull-center">
        <form @submit.prevent="submit">
          <div class="logo">
            <span
              :class="[
                'logo-icon',
                'infodatek-icon',
                'infodatek-icon-' + (session.product || '').toLowerCase(),
              ]"
            ></span
            ><br />
            <span class="logo-text">{{ session.product }}</span>
          </div>

          <div v-if="error" class="error-box">
            {{ error }}
          </div>
          <div class="form-element">
            <RButton :variant="'secondary'" @click.stop="showLogin()">
              {{ translations.Back }}
            </RButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import postSAMLLogin from "../../services/authentication/postSAMLLogin";
import RButton from "../elements/RButton.vue";

export default {
  name: "LoginSaml",
  components: {RButton},
  props: {
    session: {
      type: Object,
      default: () => {},
    },
    loginToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ssoData: {
        userid: null,
        token: null,
      },
      error: null,
      successMessage: "",
      verificationToken: "",
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  created: async function () {
    this.readSSOData();

    await this.login();
  },
  methods: {
    readSSOData() {
      const url = location.hash.substring(1);

      if (url !== "" && url.includes("userid")) {
        this.$data.ssoData = JSON.parse(
          '{"' +
            decodeURI(url)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}',
        );
      }

      location.hash = "";
    },
    async login() {
      const response = await postSAMLLogin({
        product: "RentMagic",
        userId: this.$data.ssoData.userid,
        token: this.$data.ssoData.token,
      });

      if (response.data.Status === "Success") {
        this.$emit("setBearerToken", response.data.Token);
        this.$emit("showComponent", "loginSessionList");
      } else {
        this.$data.error = response.data.Error;
      }
    },
    showLogin() {
      this.$emit("showComponent", "loginPage");
    },
  },
};
</script>

<style scoped></style>
