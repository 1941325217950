export const disposeWindowAndSubs = ({window, vueInstance}) => {
  const subWindow = window.sub?.window?.id;

  // eslint-disable-next-line no-unused-vars
  let {
    [window.id]: _del1,
    [subWindow]: _del2,
    ...remaining
  } = vueInstance.rWindows;

  vueInstance.rWindows = remaining;
};
