import {handleJsFunctionPayload} from "../../window/handleJsFunctionPayload";
import {processGlobalActions} from "../../window/processGlobalActions";
import {processLegacyJob} from "./processLegacyJob";

export const processJob = async ({job, vueInstance}) => {
  const allowedToProgress = await vueInstance[job.actionName](job);
  if (allowedToProgress === false) return;

  const {windowId} = job;
  const event = job.context.button.Event;
  const {GlobalActions, JsFunctionPayload} = job.context.button;
  await processGlobalActions({globalActions: GlobalActions});

  if (JsFunctionPayload)
    return await handleJsFunctionPayload({
      jsFunctionPayload: JsFunctionPayload,
    });

  if (event) await processLegacyJob({event, windowId});

  vueInstance.$emit("job-completed", job);
};
