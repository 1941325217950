import {getCostsToDistribute} from "./getCostsToDistribute";
import {setValuesInRow} from "../../row/setValuesInRow";
import {sumValues} from "../../../../../util/math/sumValues";
import {correctRoundingError} from "./correctRoundingError";
import {calculateWeighedInboundValue} from "./calculateWeighedInboundValue";
import Decimal from "decimal.js";

export const distributeCostsWeighed = ({rows}) => {
  const costsToDistribute = getCostsToDistribute({rows});

  const totalExcVat = sumValues(rows.map((row) => row.TotalExcVAT?.Value ?? 0));

  const receiverRowsTotalValue = new Decimal(totalExcVat)
    .minus(costsToDistribute)
    .toNumber();

  const updatedRows = rows.map((row) =>
    setValuesInRow({
      row,
      values: {
        InboundValue: calculateWeighedInboundValue({
          row,
          rows,
          receiverRowsTotalValue,
          costsToDistribute,
        }),
      },
    }),
  );
  return correctRoundingError({rows: updatedRows});
};
