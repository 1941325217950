export const normalizeRows = ({rows}) => {
  return rows.map((row) => {
    let modifiedRow = {};
    Object.keys(row).forEach((key) => {
      if (row[key]?.Key) {
        modifiedRow[key] = row[key].Key;
      } else {
        modifiedRow[key] = row[key];
      }
    });
    return modifiedRow;
  });
};
