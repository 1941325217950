import {setRowsCustomPropertiesToCustomPropertyObject} from "../rows/setRowsCustomPropertiesToCustomPropertyObject";

export const processRowsChanges = async ({rows, row, column, context}) => {
  let modifiedRows = setRowsCustomPropertiesToCustomPropertyObject({rows: rows.slice()});

  for (const processValueChangeFunctionsForRow of column.processValueChangeFunctionsForRows ||
    []) {
    const modifiedRow =
      modifiedRows.find(
        (x) => x.ClientSideUUID.Value === row.ClientSideUUID.Value,
      ) ?? row;
    modifiedRows = await processValueChangeFunctionsForRow({
      ...context,
      rows: modifiedRows,
      row: modifiedRow,
    });
  }

  return setRowsCustomPropertiesToCustomPropertyObject({
    rows: modifiedRows,
  });
};
