import {api} from "@/services/api";

export const getDraftTotals = async ({event, context}) => {
  return await api.post(
    "/v2/rental/quotationitem/totals/updated/draft",
    context,
    {
      params: {
        EventID: event,
      },
    },
  );
};
