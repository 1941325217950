import store from "../../../state/store";

/**
 * Reset window completely
 * @param {string} shouldConfirm - If window should ask for comfirmation before closing
 * @param {boolean} shouldFocus - Should this window be focused?
 * @returns {Object} newly fetched data
 */
export default async function reset(shouldConfirm = null, shouldFocus = true) {
  if (this.output?.Data?.Type === "kanbanboard") {
    let url = `/Admin/WebServices/CoreWebServices.asmx/OpenWindow`;
    this.output = await this.session.request(
      url,
      {
        request: this.input,
      },
      {Refresh: true},
    );

    await this.process(this.output);

    store.commit("windows/setWindowOutput", {
      windowId: this.id,
      output: this.output,
    });
    store.commit("updateWindow");

    store.commit("windows/increaseReloadIteration", {windowId: this.id});
    return;
  }

  await store.dispatch("resetWindow", {
    windowid: this.id,
    shouldConfirm,
    shouldFocus,
  });
}
