import {scan} from "../../../services/v2/picklist/scan";
import store from "../../../../state/store";

export const scanPickListItem = async ({search, items}) => {
  const {data} = await scan({
    scannedValue: search,
    items,
    warehouseId: store.state.activeWarehouse,
  });

  return data;
};
