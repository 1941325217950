import {setValueInRow} from "./setValueInRow";

export const setValuesInRow = ({row, values}) => {
  let updatedRow = row;
  Object.keys(values).forEach((columnName) => {
    updatedRow = setValueInRow({
      row: updatedRow,
      value: values[columnName],
      columnName,
    });
  });
  return updatedRow;
};
