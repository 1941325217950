import getOrderItemsStockPrediction from "@/services/v2/rental/order/getOrderItemsStockPrediction";

export async function getStockForOrderItems({rowsToLoad, order, customId}) {
  const orderItems = rowsToLoad.map((row) => {
    return {
      OrderItemID: row.OrderItemID?.Value,
      DateTimeExpectedStart:
        row.DateTimeShipped?.Value ?? row.DateTimeExpectedStart?.Value,
      DateTimeExpectedEnd: row.DateTimeExpectedEnd?.Value,
      Ranking: row.Ranking?.Value,
      ItemID: row[customId]?.Value ?? row.ItemID?.Value,
      Amount: row.Amount?.Value,
      ConditionID: row.ConditionID?.Value?.Key ?? row.ConditionID?.Value,
      WarehouseID: row.WarehouseID?.Value?.Key ?? row.WarehouseID?.Value,
    };
  });

  const stockPredictions = await getOrderItemsStockPrediction({
    orderItems,
    orderId: order.OrderID,
  });

  return stockPredictions;
}
