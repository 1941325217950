import AutoIncrementField from "../../../components/form/fields/AutoIncrementField.vue";
import CheckboxField from "../../../components/form/fields/CheckboxField.vue";
import ColorField from "../../../components/form/fields/ColorField.vue";
import DateField from "../../../components/form/fields/DateField.vue";
import DatetimeField from "../../../components/form/fields/DatetimeField.vue";
import DecimalField from "../../../components/form/fields/DecimalField.vue";
import MoneyField from "../../../components/form/fields/MoneyField.vue";
import MultiSelectField from "../../../components/form/fields/MultiSelectField.vue";
import MultiTreeSelectField from "../../../components/form/fields/MultiTreeSelectField.vue";
import NumberField from "../../../components/form/fields/NumberField.vue";
import ResourceField from "../../../components/form/fields/ResourceField.vue";
import RichTextEditorField from "../../../components/form/fields/RichTextEditorField.vue";
import SliderField from "../../../components/form/fields/SliderField.vue";
import TextareaField from "../../../components/form/fields/TextareaField.vue";
import TextareaSMSField from "../../../components/form/fields/TextareaSMSField.vue";
import TextField from "../../../components/form/fields/TextField.vue";
import UploadField from "../../../components/form/fields/UploadField.vue";
import SelectField from "../../../components/form/fields/SelectField.vue";
import TimeField from "../../../components/form/fields/TimeField.vue";

export const getFormFieldVueComponent = (editor) => {
  const fields = {
    AutoIncrement: AutoIncrementField,
    Checkbox: CheckboxField,
    Color: ColorField,
    Date: DateField,
    Datetime: DatetimeField,
    Decimal: DecimalField,
    Money: MoneyField,
    MultiSelect: MultiSelectField,
    MultiTreeSelect: MultiTreeSelectField,
    Number: NumberField,
    Resource: ResourceField,
    RichTextEditor: RichTextEditorField,
    Select: SelectField,
    Text: TextField,
    Textarea: TextareaField,
    TextareaSMS: TextareaSMSField,
    Slider: SliderField,
    Time: TimeField,
    Upload: UploadField,
  };
  const field = fields[editor];
  if (!field) {
    console.error(`Field ${editor} not found`);
  }
  return field;
};
