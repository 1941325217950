import {getKeyAndRowForBulkRowsMerge} from "../row/getKeyAndRowForBulkRowsMerge";

// Function to merge bulk rows
export const mergeBulkRows = ({rows, setPickedQuantityMax}) => {
  let rowsMap = {};

  rows.forEach((loopedRow) => {
    const {key, row} = getKeyAndRowForBulkRowsMerge({
      rowsMap,
      row: loopedRow,
      setPickedQuantityMax,
    });
    rowsMap[key] = row;
  });

  // Convert the object back to array
  return Object.values(rowsMap);
};
