import store from "../../state/store";
import {nonActionAxiosInstance} from "./nonActionAxiosInstance";

export async function getComboboxItems({
  columnName,
  primaryKeys = {},
  values = {},
  subject,
  tableName,
  prefix = "Single",
  warehouse = store.state.activeWarehouse,
  searchValue = "",
  maxReturnableOptions = 500,
} = {}) {
  const result = await nonActionAxiosInstance.post(
    `/Admin/WebServices/CoreWebServices.asmx/MainRadComboBox_GetItems`,
    {
      context: {
        ColumnName: columnName,
        DiPrimaryKeys: primaryKeys,
        DiValues: values,
        NumberOfItems: 0,
        Prefix: prefix,
        rowCount: maxReturnableOptions,
        Subject: subject,
        TableName: tableName,
        Text: searchValue,
      },
    },
    {
      params: {
        Warehouse: warehouse,
      },
    },
  );

  return result.data;
}
