export const assingLastSelectedLocationToDropdown = ({locations, userId}) => {
  const lastSelectedLocationID = Number(
    localStorage.getItem(`returnItem-locationID-${userId}`),
  );

  const lastSelectedLocation = locations.find(
    (location) => location.LocationID === lastSelectedLocationID,
  );

  if (!lastSelectedLocation)
    return {
      Value: null,
      Text: null,
      LocationID: null,
    };

  return {
    Value: lastSelectedLocation.Description,
    Text: lastSelectedLocation.Description,
    LocationID: lastSelectedLocation.LocationID,
  };
};
