export function getVisibleRowsCompositionGrouped({rows, page, pageSize}) {
  const normalRows = rows.filter((row) => !row.rowMeta?.compositionItem);
  const compositionRows = rows.filter((row) => row.rowMeta?.compositionItem);

  let start = (page - 1) * pageSize;
  let end = start + pageSize;

  let pageRows = normalRows.slice(start, end);

  const mergedPageRows = [];

  pageRows.forEach((row) => {
    mergedPageRows.push(row);
    const composition = row.Composition?.Value;
    if (!composition || row.rowMeta?.compositionItem) return;

    const compositionRowsWithSameComposition = compositionRows.filter(
      (compositionRow) => compositionRow.Composition.Value === composition,
    );

    if (compositionRowsWithSameComposition.length === 0) return;

    mergedPageRows.push(...compositionRowsWithSameComposition);
  });

  return mergedPageRows;
}
