import {api} from "@/services/api";

export async function getSupplierPriceConditions({supplierId}) {
  const result = await api.get(`/v2/supplier/price-condition`, {
    params: {
      supplierId,
    },
    headers: {
      NormalizeEnums: false,
    },
  });

  return result;
}
