import {api} from "@/services/api";

export default async function getInboundOrderItems({orderId}) {
  const result = await api.get(`/v2/inbound/order/${orderId}/items`);
  const collection = result.data.Collection;

  const normalizedCollection = collection.map((item) => {
    for (const key in item) {
      if (
        typeof item[key] === "object" &&
        item[key] !== null &&
        "Key" in item[key] &&
        key !== "RentalItemID"
      ) {
        item[key] = item[key].Key;
      }
    }
    return item;
  });

  return {...result, data: {Collection: normalizedCollection}};
}
