import {setRowRentalDateToInvoiceStart} from "@/functions/datagrid/invoice-item/row/setRowRentalDateToInvoiceStart";
import {processInvoiceItemRowMetaData} from "./row/processInvoiceItemRowMetaData.js";
import {fetchScannedValueRowData} from "@/functions/item-scanner/fetchScannedValueRowData";
import {setCorrectRankingToRow} from "@/functions/datagrid/row/setCorrectRankingToRow";
import {setClientSideUUIDToRow} from "@/functions/datagrid/row/setClientSideUUIDToRow";
import {convertKeyValueRowToCellRow} from "../row/convertKeyValueRowToCellRow";

export const getRowsFromScannedItem = async ({item, invoice, rows}) => {
  const criteria = [
    {
      IsReplacement: false,
      InvoiceID: invoice.InvoiceID,
      Target: "Rental.Invoice",
    },
  ];

  const scannedRowsData = await fetchScannedValueRowData({
    scannedValue: item.value,
    criteria,
  });

  return scannedRowsData.Rows.map((row, index) => {
    let newRow = convertKeyValueRowToCellRow(row);

    newRow = setCorrectRankingToRow({
      row,
      rows,
      additionalRanking: index * 10,
    });
    newRow = processInvoiceItemRowMetaData({row: newRow});
    newRow = setRowRentalDateToInvoiceStart({row: newRow, invoice});
    newRow = setClientSideUUIDToRow({row: newRow});

    return newRow;
  });
};
