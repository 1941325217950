import {convertLegacyWindowToRWindow} from "../../../functions/window/convertLegacyWindowToRWindow";
import {correctSubWindowIdOnParentWindow} from "./correctSubWindowIdOnParentWindow";

export const upsertWindow = ({vueInstance, window}) => {
  const existingWindow = vueInstance.rWindows[window.id] ?? {};

  vueInstance.rWindows = {
    ...vueInstance.rWindows,
    [window.id]: {
      ...(existingWindow ?? {}),
      ...convertLegacyWindowToRWindow({
        ...window,
        data: existingWindow.data,
        initialData: existingWindow.initialData,
      }),
    },
  };

  const rWindow = vueInstance.rWindows[window.id];
  if (!rWindow.parentWindowId) return;

  correctSubWindowIdOnParentWindow({
    vueInstance,
    subWindow: vueInstance.rWindows[window.id],
  });
};
