export const processInboundDeliveryRowsMetaData = ({rows}) => {
  return rows.map((row) => {
    return {
      ...row,
      RejectionReason: {
        ...row.RejectionReason,
        IsReadOnly: row.AmountRejected.Value <= 0,
      },
    };
  });
};
