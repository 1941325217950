export const clearSelectedEntityFieldFilterValues = ({viewFilters}) => {
  return viewFilters.map((filter) => {
    if (filter.Target === "EntityField") {
      return {
        ...filter,
        Selected: [],
      };
    }
    return filter;
  });
};
