export const togglePickedQuantityBasedOnSerialScannedStatus = ({row}) => {
  if (row.UseSerials.Value !== true) {
    return row;
  }
  if (row.SerialID?.Value) {
    return {
      ...row,
      PickedQuantity: {
        ...row.PickedQuantity,
        IsReadOnly: false,
      },
    };
  }
  return {
    ...row,
    PickedQuantity: {
      ...row.PickedQuantity,
      IsReadOnly: true,
    },
  };
};
