export const handleInitialWindowDataChange = ({
  newData,
  windowId,
  vueInstance,
}) => {
  vueInstance.rWindows[windowId] = {
    ...vueInstance.rWindows[windowId],
    initialData: {...newData},
  };
};
