export const removeDanglingRelatedOrderItems = ({rows}) =>
  rows.filter((row) => {
    const relatedOrderItemID = row.RelatedOrderItemID?.Value;
    if (!relatedOrderItemID) {
      return true;
    }

    return rows.some(
      (parentRow) => parentRow.OrderItemID.Value === relatedOrderItemID,
    );
  });
