import {parseDecimalInput} from "../../parsing/parseDecimalInput.js";

export function handleDecimalFieldChange({value, vueComponent}) {
  const parsedDecimalInput = parseDecimalInput({
    value: value,
    min: vueComponent.field?.MinNumber,
    max: vueComponent.field?.MaxNumber,
  });

  // In case the value to display stays the same, force a re-render because otherwise the input won't update
  vueComponent.$forceUpdate();

  if (Number.isNaN(parsedDecimalInput)) {
    vueComponent.$emit("input", {
      Value: null,
    });
    return;
  }

  vueComponent.$emit("input", {
    Value: parsedDecimalInput,
  });
}
