import {loadComponents} from "@/util/loadComponents";

const actionComponents = loadComponents(
  require.context(
    "@/components/datagrid/datagridRowActions/",
    true,
    /DatagridActionCell(.*)\.vue$/,
  ),
  /DatagridActionCell(.*)\.vue$/,
);

export function mapActionComponent(action) {
  return {
    ...action,
    component: actionComponents[action.type],
  };
}
