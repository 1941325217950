import {getRawValueFromDropdownValue} from "../../../../util/parsing/getRawValueFromDropdownValue";
import {getPrice} from "../../../../services/rental/item/getPrice";
import Decimal from "decimal.js";
import dayjs from "dayjs";

export async function calculateCalculatedPriceGetPrice({row, order}) {
  const {
    Discount,
    DateTimeBusinessStart,
    DateTimeBusinessEnd,
    TotalExcVAT,
    ItemID,
  } = row;

  const startDate = DateTimeBusinessStart?.Value ?? null;
  const endDate = DateTimeBusinessEnd?.Value ?? null;
  const discount = new Decimal(parseFloat(Discount?.Value ?? 0)).toNumber();
  const counterPrice = new Decimal(
    parseFloat(row.CounterPrice?.Value ?? 0),
  ).toNumber();

  let newCalculatedPrice = new Decimal(TotalExcVAT.Value);

  const itemType = getRawValueFromDropdownValue(row.ItemType.Value);

  if (
    itemType !== "Free" &&
    itemType !== "RentalCounter" &&
    endDate &&
    newCalculatedPrice
  ) {
    const price = await getPrice({
      itemId: ItemID.Value,
      startDate: dayjs(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      endDate: dayjs(endDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      customerId: order.CustomerID?.Key,
      orderId: order.OrderID,
      orderItemId: row.OrderItemID.Value,
      contractId: order.ContractID?.Key,
    });

    newCalculatedPrice = new Decimal(price.PriceGross).add(counterPrice);
    newCalculatedPrice = newCalculatedPrice
      .times(row.Amount.Value)
      .dividedBy(100)
      .times(100 + discount)
      .toDecimalPlaces(4);

    //row.PeriodPrices = price.PriceCalculation ?? [];
  }

  row.CalculatedPrice = {
    ...row.CalculatedPrice,
    Value: newCalculatedPrice.toNumber(),
  };

  return row;
}
