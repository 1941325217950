export const getSmsValueLength = ({value, maxParameterLength}) => {
  const valueParameters = value.match(/\[.*?\]/g);
  if (!valueParameters) return value.length;

  let valueWithoutParameters = value;
  for (const param of valueParameters) {
    valueWithoutParameters = valueWithoutParameters.replace(param, "");
  }

  let calculatedValueLength = valueWithoutParameters.length;

  valueParameters.forEach(() => {
    calculatedValueLength += maxParameterLength;
  });

  return calculatedValueLength;
};
