export const setNonPriceValuesOfPriceCondition = ({priceCondition, row}) => {
  const updatedRow = {...row};
  if (priceCondition.DefaultVATCodeID?.Key) {
    updatedRow.VATCodeID = priceCondition.DefaultVATCodeID.Key;
  }
  if (priceCondition.CostAccountID?.Key) {
    updatedRow.CostAccountID = priceCondition.CostAccountID?.Key;
  }

  return updatedRow;
};
