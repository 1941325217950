export function toggleRowsChecked({rows, value}) {
  return rows.map((row) => {
    return {
      ...row,
      Checked: {
        ...row.Checked,
        Value: value,
      },
      DeliveryAmount: {
        ...row.DeliveryAmount,
        Value: value ? row.Amount.Value : 0,
      },
    };
  });
}
