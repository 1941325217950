import {getRentalResources} from "@/services/v2/core/railroad-task/filter-option/getRentalResources";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";

export async function getRentalResourceFilterOptionValues() {
  const {Collection} = await getRentalResources();
  const translations = getTranslations();

  const uniqueResourceTypes = Collection.reduce((acc, resource) => {
    if (!acc.includes(resource.ResourceType)) {
      acc.push(resource.ResourceType);
    }
    return acc;
  }, []);

  return uniqueResourceTypes.map((resourceType) => {
    return {
      label: translations[resourceType],
      id: resourceType,
      children: Collection.filter(
        (resource) => resource.ResourceType === resourceType,
      ).map((resource) => {
        return {
          label: resource.Description,
          id: resource.ResourceID,
        };
      }),
    };
  });
}
