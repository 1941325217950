import {getPriceForPriceCondition} from "./getPriceForPriceCondition";
import {updatePriceConditionCostRow} from "./updatePriceConditionCostRow";
import {addPriceConditionCostRow} from "./addPriceConditionCostRow";
import {isRelatedRow} from "../../row/isRelatedRow";

export const updateRowsWithCostPriceCondition = async ({
  priceCondition,
  rows,
  row,
  getRowsWrapper,
}) => {
  const price = getPriceForPriceCondition({
    priceCondition,
    currentPrice: row.PurchasePricePerUnit.Value,
  });

  const existingRow = rows.find(
    (existingRow) =>
      isRelatedRow({rowToCheck: existingRow, rowToMatch: row}) &&
      existingRow.PriceConditionID.Value === priceCondition.PriceConditionID,
  );
  if (existingRow) {
    return updatePriceConditionCostRow({
      row,
      price,
      rows,
      existingRow,
      priceCondition,
    });
  }
  return await addPriceConditionCostRow({
    rows,
    row,
    priceCondition,
    price,
    getRowsWrapper,
  });
};
