import {notify} from "@/util/notify";
import {getTranslations} from "@/functions/session/getTranslations";

export function handleDateTimeOrderedChange({row}) {
  const translations = getTranslations();
  const {DateTimeOrdered, DateTimeDesiredDelivery} = row;

  if (!DateTimeDesiredDelivery.Value) {
    return {
      ...row,
      DateTimeOrdered: {
        Value: DateTimeOrdered.Value,
      },
    };
  }

  const dateDesired = new Date(DateTimeDesiredDelivery.Value);
  dateDesired.setHours(0, 0, 0, 0);

  const dateOrdered = new Date(DateTimeOrdered.Value);
  dateOrdered.setHours(0, 0, 0, 0);

  if (dateOrdered - dateDesired > 0) {
    notify({message: translations.DeliveryBeforeOrderDate, type: "warning"});
    return {
      ...row,
      DateTimeOrdered: {
        Value: DateTimeDesiredDelivery.Value,
      },
    };
  }
}
