import store from "../../../../../state/store";
import {nonActionAxiosInstance} from "../../../nonActionAxiosInstance";

export async function openWindow({request, warehouse}) {
  const result = await nonActionAxiosInstance.post(
    `/Admin/WebServices/CorewebServices.asmx/OpenWindow`,
    {request},
    {
      params: {
        Warehouse: warehouse ?? store.state.activeWarehouse,
      },
    },
  );

  return result.data;
}
