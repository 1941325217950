<template>
  <div :class="{'cursor-not-allowed': filter.readOnly}" :style="styles">
    <div :class="classes">
      <tree-select
        :value="filter.Selected"
        :multiple="multiple"
        :auto-load-root-options="false"
        loading-text="Loading..."
        :disable-branch-nodes="disableBranchNodes"
        :read-only="filter.readOnly"
        :options="options"
        :placeholder="filter.placeholder"
        @input="handleChange"
      >
      </tree-select>
    </div>
  </div>
</template>
<script>
import TreeSelect from "vue-treeselect/dist/vue-treeselect.cjs.js";
import "vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "TableFormSearchBarFilterOptionsList",
  components: {
    TreeSelect,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    position: {
      type: String,
      default: "right",
    },
  },
  data() {
    return {
      loadedOptions: [],
      loading: false,
    };
  },
  computed: {
    disableBranchNodes() {
      return this.filter.disableBranchNodes ?? true;
    },
    classes() {
      return {
        "filter-option": true,
        [this.position]: true,
        "text-gray-600": this.filter.readOnly,
        "cursor-not-allowed": this.filter.readOnly,
        "pointer-events-none inset-0 bg-gray-100 opacity-50 pointer-events-none":
          this.filter.readOnly,
      };
    },
    styles() {
      const filterColSpan = this.filter.colSpan ?? 1;
      const filterMaxWidth = `${15 * filterColSpan}rem` ?? "15rem";
      return {
        "max-width": filterMaxWidth,
      };
    },
    multiple() {
      return this.filter.Multiple !== false;
    },
    options() {
      if (this.filter?.loadOptions) {
        return this.loadedOptions;
      }
      return this.filter.Options.map((item) => {
        return {id: item.Key, label: item.Title, children: item.children};
      });
    },
  },
  async created() {
    if (this.filter?.loadOptionsOnCreated && this.filter?.loadOptions) {
      this.loading = true;
      this.loadedOptions = (await this.filter?.loadOptions()) ?? [];
      this.loading = false;
    }
  },
  methods: {
    async getOptions() {
      if (this.filter?.loadOptionsOnCreated && this.filter?.loadOptions) {
        this.loading = true;
        const options = (await this.filter?.loadOptions()) ?? [];
        this.loading = false;
        return options;
      }
      return [];
    },
    handleChange(value) {
      this.$emit("filter-change", {filter: this.filter, value});
    },
  },
};
</script>

<style scoped>
.filter-option ::v-deep .vue-treeselect__input-container {
  height: 20px !important;
  vertical-align: middle !important;
}

.filter-option ::v-deep .vue-treeselect__input {
  margin-top: 0px !important;
}
</style>
