import {formContainsFieldWithExtraCriteria} from "../formContainsFieldWithExtraCriteria.js";
import {processFormExtraCriteria} from "./processFormExtraCriteria.js";
import {processFormPostback} from "./processFormPostback.js";

export const processFormFieldChange = async ({
  windowData,
  windowId,
  changedField,
  vueComponent,
}) => {
  let newData = {...windowData};

  newData.Rows[0][changedField.Name] = changedField.Value;

  if (changedField.IsPostback)
    newData = await processFormPostback({
      windowId: windowId,
      windowData: newData,
      vueComponent,
    });

  if (formContainsFieldWithExtraCriteria({fields: newData.Columns}))
    newData = await processFormExtraCriteria({
      changedField,
      windowData: newData,
    });

  return newData;
};
