import {processFormPostbackResult} from "./processFormPostbackResult.js";
import {getWindowFromWindowId} from "../../window/getWindowFromWindowId.js";
import {toggleFieldsLoading} from "./toggleFieldsLoading.js";
import {getPostbackValues} from "../../../services/form/getPostbackValues.js";

export const processFormPostback = async ({
  windowId,
  vueComponent,
  windowData,
}) => {
  const window = getWindowFromWindowId({windowId, session: global.session});

  if (vueComponent)
    toggleFieldsLoading({
      vueComponent,
      windowData: windowData,
      windowId,
      value: true,
    });

  try {
    const result = await getPostbackValues({
      window,
      values: windowData.Rows[0],
    });

    return processFormPostbackResult({result, windowData});
  } catch (error) {
    console.error(error);
    return windowData;
  } finally {
    if (vueComponent)
      toggleFieldsLoading({
        vueComponent,
        windowData: windowData,
        windowId,
        value: false,
      });
  }
};
