export const hideInboundOrderItemColumns = ({
  columns,
  costDistributionType,
  allowDistributionOfCostsOnInboundOrders,
}) => {
  let newColumns = columns.slice();
  const inboundValueColumn = newColumns.find(
    (column) => column.Name === "InboundValue",
  );

  // TODO: Reintroduce Itemtype on Inbound OrderItems
  newColumns = newColumns.filter(
    (column) => column.Name !== "ItemType" && column.Name !== "HirePricePerDay",
  );

  if (
    !inboundValueColumn ||
    inboundValueColumn.IsReadOnly ||
    !inboundValueColumn.IsVisible ||
    inboundValueColumn.Ranking < 0
  ) {
    return newColumns;
  }

  if (
    !allowDistributionOfCostsOnInboundOrders ||
    costDistributionType === "Disable"
  ) {
    return newColumns.filter((col) => col.Name !== "InboundValue");
  }

  return newColumns;
};
