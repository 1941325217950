import {getReturnSelectionEndpoint} from "../../functions/datagrid/return-item/getReturnSelectionEndpoint.js";
import {alertPopup} from "../../interface/alertPopup/alertPopup.js";
import {axiosInstance} from "../axiosInstance.js";
import store from "../../../state/store.js";
import $ from "jquery";

export async function getReturnItemRows({scanValue, selectionValue, opts}) {
  const result = await axiosInstance.post(
    "/Admin/WebServices/ReturnWebServices.asmx/" +
      getReturnSelectionEndpoint({scanValue, selectionValue}),
    opts,
    {
      params: {
        Warehouse: store.state.activeWarehouse,
      },
    },
  );

  if (result?.data?.Error) {
    await alertPopup({
      icon: "warning",
      text: result.data.Error.Message,
    }).then($(".swal-modal").focus());

    return null;
  }

  return result.data?.SerialInfo;
}
