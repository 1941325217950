import {processColumnsMetaData} from "./processColumnsMetaData";
import {sortColumns} from "../columns/sortColumns.js";
import {getColumns} from "../columns/getColumns.js";

export const getAndSetColumns = async ({vueInstance, table, prefix}) => {
  let columns = await getColumns({table, prefix, criteria: []});
  columns = processColumnsMetaData({columns});
  columns = sortColumns(columns);

  vueInstance.columns = columns;
};
