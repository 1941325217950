import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export function processSalesRowMetaData({row}) {
  let newRow = {...row};
  const itemType = getRawValueFromDropdownValue(newRow.ItemType.Value);

  const isSalesRow = itemType === "Sales" || itemType === "Deposit";

  if (isSalesRow) {
    newRow.DateTimeExpectedEnd.IsReadOnly = true;
    newRow.DateTimeBusinessEnd.IsReadOnly = true;
    newRow.PeriodPrice.IsReadOnly = true;
    newRow.PeriodPrice.Value = null;
    newRow.PeriodAmount.IsReadOnly = true;
    newRow.Period.IsReadOnly = true;
    newRow.Price.IsReadOnly = false;
  }

  return newRow;
}
