import {setClientSideUUIDToRow} from "@/functions/datagrid/row/setClientSideUUIDToRow";
export function setClientSideUUIDToRows({rows}) {
  const newRows = rows.slice();
  for (const index in rows) {
    newRows[index].ClientSideUUID = setClientSideUUIDToRow({
      row: newRows[index],
    }).ClientSideUUID;
  }
  return newRows;
}
