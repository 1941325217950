import {isEqual, isObjectLike} from "lodash";

export const findDifferences = ({obj1, obj2}) => {
  if (!isEqual(obj1, obj2)) {
    return Object.keys(obj1 === null ? "" : obj1).reduce(
      (differences, key) => {
        if (!isEqual(obj1[key], obj2[key])) {
          if (isObjectLike(obj1[key]) && isObjectLike(obj2[key])) {
            const nestedDiff = findDifferences({
              obj1: obj1[key],
              obj2: obj2[key],
            });
            if (Object.keys(nestedDiff).length > 0) {
              differences[key] = nestedDiff;
            }
          } else {
            differences[key] = {obj1: obj1[key], obj2: obj2[key]};
          }
        }
        return differences;
      },
      {different: true},
    );
  }
  return {}; // Explicitly return an object indicating no differences if objects are equal
};
