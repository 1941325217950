import {processSidebarFields} from "../../form-sidebar/processSidebarFields.js";
import {findColumnValue} from "../../form-sidebar/findColumnValue.js";
import {findMetadataByColumnName} from "../../form-sidebar/findMetadataByColumnName.js";
import {findComboboxByColumnName} from "../../form-sidebar/findComboboxByColumnName.js";

export const getSidebarFields = ({columns, row, metadata}) => {
  return processSidebarFields({
    columns: Object.values(columns),
    getValueByColumnName: (name) => findColumnValue(row, name),
    getMetadataByColumnName: (name) => findMetadataByColumnName(metadata, name),
    getComboboxByColumnName: (name) => findComboboxByColumnName(row, name),
  });
};
