import {distributeCosts} from "./rows/distributeCosts";
import {removeDanglingRelatedOrderItems} from "./rows/removeDanglingRelatedOrderItems";

export const handleRowsUpdated = ({rows, action, costDistributionType}) => {
  if (action?.name === "deleteRow") {
    const updatedRows = removeDanglingRelatedOrderItems({rows});
    return distributeCosts({
      rows: updatedRows,
      row: null,
      costDistributionType,
    });
  }

  return rows;
};
