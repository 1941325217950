import {getLocalStorageColumnStyles} from "./getLocalStorageColumnStyles";

export function persistColumnResize({column, style, userMetadataKey}) {
  const key = `${userMetadataKey}-columns-styles`;

  const peristedStyles = getLocalStorageColumnStyles({
    key,
  });

  const styles = {...peristedStyles, [column.Name]: style};

  if (style.includes("min-width") || style.includes("max-width")) {
    localStorage.setItem(key, JSON.stringify(styles));
  }
}
