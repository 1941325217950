export const isStackingPriceCondition = ({priceCondition}) => {
  if (
    priceCondition.ActionType !== "ModifyRow" ||
    !priceCondition.PriceType ||
    priceCondition.PriceType === "None"
  ) {
    return true;
  }

  return priceCondition.Stacked ?? false;
};
