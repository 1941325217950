export const filterCustomRowsProperties = ({rows}) => {
  return rows.map((row) => {
    let filteredRow = {};
    for (let key in row) {
      if (key.startsWith("CUST_")) {
        filteredRow[key] = row[key];
      }
    }
    return filteredRow;
  });
};
