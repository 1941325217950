import { render, staticRenderFns } from "./KanbanCardPlannedDate.vue?vue&type=template&id=1f3552a3&scoped=true&"
import script from "./KanbanCardPlannedDate.vue?vue&type=script&lang=js&"
export * from "./KanbanCardPlannedDate.vue?vue&type=script&lang=js&"
import style0 from "./KanbanCardPlannedDate.vue?vue&type=style&index=0&id=1f3552a3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f3552a3",
  null
  
)

export default component.exports