import {processRowStatusOpen} from "./processRowStatusOpen";
import {processRowStatusApprovedToOrder} from "./processRowStatusApprovedToOrder";
import {processRowStatusOrdered} from "./processRowStatusOrdered";
import {processRowStatusConfirmedBySupplier} from "./processRowStatusConfirmedBySupplier";
import {processRowStatusDelivered} from "./processRowStatusDelivered";
import {processRowStatusClosed} from "./processRowStatusClosed";
import {processRowStatusCancelled} from "./processRowStatusCancelled";
import {processRowDeletable} from "./processRowDeletable";
import {getTranslations} from "../../../session/localstorage/getTranslations";
import {processInboundValueReadonly} from "./processInboundValueReadonly";

export const processRowMetadata = ({row, columns}) => {
  const translations = getTranslations();
  let modifiedRow = {...row};

  modifiedRow = processRowStatusOpen({
    row: modifiedRow,
    openStatus: translations.StatusOpen,
  });
  modifiedRow = processRowStatusApprovedToOrder({
    row: modifiedRow,
    approvedStatus: translations.StatusApprovedToOrder,
  });
  modifiedRow = processRowStatusOrdered({
    row: modifiedRow,
    orderedStatus: translations.StatusOrdered,
  });
  modifiedRow = processRowStatusConfirmedBySupplier({
    row: modifiedRow,
    confirmedStatus: translations.StatusConfirmedBySupplier,
  });
  modifiedRow = processRowStatusDelivered({
    row: modifiedRow,
    deliveredStatus: translations.StatusDelivered,
  });
  modifiedRow = processRowStatusClosed({
    row: modifiedRow,
    closedStatus: translations.StatusClosed,
  });
  modifiedRow = processRowStatusCancelled({
    row: modifiedRow,
    cancelledStatus: translations.StatusCancelled,
  });
  modifiedRow = processRowDeletable({row: modifiedRow});

  // TODO: Reintroduce Itemtype on Inbound OrderItems
  // modifiedRow = processRowItemType({row: modifiedRow});
  // modifiedRow = processSupplierPriceCondition({row: modifiedRow});

  modifiedRow = processInboundValueReadonly({row: modifiedRow, columns});

  return modifiedRow;
};
