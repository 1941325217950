import {getHighestRankFromRows} from "../rows/getHighestRankFromRows";

export function setCorrectRankingToRow({
  row,
  rows,
  additionalRanking = 0,
} = {}) {
  const highestRank = getHighestRankFromRows({rows});
  const newRanking = highestRank + 10 + additionalRanking;

  return {...row, Ranking: {...row.Ranking, Value: newRanking}};
}
