<template>
  <div class="flex h-max items-center">
    <div class="grid grid-flow-col gap-1 mt-1">
      <TableCellMultiSelectorValue
        v-for="(badge, index) in value"
        :value="badge"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import TableCellMultiSelectorValue, {
  renderAsText as renderItemAsText,
} from "./TableCellMultiSelector/TableCellMultiSelectorValue.vue";

export function renderAsText({value}) {
  return value.map((x) => renderItemAsText({value: x})).join(", ");
}

export default {
  props: {
    value: {
      type: Array,
      required: false,
    },
    cell: {
      type: Object,
      required: true,
    },
  },
  components: {TableCellMultiSelectorValue},
  computed: {
    labelValues() {
      return this.value;
    },
  },
};
</script>

<style scoped>
.date-cell {
  height: 29px;
  display: flex;
  align-items: center;
}
td {
  white-space: nowrap;
  line-height: 30px;
}
div {
  border: none;
  line-height: 26px;
  background-color: transparent;
}
</style>
