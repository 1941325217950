export function handleCounterValueChange({row}) {
  const orderItemCounterValue = row.Counter.Value - row.MinCounterValue.Value;

  return {
    ...row,
    OrderItemCounter: {
      ...row.OrderItemCounter,
      Value: orderItemCounterValue,
    },
  };
}
