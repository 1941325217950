export const addCustFieldsAsRootPropertiesToRows = async ({rows}) => {
  const newRows = rows.map((row) => {
    const {CustomFields} = row;
    if (CustomFields) {
      Object.keys(CustomFields).forEach((key) => {
        row[key] = CustomFields[key];
      });
    }
    return row;
  });

  return newRows;
};
