import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export const getUniqueItemIdsFromRows = ({rows}) => {
  return [
    ...new Set(
      rows
        .map((row) => getRawValueFromDropdownValue(row.ItemID.Value))
        .filter((row) => row !== null && row !== undefined),
    ),
  ];
};
