import store from "../../../../state/store";
import {alertPopup} from "../../../interface/alertPopup/alertPopup.js";
import {canDeleteSingleRow} from "./composition-delete/canDeleteSingleRow";
import {deleteDanglingVirtualCompositionRows} from "./composition-delete/deleteDanglingVirtualCompositionRows";

export default async function handleRowDeleteCompositionSupport({row, rows}) {
  const index = rows.findIndex(
    (loopedRow) =>
      loopedRow["ClientSideUUID"].Value === row.ClientSideUUID.Value,
  );

  if (index == -1) return;

  if (canDeleteSingleRow({row: rows[index]}) && row.rowMeta?.virtual !== true) {
    rows.splice(index, 1);
    rows = deleteDanglingVirtualCompositionRows({rows});

    return {rows};
  }

  const indexes = [];

  rows.forEach((loopedRow, loopedIndex) => {
    if (loopedRow.Composition.Value === rows[index].Composition.Value) {
      indexes.push(loopedIndex);
    }
  });

  let confirmationObject = {
    text: store.state.translations["RemoveCompleteComposition"],
    icon: "warning",
    dangerMode: true, // setting dangerMode to true makes the Cancel button bigger
    buttons: [store.state.translations.Cancel, true],
  };

  let confirmed = true;

  if (row.rowMeta.virtual !== true) {
    confirmed = await alertPopup(confirmationObject);
  }

  indexes.sort(function (a, b) {
    return b - a;
  });

  if (!confirmed) return;

  for (const rowIndex of indexes) {
    rows.splice(rowIndex, 1);
  }

  return {rows};
}
