import {advancedColumnIsExcluded} from "./advancedColumnIsExcluded.js";

export function setAdvancedColumnFields({processContext, columns, row}) {
  const fields = {};

  columns.forEach((column) => {
    if (
      column.IsAdvancedColumn &&
      !advancedColumnIsExcluded({processContext, column, row})
    ) {
      fields[column.Name] = column;
    }
  });

  return fields;
}
