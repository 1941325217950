<template>
  <div class="flex">
    <div
      :class="{'tabs-vue': true, 'tabs-vue-fixed': windowTop > 0, 'mt-3': true}"
      @scroll.passive="handleScroll"
    >
      <div v-if="tabs.length > 1" class="tab">
        <r-button
          variant="dark"
          class="ml-0 mr-2"
          size="sm"
          data-window-target="active"
          data-window-event="dispose-all"
          ><i class="fas fa-times"></i> {{ translations.CloseAll }}
        </r-button>
      </div>
      <tab-shortcut
        v-for="tabShortcut in shortcutButtons"
        :key="tabShortcut.MenuID"
        :tab-shortcut="tabShortcut"
      />

      <div v-if="shortcuts.length > 0" class="tab divider"></div>

      <transition name="fade">
        <button
          v-show="leftScrollArrow"
          class="btn btn-dark btn-sm scroll-btn"
          @click="scrollLeft"
        >
          <i class="fas fa-arrow-left"></i>
        </button>
      </transition>

      <div class="window-tabs flex">
        <tab
          v-for="tab of tabs"
          :key="tab.id"
          :tab="tab"
          :active-tab="activeTab"
        />
      </div>

      <transition name="fade">
        <button
          v-if="rightScrollArrow"
          class="btn btn-dark btn-sm scroll-btn"
          @click="scrollRight"
        >
          <i class="fas fa-arrow-right"></i>
        </button>
      </transition>
    </div>
  </div>
</template>

<script>
import RButton from "../../elements/RButton.vue";
import Tab from "./tab.vue";
import TabShortcut from "./tabShortcut.vue";

export default {
  name: "Tabs",
  components: {
    RButton,
    Tab,
    "tab-shortcut": TabShortcut,
  },

  data: () => {
    return {
      tabsFixed: false,
      lastScrollPosition: 0,
      windowTop: 0,
      leftScrollArrow: false,
      rightScrollArrow: false,
    };
  },
  computed: {
    tabs: function () {
      return this.$store.getters.tabs;
    },
    translations: function () {
      return this.$store.getters.translations;
    },
    activeTab: function () {
      return this.$store.getters.activeWindowID;
    },
    shortcuts: function () {
      return this.$store.getters.shortcuts;
    },
    shortcutButtons: function () {
      return this.shortcuts.Top;
    },
  },
  watch: {
    tabs() {
      this.rightScrollArrow = this.canScrollRight();
    },
  },
  created() {
    this.rightScrollArrow = this.canScrollRight();
  },
  methods: {
    canScrollRight() {
      const tabs = document.querySelector(".window-tabs");

      if (!tabs?.scrollWidth) {
        return false;
      }

      const amountScrolled = tabs.scrollWidth - tabs.clientWidth;
      const scrollLeft = tabs.scrollLeft;

      if (scrollLeft < amountScrolled) {
        return true;
      }
      return false;
    },
    handleScroll() {
      this.windowTop = window.top.scrollY;
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      // Here we determine whether we need to show or hide the navbar
      // eslint-disable-next-line no-undef
      this.tabsFixed = e.target.scrollHeight < this.lastScrollPosition;
      // Set the current scroll position as the last scroll position
      // eslint-disable-next-line no-undef
      this.lastScrollPosition = e.target.scrollHeight;
    },
    scrollLeft() {
      let content = document.querySelector(".window-tabs");

      content.scrollLeft -= 300;

      if (content.scrollLeft === 0) {
        this.leftScrollArrow = false;
      }
      this.rightScrollArrow = this.canScrollRight();
    },
    scrollRight() {
      let content = document.querySelector(".window-tabs");
      content.scrollLeft += 300;

      this.leftScrollArrow = true;
      this.rightScrollArrow = this.canScrollRight();
    },
  },
};
</script>

<style scoped style="scss">
.scroll-btn {
  height: 27.38px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.window-tabs {
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.window-tabs::-webkit-scrollbar {
  display: none;
}
.window-tabs {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.tabs-vue-fixed {
  position: fixed;
  top: 0;
  left: 55px;
  margin-top: 0 !important;
  padding-top: 16px;
  padding-left: 16px;
  z-index: 4000;
  background-color: white;
}
.tabs-vue {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
.tab {
  margin-bottom: 10px;
}

.divider {
  /* background-color: black; */
  border-right: 2px solid #e5e5e5;
  /* width: 2px; */
  height: 23px;
  margin-top: 2px;
  margin-right: 10px;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
