import {isDirty} from "./isDirty.js";
import dayjs from "dayjs";

export const isFieldDirty = ({field, initialValue, newValue}) => {
  if (field?.Type === "Date") {
    const parsedDate1 = dayjs(initialValue, ["DD-MM-YYYY", "YYYY-MM-DD"]);
    const parsedDate2 = dayjs(newValue, ["DD-MM-YYYY", "YYYY-MM-DD"]);

    if (!parsedDate1.isValid() && !parsedDate2.isValid()) return false;

    return !parsedDate1.isSame(parsedDate2, "day");
  }

  return isDirty({
    newValue,
    initialValue,
  });
};
