import store from "../../state/store";
import Hook from "../hook";
import ActionBarHorizontal from "../components/interface/ActionBarHorizontal.vue";

import Sidebar from "../components/form/formSidebar/FormSidebar.vue";

class WindowHook extends Hook {
  async afterRender(eventWindow) {
    store.commit("updateWindow");

    if (eventWindow.element?.querySelectorAll("[action-bar]").length > 0) {
      new window.vue({
        el: eventWindow.element.querySelectorAll("[action-bar]")[0],
        store,
        render: (h) => h(ActionBarHorizontal),
      });
    }
    if (eventWindow.input !== null) {
      const allWindowInput = global.session.tabs
        .filter((tab) => tab.input !== null)
        .map((tab) => tab.input);
      store.commit("setWindowHistory", allWindowInput);
    }
  }
  async afterDispose(eventWindow) {
    if (eventWindow.input !== null) {
      const allWindowInput = global.session.tabs
        .filter((tab) => tab.input !== null)
        .map((tab) => tab.input);
      store.commit("setWindowHistory", allWindowInput);
    }
  }
}

export default new WindowHook();
