export const normalizeRowKeyObjects = ({row}) => {
  let newRow = {};
  for (let prop in row) {
    if (row.hasOwnProperty(prop)) {
        if (typeof row[prop] === 'object' && row[prop] !== null && 'Key' in row[prop] && 'Value' in row[prop]) {
          newRow[row[prop]['Key']] = row[prop]['Value'];
        } else {
          newRow[prop] = row[prop];
        }
    }
  }
  
  return newRow;
}