export const setRowCustomPropertiesToCustomPropertyObject = ({row}) => {
  // for property beginning with CUST_ set that same property in the row.CustomFields object
  const modifiedRow = {...row};
  modifiedRow.CustomFields = modifiedRow.CustomFields || {};

  for (const property in modifiedRow) {
    if (property.startsWith("CUST_")) {
      modifiedRow.CustomFields = {
        ...modifiedRow.CustomFields,
        Value: {
          ...modifiedRow.CustomFields.Value,
          [property]: modifiedRow[property].Value,
        },
      };
    }
  }

  return modifiedRow;
};
