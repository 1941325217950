<template>
  <div>
    <div v-if="formattedValue" :class="`px-2 badge ${badgeClasses}`">
      {{ formattedValue }}
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

function stripTimeIfZero(date) {
  return date.replace(" 00:00", "");
}

export function renderAsText({ value }) {
  if (!value) return "";
  return stripTimeIfZero(dayjs(value).format("DD-MM-YYYY HH:mm"));
}

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    cell: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedValue() {
      return renderAsText(this);
    },
    badgeClasses() {
      return this.cell.badgeClasses ?? "badge-secondary";
    },
  },
};
</script>

<style scoped>
.date-cell {
  height: 29px;
  display: flex;
  align-items: center;
}
td {
  white-space: nowrap;
  line-height: 30px;
}
</style>
