
        
    const runtime = require("../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["src/components/charts/timeline/chartTimelineSegmentTitle.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"pancake-row\" data-action=\"openCriteria\" data-prefix=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "prefix"), env.opts.autoescape);
output += "\" data-tablename=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "fullTableName"), env.opts.autoescape);
output += "\" data-criteria='";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "criteria"), env.opts.autoescape);
output += "'>\n  <div class=\"column text-center\" style=\"width: 100%;\">\n     <span class=\"stock\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "value"), env.opts.autoescape);
output += "</span>\n  </div>\n  <div class=\"dates\" style=\"display: flex;width: 100%;\">\n     <div style=\"display: flex;\">\n        <div class=\"start-date\" style=\"display: flex;flex-wrap: wrap;\">\n           <div style=\"width: 5px; height: 100%; display: flex;\"></div>\n           <div class=\"column time\" style=\"width: 270px;\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "startDate"), env.opts.autoescape);
output += "</div>\n        </div>\n     </div>\n     <div style=\"display: flex;flex-grow:1;\"></div>\n     <div style=\"display: flex;\">\n        <div class=\"end-date\" style=\"display: flex;flex-wrap: wrap;\">\n           <div style=\"width: 5px; height: 100%; display: flex;\"></div>\n           <div class=\"column time text-right\" style=\"width: 390px;\">\n              ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "endDate"), env.opts.autoescape);
output += "\n           </div>\n        </div>\n     </div>\n  </div>\n</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("src/components/charts/timeline/chartTimelineSegmentTitle.html", ctx);
          }
        
          return nunjucks.render("src/components/charts/timeline/chartTimelineSegmentTitle.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["src/components/charts/timeline/chartTimelineSegmentTitle.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        