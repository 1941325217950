import $ from "jquery";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import startup from "../components/startup/startup.vue";
import {alertPopup} from "../interface/alertPopup/alertPopup.js";
import Tutorial from "../interface/tutorial";
import EventEmitter from "events";
import Setup from "../interface/setup";
import Window from "./window";
import store from "../../state/store";
import {drawVueManualModal} from "../interface/vueManualModal";
import {notify} from "@/util/notify";
import {login} from "@/functions/session/login";

import getWelcomeInfo from "@/services/authentication/getWelcomeInfo.js";
import {nonActionAxiosInstance} from "../services/nonActionAxiosInstance";

import("dayjs/locale/nl");
import("dayjs/locale/de");
import("dayjs/locale/fr");

/**
 * App session class
 * @property {Canvas} canvas - Session Canvas
 * @property {string} url - Session URL
 * @property {*} token - Token
 * @property {string} product - Product name
 * @property {string} username - Username
 * @property {boolean} active - Session is active
 * @property {boolean} loading - Session is loading
 * @property {boolean} locked - Session is locked
 * @property {boolean} kiosk - Session is in kiosk mode
 * @property {boolean} fullscreen - Session is fullscreen
 * @property {boolean} canViewSettingsMenu - Session can view settings menu setting
 * @property {boolean} canRegister - Session can register
 * @property {*} box - Box
 * @property {*} err - Session error
 * @property {*} comboboxes.selectedValues - Selected properties for comboboxes
 * @property {Object} comboboxes.Combobox - Warehouse or Affiliate-combobox
 * @property {Object} translations - Server translation strings
 * @property {string} language - Selected language
 * @property {Array} languages - Languages available
 * @property {*} moduleMenu - Module menu
 * @property {Object} profile - Profile
 * @property {Object} form - Form
 * @property {Object} settings - Settings
 * @property {Object} shortcuts - Shortcuts
 * @property {Object} scanbox - Combobox-object for scanbox
 * @property {Array} tabs - Tabbed Windows
 * @property {Object} windows - All Windows
 * @property {Window} activeWindow - Active window
 * @property {string} userID - User ID
 * @property {*} setup - Setup
 * @property {*} screen - Screen
 * @property {*} licenseExpirationMessage - License expiration message
 */
export default class Session extends EventEmitter {
  canvas = null;
  defaultCurrencyId = "EUR (€)";
  url = null;
  token = null;
  product = "RentMagic";
  productDomain = "rentmagic";
  username = null;
  active = false;
  loading = false;
  locked = false;
  kiosk = false;
  fullscreen = true;
  canViewSettingsMenu = true;
  canRegister = false;
  box = null;
  err = null;
  googleSSO = false;
  twoFactorAuthentication = false;
  ssoData = {};
  comboboxes = {};
  translations = {};
  language = null;
  languages = [];
  moduleMenu = null;
  profile = {};
  form = {};
  settings = {};
  shortcuts = {};
  scanbox = null;
  tabs = [];
  tutorial = new Tutorial(this);
  windows = {};
  activeWindow = null;
  setup = null;
  screen = null;
  licenseExpirationMessage = null;
  twoFactorRequired = false;
  twoStepQR = false;

  /**
   * Session constructor
   * @todo comment
   * @param {string} url - Session URL
   */
  constructor(url) {
    super();
    this.url = url;

    if (global.translations != null) {
      this.languages = Object.keys(global.translations).map((x) => ({
        ID: x,
        Name: global.translations[x].NativeLanguageName,
      }));
      this.switchLanguage(null, true);
    }

    if (global.location.hash.slice(0, "#install".length) == "#install") {
      // if (global.location.hash.slice(0, 3) == "#k=") {
      this.setup = new Setup(this);
      this.setup.start();
      return;
    }

    if (global.location.hash.slice(0, 22) == "#userconfirmationCode=") {
      let code = location.hash.slice(22);
      this.executeAppWebservice({code: code}, "UserConfirmation");
    } else {
      this.auth();
    }
  }

  /**
   * Opens a screen based on a menuID
   *
   * @param {string} menuID
   * @memberof Session
   */
  async openWindowByMenuID(menuID) {
    let window = new Window(this);
    window.loading = true;

    store.dispatch("addTab", window);
    try {
      await window.render();
      window.focus();

      let output = await this.request(
        "/Admin/WebServices/CoreWebServices.asmx/OpenMenuNode",
        {
          ID: menuID,
        },
      );

      await window.process(output);
      window.loading = false;
      await window.render();

      drawVueManualModal({
        windowID: window.id,
        helpUrl: window.output.Options.HelpUrl,
      });
    } catch (err) {
      console.error(err);
      window.dispose();
    }
    store.commit("updateWindow");
    store.commit("refreshTabs");
  }

  /** Show default startup window */
  async showDefaultWindow(searchTerm) {
    if (searchTerm != "") {
      let window = new Window(this);
      window.loading = true;
      store.dispatch("addTab", window);

      try {
        await window.render();
        store.commit("updateWindow");
        window.focus();

        let output = await this.request(
          "/Admin/WebServices/CoreWebServices.asmx/OpenNodeByValue",
          {
            value: null,
            description: searchTerm,
          },
        );

        await window.process(output);
        window.loading = false;
        await window.render();
        store.commit("updateWindow");
        drawVueManualModal({
          windowID: window.id,
          helpUrl: window.output.Options.HelpUrl || null,
        });
        store.commit("updateWindow");
      } catch (err) {
        console.error(err);
        window.dispose();
      }
    } else {
      try {
        // check if there is a query param Headless with value true and if so, return
        if (
          !global.location.search.indexOf("Headless") > -1 &&
          !global.location.search.indexOf("Headless=true") > -1
        ) {
          let window = await this.newWindow(
            null,
            null,
            this.translations.Welcome,
          );

          window.vueComponent = startup;
          window.focus();
          window.options.noReset = true;

          store.commit("updateWindow");
          if (
            location.hostname === "localhost" &&
            process.env.VUE_APP_WINDOW_HISTORY !== "false"
          ) {
            store.dispatch("reopenLastWindow");
          }

          drawVueManualModal({
            windowID: window.id,
            helpUrl:
              window.output.Options != null
                ? window.output.Options.HelpUrl
                : null,
          });
        }
      } catch (err) {
        console.error(err);
      }
      store.commit("updateWindow");
    }
  }

  /**
   * Switch to other language
   * @param {string} langID - Language id
   * @param {boolean} doNotEmitUpdate - Is update neccessairy?
   * @todo comment
   * @returns {void}
   */
  switchLanguage(
    langID,
    doNotEmitUpdate = false,
    usernameInput,
    passwordInput,
  ) {
    // set language is defined by:
    // 1. inputted languageID
    // 2. cookie -> which is the user preference cookie
    // 3. default language
    // 4. first language send

    this.username = usernameInput;
    this.form.password = passwordInput;

    if (global.translations[langID] != null) {
      this.setLanguage(langID, doNotEmitUpdate);
      return;
    }

    if (global.translations[Cookies.get("lang")] != null) {
      langID = Cookies.get("lang");
    } else if (global.translations[global.defaultLanguage] != null) {
      langID = global.defaultLanguage;
    } else {
      langID = Object.keys(global.translations)[0];
    }

    this.setLanguage(langID);
  }

  setLanguage(langID, doNotEmitUpdate) {
    this.language = langID;
    store.state.language = langID;
    store.commit("setLanguage", langID);
    this.translations = global.translations[langID];

    store.commit("setTranslations", {
      translations: this.translations,
    });

    Cookies.set("lang", langID, {sameSite: "None", secure: true, expires: 365});

    if (!doNotEmitUpdate) {
      this.emit("updated");
    }

    dayjs.locale(langID);
  }

  /**
   * Create window
   * @param {Object} input - Input object
   * @param {string} customTemplate - Custom template name, to create custom window
   * @param {string} customTitle - Custom title
   * @param {Object} customData - Custom data object
   * @param {Object} extraParams - Extra parameters
   * @param {string} vueTemplate - Vue template
   * @returns {Window} Window
   */
  newWindow(
    input,
    customTemplate = null,
    customTitle = null,
    customData = {},
    extraParams,
    vueTemplate = null,
  ) {
    let window = new Window(this);
    window.input = input;
    window.customTemplateName = customTemplate || null;
    window.customTitle = customTitle;
    window.customData = customData;
    window.template = vueTemplate;
    return window;
  }

  /**
   * Create window, and add it to tabs
   * @param {Object} input - Input object
   * @param {string} customTemplate - Custom template name, to create custom window
   * @param {string} customTitle - Custom title
   * @param {Object} customData - Custom data object
   * @param {string} vueTemplate - Vue template
   * @returns {Window} Window
   */
  createWindow(
    input,
    customTemplate = null,
    customTitle = null,
    customData = {},
    vueTemplate = null,
  ) {
    let window = this.newWindow(
      input,
      customTemplate,
      customTitle,
      customData,
      null,
      vueTemplate,
    );

    store.dispatch("addTab", window);
    return window;
  }

  /**
   * Create window, load it and focus it
   * @param {Object} input - Input object
   * @param {string} customTemplate - Custom template name, to create custom window
   * @param {string} customTitle - Custom title
   * @param {Object} customData - Custom data object
   * @param {string} vueTemplate - Vue template
   * @returns {Window} Promise
   */
  async openWindow(
    input,
    customTemplate = null,
    customTitle = null,
    customData = {},
    vueTemplate = null,
  ) {
    if (input?.Criteria[0] === null) return;
    let window = this.createWindow(
      input,
      customTemplate,
      customTitle,
      customData,
      vueTemplate,
    );
    // create, then focus and reload

    window.focus();
    await window.reload();
    store.commit("refreshTabs");
    store.commit("updateWindow");
    return window;
  }

  /**
   * Load combobox data
   * @param {string} table - Table name
   * @param {string} column - Column name
   * @param {Object} DiPrimaryKeys - Primary key dictictionary
   * @param {string} text - Input text
   * @param {Object} values - Values dictionary for primary keys
   * @param {string} webservice - Unused
   * @param {number} nOfItems - Number of items to load
   * @param {number | null} rowCount - Row offset/count
   * @returns {Promise} Promise
   */
  async combobox(
    table,
    column,
    DiPrimaryKeys,
    text,
    values,
    webservice,
    nOfItems,
    rowCount = null,
    subject = "",
    prefix = "",
  ) {
    let data = await this.request(
      "/Admin/WebServices/CoreWebServices.asmx/MainRadComboBox_GetItems",
      {
        context: {
          Text: text || "",
          NumberOfItems: nOfItems || 0,
          DiValues: values || {},
          DiPrimaryKeys: DiPrimaryKeys || {},
          TableName: table,
          ColumnName: column || null,
          RowCount: rowCount,
          Subject: subject,
          Prefix: prefix,
        },
      },
    );

    // if Subject = Rental.Category loop through all entries in data and set Enabled to true Server always sends Enabled = false and cannot be refactored safely yet
    if (subject === "Rental.Category") {
      for (let i = 0; i < data.length; i++) {
        data[i].Enabled = true;
      }
    }

    return data;
  }

  /**
   * Excecute app webservice on server
   * @param {Object} opts - Options
   * @param {string} serviceName - Name of webservice
   * @param {boolean} loginUser - User login request?
   * @returns {Promise} Promise
   */
  async executeAppWebservice(opts, serviceName, loginUser = false) {
    let wasLocked = this.locked;
    try {
      // try to excecute service

      // options are required
      if (!opts) {
        this.loading = false;
        this.emit("updated");
        return null;
      }

      // reset error
      this.err = null;

      // we are loading
      this.loading = true;
      this.emit("updated");

      // set variables
      opts.product = this.product;

      let result = null;

      if (serviceName == "GetWelcomeInfo") {
        result = await getWelcomeInfo();

        if (result.status == 401) {
          this.logout(true, true, null);
          return;
        } else {
          result = result.data;
        }
      } else {
        // perform request
        result = await this.request(
          "/Public/WebServices/AppWebServices.asmx/" +
            serviceName +
            "?LangID=" +
            this.language,
          opts,
        );
      }

      this.googleSSO = result.GoogleSSO;
      this.twoFactorAuthentication = result.TwoStepRequired;

      if (loginUser) {
        await login({result, session: this, serviceName});
      }
    } catch (err) {
      // throw error

      this.twoStepSetupRequired = err.data?.TwoStepSetupRequired;
      this.twoStepQR = err.data?.TwoStepApp;

      if (err.data && typeof err.data.Screen != "undefined") {
        this.openScreen(err.data.Screen);
      }

      if (serviceName != "GetWelcomeInfo") {
        console.error(err);
        this.err = err;
      }
    }

    this.loading = false;
    if (wasLocked) {
      location.reload();
      return;
    }
    this.emit("updated");
  }

  /**
   * send authentication request
   * @param {Object} opts - Options
   * @returns {Promise} Promise
   */
  async auth(opts = null) {
    let wasInactive = this.active == false;
    let searchTerm = "";

    if (global.location.hash.slice(0, "#open=".length) == "#open=") {
      searchTerm = location.hash.slice("#open=".length, location.hash.length);
    }
    if (global.location.search.slice(0, "?OPEN-".length) == "?OPEN-") {
      window.location.replace(
        global.location.toString().replace("?OPEN-", "#open="),
      );
    }

    if (opts) {
      opts.product = "RentMagic";
      await this.executeAppWebservice(opts, "LoginKick", true);
    } else {
      await this.executeAppWebservice({}, "GetWelcomeInfo", true);
    }

    if (wasInactive && this.active) {
      this.openSelectedMenuID({
        DefaultMenuID: this.profile.DefaultMenuID,
        searchTerm,
      });
    }

    // check if we have any query parameters
    const query = new URLSearchParams(window.location.search);

    if (query && store.state.accessToken) {
      const importedModule = await import(
        "../functions/processQueryParameters"
      );

      importedModule.default(query);
    }
  }

  async openSelectedMenuID({DefaultMenuID, searchTerm}) {
    if (
      global.location.search.indexOf("Headless") > -1 &&
      global.location.search.indexOf("Headless=true") > -1
    ) {
      $("body").removeClass("avoid-clicks");
      return;
    }
    // Show default window when the user logs or refreshes/creates a new browser tab
    if (DefaultMenuID && DefaultMenuID.Value) {
      if (DefaultMenuID.Value === "none") {
        return;
      }

      await this.openWindowByMenuID(DefaultMenuID.Value);
      return;
    }

    await this.showDefaultWindow(searchTerm);
  }

  /**
   * Log user out
   * @param {boolean} alreadyLoggedOut - If already logged out
   * @param {boolean} lock - If should lock
   * @param {*} err - Error
   * @returns {Promise} Promise
   */
  async logout(alreadyLoggedOut, lock, err) {
    let result;

    if (!alreadyLoggedOut) {
      result = await this.request(
        "/Public/WebServices/AppWebServices.asmx/Logout",
        {},
      );
    }

    store.commit("logout");

    this.err = err;

    if (lock) {
      this.locked = true;
      this.emit("updated");
    } else {
      if (typeof result === "string" && result != "") {
        await alertPopup({text: result});
      }

      location.reload();
    }

    return result;
  }

  /**
   * Send Axios request
   *
   * @param {string} uri - Request URI
   * @param {Object} postData - Request data
   * @param {Object} extraParams - Extra request parameters
   * @returns {Object} data response from the request ran by Axios
   */
  async request(uri, postData = {}, extraParams = {}, method = null, windowId) {
    try {
      let params = this.comboboxes.selectedValues;
      if (params == null) {
        params = {};
      }

      params.Warehouse = store.state.activeWarehouse;

      params.BoxID = this.box;

      for (let key in extraParams) {
        params[key] = extraParams[key];
      }

      // check if query param headless exists
      if (
        global.location.search.indexOf("headless") > -1 &&
        global.location.search.indexOf("headless=true") > -1
      ) {
        if (postData.request) {
          postData.request.headless = true;
        }
      }

      let result;
      result = await nonActionAxiosInstance({
        method: method ? method : postData ? "post" : "get",
        url: this.url + uri,
        data: postData,
        params: params,
        config: {
          windowId,
        },
        headers: {
          "Content-Language": this.language,
        },
      });

      let data =
        result?.data && result.data?.d !== undefined
          ? result.data.d
          : result.data;

      if (data.extraData) {
        global.session.activeWindow.extraData = data.ExtraData;
      }

      if (data && data.Error) {
        let err = new Error(
          typeof data.Error === "object" ? data.Error.Message : data.Error,
        );
        err.data = typeof data.Error === "object" ? data.Error : data;

        if (data.Error) {
          err.data.HadError = true;
        }

        if (typeof data.Error === "string") {
          notify({message: data.Error, type: "danger"});
        }

        throw err;
      }

      return data;
    } catch (err) {
      if (err.response?.data?.IsActionResponse) {
        await global.session.activeWindow.handleActionResponse(
          err.response.data,
        );
      }
      for (const globalAction of err?.response?.GlobalActions ?? []) {
        const importedModule = await import(
          `@/actions/${globalAction.Function}.js`
        );
        importedModule.default(globalAction.Data);
      }

      if (err["data"]?.Message && err["data"]?.HadError) {
        throw {data: err["data"]};
      }

      if (
        err?.data?.Data?.ShowLockScreen === true ||
        err?.response?.status == 401
      ) {
        this.logout(true, true, err);
      }

      if (global.session.activeWindow?.loading && !err.data?.HadError) {
        const errorMessage =
          err.response?.data?.Error?.Message ??
          store.state.translations.SomethingWentWrongTryAgainLater;

        notify({
          message: errorMessage,
          type: "danger",
        });
        global.session.activeWindow.dispose();
      }
      console.error(err);
    }
  }

  async goodRequest({
    uri,
    postData = {},
    extraParams = {},
    method = null,
    windowId,
  }) {
    return await this.request(uri, postData, extraParams, method, windowId);
  }

  /**
   * Get closest window
   * @param {Element} el - HTML element
   * @returns {Window | null} closest window
   */
  getClosestWindow(el) {
    let id = $(el).closest("[data-window-id]").attr("data-window-id");
    return this.windows[id] || null;
  }

  /**
   * Update windows with related tables
   * @param {Window} window - Current window
   * @param {string} table - Related table
   * @returns {void}
   */
  updateRelatedWindows(window, table) {
    if (!window) {
      return;
    }

    let tablename =
      table ||
      (window && window.output && window.output.Request.Subject
        ? window.output.Request.Subject
        : null);
    let refTableName = null;

    this.tabs.forEach(async function (w) {
      let relatedWindow = null;

      if (w.sub && w.sub.window.id === window.id) {
        return;
      }

      while (w != null && relatedWindow == null) {
        if (!w.output || !w.output.Request.Subject || w.waitingForReload) {
          w = null;
        } else if (w.isRelated(tablename) || w.isRelated(refTableName)) {
          relatedWindow = w;
        } else if (w.sub && w.sub.window) {
          w = w.sub.window;
        } else {
          w = null;
        }
      }

      if (relatedWindow) {
        await store.dispatch("resetWindow", {
          windowid: relatedWindow.id,
          shouldFocus: global.session.activeWindow.id == relatedWindow.id,
          shouldConfirm: false,
        });
      }
      store.commit("updateWindow");
      store.commit("refreshTabs");
    });
  }

  /**
   * Send a message to all the other active windows in the other tabs in the same browsers
   * @param {string} name - Name of the action to execute on active windows on other tabs
   * @param {Array} args - Array of arguments for the action
   * @returns {Promise} Nothing
   */
  broadcastWindowEvent(eventName, ...args) {
    localStorage.setItem(
      "global-window-event",
      JSON.stringify({command: eventName, data: args}),
    );
    localStorage.removeItem("global-window-event");
  }

  /**
   * Set screen
   * @param {string} screenName - Screen name
   * @returns {void}
   */
  openScreen(screenName) {
    this.screen = screenName;
    this.err = null;
    this.emit("updated");
  }

  /**
   *  Get user related information of the currently logged in user
   *  @returns {object} Userdata of the logged in user
   */
  async getUserData() {
    let requestUrl = "/api/v1/CurrentUserInfo";

    let result = await window.session.request(requestUrl, null);

    return result;
  }
}
