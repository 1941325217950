export const rowProcessRejectionReasonMetadata = ({row}) => {
  if (row.AmountRejected.Value <= 0)
    return {
      ...row,
      RejectionReason: {
        ...row.RejectionReason,
        IsReadOnly: true,
        Value: "",
      },
    };

  return {
    ...row,
    RejectionReason: {
      ...row.RejectionReason,
      IsReadOnly: false,
    },
  };
};
