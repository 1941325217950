import {getTranslations} from "@/functions/session/getTranslations";

export function processInvoiceDiscrepancy(rows) {
  if (Array.isArray(rows)) {
    const translations = getTranslations();

    rows.forEach((row) => {
      if (!row.OrderItemID || row.OrderItemID?.Value === null) {
        row.InvoiceDiscrepancy = {
          Value: 0,
          Icon: "fa-info-circle",
          IconColor: "info",
          IconMessage: translations.InboundInvoiceItemWithoutOrderItemID,
        };
      } else {
        row.InvoiceDiscrepancy = {
          Value: row.InvoiceDiscrepancy?.Value ?? 0,
          Icon: null,
          IconColor: null,
          IconMessage: null,
        };
      }
    });
    return rows;
  } else return rows;
}
