<template>
  <div
    class="flex justify-between font-medium block rounded-tr-md rounded-bl-md rounded-br-md max-w-full"
    :class="{
      '!text-[#888] !bg-[#eee] !rounded-lg': !railroadItem.Available,
    }"
  >
    <div
      class="hover:cursor-pointer overflow-hidden"
      @click="
        processEvent(
          railroadItem.Action,
          railroadItem.LegacyAction,
          !railroadItem.Sub,
        )
      "
    >
      {{ railroadItem.Description }}
      <span v-if="railroadItem.Blocking" class="text-primary">*</span>
    </div>
    <div
      v-if="railroadItem.EditAction && railroadItem.Enabled"
      class="text-primary cursor-alias mt-[0.5px] text-[14px] lg:mr-1"
      @click="processEvent(railroadItem.EditAction, railroadItem.LegacyAction)"
    >
      <i class="fas fa-external-link-alt"></i>
    </div>
  </div>
</template>

<script>
import processLegacyEvent from "../../../../actions/processLegacyEvent.js";
import {handleJsFunctionPayload} from "../../../../functions/window/handleJsFunctionPayload.js";

export default {
  name: "RailRoadItemSubTaskDescription",
  props: {
    railroadItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async processEvent(action, eventString, editAction) {
      if (!this.railroadItem.Editable) return;

      if (editAction) {
        await this.action(this.railroadItem.EditAction);
        return;
      }
      if (!this.railroadItem.Enabled) return;
      if (action) {
        import(`@/actions/${action.Function}.js`).then((module) => {
          module.default(action.Data);
        });
        return;
      }

      if (this.railroadItem.JSFunctionPayload) {
        await handleJsFunctionPayload({
          jsFunctionPayload: this.railroadItem.JSFunctionPayload,
        });
        return;
      }

      await processLegacyEvent(eventString, this.$store.getters.subWindow.id);
    },
  },
};
</script>

<style scoped></style>
