import {getPickListItems} from "../data/getPickListItems.js";
import {getActiveWarehouse} from "../../../util/getActiveWarehouse.js";
import {getColumns} from "../columns/getColumns.js";
import {convertKeyValueRowsToCellRows} from "../rows/convertKeyValueRowsToCellRows.js";
import {setClientSideUUIDToRows} from "../rows/setClientSideUUIDToRows.js";
import {mergeBulkRows} from "./rows/mergeBulkRows.js";
import {rowsSetNullValueForMissingProps} from "../rows/rowsSetNullValueForMissingProps.js";
import {setAllSerialRowsPickQuantityReadOnly} from "./rows/setAllSerialRowsPickQuantityReadOnly.js";
import {setPreScannedSerialRowsToPicked} from "./rows/setPreScannedSerialRowsToPicked.js";
import {setRowsSerialColumnReadOnlyForNonSerialRows} from "./rows/setRowsSerialColumnReadOnlyForNonSerialRows.js";
import {setRowsSerialPickedQuantityToInt} from "./rows/setRowsSerialPickedQuantityToInt.js";
import {setRowsOrderedPickedQuantityToInt} from "./rows/setRowsSerialOrderedQuantityToInt.js";
import {rowsTogglePickedQuantityBasedOnSerialScannedStatus} from "./rows/rowsTogglePickedQuantityBasedOnSerialScannedStatus.js";
import {setRowsAmountPickedForItemsWithoutSerials} from "./rows/setRowsAmountPickedForItemsWithoutSerials.js";
import {setItemIDReadonly} from "./rows/setItemIDReadonly.js";
import {processColumnsMetaData} from "./processColumnsMetaData.js";
import {sortColumns} from "../columns/sortColumns.js";

export const setPicklistData = async ({pickListId, criteria, settings}) => {
  let [pickListItems, columns] = await Promise.all([
    getPickListItems({
      pickListId,
      warehouseID: getActiveWarehouse(),
    }),
    getColumns({
      table: "Rental.PickListItem", //output.Request.Subject,
      prefix: "Multi",
      primaryKey: "PickListID",
      criteria: criteria,
    }),
  ]);

  let rows = convertKeyValueRowsToCellRows(pickListItems);
  rows = setClientSideUUIDToRows({rows});
  rows = mergeBulkRows({rows});
  rows = rowsSetNullValueForMissingProps({rows, columns});
  rows = setAllSerialRowsPickQuantityReadOnly({rows});
  rows = setPreScannedSerialRowsToPicked({rows});
  rows = setRowsSerialColumnReadOnlyForNonSerialRows({rows});

  rows = setRowsSerialPickedQuantityToInt({rows});
  rows = setRowsOrderedPickedQuantityToInt({rows});
  rows = rowsTogglePickedQuantityBasedOnSerialScannedStatus({rows});
  rows = setRowsAmountPickedForItemsWithoutSerials({
    rows,
    settings,
  });
  rows = setItemIDReadonly({rows});

  columns = processColumnsMetaData({columns});
  columns = sortColumns(columns);

  return {rows, columns};
};
