import {getMissingPeriodColumns} from "./getMissingPeriodColumns";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";
import {getSettings} from "@/util/getSettings";

export function processPeriodColumnChange({row}) {
  const {PeriodSelection} = getSettings();
  const translations = getTranslations();

  let missingColumnsArray = getMissingPeriodColumns({
    row,
    setting: PeriodSelection,
  });

  const icon = {
    Icon: "fa-exclamation-triangle",
    IconColor: "warning",
    IconMessage: translations.PeriodColumnRequired,
  };
  const emptyIcon = {
    Icon: null,
    IconColor: null,
    IconMessage: null,
  };
  const emptyColumns = {
    Period: {
      ...row.Period,
      ...emptyIcon,
    },
    PeriodAmount: {
      ...row.PeriodAmount,
      ...emptyIcon,
    },
    PeriodPrice: {
      ...row.PeriodPrice,
      ...emptyIcon,
    },
  };

  if (PeriodSelection === "Default") {
    return row;
  }

  if (
    PeriodSelection === "ItemSinglePeriodEnforced" &&
    missingColumnsArray.length > 2
  ) {
    return {
      ...row,
      ...emptyColumns,
    };
  }

  if (
    (PeriodSelection === "AllowItemSinglePeriodPriceCalculation" ||
      PeriodSelection === "ItemSinglePeriodCalculateEndDateEnforced") &&
    missingColumnsArray.length > 1
  ) {
    return {
      ...row,
      ...emptyColumns,
    };
  }

  return {
    ...row,
    Period: {
      ...row.Period,
      ...(missingColumnsArray.includes("Period") ? icon : emptyIcon),
    },
    PeriodAmount: {
      ...row.PeriodAmount,
      ...(missingColumnsArray.includes("PeriodAmount") ? icon : emptyIcon),
    },
    PeriodPrice: {
      ...row.PeriodPrice,
      ...(missingColumnsArray.includes("PeriodPrice") ? icon : emptyIcon),
    },
  };
}
