import {calculateMaxAmount} from "./max-amount/calculateMaxAmount";

export const rowSetMaxAmountRejected = ({row}) => {
  const maxAmount = calculateMaxAmount({
    row,
    currentAmount: row.AmountRejected?.Value ?? 0,
  });
  return {
    ...row,
    AmountRejected: {
      ...row.AmountRejected,
      Max: maxAmount,
    },
  };
};
