import {cloneDeep} from "lodash";
import {isRowPresentInRowsArray} from "@/functions/datagrid/row/isRowPresentInRowsArray";

export function assignDeletedRows({initialRows, newRows}) {
  const assignedDeletedRows = cloneDeep(newRows);

  if (initialRows.length < 1) {
    return assignedDeletedRows;
  }

  for (const row of initialRows) {
    if (!isRowPresentInRowsArray({row: row, rows: assignedDeletedRows})) {
      assignedDeletedRows.push({
        ...row,
        Delete: true,
      });
    }
  }
  return assignedDeletedRows;
}
