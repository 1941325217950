import store from "../../../state/store.js";
import {api} from "../api.js";

export default async function canReturn(orderItemId) {
  const result = await api.get(`/v2/orderitems/${orderItemId}/canReturn`, {
    params: {
      Warehouse: store.state.activeWarehouse,
      include: "prices",
    },
  });

  return result.data;
}
