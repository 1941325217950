import {isStackingPriceCondition} from "./isStackingPriceCondition";
import {getPriceForPriceCondition} from "./getPriceForPriceCondition";
import {setValuesForRowInRows} from "../setValuesForRowInRows";
import {setNonPriceValuesOfPriceCondition} from "./setNonPriceValuesOfPriceCondition";

export const updateRowsWithModifyRowPriceCondition = ({
  rows,
  row,
  priceCondition,
  itemPrice,
}) => {
  let valuesToUpdate = {};
  if (priceCondition.PriceType && priceCondition.PriceType !== "None") {
    const currentPrice = isStackingPriceCondition({priceCondition})
      ? row.PurchasePricePerUnit?.Value ?? 0
      : itemPrice;

    const updatedPrice = getPriceForPriceCondition({
      priceCondition,
      currentPrice,
    });

    valuesToUpdate = {PurchasePricePerUnit: updatedPrice};
  }
  valuesToUpdate = setNonPriceValuesOfPriceCondition({
    priceCondition,
    row: valuesToUpdate,
  });

  return setValuesForRowInRows({
    row,
    rows,
    values: valuesToUpdate,
  });
};
