<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <div ref="datagrid">
      <datagrid-standalone
        v-if="loaded && columns.length"
        :rows="rows"
        :actions="actions"
        :columns="columns"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :window-id="rWindow.id"
        :user-metadata-key="userMetadataKey"
        :page="page"
        :visible-row-function="getVisibleRowsCompositionGrouped"
        :page-size="pageSize"
        :process-context="{quotation}"
        :action-functions="actionFunctions"
        :skeleton-rows="addItemQueue.length"
        @click-row="handleRowClick"
        @loading="loading = $event"
        @rows-updated="handleRowsUpdated($event)"
        @cell-icon-click="handleCellIconClick($event)"
        @page-size-change="handlePageSizeChange"
        @page-change="handlePageChange({page: $event})"
      >
        <template #header>
          <datagrid-header-quotation-item
            :loaded="loaded"
            :entity="entity"
            :scan-amount="scanAmount"
            :settings="settings"
            :page-size="pageSize"
            @scan-amount-change="handleScanAmountChange"
            @item-select="addItem"
            @dialog-toggle="showItemPickerDialog = !showItemPickerDialog"
            @page-size-change="handlePageSizeChange"
          />
        </template>
        <template #footer>
          <datagrid-quotation-item-footer
            :rows="rows"
            :loading-page="loadingPage"
            :total-weights="totalWeights"
            :extra-total-values="extraTotalValues"
            :page="page"
            :page-size="pageSize"
            :active-row="activeRow"
            :currency="currency"
            :default-currency-id="defaultCurrencyId"
            @page-change="handlePageChange({page: $event})"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
//Generic
import DatagridStandalone from "../DatagridStandalone.vue";
import {notify} from "../../../util/notify";
import {cloneDeep} from "lodash";
import {getUserId} from "../../../functions/session/getUserId";
import {getColumns} from "../../../functions/datagrid/columns/getColumns";
import {sortColumns} from "../../../functions/datagrid/columns/sortColumns";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";
import {mapActionComponent} from "../../../functions/datagrid/mapActionComponent";
import {processColumnSettings} from "../../../functions/datagrid/columns/processColumnSettings";
import {processDatagridDatepickers} from "../../../functions/datagrid/columns/processDatagridDatepickers.js";

//Quotation
import DatagridHeaderQuotationItem from "../datagridHeaders/DatagridHeaderQuotationItem.vue";
import DatagridQuotationItemFooter from "../datagridFooters/DatagridQuotationItemFooter.vue";
import {addNewRows} from "../../../functions/datagrid/quotation-item/addNewRows";
import {getQuotation} from "../../../services/quotations/getQuotation";
import {getQuotationItems} from "../../../services/quotations/getQuotationItems";
import {saveQuotationItems} from "../../../functions/datagrid/quotation-item/saveQuotationItems";
import {processColumnsMetaData} from "../../../functions/datagrid/quotation-item/processColumnsMetaData";
import {processQuotationItemRowMetaData} from "../../../functions/datagrid/quotation-item/row/processQuotationItemRowMetaData";

//Rows
import {sortRows} from "../../../functions/datagrid/rows/sortRows";
import {normalizeValueKeyRows} from "../../../functions/datagrid/rows/normalizeValueKeyRows.js";
import {sanitizeServerRows} from "../../../functions/datagrid/sanitizeServerRows";
import {loadRowDataForPage} from "../../../functions/datagrid/quotation-item/loadRowDataForPage";
import {integrateRowsChanges} from "../../../functions/datagrid/rows/integrateRowsChanges";
import {convertKeyValueRowsToCellRows} from "../../../functions/datagrid/rows/convertKeyValueRowsToCellRows";
import {setClientSideUUIDToRows} from "../../../functions/datagrid/rows/setClientSideUUIDToRows";
import {getVisibleRowsCompositionGrouped} from "../../../functions/datagrid/rows/getVisibleRowsCompositionGrouped";
import {handleRowsUpdated} from "../../../functions/datagrid/quotation-item/event-handlers/handleRowsUpdated.js";
import {addCustFieldsAsRootPropertiesToRows} from "../../../functions/datagrid/quotation-item/rows/addCustFieldsAsRootPropertiesToRows.js";
import handleRowDeleteCompositionSupport from "../../../functions/datagrid/row/handleRowDeleteCompositionSupport";
import {applyRentalQuotationItemPricesUpdatedDraftHook} from "../../../functions/datagrid/quotation-item/rows/applyRentalQuotationItemPricesUpdatedDraftHook";
import {getDataWebhooks} from "../../../functions/session/localstorage/getDataWebhooks";
import {getExtraTotalValues} from "../../../functions/datagrid/quotation-item/rows/getExtraTotalValues";
import {prepareRowsForRender} from "../../../functions/datagrid/rows/prepareRowsForRender";
import {setCustomRowsPropertiesFromCustomPropertyObject} from "../../../functions/datagrid/rows/setCustomRowsPropertiesFromCustomPropertyObject.js";
import {getTotalWeightFromRows} from "../../../functions/datagrid/rows/getTotalWeightFromRows.js";
import {handleJobExecution} from "../../../functions/datagrid/actions/handleJobExecution.js";
import {confirmDiscardUnsavedChanges} from "../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {setInitialDatagridDataToWindow} from "../../../functions/datagrid/data/setInitialDatagridDataToWindow.js";
import {updateRows} from "../../../functions/datagrid/rows/updateRows.js";

export default {
  name: "DatagridQuotationItem",
  components: {
    DatagridHeaderQuotationItem,
    DatagridQuotationItemFooter,
    DatagridStandalone,
  },
  provide() {
    const vm = this;

    const rate = {};
    Object.defineProperty(rate, "rate", {
      enumerable: true,
      get: () => this.rate,
    });

    return {
      rate,
      translations: this.translations,
      rateCallback(row, followSetting = true) {
        if (vm.selectedCurrencyId === vm.defaultCurrencyId && followSetting)
          return 1;
        return row?.Rate?.Value ?? 1;
      },
    };
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      entity: "DatagridQuotationItem",
      quotationId: null,
      quotation: {},
      criteria: [],
      rows: [],
      originalRows: [],
      columns: {},
      page: 1,
      pageSize: 10,
      activeRow: null,
      showItemPickerDialog: false,
      selectedItemId: null,
      addItemQueue: [],
      scanAmount: 1,
      loading: false,
      loaded: false,
      loadingPage: false,
      defaultCurrencyId: global.session?.defaultCurrencyId,
      extraTotalValues: {},
      sortBy: "Ranking",
      sortDirection: "asc",
      translations: getTranslations(),
      actions: [{type: "Delete"}].map(mapActionComponent),
      actionFunctions: {
        deleteRow: handleRowDeleteCompositionSupport,
      },
    };
  },
  computed: {
    totalWeights() {
      return getTotalWeightFromRows({rows: this.rows});
    },
    rate() {
      return this.rows[0]?.Rate?.Value ?? 1;
    },
    currency() {
      return (
        this.quotation?.CurrencyID?.Description ??
        this.defaultCurrencyId ??
        null
      );
    },
    selectedCurrencyId() {
      return (
        this.rWindow.request.Data?.ClientCurrency ?? this.defaultCurrencyId
      );
    },
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Rental.QuotationItem-Multi`;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.dataWebhooks = getDataWebhooks();
    this.criteria = this.rWindow.criteria ?? this.rParentWindow.criteria;
    const rentalQuotationItemPricesUpdatedDraftWebhookEvent =
      this.dataWebhooks.some(
        (webhook) =>
          webhook.EventID === "rental.quotationitem.prices.updated.draft",
      );

    this.quotationId = this.criteria[0].QuotationID;

    let [quotationItemReponse, columns, quotationResult] = await Promise.all([
      getQuotationItems({
        quotationId: this.quotationId,
        params: {
          include: `CompositionItem${
            !rentalQuotationItemPricesUpdatedDraftWebhookEvent
              ? ",PeriodPrices"
              : ""
          }`,
        },
      }),
      getColumns({
        table: "Rental.QuotationItem",
        prefix: "MultiEdit",
        primaryKey: "QuotationID",
        request: this.criteria,
      }),
      getQuotation({quotationId: this.quotationId}),
    ]);

    const quotation = quotationResult.data;

    let rows = await Promise.all(
      convertKeyValueRowsToCellRows(quotationItemReponse.data?.Collection).map(
        (x) => processQuotationItemRowMetaData({row: x, quotation}),
      ),
    );

    rows = setClientSideUUIDToRows({rows});

    this.rows = rows;

    columns = processColumnSettings(columns);
    columns = sortColumns(columns);
    columns = processDatagridDatepickers({columns});

    await sanitizeServerRows({rows, columns});

    this.columns = columns;

    rows = setCustomRowsPropertiesFromCustomPropertyObject({rows: rows});
    rows = await applyRentalQuotationItemPricesUpdatedDraftHook({
      rows,
      quotation,
      dataWebHooks: this.dataWebhooks,
    });

    rows = prepareRowsForRender({rows, columns});
    this.rows = rows;

    this.quotation = quotation;
    rows = await this.loadAndApplyRowDataForPage({
      rows: this.rows,
      page: this.page,
      pageSize: this.pageSize,
      quotation: this.quotation,
    });

    this.rows = sortRows(rows);
    this.loaded = true;

    await new Promise((r) => setTimeout(r, 0));
    this.columns = [].concat(
      await processColumnsMetaData({
        columns: this.columns,
        dataWebHooks: this.dataWebhooks,
      }),
    );

    this.originalRows = cloneDeep(this.rows);
    this.extraTotalValues = await getExtraTotalValues({
      rows: await addCustFieldsAsRootPropertiesToRows({
        rows: this.rows.filter((row) => !row.rowMeta?.virtual),
      }),
      quotation: this.quotation,
      dataWebHooks: this.dataWebhooks,
    });

    const columnsObject = this.columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {});

    setInitialDatagridDataToWindow({
      window: this.rWindow,
      columns: columnsObject,
      rows: this.rows,
      vueInstance: this,
    });
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async addItem(item) {
      const datagridElement =
        this.$refs.datagrid.querySelector(".datagrid-table");

      this.addItemQueue.push(item);
      if (this.addItemQueue.length > 1) {
        return;
      }

      while (this.addItemQueue.length > 0) {
        try {
          const queuedItem = this.addItemQueue[0];
          const newRows = await addNewRows({
            item: queuedItem,
            columns: this.columns,
            rows: this.rows,
            quotation: this.quotation,
            settings: this.settings,
            scanAmount: this.scanAmount,
          });

          const oldRows = cloneDeep(this.rows);
          this.updateRows({newRows, oldRows, vueComponent: this});
        } catch (error) {
          console.error(error);
        } finally {
          this.addItemQueue.shift();
        }
      }

      let rows = this.rows;
      rows = prepareRowsForRender({rows, columns: this.columns});
      this.rows = rows;

      rows = await applyRentalQuotationItemPricesUpdatedDraftHook({
        rows,
        quotation: this.quotation,
        dataWebHooks: this.dataWebhooks,
      });

      if (this.rows !== rows) {
        rows = prepareRowsForRender({rows, columns: this.columns});
        this.rows = rows;
      }

      this.extraTotalValues = {
        ...(await getExtraTotalValues({
          rows: this.rows,
          quotation: this.quotation,
          dataWebHooks: this.dataWebhooks,
        })),
      };

      this.navigateToLastPage();
      this.scrollToBottom(datagridElement);
    },
    async save(job) {
      try {
        if (!this.loaded) return false;

        const currentDirtyState = this.rWindow.data.dirty;
        this.$emit("data-change", {
          windowId: this.rWindow.id,
          newData: {
            ...this.rWindow.data,
            dirty: false,
          },
        });

        this.$store.state.loading = true;
        document.activeElement.blur();

        while (this.loading || this.loadingPage) {
          await new Promise((r) => setTimeout(r, 0));
        }
        // normalize rows replace all {Key,Value} object properties in  the Value property of each row with the Key property
        this.rows = normalizeValueKeyRows({rows: this.rows});

        const success = await saveQuotationItems({
          quotation: this.quotation,
          quotationItemRows: this.rows.filter((row) => !row.rowMeta?.virtual),
        });

        if (!success)
          this.$emit("data-change", {
            windowId: this.rWindow.id,
            newData: {
              ...this.rWindow.data,
              dirty: currentDirtyState,
            },
          });
        if (success)
          notify({
            message: this.translations["SaveSuccessful"],
            type: "success",
          });
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);

        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }
        this.$store.state.loading = true;

        let quotationItemReponse = await getQuotationItems({
          quotationId: this.quotationId,
          params: {
            include: "CompositionItem",
          },
        });

        let rows = await Promise.all(
          convertKeyValueRowsToCellRows(
            quotationItemReponse.data?.Collection,
          ).map((x) =>
            processQuotationItemRowMetaData({
              row: x,
              quotation: this.quotation,
            }),
          ),
        );

        rows = setClientSideUUIDToRows({rows});
        await sanitizeServerRows({rows, columns: this.columns});

        rows = setCustomRowsPropertiesFromCustomPropertyObject({rows: rows});
        rows = await applyRentalQuotationItemPricesUpdatedDraftHook({
          rows,
          quotation: this.quotation,
          dataWebHooks: this.dataWebhooks,
        });

        rows = prepareRowsForRender({rows, columns: this.columns});
        this.rows = sortRows(rows);
        this.originalRows = cloneDeep(this.rows);

        const columnsObject = this.columns.reduce((acc, column) => {
          acc[column.Name] = column;
          return acc;
        }, {});

        setInitialDatagridDataToWindow({
          window: this.rWindow,
          columns: columnsObject,
          rows: this.rows,
          vueInstance: this,
        });
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async sortRows(job) {
      this.rows = sortRows(this.rows);
      notify({message: this.translations["RowsSorted"], type: "success"});
      this.$emit("job-completed", job);
      return false;
    },
    handleRowsUpdated(event) {
      handleRowsUpdated({...event, vueComponent: this});
    },
    updateRows,
    handleScanAmountChange(scanAmount) {
      this.scanAmount = scanAmount.target.value;
    },
    async handlePageChange({page}) {
      this.page = page;
      this.rows = await this.loadAndApplyRowDataForPage({
        rows: this.rows,
        page,
        pageSize: this.pageSize,
        quotation: this.quotation,
      });
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    scrollToBottom(element) {
      element.scrollTop = element.scrollHeight;
    },
    navigateToLastPage() {
      const rowCount = this.rows.filter(
        (row) => !row.rowMeta?.compositionItem,
      ).length;
      this.page = Math.ceil(rowCount / this.pageSize);
    },
    navigateToPage({page}) {
      this.page = page;
    },
    async loadAndApplyRowDataForPage() {
      const changedRowsObject = await loadRowDataForPage({
        rows: this.rows,
        page: this.page,
        pageSize: this.pageSize,
        quotation: this.quotation,
      });

      return integrateRowsChanges({
        originalRows: this.rows,
        changedRows: changedRowsObject,
      });
    },
    handleCellIconClick({row, cell}) {
      if (cell.Name === "Amount") {
        this.selectedItemId = row.ItemID.Value;
        this.showItemPickerDialog = true;
        setTimeout(() => {
          this.selectedItemId = null;
        }, 300);
      }
    },
    handleRowClick(row) {
      this.updateActiveRow(row);
    },
    updateActiveRow(row) {
      this.activeRow = row;
    },
    getVisibleRowsCompositionGrouped,
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-input {
  padding: 0;
}
.btn-group {
  .input-group-append {
    box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%) !important;
    border: 1px solid #ced4da;
    height: 31.5px !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin-left: -2px;
    :hover {
      background-color: #eeeeee;
    }
  }
  .input-group-append:hover {
    background-color: #eeeeee;
    cursor: default;
  }
}
</style>
