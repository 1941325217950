export const processSidebarFields = ({
  columns,
  getValueByColumnName,
  getMetadataByColumnName,
  getComboboxByColumnName,
}) => {
  if (columns) {
    const sidebarFields = columns.filter((column) => column.IsSideBarColumn);
    sidebarFields.forEach((field) => {
      field.Value = getValueByColumnName(field.Name);
      field.Metadata = getMetadataByColumnName(field.Name);
      field.Combobox = getComboboxByColumnName(field.Name);
    });
    if (sidebarFields.length > 0)
      return sidebarFields.sort((a, b) => a.Ranking - b.Ranking);
  }

  return [];
};
