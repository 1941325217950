import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export function translateGetOrderItems(response) {
  for (const orderItem in response.Collection) {
    // replace ItemID value with ItemID.Key
    response.Collection[orderItem].ItemID = getRawValueFromDropdownValue(
      response.Collection[orderItem].ItemID,
    );
  }

  return response;
}
