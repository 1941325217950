import {getSmsValueLength} from "./getSmsValueLength.js";
import store from "../../../../state/store.js";

export const calculateRemainingCharactersSMSMessage = ({
  value,
  maxCharacters,
}) => {
  if (!value) return maxCharacters;

  const maxParameterLength = store.state.settings.SMSParameterLength;
  const smsLength = getSmsValueLength({value, maxParameterLength});

  return maxCharacters - smsLength;
};
