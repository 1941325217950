import {convertCellRowsToKeyValueRows} from "@/functions/datagrid/rows/convertCellRowsToKeyValueRows";
import {saveButtonClick} from "@/services/rent-web-services/saveButtonClick";

export const saveInvoiceItems = async ({invoiceItemRows, invoice}) => {
  const headerData = {
    RentalType: null,
    CategoryID: null,
    DefaultPeriodID: null,
  };
  const liRowData = convertCellRowsToKeyValueRows({rows: invoiceItemRows});
  const request = {
    IsSubWindow: false,
    HideEmptyPlaceHolder: true,
    HideDefaultFilters: false,
    Subject: "Rental.virtual_QuickRent",
    TableToSelect: null,
    ParentSubject: "",
    TitleResourceID: "HandOut",
    SubTitleResourceID: null,
    ExcelAction: null,
    LinkedSubject: null,
    Prefix: "Multi",
    Headless: false,
    RunDesktopHooks: true,
    Criteria: [
      {
        InvoiceID: invoice.InvoiceID,
        Target: "Rental.Invoice",
      },
    ],
    EntityTranslationLanguage: null,
    IsTableValuedFunction: false,
    TableValuedFunctionParameters: null,
    Data: {
      Search: [],
      Filters: {},
      SortOn: null,
      SortReverse: false,
      SelectedTabActionName: null,
      PageNumber: 1,
      PageSize: null,
      ShowCurrencyToggle: false,
      ToggleCurrency: false,
      ClientCurrency: "EUR",
      ClientCurrencySymbol: "€",
      OldClientCurrency: null,
      ForeignCurrency: "EUR",
    },
    PreviousPostBackValues: null,
    PostBackValues: null,
  };

  return await saveButtonClick({request, headerData, liRowData});
};
