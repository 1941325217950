import {getRawValueFromDropdownValue} from "../../util/parsing/getRawValueFromDropdownValue.js";
import {getTranslations} from "../session/localstorage/getTranslations.js";

export const getNotificationRowAddedMessage = ({scannedRows}) => {
  const translations = getTranslations();

  const isCompositionItem = scannedRows.some((row) => row.CompositionID?.Value);
  const isFreeItem =
    getRawValueFromDropdownValue(scannedRows[0].ItemType?.Value) === "Free";

  const translation =
    translations["Rental.OrderItem-MultiEdit-NotificationRowAdded"];

  return translation
    .replace(
      "{itemType}",
      isCompositionItem ? translations["Composition"] : translations["Item"],
    )
    .replace(
      "{itemId}",
      isCompositionItem
        ? scannedRows[0].CompositionID?.Value
        : scannedRows[0].ItemID.Value,
    )
    .replace(
      "{itemDescription}",
      isCompositionItem
        ? " | " + scannedRows[0].CompositionDescription?.Value ?? ""
        : isFreeItem
        ? "".trimEnd().slice(0, -1).slice(0, -1)
        : " | " +
          (scannedRows[0].ItemDescription?.Value ??
            scannedRows[0].Description?.Value ??
            ""),
    );
};
