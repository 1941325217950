import {clearInvalidLinkedField} from "./clearInvalidLinkedField.js";
import {getOptions} from "../../fields/select-field/getOptions.js";

export const processLinkedFormFieldFromCriteria = async ({
  linkedField,
  columns,
  row,
}) => {
  if (linkedField.Type === "Date" || linkedField.Editor === "date")
    return clearInvalidLinkedField({linkedField, columns, row});

  const newOptions = await getOptions({
    maxReturnableOptions: null,
    field: linkedField,
    row,
  });

  const newCriterionFieldOptionsIncludesSelectedValue = newOptions.some(
    (option) => option.Value === row[linkedField.Name],
  );
  if (
    !newCriterionFieldOptionsIncludesSelectedValue &&
    linkedField.Editor !== "autoIncrement"
  )
    return clearInvalidLinkedField({linkedField, columns, row});

  return {
    newRow: row,
    newColumns: {
      ...columns,
      [linkedField.Name]: {
        ...linkedField,
        Dropdown: {...linkedField.Dropdown, Items: newOptions},
      },
    },
  };
};
