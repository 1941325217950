<template>
  <div
    class="h-[31.5px] shadow-[1px_1px_1px_0px_rgba(0,0,0,0.09)] border border-[#ced4da] rounded-[3px] px-1"
    :class="{
      readonly: field.IsReadOnly,
    }"
  >
    <date-range-picker
      ref="picker"
      :locale-data="dataFormat"
      :date-range="value"
      :date-format="dateFormat"
      :show-dropdowns="true"
      :auto-apply="false"
      :readonly="field.IsReadOnly"
      :ranges="false"
      :single-date-picker="true"
      :append-to-body="appendToBody"
      :time-picker="true"
      :show="open"
      @update="handleChange"
    >
    </date-range-picker>

    <div class="date-time-field flex w-full justify-end">
      <div
        class="pt-1.5 pl-0.5 min-w-0 flex-1 overflow-hidden truncate"
        :class="{'text-[#888]': field.IsReadOnly}"
        @click="togglePicker"
      >
        {{ startDate }}
      </div>

      <div v-if="!field.IsReadOnly" class="flex justify-end">
        <button
          v-if="!field.IsRequired && value.startDate !== null"
          class="btn btn-link px-1"
          @click="handleChange(null)"
        >
          <i class="fas fa-times text-dark"></i>
        </button>

        <button class="btn btn-link px-1" @click="togglePicker">
          <i class="fas fa-calendar-alt text-dark"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import DateRangePicker from "../../calendar/DateRangePicker.vue";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export default {
  name: "DateField",
  components: {
    DateRangePicker,
  },
  inject: ["translations"],
  props: {
    field: {
      type: Object,
      required: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      open: false,
    };
  },
  computed: {
    formattedDateValue() {
      if (this.field.Value === null) return {startDate: null, endDate: null};
      // Remove characters from C# version of EPOCH unix timestamp

      const startDate = dayjs(this.field.Value ?? new Date()).format(
        "YYYY-MM-DD HH:mm",
      );

      const dateRange = {
        startDate,
        endDate: "",
      };

      return dateRange;
    },
    startDate() {
      if (this.value.startDate) {
        return dayjs(this.value.startDate).format("DD-MM-YYYY HH:mm");
      }
      return "";
    },
    dataFormat() {
      return {
        direction: "ltr",
        format: "dd-mm-yyyy hh:mm",
        separator: " - ",
        changed: false,
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: [
          this.translations["calendar-sun"],
          this.translations["calendar-mon"],
          this.translations["calendar-tue"],
          this.translations["calendar-wed"],
          this.translations["calendar-thu"],
          this.translations["calendar-fri"],
          this.translations["calendar-sat"],
        ],
        monthNames: [
          this.translations["calendar-jan"],
          this.translations["calendar-feb"],
          this.translations["calendar-mar"],
          this.translations["calendar-apr"],
          this.translations["calendar-may"],
          this.translations["calendar-jun"],
          this.translations["calendar-jul"],
          this.translations["calendar-aug"],
          this.translations["calendar-sep"],
          this.translations["calendar-oct"],
          this.translations["calendar-nov"],
          this.translations["calendar-dec"],
        ],
        firstDay: 0,
      };
    },
  },

  watch: {
    field: {
      handler(newValue) {
        this.value = this.formattedDateValue;
      },
      deep: true,
    },
  },
  created() {
    this.value = this.formattedDateValue;
  },
  methods: {
    dateFormat(classes) {
      return classes;
    },
    valueChanged(value) {
      this.$emit("valueChanged", value);
    },
    parseDateTimeRangeValue(dateRange) {
      if (!dateRange) return null;
      if (dateRange.startDate === null) return null;
      return dayjs(dateRange.startDate).format("YYYY-MM-DD HH:mm");
    },
    handleChange(value) {
      this.$emit("input", this.parseDateTimeRangeValue(value));
    },
    togglePicker() {
      if (
        Object.prototype.hasOwnProperty.call(this.field, "IsReadOnly") &&
        !this.field.IsReadOnly
      ) {
        this.open = !this.open;
        this.$refs.picker.togglePicker();
      }
    },
  },
};
</script>

<style scoped>
.border {
  border-color: #ced4da !important;
}
.date-field {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%);
  border: 1px solid #ced4da;
  height: 31.5px !important;
  border-radius: 3px;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.date-field .btn {
  margin: 0px;
}

.readonly {
  cursor: not-allowed !important;
  background-color: #eeeeee !important;
}
</style>
