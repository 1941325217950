export const setItemIDReadonly = ({rows}) =>
  rows.map((row) => {
    return {
      ...row,
      ItemID: {
        ...row.ItemID,
        IsReadOnly: true,
      },
    };
  });
