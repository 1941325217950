import dayjs from "dayjs";
export function setAndForceMinDates({row}) {
  const newRow = {...row};
  if (newRow.DateTimeBusinessStart.Value) {
    const minBussinessEndDate = dayjs(
      newRow.DateTimeBusinessStart.Value,
      "YYYY-MM-DD",
    ).format("YYYY-MM-DD");

    newRow.DateTimeBusinessEnd.Min = minBussinessEndDate;

    if (
      dayjs(newRow.DateTimeBusinessEnd.Value, "YYYY-MM-DD").isBefore(
        minBussinessEndDate,
      )
    ) {
      newRow.DateTimeBusinessEnd.Value = newRow.DateTimeBusinessStart.Value;
    }
  }

  if (newRow.DateTimeExpectedStart.Value) {
    const minExpectedEndDate = dayjs(
      newRow.DateTimeExpectedStart.Value,
      "YYYY-MM-DD",
    ).format("YYYY-MM-DD");

    newRow.DateTimeExpectedEnd.Min = minExpectedEndDate;

    if (
      dayjs(newRow.DateTimeExpectedEnd.Value, "YYYY-MM-DD").isBefore(
        minExpectedEndDate,
      )
    ) {
      newRow.DateTimeExpectedEnd.Value = newRow.DateTimeExpectedStart.Value;
    }
  }

  return newRow;
}
