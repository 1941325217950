<template>
  <div class="px-2 flex justify-center content-center">
    <input class="readonly" type="checkbox" disabled :checked="checked" />
  </div>
</template>

<script>
export function renderAsText() {
}

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    cell: {
      type: Object,
      required: true,
    },
  },
  computed: {
    checked() {
      if (this.value == "true") {
        return "checked";
      }
      return "false";
    },
  },
};
</script>

<style scoped>
.date-cell {
  height: 29px;
  display: flex;
  align-items: center;
}
td {
  white-space: nowrap;
  line-height: 30px;
}
div {
  border: none;
  height: 26px;
  background-color: transparent;
}
</style>
