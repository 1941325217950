import {getSerialInfo} from "@/services/rent-web-services/getSerialInfo";
import {unixMilisecondsToDayjs} from "@/util/formatting/unixToDayjs";
import handleActionResponse from "@/model/window-actions/handleActionResponse";
import {checkServerErrorNotification} from "../checkServerErrorNotification";

export async function fetchScannedValueRowData({scannedValue, criteria}) {
  const getSerialInfoInput = {
    headerData: {
      RentalType: "",
      CategoryID: "",
    },
    liRowData: [],
    request: {
      Criteria: criteria,
      Data: {
        Search: [],
        Filters: {},
        SortOn: null,
        SortReverse: false,
        SelectedTabActionName: null,
        PageNumber: 1,
        PageSize: null,
        ShowCurrencyToggle: true,
        ToggleCurrency: false,
        ClientCurrency: "EUR",
        ClientCurrencySymbol: "€",
        OldClientCurrency: null,
        ForeignCurrency: null,
      },
      IsSubWindow: false,
      HideEmptyPlaceHolder: true,
      HideDefaultFilters: false,
      Subject: "Rental.virtual_QuickRent",
      TableToSelect: null,
      ParentSubject: "",
      TitleResourceID: "HandOut",
      SubTitleResourceID: null,
      LinkedSubject: null,
      Prefix: "Multi",
      EntityTranslationLanguage: null,
      TableValuedFunctionParameters: [],
      PreviousPostBackValues: null,
      PostBackValues: null,
    },
    rowRanking: null,
    scannedSerial: scannedValue,
  };

  const result = await getSerialInfo(getSerialInfoInput);
  const serverResponse = checkServerErrorNotification({result});

  if (serverResponse === "error") {
    console.error("Error");
  }

  await handleActionResponse(result);

  result.Rows?.forEach((row, index) => {
    Object.keys(row).forEach((key) => {
      if (typeof row[key] === "string" && row[key]?.startsWith("/Date(")) {
        row[key] = unixMilisecondsToDayjs(row[key]).format("YYYY-MM-DD");
      }
    });
    if (row.ItemUnitID) {
      row.ItemUnitID = {
        Key: row.ItemUnitID,
        Description: result.Metadata[index]?.ItemUnitID?.Description,
      };
    }
  });

  return result;
}
