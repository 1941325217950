import {getSettings} from "../../../util/getSettings.js";
const {PeriodSelection} = getSettings();

export function handleItemTypeSalesChangeOnPeriodSelection({row}) {
  let newRow = {...row};

  if (newRow.Period) {
    newRow.Period.IsReadOnly = false;
  }

  if (newRow.PeriodAmount) {
    newRow.PeriodAmount.IsReadOnly = false;
  }

  if (newRow.PeriodPrice) {
    newRow.PeriodPrice = {
      ...newRow.PeriodPrice,
      IsReadOnly: false,
      Value: null,
    };
  }

  if (
    (PeriodSelection === "Default" ||
      PeriodSelection === "AllowItemSinglePeriodPriceCalculation") &&
    newRow.DateTimeBusinessEnd
  ) {
    newRow.DateTimeBusinessEnd.IsReadOnly = false;
  }

  return newRow;
}
