import {convertKeyValueRowToCellRow} from "../row/convertKeyValueRowToCellRow";
import {assignScannedItemValuesToCorrectRowValues} from "@/functions/datagrid/inbound-orderitem/row/assignScannedItemValuesToCorrectRowValues";
import {rowRemoveAllNonExistingColumnProperties} from "@/functions/datagrid/row/rowRemoveAllNonExistingColumnProperties";
import {processRowMetadata} from "@/functions/datagrid/inbound-orderitem/row/processRowMetadata";
import {addMissingKeysToRow} from "@/functions/datagrid/row/addMissingKeysToRow";
import {setClientSideUUIDToRow} from "@/functions/datagrid/row/setClientSideUUIDToRow";
import {applyPriceConditions} from "./rows/applyPriceConditions";
import {sortRowsByRanking} from "../row/sortByRanking";
import {handleTotalPriceChangeForRows} from "./rows/handleTotalPriceChangeForRows";
import {handleVATCodeIDChangeOnRows} from "./rows/handleVATCodeIDChangeOnRows";
import {setDefaultVatCodeID} from "./rows/setDefaultVatCodeID";
import {flattenKeyObjectsInRow} from "../../parsing/flattenKeysInRowCollection";
import {distributeCosts} from "./rows/distributeCosts";
import {processInboundValueReadonlyForRows} from "./rows/processInboundInputReadonlyForRows";
import {validateInboundValue} from "./rows/validateInboundValue";
import {getUpdatedRowFromRows} from "./rows/apply-price-conditions/getUpdatedRowFromRows";

export const getRows = async ({
  scannedRow,
  rows,
  columns,
  inboundOrder,
  inboundItems,
  priceConditions,
  warehouseId,
  vatCodes,
  supplier,
  costDistributionType,
}) => {
  let modifiedScannedRow = flattenKeyObjectsInRow({row: scannedRow});

  modifiedScannedRow = convertKeyValueRowToCellRow(modifiedScannedRow);

  modifiedScannedRow = setDefaultVatCodeID({row: modifiedScannedRow, supplier});

  modifiedScannedRow = addMissingKeysToRow({row: modifiedScannedRow, columns});

  modifiedScannedRow = assignScannedItemValuesToCorrectRowValues({
    rows: rows,
    newRow: modifiedScannedRow,
    inboundOrder: inboundOrder,
  });

  modifiedScannedRow = rowRemoveAllNonExistingColumnProperties({
    row: modifiedScannedRow,
    columns: columns,
  });

  modifiedScannedRow = setClientSideUUIDToRow({row: modifiedScannedRow});

  modifiedScannedRow = processRowMetadata({row: modifiedScannedRow, columns});

  let updatedRows = await applyPriceConditions({changedColumnName: null})({
    row: modifiedScannedRow,
    priceConditions,
    rows: [...rows, modifiedScannedRow],
    columns,
    inboundOrder,
    inboundItems,
    warehouseId,
    vatCodes,
    supplier,
    costDistributionType,
  });

  updatedRows = handleVATCodeIDChangeOnRows({rows: updatedRows, vatCodes});
  updatedRows = handleTotalPriceChangeForRows({changedColumnName: null})({
    rows: updatedRows,
    row: updatedRows.find(
      (x) => x.OrderItemID.Value === modifiedScannedRow.OrderItemID.Value,
    ),
  });

  updatedRows = processInboundValueReadonlyForRows({
    rows: updatedRows,
    columns,
  });

  updatedRows = distributeCosts({
    rows: updatedRows,
    row: getUpdatedRowFromRows({row: modifiedScannedRow, rows: updatedRows}),
    costDistributionType,
  });

  updatedRows = validateInboundValue({rows: updatedRows, costDistributionType});

  return sortRowsByRanking({rows: updatedRows});
};
