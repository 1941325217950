import {handleInboundDeliveryAmountChange} from "./row/handleInboundDeliveryAmountChange.js";

export const processColumnsMetaData = ({columns}) => {
  const newColumns = columns.slice();

  newColumns.forEach((newColumn) => {
    newColumn.processValueChangeFunctions = [];
    newColumn.processValueChangeFunctionsForRows = [];

    if (newColumn.Name === "AmountRejected") {
      newColumn.processValueChangeFunctions.push(
        handleInboundDeliveryAmountChange,
      );
    }
    if (newColumn.Name === "AmountReceived") {
      newColumn.processValueChangeFunctions.push(
        handleInboundDeliveryAmountChange,
      );
    }
  });
  return newColumns;
};
