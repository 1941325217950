import dayjs from "dayjs";

export const setFullyInvoicedToRow = ({row}) => {
  const {DateTimeInvoicedUnto, DateTimeRentalEnd, IsFullyInvoiced} = row;

  const dateTimeInvoicedUnto = DateTimeInvoicedUnto?.Value;
  const dateTimeRentalEnd = DateTimeRentalEnd?.Value;
  const isFullyInvoiced = IsFullyInvoiced;

  if (!dateTimeInvoicedUnto || !dateTimeRentalEnd || isFullyInvoiced) {
    return row;
  }

  const datesAreEqual = dayjs(dateTimeInvoicedUnto).isSame(dateTimeRentalEnd);

  return {
    ...row,
    IsFullyInvoiced: {...row.IsFullyInvoiced, Value: datesAreEqual},
  };
};
