import {sumValues} from "../../../../../util/math/sumValues";
import {isDistributionReceiverRow} from "./isDistributionReceiverRow";
import {hasReceiverRelation} from "./hasReceiverRelation";

export const getCostsToDistribute = ({rows}) =>
  sumValues(
    rows
      .filter(
        (row) =>
          !isDistributionReceiverRow(row) && !hasReceiverRelation({rows, row}),
      )
      .map((row) => row.TotalExcVAT?.Value ?? 0),
  );
