import {valuesAreEmptyStringAndFalsyValues} from "../../valuesAreEmptyStringAndFalsyValues.js";
import {stripDateOfHoursAndMinutes} from "../../stripDateOfHoursAndMinutes.js";
import {valuesAreNullAndUndefinedOrNaN} from "../../valuesAreNullAndUndefinedOrNaN.js";
import {hasDifferences} from "../rows/updateRows/hasDifferences.js";

export function rowHasValueChanges({newRow, originalRow, columns}) {
  if (!originalRow) return true;

  return columns.some((column) => {
    const columnName = column.Name;

    let cellValue =
      newRow[columnName]?.Value?.Key ??
      newRow[columnName]?.Value?.Value ??
      newRow[columnName]?.Value;
    let originalCellValue =
      originalRow[columnName]?.Value?.Key ?? originalRow[columnName]?.Value;

    if (
      valuesAreNullAndUndefinedOrNaN({
        value1: cellValue,
        value2: originalCellValue,
      }) ||
      valuesAreEmptyStringAndFalsyValues({
        value1: cellValue,
        value2: originalCellValue,
      })
    ) {
      return false;
    }

    if (columnName.includes("DateTime")) {
      cellValue = stripDateOfHoursAndMinutes(cellValue);
      originalCellValue = stripDateOfHoursAndMinutes(originalCellValue);
    }

    return hasDifferences({obj1: cellValue, obj2: originalCellValue});
  });
}
