export async function getUniqueDimensionsFromDimensionObjects({
  dimensionObjects,
}) {
  const uniqueDimensions = [];

  for (const dimensionObject of dimensionObjects) {
    if (
      !uniqueDimensions.find(
        (x) => x.DimensionID.Key === dimensionObject.DimensionID.Key,
      )
    ) {
      uniqueDimensions.push(dimensionObject);
    }
  }

  // get the dimension values for each dimension

  for (const dimension of uniqueDimensions) {
    dimension.DimensionObjects = [];

    for (const dimensionObject of dimensionObjects) {
      if (dimensionObject.DimensionID.Key === dimension.DimensionID.Key) {
        dimension.DimensionObjects.push({
          value: dimensionObject.DimensionObjectID,
          label: dimensionObject.Description,
        });
      }
    }
  }

  return uniqueDimensions;
}
