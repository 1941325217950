import {api} from "@/services/api";
import store from "../../../../state/store";


export async function getStock({
  serialId,
  startDate,
  endDate,
  excludeOrderId,
  warehouseID
}) {
  const result = await api.get(
    `/v2/rental/itemserial/${encodeURIComponent(
      serialId,
    )}/stock/${encodeURIComponent(startDate)}${
      endDate != null ? `/${encodeURIComponent(endDate)}` : ``
    }`,
    {
      windowId: global.session?.activeWindow.id ?? "",
      params: {
        warehouseID: warehouseID ?? store.state.activeWarehouse,
        excludeOrderID: excludeOrderId,
      },
    },
  );

  return result.data;
}
