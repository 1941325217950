<template>
  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pie">
    <div
      :class="{
        card: true,
        'card-chart': true,
        'h-100': true,
        unavailable,
      }"
    >
      <div class="card-body">
        <r-button
          class="float-right text-grey mb-0 mt-[-10px] mx-[4px] mr-[-6px]"
          variant="link"
          :title="diagramData.title"
        >
          <i class="fas fa-question"></i>
        </r-button>
        <r-button
          v-if="diagramData.detailUrl"
          class="float-right text-grey"
          variant="link"
          @click="openWindow(diagramData.detailUrl)"
        >
          <i class="fas fa-list"></i>
        </r-button>
        <h5 class="title">
          <strong>{{ diagramData.title }}</strong>
        </h5>
        <div>
          <apexchart
            v-if="diagramSettings.series.length > 0"
            :width="windowWidth > 800 ? '100%' : '100%'"
            :type="diagramSettings.chartOptions.chart.type"
            :options="diagramSettings.chartOptions"
            :series="diagramSettings.series"
          ></apexchart>
        </div>
      </div>
      <div class="overlay">
        <div class="text">{{ $store.getters.translations.NoDataFound }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatNumber} from "../../util/formatting/shortTypedPrice";
import VueApexCharts from "vue-apexcharts";
import RButton from "../elements/RButton.vue";
import {api} from "../../services/api";
import {axiosInstance} from "../../services/axiosInstance";

export default {
  components: {
    apexchart: VueApexCharts,
    RButton,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      unavailable: true,
      diagramData: {},
      diagramSettings: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            height: 120,
            type: "pie",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },
          },
          dataLabels: {
            formatter: function (val, opts) {
              return [
                `${opts.w.config.labels[opts.seriesIndex]}`,
                `${formatNumber(opts.w.config.series[opts.seriesIndex], true)}`,
                `${formatNumber(val)} %`,
              ];
            },
          },
          labels: [],
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],

          fill: {
            opacity: 1,
          },
        },
      },
      windowWidth: window.innerWidth,
    };
  },
  mounted() {},
  created: async function () {
    if (this.endpoint) {
      const result = await axiosInstance.get(this.endpoint);

      this.diagramData = result.data;

      if (result.status === 500) {
        this.unavailable = true;
        return;
      }

      // If unavailable or necessary data is not available show data not available
      if (
        this.diagramData.unavailable ||
        Object.keys(this.diagramData).length === 0
      ) {
        this.unavailable = true;
        return;
      }

      this.diagramSettings.series = Object.values(this.diagramData.data);

      this.diagramSettings.chartOptions.labels = Object.keys(
        this.diagramData.data,
      );
      this.diagramSettings.chartOptions.theme = {
        monochrome: {
          enabled: true,
          color: "#f26522",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      };
      this.unavailable = false;
    }
  },
  methods: {
    openWindow: async function (url) {
      const result = await api.get(url);

      window.session.openWindow(result.data);
    },
  },
};
</script>

<style></style>
