export const handleAmountChange = ({row}) => {
  if (row.Amount.Value <= 0)
    return {
      ...row,
      Amount: {
        ...row.Amount,
        Value: 1,
      },
    };
};
