export const setCorrectStatusWhenPicked = ({row}) => {
  if (!row.PickedQuantity.Value > 0) {
    return {
      ...row,
      Status: {
        ...row.Status,
        Value: "Open",
      },
      StatusCode: {
        ...row.StatusCode,
        Value: 100,
      },
    };
  }

  return {
    ...row,
    Status: {
      ...row.Status,
      Value: "Picked",
    },
    StatusCode: {
      ...row.StatusCode,
      Value: 150,
    },
  };
};
