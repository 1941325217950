<template>
  <rich-text-editor :value="value" :read-only="readonly" @input="handleInput" />
</template>

<script>
import RichTextEditor from "../../elements/RichTextEditor.vue";

export default {
  name: "RichTextEditorField",
  components: {
    RichTextEditor,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    value() {
      return this.field.Value ?? "";
    },
    readonly() {
      return this.field.IsReadOnly;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", {Value: value});
    },
  },
};
</script>
