import {getStatusCodeRow} from "./getStatusCodeRow.js";

export async function handleStatusChange({row}) {
  if (!row.Status.Value) return row;

  switch (row.Status.Value) {
    case "Open":
      return getStatusCodeRow({row: row, code: "100"});
    case "Sent":
      return getStatusCodeRow({row: row, code: "200"});
    case "Received":
      return getStatusCodeRow({row: row, code: "800"});
    case "Problem":
      return getStatusCodeRow({row: row, code: "900"});
    case "Cancelled":
      return getStatusCodeRow({row: row, code: "999"});
  }
}
