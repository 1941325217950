import {api} from "@/services/api";

export async function getPriceBulk({rentalPeriods, params}) {
  const result = await api.post(`/v2/rental/item/_price`, rentalPeriods, {
    windowId: global.session.activeWindow.id,
    params,
  });

  return result.data;
}
