import {api} from "@/services/api";

export async function getRailroadTasks({
  include,
  filterOnFirstOpenTaskForEntity,
  search,
  filters,
  orderBy,
  sortReverse,
  size,
}) {
  let params = {
    filterOnFirstOpenTaskForEntity,
    include,
    ...filters,
    orderBy,
    sortReverse,
    size,
  };

  if (search) {
    params = {...params, search};
  }
  const result = await api.get(`/v2/core/railroad-task`, {
    params,
  });

  return result.data;
}
