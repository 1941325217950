import {notify} from "../../../../util/notify";

export const blockRowSameSerialSelection = ({row, oldRow, rows}) => {
  if (row.SerialID?.Value) {
    const sameSerialRows = rows
      .filter((fRow) => fRow.ClientSideUUID.Value !== row.ClientSideUUID.Value)
      .some((r) => r.SerialID?.Value === row.SerialID?.Value);

    if (sameSerialRows) {
      notify({message: "Serial already selected", type: "error"});
      return {
        ...row,
        PickedQuantity: {
          ...row.PickedQuantity,
          Value: 0,
        },
        Status: {
          ...row.Status,
          Value: "Open",
        },
        SerialID: {
          ...row.SerialID,
          Value: null,
        },
      };
    }
  }
  return row;
};
