import Decimal from "decimal.js";

export function sumValues(values) {
  let totalPrice = new Decimal(0);

  for (const value of values) {
    if (!isNaN(value)) {
      totalPrice = totalPrice.plus(value);
    }
  }

  return totalPrice.toNumber();
}
