import DatagridInboundOrderItemInboundValuePopup from "../../../../components/datagrid/datagridPopups/DatagridInboundOrderItemInboundValuePopup.vue";
import {sumValues} from "../../../../util/math/sumValues";
import {getInboundValueValidationMessage} from "./validate-inbound-value/getInboundValueValidationMessage";
import Decimal from "decimal.js";

export const validateInboundValue = ({rows, costDistributionType}) => {
  const totalExcVat = sumValues(rows.map((row) => row.TotalExcVAT?.Value ?? 0));
  const totalInboundValue = sumValues(
    rows.map((row) => row.InboundValue?.Value ?? 0),
  );

  if (totalExcVat !== totalInboundValue) {
    const message = getInboundValueValidationMessage({
      totalInboundValue,
      totalExcVat,
    });

    const correctionAmount = new Decimal(totalExcVat)
      .minus(totalInboundValue)
      .toNumber();
    return rows.map((row) => {
      if (row.InboundValue?.IsReadOnly === true) {
        return row;
      }
      return {
        ...row,
        InboundValue: {
          ...row.InboundValue,
          Icon: "fa-exclamation-triangle",
          IconColor: "error",
          IconComponent: DatagridInboundOrderItemInboundValuePopup,
          IconComponentData: {costDistributionType, message, correctionAmount},
        },
      };
    });
  } else {
    return rows.map((row) => ({
      ...row,
      InboundValue: {
        ...row.InboundValue,
        Icon: undefined,
        IconColor: undefined,
        IconMessage: undefined,
        IconComponent: undefined,
        IconComponentData: undefined,
      },
    }));
  }
};
