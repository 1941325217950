import {rowSetMaxAmountRejected} from "./rowSetMaxAmountRejected.js";
import {handleAmountRejectedChange} from "./handleAmountRejectedChange.js";
import {rowProcessRejectionReasonMetadata} from "./rowProcessRejectionReasonMetadata.js";

export const handleInboundDeliveryAmountChange = ({row}) => {
  let modifiedRow = {...row};

  modifiedRow = rowSetMaxAmountRejected({row: modifiedRow});
  modifiedRow = handleAmountRejectedChange({row: modifiedRow});
  modifiedRow = rowProcessRejectionReasonMetadata({row: modifiedRow});

  return modifiedRow;
};
