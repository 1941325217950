export function setRankingLowerThanFirst({rows, row}) {
  const firstRow = rows[0];
  const newRanking = firstRow ? firstRow.Ranking.Value - 1 : 0;

  return {
    ...row,
    Ranking: {
      ...row.Ranking,
      Value: newRanking,
    },
  };
}
