<template>
  <div class="login cover v-center">
    <div class="container">
      <div class="box pull-center">
        <form @submit.prevent="submit">
          <div class="logo">
            <span
              :class="[
                'logo-icon',
                'infodatek-icon',
                'infodatek-icon-' + (session.product || '').toLowerCase(),
              ]"
            ></span
            ><br />
            <span class="logo-text">{{ session.product }}</span>
          </div>
          <div v-if="error" class="error-box">
            {{ error }}
          </div>

          <div v-if="activeSessions.length == 0">
            <div class="pull-center spinner"></div>
            <br />
            <div>
              {{ translations.CreateSessionPending }}
            </div>
          </div>
          <div v-else>
            <div class="form-element">
              <select v-model="selectedSessionId" name="kick">
                <option
                  v-for="(userName, sessionId) in activeSessions"
                  :key="sessionId"
                  :value="sessionId"
                >
                  {{ userName }}
                </option>
              </select>
            </div>

            <div class="form-element">
              <button
                name="login"
                class="button button-default login-button"
                type="submit"
              >
                {{ translations.LogIn }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import getSessions from "../../services/authentication/getSessions";
import getUserSessions from "../../services/authentication/getUserSessions";

import postCreateSession from "../../services/authentication/postCreateSession.js";
import postEndSession from "../../services/authentication/postEndSession.js";

export default {
  name: "LoginSessionList",
  props: {
    session: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      error: null,
      successMessage: "",
      activeSessions: [],
      selectedSessionId: null,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  created: async function () {
    await this.initialize();
  },
  methods: {
    async initialize(createNewSession = false) {
      const sessionResponse = await postCreateSession(createNewSession);

      this.$data.error = sessionResponse.data.Error;

      switch (sessionResponse.data.Status) {
        case "TooManyUsers":
          await this.fetchSessionList(false);
          break;
        case "UserAlreadyActive":
          await this.fetchSessionList(true);
          this.selectFirstSession();
          break;
        default:
          this.$emit("setSessionId", sessionResponse.data.SessionId);
          break;
      }
    },
    async fetchSessionList(showUserSessions) {
      let response = null;

      if (showUserSessions) {
        response = await getUserSessions();
      } else {
        response = await getSessions();
      }

      if (response.status == 200) {
        this.$data.activeSessions = response.data;
      }
    },
    async submit() {
      if (
        this.$data.selectedSessionId == "00000000-0000-0000-0000-000000000000"
      ) {
        await this.initialize(true);
        return;
      }

      const endSessionResponse = await postEndSession(
        this.$data.selectedSessionId,
      );

      if (endSessionResponse.status == 200) {
        await this.initialize(true);
      }
    },
    selectFirstSession() {
      const firstSessionId = Object.keys(this.$data?.activeSessions ?? {})?.[0];
      if (firstSessionId) {
        this.selectedSessionId = firstSessionId;
      }
    },
  },
};
</script>

<style scoped></style>
