export function convertRowToliRowDataRow(row) {
  const newRow = {};
  Object.keys(row).forEach((key) => {
    newRow[key] = row[key].Value;

    if (newRow[key]?.Key) {
      newRow[key] = newRow[key].Key;
    }

    if (newRow[key]?.Start) {
      newRow[key] = newRow[key].Start;
    }
  });

  return newRow;
}
