import DatagridIconPriceCalculations from "@/components/datagrid/datagridPopups/DatagridPopupPriceCalculations";
import {getSettings} from "../../../../util/getSettings.js";
import translation from "@/util/translation";

export function processPopupPriceCalculations({row}) {
  const {PeriodSelection} = getSettings();
  const newRow = {...row};

  if (newRow.Price.Value < 0.01 || !newRow.Price.Value) {
    newRow.Price = {
      ...removeInformationPopup(row),
    };
    return newRow;
  }

  if ((newRow.ItemType?.Value?.Key ?? newRow.ItemType?.Value) === "Sales") {
    newRow.Price = {
      ...row.Price,
      ...setInformationForPrice(
        "Rental.OrderItem-MultiEdit-NoPriceCalculationForSalesItem",
      ),
    };
    return newRow;
  }

  if ((newRow.ItemType?.Value?.Key ?? newRow.ItemType?.Value) === "Free") {
    newRow.Price = {
      ...row.Price,
      ...setInformationForPrice(
        "Rental.OrderItem-MultiEdit-NoPriceCalculationForFreeItem",
      ),
    };
    return newRow;
  }

  if ((newRow.ItemType?.Value?.Key ?? newRow.ItemType?.Value) === "Deposit") {
    newRow.Price = {
      ...removeInformationPopup(row),
    };
    return newRow;
  }

  if (
    PeriodSelection === "ItemSinglePeriodEnforced" &&
    (!newRow.Period.Value ||
      newRow.PeriodPrice.Value === 0 ||
      !newRow.PeriodPrice.Value ||
      !newRow.PeriodAmount.Value)
  ) {
    newRow.Price = {
      ...row.Price,
      ...setInformationForPrice(
        "Rental.OrderItem-MultiEdit-NoPeriodPriceCalculation",
      ),
    };
    return newRow;
  }

  if (
    PeriodSelection === "AllowItemSinglePeriodPriceCalculation" &&
    (!newRow.Period.Value ||
      newRow.PeriodPrice.Value === 0 ||
      !newRow.PeriodPrice.Value)
  ) {
    newRow.Price = {
      ...row.Price,
      ...setInformationForPrice(
        "Rental.OrderItem-MultiEdit-NoPeriodPriceCalculation",
      ),
    };
    return newRow;
  }

  if (!newRow.PriceProjection?.Value) {
    newRow.Price = {
      ...row.Price,
      ...setInformationForPrice(
        "Rental.OrderItem-MultiEdit-NoPriceCalculation",
      ),
    };
    return newRow;
  }

  newRow.Price = {
    ...row.Price,
    ...setInformationForPrice(),
  };

  return newRow;
}

function setInformationForPrice(message) {
  return {
    Icon: "fa-info-circle",
    IconColor: "info",
    IconMessage: message ? translation(message) : null,
    IconComponent: DatagridIconPriceCalculations,
  };
}

function removeInformationPopup(row) {
  return {
    ...row.Price,
    Icon: null,
    IconColor: null,
    IconMessage: null,
    IconComponent: null,
  };
}
