export const setRowSerialPickedQuantityToInt = ({row}) => {
  if (row.UseSerials.Value !== true) {
    return row;
  }
  return {
    ...row,
    OrderedQuantity: {
      ...row.OrderedQuantity,
      Editor: "Int",
    },
  };
};
