<template>
  <td
    class="border text-center"
    :rowspan="rowspan"
    data-column-name="ShowAdvanced"
  >
    <a class="btn btn-link cursor-pointer inline-block" @click="toggleAdvanced">
      <i v-if="!showAdvanced" class="fas fa-chevron-down pt-1.5"></i>
      <i v-else class="fas fa-chevron-up pt-1.5"></i>
    </a>
  </td>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowspan: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      cellValue: this.value?.Value,
      row: {},
    };
  },
  computed: {
    showAdvanced() {
      return this.value;
    },
  },
  created() {
    this.row = this.value;
  },
  methods: {
    toggleAdvanced() {
      this.$emit("change", !this.value);
    },
  },
};
</script>

<style scoped>
td {
  white-space: nowrap;
}

input {
  height: 31px;
  box-shadow: none !important;
  border: none;
  background-color: transparent;
}

.btn {
  padding: 0 0.75rem !important;
  vertical-align: bottom !important;
}
</style>
