import {handleJsFunctionPayload} from "../../../../functions/window/handleJsFunctionPayload";

export const processActions = async ({button, vueInstance}) => {
  if (button.JsFunctionPayload) {
    await handleJsFunctionPayload({
      jsFunctionPayload: button.JsFunctionPayload,
    });
    return;
  }
  if (button.GlobalActions && button.GlobalActions.length)
    vueInstance.processGlobalActions(button.GlobalActions);
  if (button.ComponentActions && button.ComponentActions.length)
    vueInstance.processComponentActions(button.ComponentActions);
};
