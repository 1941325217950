import {fetchOptions} from "./fetchOptions.js";

export const getOptions = async ({
  field,
  row,
  searchValue,
  maxReturnableOptions,
}) => {
  if (field.Dropdown?.TableName) {
    return await fetchOptions({field, row, searchValue, maxReturnableOptions});
  }

  if (field.Dropdown?.Items) {
    return await field.Dropdown.Items;
  }

  return field.Options ?? [];
};
