export async function handleItemIdChange({row, cell}) {
  let newRow = {...row};
  newRow.ItemID.Description = cell.Text;
  newRow.Description.Value = cell.Text.replace(`${newRow.ItemID.Value} - `, "");
  newRow.Description.IsReadOnly = true;

  newRow.SerialID.Value = null;
  newRow.SerialID.Description = null;

  newRow.Amount.Value = 1;
  newRow.Amount.IsReadOnly = false;
  newRow.Status.Value = "Open";
  newRow.Status.Description = "Open";
  newRow.Status.Text = "Open";
  newRow.Status.IsReadOnly = false;

  return newRow;
}
