export function setReturnItemFormFieldsFromOrder({vueComponent, criteria}) {
  vueComponent.formFields = {
    ...vueComponent.formFields,
    Value: {
      ...vueComponent.formFields.Value,
      Value: criteria.Order,
      ReturnType: "Order",
      IsReadOnly: false,
    },
    Type: {
      ...vueComponent.formFields.Type,
      Value: "Order",
      IsReadOnly: true,
    },
  };
}
