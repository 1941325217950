// getEmptyRequiredCellNamesPerRow.js
import {getRequiredColumns} from "../columns/getRequiredColumns";
import {isEmptyCell} from "../cell/isEmptyCell";

export const getEmptyRequiredCellNamesPerRow = ({rows, columns}) => {
  const requiredColumns = getRequiredColumns({
    columns: columns.filter((column) => column.IsVisible),
  });

  return rows.reduce((acc, row) => {
    requiredColumns.forEach((columnName) => {
      if (isEmptyCell({row, columnName})) {
        const clientSideUUID = row.ClientSideUUID.Value;
        if (!acc[clientSideUUID]) {
          acc[clientSideUUID] = [];
        }
        acc[clientSideUUID].push(columnName);
      }
    });
    return acc;
  }, {});
};
