export const handleAmountRejectedChange = ({row}) => {
  const currentAmount = row.AmountRejected?.Value ?? 0;
  const maxAmount = row.AmountReceived.Value ?? 0;

  if (currentAmount > maxAmount)
    return {
      ...row,
      AmountRejected: {
        ...row.AmountRejected,
        Value: maxAmount,
      },
    };

  if (currentAmount <= maxAmount) {
    return {
      ...row,
      AmountRejected: {
        ...row.AmountRejected,
        Value: currentAmount,
      },
    };
  }
};
