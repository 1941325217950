<template>
  <div class="flex" :class="{'data-filter-date-has-value': value.startDate}">
    <date-range-picker
      v-model="value"
      :locale-data="getLocale($store.state.language)"
      :date-format="dateFormat"
      :closed-days="closedDays"
      :ranges="false"
      :min-width="220"
    />
    <button
      v-if="value.startDate"
      class="btn-link text-dark ml-1"
      style="margin-left: -25px !important; z-index: 38"
      @click="clearDateRange"
    >
      <i class="fas fa-times pt-1.5"></i>
    </button>
  </div>
</template>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import dayjs from "dayjs";
import locale from "@/locale/calendar";

export default {
  name: "DataFilterDateRange",
  components: {
    DateRangePicker,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: {},
      startDate: "",
      endDate: "",
      closedDays: [],
    };
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    filter: {
      handler(newValue) {
        if (newValue.value?.startDate && newValue.value?.endDate) {
          this.value = newValue.value;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getLocale(lang) {
      return locale(lang);
    },
    clearDateRange(newValue) {
      this.value.startDate = null;
      this.value.endDate = null;
      this.$emit("input", newValue);
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = this.closedDays.includes(
          dayjs(date).format("YYYY-MM-DD"),
        );
      }
      return classes;
    },
  },
};
</script>

<style lang="scss">
.data-filter-date-has-value {
  .vue-daterange-picker {
    padding-right: 17px;
  }
}
</style>
