import { render, staticRenderFns } from "./DatagridFooterWeightSum.vue?vue&type=template&id=f360bee4&scoped=true&"
import script from "./DatagridFooterWeightSum.vue?vue&type=script&lang=js&"
export * from "./DatagridFooterWeightSum.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f360bee4",
  null
  
)

export default component.exports