export function normalizeValueRows({rows}) {
  return rows.map((row) => {
    const newRow = {};
    Object.keys(row).forEach((key) => {
      if (typeof row[key] === "object" && "Value" in row[key]) {
        newRow[key] = row[key].Value;
      } else {
        newRow[key] = row[key];
      }
    });

    return newRow;
  });
}
