export async function loadRowDataForPage({rows, page, pageSize}) {
  try {
    const start = (page - 1) * pageSize;
    const end = page * pageSize;

    const modifiedRows = rows.slice(start, end);

    const result = {};
    const originalRows = rows.reduce(
      (acc, row) => ({...acc, [row.ClientSideUUID.Value]: row}),
      {},
    );

    for (let row of modifiedRows) {
      const originalRow = originalRows[row.ClientSideUUID.Value];
      const modifiedProperties = {};

      for (let key in row) {
        if (JSON.stringify(row[key]) !== JSON.stringify(originalRow[key])) {
          modifiedProperties[key] = row[key];
        }
      }

      if (Object.keys(modifiedProperties).length > 0) {
        result[row.ClientSideUUID.Value] = modifiedProperties;
      }
    }

    return result;
  } catch (e) {
    console.log(e);
  }
}
