<template>
  <div >
    <span :class="`px-2 badge ${badgeClasses}`">
      <i v-if="iconClass" :class="`fas ${iconClass}`"></i>
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
function getOptions({ cell, column }) {
  if (cell?.Dropdown?.Items?.length > 0) {
    return value.Dropdown.Items;
  }
  
  if (column.Dropdown?.Items?.length > 0) {
    return column.Dropdown.Items;
  }
  
  return [];
}

function getOptionByValue(options, value) {
  return options.find((item) => item.Value === value);
}

export function renderAsText({ cell, column, value }) {
  const options = getOptions({ cell, column });
  const option = getOptionByValue(options, value);
  if (option) return option.Text;
  return value;
}

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    cell: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
  },
  computed: {
    badgeClasses() {
      return this.cell.badgeClasses ?? "badge-secondary";
    },
    iconClass() {
      return this.cell.iconClass;
    },
    displayValue() {
      return renderAsText(this);
    },
  },
};
</script>

<style scoped>
.date-cell {
  height: 29px;
  display: flex;
  align-items: center;
}
td {
  white-space: nowrap;
  line-height: 30px;
}
div {
  border: none;
  line-height: 26px;
  background-color: transparent;
}
</style>
