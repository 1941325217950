import {isEqual} from "lodash";

export const isDirty = ({newValue, initialValue}) => {
  if (
    newValue == initialValue ||
    (typeof newValue === "object" &&
      typeof initialValue === "object" &&
      isEqual(newValue, initialValue))
  ) {
    return false;
  }

  const noChangeValues = [null, undefined, "", ":00", "{}", false];

  return !(
    noChangeValues.includes(initialValue) && noChangeValues.includes(newValue)
  );
};
