<template>
  <div class="h-[32.5px]">
    <input
      :id="fieldName"
      :value="value"
      type="text"
      class="form-control placeholder:text-primary min-h-full"
      :class="{
        'editable-text': !readonly,
        'text-right':
          value !== translations?.['NewFormAutoincrementingDescription'],
      }"
      :readonly="readonly"
      :disabled="readonly"
      :maxlength="field.Length"
      :title="value"
      @change="handleChange"
    />
  </div>
</template>

<script>
import {handleNumberFieldChange} from "../../../functions/form/input-field-logic/handleNumberFieldChange.js";

export default {
  name: "NumberField",
  inject: ["translations"],
  props: {
    field: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    rowCell: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    value() {
      return this.field.Value;
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    readonly() {
      return this.field.IsReadOnly;
    },
  },
  methods: {
    handleChange(event) {
      handleNumberFieldChange({
        value: event.target?.value,
        field: this.field,
        vueComponent: this,
      });
    },
  },
};
</script>

<style scoped>
input[readonly] {
  background-color: #eeeeee;
  cursor: not-allowed;
  color: #888;
}
</style>
