import translation from "../../../../util/translation.js";

export const generateMessage = ({stock, row, exceedingBy, isNonStockItem}) => {
  if (isNonStockItem) {
    return translation("Rental.OrderItem-MultiEdit-StockPopup-NoStockItem");
  }

  if (
    stock - row.Amount.Value >= 0 ||
    row.rowMeta?.physicalItemStock - row.Amount.Value >= 0
  ) {
    return translation("Rental.OrderItem-MultiEdit-StockPopup-InStock").replace(
      "{stock}",
      stock,
    );
  }
  if (
    row.rowMeta?.stock - row.Amount.Value < 0 ||
    row.rowMeta?.physicalItemStock - row.Amount.Value < 0
  ) {
    return translation("Rental.OrderItem-MultiEdit-StockPopup-StockExceeding")
      .replace("{exceedingBy}", exceedingBy)
      .replace("{stock}", stock);
  }
  return null;
};
