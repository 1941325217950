<template>
  <nav
    v-if="$store.state.accessToken && !$store.state.headless"
    @mouseleave="$store.commit('hideSubMenu')"
  >
    <div class="menu-main">
      <div class="menu-logo"></div>
      <div class="menu-main-middle">
        <div
          v-for="(menuItem, index) of menuItems"
          :key="menuItem.ID"
          :class="{
            'menu-item-icon-wrapper': true,
            'w-100': true,
            active: selectedMenuItemId === index && subMenuOpen,
          }"
          @mouseover="openSubMenu(index)"
        >
          <div v-if="menuItem.Position === 'Top'" class="menu-item-icon">
            <i :class="menuItem.Icon"></i>
          </div>
          <p v-if="menuItem.Position === 'Top' && showMenuTitles" class="title">
            {{ menuItem.Text }}
          </p>
        </div>
      </div>
      <div class="menu-main-bottom">
        <div class="menu-item-icon-wrapper">
          <div
            class="menu-item-icon toggle-menu"
            data-canvas-event="usersettings"
          >
            <i class="fas fa-user"></i>
          </div>
        </div>
        <div
          v-for="(menuItem, index) of menuItems"
          :key="menuItem.ID"
          :class="{
            'menu-item-icon-wrapper': true,
            'w-100': true,
            active: selectedMenuItemId === index && subMenuOpen,
          }"
          @mouseover="openSubMenu(index)"
        >
          <div v-if="menuItem.Position === 'Bottom'" class="menu-item-icon">
            <i :class="menuItem.Icon"></i>
          </div>
        </div>
        <div class="menu-item-icon-wrapper">
          <menu-item-logout />
        </div>
      </div>
    </div>

    <transition name="component-fade">
      <div v-show="subMenuOpen" class="menu-submenu">
        <div class="menu-submenu-header">
          <span
            class="float-right close-button"
            @click="$store.commit('toggleSubMenu')"
          >
            <i class="fas fa-times" @click="$store.commit('toggleSubMenu')"></i>
          </span>
          <div class="sub-menu-title w-100">
            {{ selectedMenuItem.Text }}
          </div>
        </div>

        <div class="sub-menu-items">
          <menu-sub-item
            v-for="menuItem of subMenuItems"
            :key="menuItem.ID"
            :menu-item="menuItem"
            :selected-menu-item="selectedMenuItem"
          />
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import MenuSubItem from "./MenuSubItem.vue";

export default {
  components: {
    "menu-sub-item": MenuSubItem,
    MenuItemLogout: () => import("./MenuItemLogout.vue"),
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedMenuItem: {},
    selectedMenuItemId: null,
  }),
  computed: {
    subMenuOpen: function () {
      return this.$store.getters.subMenuOpen;
    },
    subMenuItems: function () {
      return this.$store.getters.subMenuItems;
    },
    showMenuTitles: function () {
      return this.$store.getters.showMenuTitles;
    },
  },
  methods: {
    openSubMenu: function (index) {
      this.$store.commit("setSubMenuItems", {
        subMenuItems: this.menuItems[index].children,
      });
      if (!this.subMenuOpen) this.$store.commit("toggleSubMenu");
      this.selectedMenuItem = this.menuItems[index];
      this.selectedMenuItemId = index;
    },
    outsideMenuClick: function () {
      if (this.subMenuOpen) this.$store.commit("hideSubMenu");
    },
  },
};
</script>
<style scoped lang="scss">
nav {
  background-color: white;
  height: 100%;
  width: auto;
}

.menu-main::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
  width: 0 !important;
  height: 0 !important;
}

.menu-main {
  z-index: 120;
  height: 100%;
  min-width: 55px;
  background-color: white;

  z-index: 4009;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto;

  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important; /* for Firefox */
  overflow-y: scroll !important;
  scrollbar-visibility: hidden;

  .title {
    text-align: center;
    font-size: 11px;
    font-weight: 900;
    margin-top: -10px;
  }

  .menu-item-icon {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #565656;
    transition: 0.2s;
  }
  .menu-item-icon.active {
    color: #f26522;
    transition: 0.2s;
  }
}

.menu-item-icon-wrapper:hover,
.menu-item-icon-wrapper.active {
  cursor: pointer;
  background-color: #e5e5e5;
  transition: 0.5s;
  .menu-item-icon {
    color: #f26522;
  }
}

.menu-submenu {
  position: absolute;
  height: 100%;
  width: auto;
  transition: 1.8s;
  display: flex;
  flex-direction: column;
  width: 304px;
  word-wrap: break-word;

  background-color: #e5e5e5;
  left: 55px;

  top: 0;
  z-index: 4005;

  .sub-menu-items {
    height: 100%;
    overflow-x: scroll;
  }

  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.25s ease-in;

  .menu-submenu-header {
    border-bottom: 2px solid grey;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
  }

  .sub-menu-title {
    font-weight: 600;
    font-size: 22px;
    padding-left: 14px;
  }

  .close-button {
    font-size: 20px;
    cursor: pointer;
    padding-right: 15px;
  }
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.5s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
