import { render, staticRenderFns } from "./DatagridHeaderInboundDelivery.vue?vue&type=template&id=08d1dff9&"
import script from "./DatagridHeaderInboundDelivery.vue?vue&type=script&lang=js&"
export * from "./DatagridHeaderInboundDelivery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports