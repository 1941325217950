import {processPopupPriceCalculations} from "../row/processPopupPriceCalculations";
export function rowsProcessPopupPriceCalculations({rows}) {
  const newRows = rows.slice();
  for (const index in rows) {
    newRows[index] = processPopupPriceCalculations({
      row: rows[index],
      rows,
    });
  }
  return newRows;
}
