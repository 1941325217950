import {getRoundingError} from "./getRoundingError";
import {setValuesInRow} from "../../row/setValuesInRow";
import {isDistributionReceiverRow} from "./isDistributionReceiverRow";
import Decimal from "decimal.js";

export const correctRoundingError = ({rows}) => {
  const roundingError = getRoundingError({rows});
  if (roundingError === 0) {
    return rows;
  }
  let hasCorrectedRoundingError = false;
  return rows.map((row) => {
    if (!hasCorrectedRoundingError && isDistributionReceiverRow(row)) {
      hasCorrectedRoundingError = true;
      const inboundValue = new Decimal(row.InboundValue?.Value ?? 0)
        .plus(roundingError)
        .toNumber();
      return setValuesInRow({row, values: {InboundValue: inboundValue}});
    }
    return row;
  });
};
