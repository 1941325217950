<template>
  <div
    class="bg-gray-100 rounded-lg px-3 py-3 column-width max-w-[320px] min-w-[320px] mr-4"
  >
    <div
      class="text-gray-700 font-semibold tracking-wide text-md flex w-full justify-between"
    >
      <div class="uppercase">{{ lane.Title }} ({{ cardCount }})</div>

      <div
        class="w-5 h-5 rounded-full round bg-gray"
        :style="{'background-color': laneColor}"
      ></div>
    </div>
    <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
    <div>
      <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
      <TransitionGroup name="fade" tag="div">
        <kanban-card
          v-for="card in cards"
          :key="card.TaskID"
          :card="card"
          :context="context"
          :lane="lane"
          :sub-tasks="cards.filter((c) => c.ParentTaskID === card.TaskID)"
          class="mt-3"
        ></kanban-card>
      </TransitionGroup>
      <!-- </transition-group> -->
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import KanbanCard from "./KanbanCard.vue";

dayjs.extend(isBetween);

export default {
  name: "KanbanLane",
  components: {
    KanbanCard,
  },
  props: {
    context: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    lane: {
      type: Object,
      required: true,
    },
    cards: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    laneColor() {
      return this.lane.Color?.substring(0, 7) ?? "#D3D3D3";
    },
    cardCount() {
      return this.cards?.length ?? 0;
    },
  },
};
</script>

<style scoped>
.empty-lane {
  width: 140px !important;
  min-width: 140px !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
  z-index: 0;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
