import {processRowPrices} from "../row/processRowPrices";

export async function rowsProcessPrices({rows, quotation}) {
  const newRows = await Promise.all(
    rows.map(async (row) => {
      let newRow = await processRowPrices({row, quotation});
      return newRow;
    }),
  );

  return newRows;
}
