<template>
  <form @submit.prevent="submit">
    <div class="title mb-1">
      {{ step }}. {{ translations.TwoFactorSetupVerifyTokenTitle }}
    </div>
    <div v-if="!error" class="ml-1">
      <p>
        {{ translations.TwoFactorSetupVerifyTokenDescription }}
      </p>
    </div>
    <div v-else class="text-center text-danger mb-3">
      {{ error }}
    </div>
    <div class="mb-2">
      <div class="flex justify-center items-center flex-row gap-3 mb-[0.9rem]">
        <div v-for="index in 6" :key="index">
          <input
            :id="'digit-' + index"
            v-model="code[index - 1]"
            type="text"
            class="digit-box w-9 py-6 text-center text-2xl"
            @input="onInput(index)"
            @keydown="backwardFocus($event, index)"
          />
        </div>
      </div>
      <div class="flex justify-center h-[31px]">
        <button id="btnVerify" class="btn btn-primary py-1 my-[-4px] w-36">
          {{ translations.Verify }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import postVerifyTOTP from "../../../services/totp/postVerifyTOTP.js";

export default {
  name: "LoginTwoFactorVerify",
  props: {
    loginToken: {
      type: String,
      default: "",
    },
    totpData: {
      type: Object,
      required: false,
      default: () => {},
    },
    step: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: "",
      username: "",
      code: ["", "", "", "", "", ""],
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  methods: {
    async submit() {
      this.$data.error = "";

      const result = await postVerifyTOTP({
        loginToken: this.$props.loginToken,
        totpCode: this.code.join(""),
      });

      if (result.status == 200) {
        if (result.data === true) {
          this.$emit("processVerificationResult", true, this.code.join(""));
        } else {
          this.$data.error = this.translations.LoginStatusInvalidMFACode;
        }
      } else {
        this.$data.error = result.data.Error.Message;
      }
    },
    onInput(index) {
      const correctedIndex = index - 1;
      const newValue = this.code[correctedIndex];

      const focusIndex =
        newValue.length + correctedIndex > 6
          ? 6
          : newValue.length + correctedIndex;

      this.splitMultipleCharacters(newValue, correctedIndex, focusIndex);
      this.forwardFocus(focusIndex);
    },
    forwardFocus(index) {
      if (index - 1 < 0) {
        return;
      }
      if (this.code[index - 1].length === 1 && index <= 6) {
        let nextInput =
          document.getElementById(`digit-${index + 1}`) ??
          document.getElementById("btnVerify");

        if (nextInput) {
          nextInput.focus();
        }
      }
    },
    backwardFocus(event, index) {
      if (event.key === "Backspace" && index > 1 && !this.code[index - 1]) {
        let prevInput = document.getElementById(`digit-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    },
    splitMultipleCharacters(value, fromIndex, toIndex) {
      if (value.length > 1) {
        let charIndex = 0;
        for (let i = fromIndex; i < toIndex; i++) {
          this.code[i] = value[charIndex++];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  text-align: left;
  padding: 15px;
}

.title {
  font-size: 17.6px;
  font-weight: 600;
}

.btn {
  transition: box-shadow 0.15s ease-in-out;
}

.btn:focus {
  box-shadow: 0px 1px 23px -4px rgba(242, 101, 34, 0.75) !important;
}

.digit-box {
  border: 1px solid;
  border-radius: 8px;
  border-color: #dee2e6;
  transition: box-shadow 0.15s ease-in-out;
}

.digit-box:focus {
  box-shadow: rgba(242, 101, 34, 0.3) 0px 2px 10px 1px;
  border-color: rgba(242, 101, 34, 0.3) !important;
  border-width: 1px !important;
}
</style>
