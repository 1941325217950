import {calculatePriceDifference} from "../row/calculatePriceDifference";
export function rowsProcessPriceDifference({rows}) {
  const newRows = rows.slice();
  for (const index in rows) {
    rows[index] = calculatePriceDifference({
      row: rows[index],
      rows,
    });
  }
  return newRows;
}
