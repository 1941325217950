import {getWindowFromWindowId} from "../../window/getWindowFromWindowId.js";
import {getTranslations} from "../../session/localstorage/getTranslations.js";

export const openNewEntityForm = async ({column, windowId}) => {
  const translations = getTranslations();
  const window = getWindowFromWindowId({windowId, session: global.session});

  const input = {
    Subject: column.Dropdown.TableName,
    Prefix: "New",
    Criteria: [{}],
    ExecuteGlobalAction: "selectNewValueInReferencedWindow",
    ExecuteGlobalActionParameters: {
      ColumnName: column.Name,
      ReferencingTableName: window.output.Request.Subject,
    },
    buttonFilterCallback: () => {
      return [
        {
          ActionName: "Save",
          ComponentActions: [],
          Description: "Save",
          DisplayType: "primary",
          Event:
            "action:Save:Customer.Customer:../../Admin/WebServices/CoreWebServices.asmx:SaveData",
          FullTableName: "Customer.Customer",
          GlobalActions: [],
          Icon: '<i class="fas fa-save"></i>',
          JsFunctionPayload: null,
          Options: null,
          Prefix: "Detail",
          Ranking: 0,
          RawDescription: "Save",
          RawTitle: "Save",
          SendData: ["Selection", "Changes", "Metadata"],
          Title: translations.SaveAndFill,
          WebMethod: "SaveData",
          WebService: "../../Admin/WebServices/CoreWebServices.asmx",
        },
      ];
    },
  };

  const customData = {
    referredWindowId: windowId
  };

  await window.session.openWindow(input, null, null, customData);
};
