import React from "react";
import {renderToStaticMarkup} from "react-dom/server";
import {availabilityListStatus} from "@/components/item/availabilityListStatus";

export function availabilityList({
  translations,
  availabilityList,
  startDate,
  endDate,
  itemId,
  warehouse,
  currentWarehouse,
}) {
  const reactComponent = (
    <div id="availabilityModal" className="availabilityList container pt-3">
      <div className="text-center">
        <p>
          {translations.ItemID} <br />
          <strong>{itemId}</strong>
        </p>
        <p>
          {translations.AvailabilityList_SelectedPeriod}
          <br />
          <strong>
            {startDate} - {endDate}
          </strong>
        </p>
      </div>

      <table className="table table-sm">
        <tbody>
          {availabilityList?.map((status) => {
            return availabilityListStatus({status, translations});
          })}
        </tbody>
      </table>
    </div>
  );

  return renderToStaticMarkup(reactComponent);
}
