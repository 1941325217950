import {uniqBy} from "lodash";

export const getDatagridGroupFilterChildren = ({
  rows,
  groupNodeColumnName,
  groupNodeColumnValue,
  childNodeColumnName,
}) => {
  return uniqBy(
    rows
      .filter((row) => row[groupNodeColumnName].Value === groupNodeColumnValue)
      .map((row) => ({
        id: row[childNodeColumnName]?.Value,
        label: row[childNodeColumnName]?.Value,
      })),
    "id",
  );
};
