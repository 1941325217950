import {setRankingLowerThanFirst} from "../row/setRankingLowerThanFirst";
import {createVirtualRow} from "../order-item/rows/rowsGenerateVirtualCompositionRows/createVirtualRow";
import {createCompositionMapping} from "../row/createCompositionMapping";
import {setClientSideUUIDToRows} from "./setClientSideUUIDToRows";

export function rowsGenerateVirtualCompositionRows({rows}) {
  const modifiedRows = rows.slice();
  const compositionMapping = createCompositionMapping(modifiedRows);
  let updatedRows = insertVirtualRows(modifiedRows, compositionMapping);
  updatedRows = setClientSideUUIDToRows({rows: updatedRows});
  return updatedRows;
}

function insertVirtualRows(rows, compositionMapping) {
  const modifiedRows = [...rows];

  for (const compositionRow in compositionMapping) {
    if (compositionMapping[compositionRow].length > 0) {
      if (
        compositionMapping[compositionRow].some(
          (row) => row.rowMeta?.virtual === true,
        )
      ) {
        // this group already has a virtual row
        continue;
      }
      let virtualRow = createVirtualRow({
        row: compositionMapping[compositionRow][0],
        rows: modifiedRows,
      });

      virtualRow = setRankingLowerThanFirst({
        rows: compositionMapping[compositionRow],
        row: virtualRow,
      });

      modifiedRows.push(virtualRow);
    }
  }

  modifiedRows.sort((a, b) => {
    if (a.Ranking.Value < b.Ranking.Value) {
      return -1;
    } else if (a.Ranking.Value > b.Ranking.Value) {
      return 1;
    } else {
      return 0;
    }
  });

  return modifiedRows;
}
