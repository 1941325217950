<template>
  <div
    class="w-full border-top border-t-gray-200 px-3 pt-2 bg-grey border-l-4 border-l-gray-200 border-solid border-r-0 border-b-0 pb-3 rounded-bl-md"
    :style="borderStyle"
    :class="{
      'cursor-pointer': clickable,
    }"
    @click="clickAction(task.ClickActions)"
  >
    <div class="">
      <div class="flex justify-between">
        <div>
          <div class="font-semibold tracking-wide text-sm">
            {{ task.Title }}
            <div v-if="true" class="inline-block ml-1">
              <tooltip :message="translations.TaskIsQueued">
                <i class="fad fa-business-time"></i>
              </tooltip>
            </div>
          </div>
          <div class="tracking-wide text-sm">
            {{ task.Description }}
          </div>
        </div>

        <div class="flex">
          <kanban-card-resource
            v-for="resource in resources"
            :key="resource.Description"
            :resource="resource"
            :is-sub-task="true"
          />
        </div>
      </div>
      <kanban-card-planned-date
        :card="task"
        :start-date="task.StartDate"
        :end-date="task.EndDate"
      />
    </div>
  </div>
</template>

<script>
import KanbanCardPlannedDate from "./KanbanCardPlannedDate.vue";
import KanbanCardResource from "./KanbanCardResource.vue";
import Tooltip from "../util/Tooltip.vue";

import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import action from "../../functions/action";

export default {
  name: "KanbanCardSubCard",
  components: {
    Tooltip,
    KanbanCardPlannedDate,
    KanbanCardResource,
  },
  props: {
    laneColor: {
      type: String,
      default: "#fff",
    },
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    resources() {
      return this.task.Resources;
    },
    borderStyle() {
      return {
        "border-left-color": this.laneColor + 50,
      };
    },
    translations() {
      return getTranslations();
    },
    clickable() {
      return this.task.ClickActions?.length > 0 ?? false;
    },
  },
  methods: {
    clickAction(clickActions) {
      for (let actionData of clickActions ?? []) {
        action(actionData);
      }
    },
  },
};
</script>

<style scoped></style>
