import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export function processSalesRowMetaData({row}) {
  let newRow = {...row};
  const itemType = getRawValueFromDropdownValue(newRow.ItemType.Value);

  const isSalesRow = itemType === "Sales" || itemType === "Deposit";
  const rowStatusOpen = newRow.Status.Value === "Open";

  if (isSalesRow) {
    newRow.PeriodPrice.IsReadOnly = true;
    newRow.PeriodPrice.Value = null;
    newRow.PeriodAmount.IsReadOnly = true;
    newRow.Period.IsReadOnly = true;
    newRow.DateTimeBusinessStart.IsReadOnly = true;
    newRow.DateTimeExpectedStart.IsReadOnly = true;
    newRow.DateTimeExpectedEnd.IsReadOnly = true;
    newRow.DateTimeBusinessEnd.IsReadOnly = true;
  }

  if (isSalesRow && rowStatusOpen) {
    newRow.DateTimeBusinessStart.IsReadOnly = false;
    newRow.DateTimeExpectedStart.IsReadOnly = false;
  }

  if (isSalesRow && !row.IsFullyInvoiced?.Value) {
    newRow.Price.IsReadOnly = false;
  }

  return newRow;
}
