export function canDeleteSingleRow({row}) {
  if (row.Composition?.rowMeta?.virtual === true) return false;
  if (!row.Composition?.Value) return true;

  if (
    ["Adjustable", "AdjustableDownwards"].indexOf(
      row.CompositionDefinition?.Value?.QuantityPolicy,
    ) > -1
  )
    return true;
  return false;
}
