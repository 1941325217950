import {rowsContainPhysicalStockItems} from "../../stock/rowsContainPhysicalStockItems.js";
import {getRawValueFromDropdownValue} from "../../../../util/parsing/getRawValueFromDropdownValue.js";
import {rowContainsPhysicalStockItem} from "../../stock/rowContainsPhysicalStockItem.js";
import {getStockForOrderItems} from "../stock/getStockForOrderItems.js";
import DataLoader from "dataloader";

export async function physicalItemRowsStockFetch({rows, order}) {
  if (!rowsContainPhysicalStockItems({rows})) return rows;

  const stockLoader = new DataLoader((rows) =>
    getStockForOrderItems({
      rowsToLoad: rows,
      order,
      customId: "PhysicalItemID",
    }),
  );

  return Promise.all(
    rows.map(async (row, index) => {
      if (row.rowMeta?.virtual || !rowContainsPhysicalStockItem({row}))
        return row;

      if (getRawValueFromDropdownValue(row.ItemType?.Value) === "Free") {
        return row;
      }
      if (row.OrderItemID?.Value?.toString().includes("_")) {
        return row;
      }

      const orderItemStockPrediction = await stockLoader.load(row);

      if (
        !orderItemStockPrediction ||
        orderItemStockPrediction instanceof Error
      ) {
        return row;
      }

      if (orderItemStockPrediction.OrderItemID !== row.OrderItemID.Value) {
        throw new Error(
          `Stock prediction is out of order! ${orderItemStockPrediction.OrderItemID} != ${row.OrderItemID.Value} (index ${index})`,
        );
      }

      return {
        ...row,
        rowMeta: {
          ...row.rowMeta,
          physicalItemStock: orderItemStockPrediction.ItemStock,
        },
      };
    }),
  );
}
