import {capitalizeFirstCharInString} from "../../../interface/capitalizeFirstCharInString.js";

const backendAliases = {
  "multi-selector": "MultiSelector",
};

export function getComponent(name) {
  return () => import(`./TableCell${name}.vue`);
}

export function getName({cell, column}) {
  const rawName = cell?.Editor ?? column.Editor ?? column.Type;

  return capitalizeFirstCharInString({
    string: backendAliases[rawName] ?? rawName,
  });
}

export function detectComponent({cell, column}) {
  return getComponent(getName({cell, column}));
}
