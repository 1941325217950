import {getPriceBulk} from "@/services/v2/rental/item/getPriceBulk";

export async function getPeriodBasedEndDate({
  period,
  periodAmount,
  startDate,
  itemId,
  itemType,
  params,
}) {
  const rentalPeriods = [
    {
      ItemID: itemId,
      ItemType: itemType,
      StartDate: startDate,
      Amount: periodAmount,
      Period: period,
      PeriodPrices: [
        {
          Period: "Dag",
          Price: 0,
        },
      ],
    },
  ];

  const data = await getPriceBulk({rentalPeriods, params});

  return data[0].PriceCalculation[0].EndDate;
}
