import dayjs from "dayjs";

export const processDaysCalculated = ({newData, changedField}) => {
  let newRow = {...newData.Rows?.[0]};
  if (!newRow) return newData;

  let daysCalculated = newRow.DaysCalculated;
  let startDate = newRow.DateTimeExpectedStart;
  let endDate = newRow.DateTimeExpectedEnd;

  if (dayjs(startDate, "YYYY-MM-DD").isValid())
    startDate = dayjs(startDate, "YYYY-MM-DD").format("DD-MM-YYYY");
  if (dayjs(endDate, "YYYY-MM-DD").isValid())
    endDate = dayjs(endDate, "YYYY-MM-DD").format("DD-MM-YYYY");

  if (changedField?.Name === "DateTimeExpectedStart" && daysCalculated) {
    endDate = dayjs(startDate, "DD-MM-YYYY").add(daysCalculated - 1, "day");
  }

  if (changedField?.Name === "DateTimeExpectedEnd" && startDate && endDate) {
    const newDaysCalculated =
      dayjs(endDate, "DD-MM-YYYY").diff(dayjs(startDate, "DD-MM-YYYY"), "day") +
      1;

    if (daysCalculated !== newDaysCalculated)
      daysCalculated = newDaysCalculated;
  }

  if (
    changedField?.Name === "DaysCalculated" &&
    startDate &&
    daysCalculated > 0
  ) {
    endDate = dayjs(startDate, "DD-MM-YYYY")
      .add(daysCalculated - 1, "day")
      .format("DD-MM-YYYY");
  }

  if (!daysCalculated && startDate && endDate)
    daysCalculated =
      dayjs(endDate, "DD-MM-YYYY").diff(dayjs(startDate, "DD-MM-YYYY"), "day") +
      1;

  newRow = {
    ...newRow,
    DateTimeExpectedStart: startDate,
    DateTimeExpectedEnd: endDate,
    DaysCalculated: daysCalculated,
  };

  newData.Rows[0] = newRow;

  return newData;
};
