import {getWindowFromWindowId} from "../../functions/window/getWindowFromWindowId.js";
import {dirtyCheckWithPrompt} from "@/interface/window/dirty";
import store from "../../../state/store";
import Hook from "@/hook";

/**
 * Close and remove window object
 * @see {@link Canvas}
 * @see {@link Session}
 * @param {boolean} shouldConfirm - If confirmation is needed to close the window
 * @param {boolean} keepTabSelection - If the window is being reloaded
 * @param {boolean} tabDeleted - If the tab reference is already gone
 * @returns {void}
 */
export default async function dispose(
  shouldConfirm = true,
  keepTabSelection = false,
  tabDeleted = false,
) {
  // Run hookable event
  Hook.run("dispose", this, async (e) => {
    const dirtyPromptConfirmed = shouldConfirm
      ? await dirtyCheckWithPrompt(this)
      : false;

    try {
      if (shouldConfirm === true && !dirtyPromptConfirmed) {
        e.cancel = true;
        return;
      }
    } catch (error) {
      console.error(error);
    }

    const windowId = this.id;
    // remove combobox references
    this.removeComboboxReferences();

    const subWindowId = this.sub?.window?.id;

    if (subWindowId) {
      const subWindow = getWindowFromWindowId({
        windowId: subWindowId,
        session: this.session,
      });
      await subWindow.dispose(!dirtyPromptConfirmed);
    }
    // delete reference from session
    delete this.session.windows[this.id];

    // remove self from session tab
    this.session.tabs = this.session.tabs.filter((tab) => tab.id !== windowId);

    const subWindow = this.session.activeWindow.sub?.window ?? {};

    // If we are the active window
    if (this.session.activeWindow === this && subWindow !== this) {
      // Focus a new window

      this.session.activeWindow = null;

      let index = this.session.tabs.indexOf(this);
      let newIndex = Math.max(index - 1, 0);
      let newWindow = this.session.tabs[newIndex];

      if (newWindow) {
        newWindow.focus();
      }
    }

    store.commit("updateWindow");
    store.commit("refreshTabs");
    global.eventBus.emit("afterDispose", this);
  });
}
