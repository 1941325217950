import {getItemDescriptionFromScanRow} from "@/functions/datagrid/stock-transfer-item/getItemDescriptionFromScanRow";

export function applyItemDescriptionToRows({rows}) {
  let itemDescriptionRows = [];

  for (const row of rows) {
    itemDescriptionRows.push({
      ...row,
      Description: {
        Value: getItemDescriptionFromScanRow({row}),
        Editor: "String",
      },
    });
  }

  return itemDescriptionRows;
}
