import {patchPickListItems} from "../../../../services/v2/picklist/patchPickListItems";
import {getTranslations} from "../../../session/localstorage/getTranslations.js";
import {notify} from "../../../../util/notify";

export const patchItems = async (formattedRows) => {
  const translations = getTranslations();

  try {
    const result = await patchPickListItems({pickListItems: formattedRows});

    if (result.status === 400 && result?.data?.Error?.Message) {
      notify({message: result.data.Error.Message, type: "error"});
      return "Error";
    }

    if (result.status === 400) throw new Error();

    notify({
      message: translations["SaveSuccessful"],
      type: "success",
    });
  } catch (error) {
    notify({
      message: translations["SaveFailed"],
      type: "error",
    });
    return "Error";
  }
};
