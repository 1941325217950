import {getDraftTotals} from "../../../../services/v2/rental/quotationItem/prices/getDraftTotals";
import {createPricesUpdateHookRequestBody} from "../../order-item/rows/rows-process-webhook/createPricesUpdateHookRequestBody";
import {hasDataHookEvent} from "../../../../hooks/hasDataHookEvent";

export const getExtraTotalValues = async ({rows, quotation, dataWebHooks}) => {
  if (
    !hasDataHookEvent({
      dataWebHooks,
      eventId: "rental.quotationitem.totals.updated.draft",
    })
  ) {
    return {};
  }
  const context = createPricesUpdateHookRequestBody({context: quotation, rows});
  const {data} = await getDraftTotals({event: "processTotals", context});

  return data;
};
