import {flattenKeyObjectsInRow} from "./flattenKeysInRowCollection";

export const parseRowCollectionKeyObjectResponse = async ({response}) => {
  return {
    ...response,
    data: {
      ...response.data,
      Collection: response.data.Collection.map((row) => {
        const normalizedRow = flattenKeyObjectsInRow({row});
        normalizedRow.SupplierDescription = row.SupplierID?.Description;
        return normalizedRow;
      }),
    },
  };
};
