import {cloneDeep} from "lodash";

export const processValueChanges = async ({
  rows,
  index,
  column,
  oldRow,
  cell,
  context,
}) => {
  const modifiedRows = cloneDeep(rows);

  for (const processValue of column.processValueChangeFunctions || []) {
    let modifiedRow = {};

    try {
      modifiedRow = await processValue({
        ...context,
        row: modifiedRows[index],
        rows: modifiedRows,
        oldRow,
        cell,
      });
    } catch (error) {
      console.error("Error during processing value changes:", error);
      modifiedRow = oldRow;
    }
    modifiedRows[index] = {
      ...modifiedRows[index],
      ...modifiedRow,
    };
  }

  return modifiedRows;
};
