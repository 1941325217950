import {newWindow} from "../../services/coreWebServices";
export default async function openWindow({
  subject,
  actionName,
  criteria,
  prefix,
}) {
  const result = await newWindow({subject, actionName, criteria, prefix});
  await global.session.activeWindow.handleActionResponse(result.data);
}
