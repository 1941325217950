export async function createReturn({returnItems}) {
  let result = await window.session.request(
    "/Admin/WebServices/ReturnWebServices.asmx/CreateReturn",
    {
      returnItems,
    },
  );

  // eslint-disable-next-line no-undef
  await session.activeWindow.handleActionResponse(result);

  return result;
}
