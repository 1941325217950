export function getMissingPeriodColumns({row, setting}) {
  const missingColumnArray = [];

  Object.entries(row).forEach(([property, value]) => {
    if (
      setting === "AllowItemSinglePeriodPriceCalculation" &&
      (property === "Period" || property === "PeriodPrice") &&
      (value.Value === null ||
        value.Value === undefined ||
        value.Value === 0 ||
        (isNaN(value.Value) && property !== "Period"))
    ) {
      missingColumnArray.push(property);
    }
    if (
      setting === "ItemSinglePeriodCalculateEndDateEnforced" &&
      (property === "Period" || property === "PeriodAmount") &&
      (value.Value === null ||
        value.Value === undefined ||
        value.Value === 0 ||
        (isNaN(value.Value) && property !== "Period"))
    ) {
      missingColumnArray.push(property);
    }
    if (
      setting === "ItemSinglePeriodEnforced" &&
      (property === "Period" ||
        property === "PeriodPrice" ||
        property === "PeriodAmount") &&
      (value.Value === null ||
        value.Value === undefined ||
        value.Value === 0 ||
        (isNaN(value.Value) && property !== "Period"))
    ) {
      missingColumnArray.push(property);
    }
  });

  return missingColumnArray;
}
