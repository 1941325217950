import getInboundItems from "@/services/inbound/getInboundItems";

export default async function fetchInboundItemScannerData({
  params = {},
  search = undefined,
} = {}) {
  const supplierId = params.supplierId;
  const result = await getInboundItems({
    params: {search, SupplierID: supplierId},
  });
  return result.data?.Collection ?? [];
}
