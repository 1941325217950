import customParseFormat from "dayjs/plugin/customParseFormat";
import Hook from "../hook";
import vueDateTemplate from "../components/elements/vueDate.vue";
import vueDateTimeTemplate from "../components/elements/vueDateTime.vue";
import Vue from "vue";
import $ from "jquery";
import dayjs from "dayjs";
import store from "../../state/store.js";
import {extractSubWindowFromWindowObject} from "../util/extractSubWindowFromWindowObject.js";
import {parseBooleanAttribute} from "../functions/parsing/parseBooleanAttribute.js";

dayjs.extend(customParseFormat);

class FormHook extends Hook {
  async afterFocus(eventWindow) {
    bindVueElements(eventWindow);
  }

  async afterRender(eventWindow) {
    bindVueElements(eventWindow);
  }

  async afterProcess(eventWindow) {
    bindVueElements(eventWindow);
  }
}

function bindVueElements(eventWindow) {
  if (eventWindow.output.Request.Prefix === "New") {
    const relevantWindow =
      extractSubWindowFromWindowObject({window: eventWindow}) ?? eventWindow;

    const calendarDateTimeFields = $(eventWindow.element).find(
      "div [data-editor-type='calendar-date-time']",
    );

    for (const calendarField of calendarDateTimeFields) {
      const vueInstance = new Vue({
        el: `#${calendarField.id}`,
        store,
        render: (h) =>
          h(vueDateTimeTemplate, {
            props: {
              windowId: $(calendarField).attr("window-id"),
              name: $(calendarField).attr("name"),
              index: $(calendarField).attr("index"),
              value: $(calendarField).attr("value"),
              today: $(calendarField).attr("today"),
              readonly: $(calendarField).attr("read-only"),
              showTime: $(calendarField).attr("show-time") === "true",
              filterOnOpeningsDay: $(calendarField).attr(
                "filter-on-openings-day",
              ),
              timePicker: $(calendarField).attr("time-picker"),
              postback: $(calendarField).attr("postback") === "true",
              required: parseBooleanAttribute(
                $(calendarField).attr("isRequired"),
              ),
            },
          }),
      });

      relevantWindow.vueColumns = {
        ...relevantWindow.vueColumns,
        [$(calendarField).attr("name")]: vueInstance,
      };
    }

    const calendarDateFields = $(eventWindow.element).find(
      "div [data-editor-type='calendar']",
    );

    for (const calendarField of calendarDateFields) {
      const vueInstance = new Vue({
        el: `#${calendarField.id}`,
        store,
        render: (h) =>
          h(vueDateTemplate, {
            props: {
              windowId: $(calendarField).attr("window-id"),
              name: $(calendarField).attr("name"),
              index: $(calendarField).attr("index"),
              providedValue: $(calendarField).attr("value"),
              today: $(calendarField).attr("today"),
              readonly: $(calendarField).attr("read-only"),
              showTime: $(calendarField).attr("show-time") === "true",
              filterOnOpeningsDay: $(calendarField).attr(
                "filter-on-openings-day",
              ),
              timePicker: $(calendarField).attr("time-picker"),
              postback: $(calendarField).attr("postback") === "true",
            },
          }),
      });

      relevantWindow.vueColumns = {
        ...relevantWindow.vueColumns,
        [$(calendarField).attr("name")]: vueInstance,
      };
    }
  }
}

/** Date filter 'equals' hook */
class DateEqualsHook extends Hook {
  /**
   * Set correct filters
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    if (
      !window.output.Options ||
      window.output.Options.Filters_UseDate == null ||
      !window.output.Options.Filters_UseDate.endsWith ||
      !window.output.Options.Filters_UseDate.endsWith(" =")
    ) {
      return;
    }

    let filterTarget = window.output.Options.Filters_UseDate.slice(0, -2);
    let filterText = window.session.translations.Date;
    if (
      window.output.Request.Data.Filters.hasOwnProperty(
        window.output.Options.Filters_UseDate,
      )
    ) {
      filterText =
        window.session.translations.Date +
        ": " +
        window.output.Request.Data.Filters[
          window.output.Options.Filters_UseDate
        ];
    }

    window.filters = {
      usedate: filterTarget,
      date: [
        {
          text: filterText,
          suffix: "=",
          value: null,
        },
      ],
      other: [],
      selectedOthers: 0,
    };
  }
}

/** @ignore */
export default [new DateEqualsHook(), new FormHook()];
