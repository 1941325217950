<template>
  <div @keydown.enter="selectOptionEnter">
    <v-select
      id="quickOpen"
      v-model="selectedValue"
      :options="filter.options"
      :placeholder="`${translations.Search}`"
      label="label"
      :reset-on-options-change="true"
      :loading="loading"
      @search="fetchOptions"
      @option:selected="selectOption"
    >
      <template #option="option">
        <span>{{ option.label }}</span>
      </template>
      <div slot="no-options">{{ translations.NoRowsFound }}</div>
      <template #spinner="{loading}">
        <i v-if="loading" class="fas fa-spinner fa-spin text-xl"></i>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {removeScanFieldFilterValueFromString} from "../../functions/item-scanner/removeScanFieldFilterValueFromString";
import Window from "../../model/window";
import {quickSearch} from "../../services/quickSearch";
import {getSerial} from "../../services/serials/getSerial";
export default {
  components: {
    "v-select": vSelect,
  },
  data: function () {
    return {
      filter: {
        options: [],
      },
      loading: false,
      selectedValue: null,
      requestData: null,
      newFetchData: null,
      searchValue: null,
    };
  },
  computed: {
    translations: function () {
      return this.$store.state.translations;
    },
  },
  methods: {
    parseSearchValue(value) {
      return value.replace(
        this.$store.state.settings.ScanFieldFilterValue ?? "",
        "",
      );
    },
    fetchOptions: async function (event) {
      if (!event) return;
      this.searchValue = event;
      if (this.loading) {
        this.newFetchData = event;
        return;
      }
      this.loading = true;

      const requestData = await quickSearch({
        searchValue: this.parseSearchValue(event),
      });

      this.filter.options;

      const newOptions = [];
      const seenValues = new Set();

      for (const searchResult of requestData) {
        const value = searchResult.Value;
        if (!seenValues.has(value)) {
          newOptions.push({label: searchResult.Text, value: value});
          seenValues.add(value);
        }
      }

      this.filter.options = newOptions;

      this.loading = false;
      if (this.newFetchData !== null) {
        const newFetchData = this.newFetchData;
        this.newFetchData = null;

        await this.fetchOptions(newFetchData);
      }
    },
    async selectOptionEnter() {
      this.filter.options = [];

      const parsedSearchValue = this.parseSearchValue(this.searchValue);

      while (this.loading) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      const event = this.filter.options?.find((option) =>
        option.value.includes(parsedSearchValue),
      );

      this.selectOption(event);
    },
    selectOption: async function (event) {
      let window = new Window(global.session);
      this.filter.options = [];

      try {
        window.customTitle = "<i class='fas fa-spinner fa-spin'>";
        const value =
          event.value?.replace(
            this.$store.state.settings.ScanFieldFilterValue ?? "",
            "",
          ) ?? "";

        let output = await global.session.request(
          "/Admin/WebServices/CoreWebServices.asmx/OpenNodeByValue",
          {
            value,
            description: event.label ?? "",
          },
        );

        await window.process(output);
        window.customTitle = null;
        window.loading = false;
        window.focus();
        await window.render();
        this.$store.commit("refreshTabs");
        this.$store.commit("updateWindow");
      } catch (err) {
        console.error(err);
        window.dispose();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/vue-select/src/scss/vue-select";
.v-select {
  .vs__search,
  .vs__search:focus {
    color: #242424 !important;
    line-height: 1.2;
  }

  .vs__dropdown-menu {
    z-index: 2900;
  }
  .vs__dropdown-option--highlight {
    background-color: #e1e1e1 !important;
    color: black;
  }

  .vs__open-indicator {
    color: black;
    fill: black;
    transform: scale(0.65);

    width: 15px !important;
  }
}
</style>
