import {api} from "../../api";
import {normalizePickListItemsResponse} from "./normalizePickListItemsResponse";

export const fetchPickListItems = async ({pickListId, warehouseID}) => {
  const response = await api.get(
    `/v2/picklists/${pickListId}/items/_available-items`,
    {params: {warehouse: warehouseID}},
  );

  return normalizePickListItemsResponse({response});
};
