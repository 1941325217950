import {updateRowsWithItemPriceCondition} from "./updateRowsWithItemPriceCondition";
import {updateRowsWithCostPriceCondition} from "./updateRowsWithCostPriceCondition";
import {updateRowsWithModifyRowPriceCondition} from "./updateRowsWithModifyRowPriceCondition";

export const applyPriceCondition = async ({
  row,
  priceCondition,
  rows,
  itemPrice,
  getRowsWrapper,
}) => {
  if (priceCondition.ActionType === "ModifyRow") {
    return {
      rows: updateRowsWithModifyRowPriceCondition({
        rows,
        row,
        priceCondition,
        itemPrice,
      }),
      priceConditionOrderItemIDs: [],
    };
  }

  if (priceCondition.ActionType === "Item") {
    return await updateRowsWithItemPriceCondition({
      priceCondition,
      rows,
      row,
      currentPrice: row.PurchasePricePerUnit.Value,
      getRowsWrapper,
    });
  }
  if (priceCondition.ActionType === "Cost") {
    return await updateRowsWithCostPriceCondition({
      priceCondition,
      rows,
      row,
      getRowsWrapper,
    });
  }

  return {rows, priceConditionOrderItemIDs: []};
};
