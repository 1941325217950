import {v4 as uuidv4} from "uuid";
import {getNextAvailableRanking} from "./GetNextAvailableRanking";
import {setNonPriceValuesOfPriceCondition} from "./setNonPriceValuesOfPriceCondition";

export const addPriceConditionCostRow = async ({
  rows,
  row,
  priceCondition,
  price,
  getRowsWrapper,
}) => {
  const newOrderItemID = uuidv4();

  const nextRanking = getNextAvailableRanking({rows, row});

  // TODO: Reintroduce Itemtype on Inbound OrderItems
  let rowToAdd = {
    // ItemType: "PriceCondition",
    Description: priceCondition.Description,
    PriceConditionID: priceCondition.PriceConditionID,
    RelatedOrderItemID: row.OrderItemID.Value,
    Amount: priceCondition.AmountType === "Follow" ? row.Amount?.Value ?? 1 : 1,
    Ranking: nextRanking,
    OrderItemID: newOrderItemID,
    UnitPrice: price,
  };

  rowToAdd = setNonPriceValuesOfPriceCondition({
    row: rowToAdd,
    priceCondition,
  });

  return {
    rows: await getRowsWrapper({
      scannedRow: rowToAdd,
      rows,
    }),
    priceConditionOrderItemIDs: [newOrderItemID],
  };
};
