export function processRowDeletable({row}) {
  const newRow = {...row};

  if (newRow.TotalInvoiced?.Value > 0 || newRow.DateTimeShipped?.Value) {
    newRow.rowMeta = {
      ...newRow.rowMeta,
      deletable: false,
    };
  }
  return newRow;
}
