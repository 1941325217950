export const setRowCellsReadonly = ({row, exceptions = []}) => {
  const modifiedRow = {...row};
  Object.keys(modifiedRow).forEach((fieldName) => {
    modifiedRow[fieldName] = {
      ...modifiedRow[fieldName],
      IsReadOnly: !exceptions.includes(fieldName),
    };
  });

  return modifiedRow;
};
