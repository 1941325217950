import {handleVATCodeIDChange} from "@/functions/datagrid/row/handleVATCodeIDChange";
import {processTotalExcVat} from "@/functions/datagrid/order-item/row/processTotalExcVat";
import {handleItemIdChange} from "@/functions/datagrid/order-item/row/handleItemIdChange";
import {rowsStockFetch} from "@/functions/datagrid/order-item/rows/rowsStockFetch";
import {rowsProcessPopupStockPredictions} from "@/functions/datagrid/order-item/rows/rowsProcessPopupStockPredictions";
import {calculateCalculatedPrice} from "@/functions/datagrid/order-item/row/calculateCalculatedPrice";
import {handleRankingChange} from "@/functions/datagrid/row/handleRankingChange";
import {processRowsItemSerialStock} from "@/functions/datagrid/order-item/row/processRowsItemSerialStock";
import {setAndForceMinDates} from "@/functions/datagrid/order-item/row/setAndForceMinDates";
import {calculatePriceDifference} from "@/functions/datagrid/order-item/row/calculatePriceDifference";
import {handleItemDescriptionChange} from "@/functions/datagrid/order-item/row/handleItemDescriptionChange";
import {processPeriodPrice} from "@/functions/datagrid/order-item/row/processPeriodPrice";
import {processUseSinglePeriodPriceChange} from "@/functions/datagrid/order-item/row/processUseSinglePeriodPriceChange";
import {processRowPrices} from "@/functions/datagrid/order-item/row/processRowPrices";
import {processPeriodPriceChange} from "@/functions/datagrid/order-item/row/processPeriodPriceChange";
import {processSerialIdChange} from "@/functions/datagrid/order-item/row/processSerialIdChange";
import {fetchCounterPrice} from "@/functions/datagrid/order-item/row/fetchCounterPrice";
import {processItemType} from "@/functions/datagrid/order-item/row/processItemType";
import {processFreeRow} from "@/functions/datagrid/order-item/row/processFreeRow";
import {processSalesRow} from "@/functions/datagrid/order-item/row/processSalesRow";
import {processPopupPriceCalculations} from "@/functions/datagrid/order-item/row/processPopupPriceCalculations";
import {fetchPeriodBasedExpectedEndDate} from "@/functions/datagrid/order-item/row/fetchPeriodBasedExpectedEndDate";
import {rowsVirtualResumTotalExcVat} from "@/functions/datagrid/order-item/rows/rowsVirtualResumTotalExcVat";
import {rowsVirtualResumCalculatedPrice} from "@/functions/datagrid/order-item/rows/rowsVirtualResumCalculatedPrice";
import {rowAmountChangeWrapper} from "@/functions/datagrid/order-item/row/change-event-wrappers/rowAmountChangeWrapper";
import {rowsAmountChangeWrapper} from "@/functions/datagrid/order-item/rows/change-event-wrappers/rowsAmountChangeWrapper";
import {rowsProcessVirtualRowAmountChange} from "@/functions/datagrid/order-item/rows/rowsProcessVirtualRowAmountChange";
import {setFullyInvoicedToRow} from "@/functions/datagrid/order-item/row/setFullyInvoicedToRow";
import {processPeriodColumnChange} from "@/functions/datagrid/order-item/row/processPeriodColumnChange";
import {clearPeriodPricesOnPeriodClearWhenPeriodUseOptional} from "./row/clearPeriodPricesOnPeriodClearWhenPeriodUseOptional.js";
import {handleAmountChange} from "../row/handleAmountChange.js";
import {physicalItemRowsStockFetch} from "./rows/physicalItemRowsStockFetch.js";
import {processSerialRow} from "../row/processSerialRow.js";
import {processRowWarehouseIdChange} from "../row/processRowWarehouseIdChange.js";

export function processColumnsMetaData({columns}) {
  const newColumns = columns.slice();

  newColumns.forEach((newColumn) => {
    newColumn.processValueChangeFunctions = [];
    newColumn.processValueChangeFunctionsForRows = [];

    if (newColumn.Name === "VATCodeID") {
      newColumn.processValueChangeFunctions.push(handleVATCodeIDChange);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
    }

    if (newColumn.Name === "ItemID") {
      newColumn.processValueChangeFunctions.push(handleItemIdChange);
      newColumn.processValueChangeFunctions.push(processSerialRow);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctionsForRows.push(rowsStockFetch);
      newColumn.processValueChangeFunctionsForRows.push(
        physicalItemRowsStockFetch,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsProcessPopupStockPredictions,
      );
      newColumn.openRef = true;
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "Ranking") {
      newColumn.processValueChangeFunctions.push(handleRankingChange);
    }

    if (newColumn.Name === "DateTimeExpectedStart") {
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(processRowsItemSerialStock);
      newColumn.processValueChangeFunctionsForRows.push(rowsStockFetch);
      newColumn.processValueChangeFunctionsForRows.push(
        physicalItemRowsStockFetch,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsProcessPopupStockPredictions,
      );
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(
        fetchPeriodBasedExpectedEndDate,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "DateTimeExpectedEnd") {
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(processRowsItemSerialStock);
      newColumn.processValueChangeFunctionsForRows.push(rowsStockFetch);
      newColumn.processValueChangeFunctionsForRows.push(
        physicalItemRowsStockFetch,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsProcessPopupStockPredictions,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "DateTimeRentalEnd") {
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
      newColumn.processValueChangeFunctions.push(setFullyInvoicedToRow);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "ItemDescription") {
      newColumn.processValueChangeFunctions.push(handleItemDescriptionChange);
    }

    if (newColumn.Name === "Amount") {
      newColumn.processValueChangeFunctions.push(handleAmountChange);
      newColumn.processValueChangeFunctions.push(rowAmountChangeWrapper);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsAmountChangeWrapper,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsProcessVirtualRowAmountChange,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "SerialID") {
      newColumn.processValueChangeFunctions.push(processSerialIdChange);
      newColumn.processValueChangeFunctions.push(processRowsItemSerialStock);
      newColumn.processValueChangeFunctions.push(rowAmountChangeWrapper);
    }

    if (newColumn.Name === "Discount") {
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
    }

    if (newColumn.Name === "TotalExcVAT") {
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
    }

    if (newColumn.Name === "DateTimeBusinessStart") {
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
      newColumn.processValueChangeFunctions.push(
        processUseSinglePeriodPriceChange,
      );
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "DateTimeBusinessEnd") {
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );

      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "ExpectedCounter") {
      newColumn.processValueChangeFunctions.push(fetchCounterPrice);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "DateTimeReturned") {
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "Price") {
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "ItemType") {
      newColumn.processValueChangeFunctions.push(processFreeRow);
      newColumn.processValueChangeFunctions.push(processItemType);
      newColumn.processValueChangeFunctions.push(processSalesRow);
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "WarehouseID") {
      newColumn.processValueChangeFunctions.push(processRowWarehouseIdChange);
    }

    if (newColumn.Name === "ConditionID") {
      newColumn.processValueChangeFunctionsForRows.push(rowsStockFetch);
      newColumn.processValueChangeFunctionsForRows.push(
        physicalItemRowsStockFetch,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsProcessPopupStockPredictions,
      );
    }

    if (newColumn.Name === "Period") {
      newColumn.processValueChangeFunctions.push(processPeriodPrice);
      newColumn.processValueChangeFunctions.push(processPeriodColumnChange);
      newColumn.processValueChangeFunctions.push(
        processUseSinglePeriodPriceChange,
      );
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctionsForRows.push(rowsStockFetch);
      newColumn.processValueChangeFunctionsForRows.push(
        physicalItemRowsStockFetch,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsProcessPopupStockPredictions,
      );
      newColumn.processValueChangeFunctions.push(
        fetchPeriodBasedExpectedEndDate,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
      newColumn.processValueChangeFunctions.push(
        clearPeriodPricesOnPeriodClearWhenPeriodUseOptional,
      );
    }

    if (newColumn.Name === "PeriodPrice") {
      newColumn.processValueChangeFunctions.push(processPeriodPriceChange);
      newColumn.processValueChangeFunctions.push(processPeriodColumnChange);
      newColumn.processValueChangeFunctions.push(
        processUseSinglePeriodPriceChange,
      );
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }

    if (newColumn.Name === "PeriodAmount") {
      newColumn.processValueChangeFunctions.push(
        processUseSinglePeriodPriceChange,
      );
      newColumn.processValueChangeFunctions.push(processPeriodColumnChange);
      newColumn.processValueChangeFunctions.push(processRowPrices);
      newColumn.processValueChangeFunctions.push(processTotalExcVat);
      newColumn.processValueChangeFunctions.push(calculateCalculatedPrice);
      newColumn.processValueChangeFunctions.push(calculatePriceDifference);
      newColumn.processValueChangeFunctions.push(setAndForceMinDates);
      newColumn.processValueChangeFunctions.push(
        fetchPeriodBasedExpectedEndDate,
      );
      newColumn.processValueChangeFunctionsForRows.push(rowsStockFetch);
      newColumn.processValueChangeFunctionsForRows.push(
        physicalItemRowsStockFetch,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsProcessPopupStockPredictions,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumTotalExcVat,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        rowsVirtualResumCalculatedPrice,
      );
      newColumn.processValueChangeFunctions.push(processPopupPriceCalculations);
    }
  });

  return newColumns;
}
