export const setPreScannedSerialRowsToPicked = ({rows}) => {
  return rows.map((row) => {
    if (row.SerialID?.Value) {
      return {
        ...row,
        PickedQuantity: {
          ...row.PickedQuantity,
          Value: 1,
        },
        Status: {
          ...row.Status,
          Value: "Picked",
        },
        StatusCode: {
          ...row.StatusCode,
          Value: 150,
        },
      };
    }
    return row;
  });
};
