import {getSuppliers} from "@/services/v2/suppliers/getSuppliers";
import getInboundOrder from "@/services/inbound/getInboundOrder";

export async function getExpectedDeliveryDaysFromSupplier({row}) {
  const supplierArray = await getSuppliers();
  const order = await getInboundOrder({param: row.OrderID.Value});

  for (const supplier in supplierArray) {
    if (
      order.data.SupplierID.Key === supplier.SupplierID &&
      supplier.ExpectedDeliveryDays
    ) {
      return supplier.ExpectedDeliveryDays;
    }
  }

  return 0;
}
