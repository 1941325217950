import {getItem} from "@/services/v2/items/getItem";
import {processTotalExcVat} from "./processTotalExcVat";
import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import Decimal from "decimal.js";

export async function setSalesPrice({row}) {
  let newRow = {...row};

  const itemId = getRawValueFromDropdownValue(newRow.ItemID.Value);
  const item = await getItem(itemId);

  const salesPrice = item.SalesPrice;
  const discount = new Decimal(parseFloat(row.Discount?.Value ?? 0)).toNumber();

  if (salesPrice && discount) {
    const newCalculatedPrice = new Decimal(salesPrice);

    newRow.Price.Value = salesPrice;
    newRow.CalculatedPrice.Value = newCalculatedPrice
      .times(row.Amount.Value)
      .dividedBy(100)
      .times(100 + discount)
      .toDecimalPlaces(4)
      .toNumber();
  }

  if (salesPrice && !discount) newRow.CalculatedPrice.Value = salesPrice;

  if (!salesPrice) newRow.CalculatedPrice.Value = null;

  newRow.Price.Value = item.SalesPrice;
  newRow.Price.OldValue = row.Price.Value;

  newRow = processTotalExcVat({row: newRow});

  return newRow;
}
