import {getRawValueFromDropdownValue} from "../../../../util/parsing/getRawValueFromDropdownValue";
import {handleExistingRow} from "../merge-bulk-rows/handleExistingRow";
import {getIdFromRow} from "./merge-bulk-row/getIdFromRow";
import {handleNewRowCondition} from "./merge-bulk-row/handleNewRowCondition";
import {handleSerialsValue} from "./merge-bulk-row/handleSerialsValue";

export const getKeyAndRowForBulkRowsMerge = ({
  rowsMap,
  row,
  setPickedQuantityMax,
}) => {
  const condition = getRawValueFromDropdownValue(row.ConditionID.Value);
  const id = getIdFromRow({row, condition});

  if (row.UseSerials.Value) {
    return handleSerialsValue({row});
  }

  if (!rowsMap[id]) {
    return handleNewRowCondition({id, setPickedQuantityMax, row});
  }

  return {
    key: id,
    row: handleExistingRow({
      existingRow: rowsMap[id],
      setPickedQuantityMax,
      row,
    }),
  };
};
