<template>
  <div>
    <slot>
      <div class="flex">
        <div
          class="fake-button button button-menu no-hover no-action mr-auto h-fit"
        >
          <span>{{ rows.length }}</span>
        </div>
        <div class="flex ml-2">
          <datagrid-pagination
            v-if="count > pageSize"
            :page="page"
            :count="count"
            :page-size="pageSize"
            @page-change="$emit('page-change', $event)"
          />
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import DatagridPagination from "@/components/datagrid/DatagridPagination.vue";
export default {
  name: "DatagridFooter",
  components: {
    DatagridPagination,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 10,
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    count() {
      return this.rows.filter((row) => !row.rowMeta?.compositionItem).length;
    },
  },
};
</script>

<style scoped></style>
