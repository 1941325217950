import {setCorrectActiveTab} from "./setCorrectActiveTab";
import {setCorrectActiveWindowAfterEvent} from "./setCorrectActiveWindowAfterEvent";
import {upsertWindow} from "./upsertWindow";

export const handleWindowFocus = ({vueInstance, window}) => {
  if (!vueInstance.rWindows[window.id]) {
    upsertWindow({vueInstance, window});
  }

  setCorrectActiveWindowAfterEvent({vueInstance, window});
  // set correct active tab
  setCorrectActiveTab({vueInstance});
};
