export const processFieldType = (type) => {
  switch (type) {
    case "Text":
    case "String":
      return "Text";

    case "Int":
    case "Int32":
    case "Number":
      return "Number";

    case "Float":
    case "Decimal":
      return "Decimal";

    case "MultiSelect":
    case "Multi-selector":
      return "MultiSelect";

    case "Money":
      return "Money";
    case "Boolean":
      return "Checkbox";
    case "Date":
      return "Date";
    case "DateTime":
      return "Datetime";
    case "Time":
      return "Time";
    case "Select":
      return "Select";
    case "Textarea":
      return "Textarea";
    default:
      return "Text";
  }
};
