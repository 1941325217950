export const setRowsAmountPickedForItemsWithoutSerials = ({rows, settings}) => {
  if (settings.SetAmountPickedForItemsWithoutSerials === true) {
    return rows.map((row) => {
      if (row.UseSerials.Value === false) {
        return {
          ...row,
          PickedQuantity: {
            ...row.PickedQuantity,
            Value: row.OrderedQuantity.Value,
          },
          Status: {
            ...row.Status,
            Value: "Picked",
          },
          StatusCode: {
            ...row.StatusCode,
            Value: "150",
          },
        };
      }
      return row;
    });
  }
  return rows;
};
