export const rowsSetDefaultAmountReceived = ({rows}) => {
  const newRows = rows.slice();
  newRows.forEach((row) => {
    if (row.AmountReceived.Value === null) {
      row.AmountReceived.Value = 0;
    }
  });

  return newRows;
};
