import chartTimelineSegmentTitle from "../../components/charts/timeline/chartTimelineSegmentTitle.html";

export const generateStockMessage = ({item, startDate, endDate}) => {
  return chartTimelineSegmentTitle({
    fullTableName: item.fullTableName,
    prefix: item.prefix,
    criteria: JSON.stringify(item.criteria),
    startDate: startDate.format("DD-MM-YYYY HH:mm"),
    endDate: endDate.format("DD-MM-YYYY HH:mm"),
    value: item.details || item.value,
  });
};
