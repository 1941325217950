<template>
  <div>
    <tree-select
      :multiple="true"
      :options="options"
      :value="selectedOptions"
      :placeholder="filterTitle"
      :show-count="true"
      :value-consists-of="'LEAF_PRIORITY'"
      :disable-branch-nodes="disableBranchNodes"
      @input="handleInput($event)"
    >
    </tree-select>
  </div>
</template>
<script>
import TreeSelect from "vue-treeselect";
import "vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "DatagridFilter",
  components: {
    TreeSelect,
  },
  props: {
    filterName: {
      type: String,
      required: true,
      default: "",
    },
    filterTitle: {
      type: String,
      required: true,
      default: "",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    disableBranchNodes: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", {name: this.filterName, value});
    },
  },
};
</script>
