<template>
  <th
    ref="column"
    :colspan="column.Colspan || 1"
    :data-column-name="column.Name"
    :data-column-ranking="column.Ranking"
    :style="style"
    :class="{
      'px-2 py-2': true,
      'cursor-pointer': column.Title === '',
    }"
    @click="handleClick"
  >
    <div :class="cellClasses" :title="column.Title">
      <div v-if="column.IsEditorColumn">
        <component
          :is="cellComponent"
          v-if="column.IsVisible"
          :classes="cellClasses"
          :column="column"
          :value="columnHeaderValue"
          :columns="columns"
          :data-component="`datagrid-cell-${cellComponentName}`"
          @change="handleChange"
        ></component>
      </div>
      <div v-else>
        <div v-if="titleIsHtmlElement" v-html="column.Title" />
        <div v-else>
          {{ column.Title }}
        </div>
      </div>
    </div>
  </th>
</template>

<script>
import RCheckbox from "../elements/RCheckbox.vue";

export default {
  components: {RCheckbox},
  props: {
    column: {
      type: Object,
      required: true,
    },
    columnHeaderValue: {
      type: Object,
      required: false,
      default: null,
    },
    columns: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      default: "",
    },
    sortDirection: {
      type: String,
      default: "asc",
    },
  },
  computed: {
    style() {
      let styles = this.column.Styles ?? "";
      if (this.column.Width) {
        styles += `width: ${this.column.Width}px`;
      }
      return styles;
    },
    titleIsHtmlElement() {
      return this.column.Title?.startsWith("<");
    },
    cellClasses() {
      const alignRightTypes = ["Decimal", "Int", "Money"];
      const classes = {
        hidden: !this.column.IsVisible ?? this.column.IsAdvancedColumn,
        [this.column.Editor ?? this.column.Type]: true,
      };

      if (
        alignRightTypes.includes(this.column.Type) &&
        (!this.column.Editor || alignRightTypes.includes(this.column.Editor))
      ) {
        classes["text-right"] = true;
      }

      return classes;
    },
    cellComponentName() {
      const cellFieldName =
        this.value?.Editor ?? this.column.Editor ?? this.column.Type;
      return `${
        cellFieldName.charAt(0).toUpperCase() + cellFieldName.substring(1)
      }`;
    },
    cellComponent() {
      return () =>
        import(`./datagridCellTypes/DatagridCell${this.cellComponentName}.vue`);
    },
  },
  mounted() {
    this.ro = new ResizeObserver(this.onResize);
    this.ro.observe(this.$refs.column);
  },
  beforeDestroy() {
    this.ro.unobserve(this.$refs.column);
  },
  methods: {
    onResize() {
      const style = this.$refs.column?.attributes?.style?.nodeValue;

      if (style) {
        this.$emit("column-resize", {column: this.column, style});
      }
    },
    handleClick() {
      this.$emit("column-click", this.column);
    },
    handleChange($event) {
      this.$emit("column-header-change", {column: this.column, value: $event});
    },
  },
};
</script>

<style scoped>
th {
  white-space: nowrap;
  font-size: 15.4px;
  font-weight: 600;

  box-shadow: inset 0 1px 0 1px #dee2e6, inset 0 -1px 0 -1px #dee2e6;
}

th.Select {
  width: 200px;
}

.hidden {
  display: none;
}
</style>
