import {isDistributionReceiverRow} from "./isDistributionReceiverRow";
import Decimal from "decimal.js";
import {getTotalExcVatIncludingNonReceiverRelatedRows} from "./getTotalExcVatIncludingNonReceiverRelatedRows";

export const calculateWeighedInboundValue = ({
  row,
  rows,
  receiverRowsTotalValue,
  costsToDistribute,
}) => {
  if (isDistributionReceiverRow(row)) {
    const totalExcVat = getTotalExcVatIncludingNonReceiverRelatedRows({
      row,
      rows,
    });
    return new Decimal(totalExcVat)
      .dividedBy(receiverRowsTotalValue)
      .times(costsToDistribute)
      .times(100)
      .round()
      .dividedBy(100)
      .plus(totalExcVat)
      .toNumber();
  }
  return 0;
};
