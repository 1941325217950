import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export function processFreeRow({row}) {
  const newRow = {...row};
  const itemType = getRawValueFromDropdownValue(newRow.ItemType.Value);
  const isFreeRow = itemType === "Free";

  if (isFreeRow) {
    newRow.DateTimeExpectedStart.IsReadOnly = true;
    newRow.DateTimeExpectedEnd.IsReadOnly = true;

    newRow.DateTimeBusinessStart.IsReadOnly = true;
    newRow.DateTimeBusinessEnd.IsReadOnly = true;

    newRow.ItemID.Editor = "String";

    newRow.Period.IsReadOnly = true;
    newRow.PeriodAmount.IsReadOnly = true;
    newRow.PeriodPrice.IsReadOnly = true;
    newRow.Price.IsReadOnly = false;
    newRow.Discount.IsReadOnly = false;

    newRow.ItemID.openRef = false;
    newRow.ItemID.IsReadOnly = false;
    newRow.ItemDescription.IsReadOnly = false;

    if (newRow.ItemID.Value?.Key) {
      newRow.ItemID = {
        ...newRow.ItemID,
        Value: newRow.ItemID.Value.Key,
      };
    }

    newRow.rowMeta = {
      ...newRow.rowMeta,
      deletable: true,
    };
  }

  return newRow;
}
