import {sortRowsByAlphabet} from "./sortRowsByAlphabet";
import {sortRowsByDate} from "./sortRowsByDate";

export function sortRowsByColumn({rows, column}) {
  const sortedRows = rows.slice();

  if (column.Type === "date") {
    return sortRowsByDate({rows: sortedRows, column});
  }

  return sortRowsByAlphabet({rows: sortedRows, column});
}
