import {api} from "../../api";
import store from "../../../../state/store";

export async function getPrice({
  itemId,
  startDate,
  endDate,
  customerId,
  orderId,
  orderItemId,
  contractId,
  discount,
}) {
  const result = await api.get(
    `/v2/rental/item/${encodeURIComponent(itemId)}/price/${encodeURIComponent(
      startDate,
    )}/${encodeURIComponent(endDate)}`,
    {
      windowId: global.session?.activeWindow.id ?? "",
      params: {
        WarehouseID: store.state.activeWarehouse,
        CustomerID: customerId,
        OrderID: orderId,
        OrderItemID: orderItemId,
        ContractID: contractId,
        Discount: discount,
      },
    },
  );

  return result.data;
}
