export function formatRowToKeyValuePairs(row) {
  const formattedRow = {};

  Object.keys(row).forEach((key) => {
    // check if row[key].Value is object
    if (typeof row[key] === "object") {
      formattedRow[key] = row[key].Value;
    } else {
      formattedRow[key] = row[key];
    }
  });
  return formattedRow;
}
