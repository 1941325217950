import {sumValues} from "../../../../util/math/sumValues";
import {getInboundValueValidationMessage} from "./validate-inbound-value/getInboundValueValidationMessage";

export const validateInboundValueOnSave = ({rows, columns}) => {
  if (!columns.some((column) => column.Name === "InboundValue")) {
    return null;
  }
  const totalExcVat = sumValues(rows.map((row) => row.TotalExcVAT?.Value ?? 0));
  const totalInboundValue = sumValues(
    rows.map((row) => row.InboundValue?.Value ?? 0),
  );

  if (totalExcVat !== totalInboundValue) {
    return getInboundValueValidationMessage({
      totalInboundValue,
      totalExcVat,
    });
  }
  return null;
};
