export const processFormPostbackMetadata = ({newData}) => {
  const newColumns = {...newData.Columns};
  const newMetadata = newData.Metadata?.[0];

  if (!newMetadata || !newColumns) return newData;

  Object.entries(newMetadata).forEach(([key, value]) => {
    if (value.Description && newColumns[key])
      newColumns[key].Label = value.Description;

    if (value.IsReadOnly && newColumns[key])
      newColumns[key].IsReadOnly = value.IsReadOnly;
  });

  return newData;
};
