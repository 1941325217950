import {setValuesInRow} from "../../row/setValuesInRow";
import {isDistributionReceiverRow} from "./isDistributionReceiverRow";
import {isRelatedRow} from "../../row/isRelatedRow";
import {isSameRow} from "../../row/isSameRow";
import {getTotalExcVatIncludingNonReceiverRelatedRows} from "./getTotalExcVatIncludingNonReceiverRelatedRows";

export const distributeCostsForRelatedRows = ({rows, row}) => {
  return rows.map((rowToUpdate) => {
    if (
      row &&
      !isSameRow({a: row, b: rowToUpdate}) &&
      !isRelatedRow({rowToMatch: row, rowToCheck: rowToUpdate})
    ) {
      return rowToUpdate;
    }
    const isReceiver = isDistributionReceiverRow(rowToUpdate);
    const newValue = getTotalExcVatIncludingNonReceiverRelatedRows({
      rows,
      row: rowToUpdate,
    });
    return setValuesInRow({
      row: rowToUpdate,
      values: {InboundValue: isReceiver ? newValue : 0},
    });
  });
};
