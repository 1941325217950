<template>
  <div>
    <div class="font-bold">
      {{ formatDate(priceCalculation.StartDate) }} -
      {{ formatDate(priceCalculation.EndDate) }}
      <hr class="bg-white mt-0" />
    </div>
    <div>
      <table>
        <tr v-for="period in priceCalculation.Periods" :key="period.PriceId">
          <td class="flex justify-between">
            <div class="mr-1">
              {{ period.Period }}
            </div>
            <div>
              ({{ formatPopupPrice(period.PriceGross ?? period.Price) }})
            </div>
          </td>
          <td class="opacity-60 text-center">X</td>

          <td class="text-center">
            {{ period.Amount }}
          </td>
          <td class="opacity-60 text-center">=</td>
          <td class="text-right">
            {{
              getTotalPeriodPrice({
                price: period.PriceGross ?? period.Price,
                amount: period.Amount,
              })
            }}
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-2">
      <hr class="bg-white w-full mt-0" />
      <div class="flex">
        <div class="w-1/2">{{ translations["Total"] }}</div>

        <div class="w-1/2 text-right">{{ totalExcVat }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatPrice} from "@/util/formatting/formatPrice";
import Decimal from "decimal.js";
import dayjs from "dayjs";

export default {
  name: "DatagridPopupPriceCalculation",
  inject: ["translations"],
  props: {
    priceCalculation: {
      type: Object,
      required: true,
    },
    rate: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    totalExcVat() {
      let total = new Decimal(0);

      for (const price of this.priceCalculation.Periods) {
        const periodPrice = new Decimal(
          parseFloat(price.PriceGross ?? price.Price),
        )
          .times(this.rate)
          .times(parseFloat(price.Amount));

        total = total.plus(periodPrice);
      }

      return formatPrice(total.toNumber());
    },
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD-MM-YYYY");
    },
    formatPopupPrice(price) {
      return formatPrice(new Decimal(price).times(this.rate).toNumber());
    },
    getTotalPeriodPrice({price, amount}) {
      return formatPrice(
        new Decimal(price).times(this.rate).mul(amount).toNumber(),
      );
    },
  },
};
</script>

<style scoped>
div {
  font-variant-numeric: tabular-nums;
}
td {
  min-width: 25px;
}
</style>
