import {getRowConflict} from "./getRowConflict";

export function getRowConflicts({rows}) {
  const rowConflicts = [];

  for (const key in rows) {
    const row = rows[key];

    const rowConflict = getRowConflict({row, rows});
    if (rowConflict) {
      rowConflicts.push(rowConflict);
    }
  }

  return rowConflicts;
}
