<template>
  <tree-select
    v-model="value"
    :multiple="multiple"
    :options="options"
    value-consists-of="ALL_WITH_INDETERMINATE"
  />
</template>

<script>
import TreeSelect from "vue-treeselect/dist/vue-treeselect.cjs.js";
import "vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "MultiTreeSelectField",
  components: {TreeSelect},
  props: {
    field: {
      type: Object,
      required: true,
    },
    fetchOptions: {
      type: Function,
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      value: this.field.Value,
      options: [],
    };
  },
  watch: {
    field: {
      handler(newValue) {
        this.value = newValue.Value;
      },
      deep: true,
    },
    value(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted() {
    this.setOptions();
  },
  methods: {
    async setOptions() {
      if (this.field.fetchOptions) {
        this.options = await this.field.fetchOptions();
        return;
      }
      this.options = this.field?.Dropdown?.Items;
    },
  },
};
</script>

<style></style>
