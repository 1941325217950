<template>
  <div class="bg-white shadow-sm rounded-lg">
    <div
      ref="cardContainer"
      class="px-3 border-l-4 border-solid border-r-0 border-b-0 border-t-0 pt-3 rounded-tl-lg pb-3"
      :style="borderStyle"
      :class="{
        'pb-3 rounded-bl-lg': !subTasks.length,
        'cursor-pointer': clickable,
      }"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      @click="clickAction"
    >
      <div class="flex justify-between">
        <div class="w-full">
          <div
            class="text-xs text-[#565656] font-semibold flex justify-between w-full"
          >
            <div>
              {{ task.Breadcrumb }}
            </div>
            <div v-if="loading.length">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </div>
          <div class="text-gray-700 font-semibold tracking-wide text-md">
            {{ task.Title }}
            <div v-if="task.IsQueued" class="inline-block ml-1">
              <tooltip :message="translations.TaskIsQueued">
                <i class="fad fa-business-time"></i>
              </tooltip>
            </div>
          </div>
          <div class="tracking-wide text-sm px-1 overflow-hidden">
            {{ task.Description }}
          </div>
          <slot name="card-body"
            ><div class="mt-1"></div>
            <div
              v-for="(property, index) in properties"
              :key="index"
              class="text-sm"
            >
              <span class="font-bold text-gray-600">{{ property.title }}:</span>
              {{
                translations[property.value]
                  ? translations[property.value]
                  : property.value
              }}
            </div>
          </slot>
        </div>

        <div class="flex">
          <kanban-card-resource
            v-for="(resource, index) in resources"
            :key="index"
            :resource="resource"
            :is-sub-task="false"
          />
        </div>
      </div>
      <span
        v-if="task.SubDescription"
        class="tracking-wide text-sm px-1 overflow-hidden"
      >
        {{ task.SubDescription }}
      </span>
      <kanban-card-planned-date
        :start-date="task.StartDate"
        :end-date="task.EndDate"
        :card="card"
      />
    </div>
    <div v-if="subTasks.length">
      <kanban-card-sub-card
        v-for="subTask in subTasks"
        :key="subTask.TaskID"
        :lane-color="lane.Color"
        :task="subTask"
      />
    </div>
  </div>
</template>
<script>
import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import action from "../../functions/action.js";

import KanbanCardPlannedDate from "./KanbanCardPlannedDate.vue";
import KanbanCardResource from "./KanbanCardResource.vue";
import KanbanCardSubCard from "./KanbanCardSubCard.vue";
import Tooltip from "../util/Tooltip.vue";
import {v4 as uuidv4} from "uuid";

export default {
  name: "KanbanCard",
  components: {
    KanbanCardPlannedDate,
    KanbanCardResource,
    KanbanCardSubCard,
    Tooltip,
  },
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    lane: {
      type: Object,
      default: () => ({}),
    },
    subTasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      properties: [],
      loading: [],
    };
  },
  computed: {
    resources() {
      return this.task.Resources;
    },
    task() {
      return this.card ?? {};
    },
    borderStyle() {
      return {
        "border-color": this.lane?.Color.substring(0, 7) ?? "#D3D3D3",
      };
    },
    clickable() {
      return this.task.ClickActions?.length > 0 ?? false;
    },
    translations() {
      return getTranslations();
    },
  },
  async mounted() {
    // wait 1 tick
    await this.$nextTick();
    this.createObserver();
  },
  beforeDestroy() {
    this.destroyObserver();
  },
  methods: {
    async getCardProperties({card, context}) {
      if (card.properties) return card.properties;
      if (card.getCardProperties)
        return await card.getCardProperties({card, ...context});
      return card.properties ?? [];
    },
    handleMouseDown() {
      this.startTime = new Date().getTime();
      this.isDragging = false;
    },
    handleMouseUp() {
      const endTime = new Date().getTime();
      if (endTime - this.startTime > 300) {
        this.isDragging = true;
      }
    },
    clickAction(e) {
      if (this.isDragging) {
        e.stopImmediatePropagation();
        return;
      }
      for (let actionData of this.task.ClickActions) {
        action(actionData);
      }
    },
    async createObserver() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1, // Adjust as needed
      };

      this.observer = new IntersectionObserver(this.handleIntersect, options);
      await this.$nextTick();
      this.observer.observe(this.$refs.cardContainer);
    },
    handleIntersect(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.handleVisibilityChange();
        }
      });
    },
    async getAndSetProperties() {
      this.properties = await this.getCardProperties({
        card: this.card,
        context: this.context,
      });
    },
    async handleVisibilityChange() {
      // Your code to execute when the element becomes visible

      if (this.properties.length) return;
      const genericUUID = uuidv4();
      this.loading.push(genericUUID);
      try {
        await this.getAndSetProperties();
      } catch (error) {
        console.error("Error getting card properties", error);
      } finally {
        this.loading = this.loading.filter((id) => id !== genericUUID);
      }

      // Add any additional code you need here
    },
    destroyObserver() {
      if (this.observer) {
        this.observer.disconnect();
      }
    },
  },
};
</script>
