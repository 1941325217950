import {updateRowsWithSpecificItemPriceCondition} from "./updateRowsWithSpecificItemPriceCondition";
import {updateRowsWithGenericItemPriceCondition} from "./updateRowsWithGenericItemPriceCondition";

export const updateRowsWithItemPriceCondition = async ({
  priceCondition,
  rows,
  row,
  getRowsWrapper,
}) => {
  if (!priceCondition.ItemID?.Key) {
    return updateRowsWithGenericItemPriceCondition({
      priceCondition,
      row,
      rows,
      getRowsWrapper,
    });
  }
  return await updateRowsWithSpecificItemPriceCondition({
    rows,
    row,
    priceCondition,
    getRowsWrapper,
  });
};
