import {rowAmountChangeWrapper} from "../row/change-event-wrappers/rowAmountChangeWrapper";
import {rowsAmountChangeWrapper} from "./change-event-wrappers/rowsAmountChangeWrapper";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";

const translations = getTranslations();

export const rowsProcessVirtualRowAmountChange = async ({
  row,
  rows,
  quotation,
}) => {
  if (!row.rowMeta?.virtual) return rows;

  const compositionRows = rows.filter(
    (fRow) => fRow.Composition?.Value === row.Composition?.Value,
  );
  const missingCompositionItemAmounts = compositionRows.some((row) => {
    !row.CompositionItem?.Amount;
  });
  const rowIndex = rows.findIndex(
    (fRow) => fRow.ClientSideUUID.Value === row.ClientSideUUID.Value,
  );

  if (missingCompositionItemAmounts) {
    return [
      ...rows.slice(0, rowIndex),
      {
        ...row,
        Amount: {
          ...row.Amount,
          IsReadOnly: true,
          Icon: "fa-exclamation-triangle",
          IconColor: "warning",
          IconMessage:
            translations[
              "Rental.OrderItem-MultiEdit-PopupCompositionAmountEditedOnCompositionTitle"
            ],
          IconSubMessage:
            translations[
              "Rental.OrderItem-MultiEdit-PopupCompositionAmountEditedOnCompositionDescription"
            ],
        },
      },
    ];
  }

  let modifiedRows = await Promise.all(
    rows.map(async (cRow) => {
      if (cRow.Composition?.Value !== row.Composition?.Value) return cRow;
      if (cRow.rowMeta?.virtual) return cRow;

      let newRow = {
        ...cRow,
        Amount: {
          ...cRow.Amount,
          Value: cRow.CompositionItem.Value.Amount * row.Amount.Value,
        },
      };

      return await rowAmountChangeWrapper({row: newRow, rows, quotation});
    }),
  );
  return await rowsAmountChangeWrapper({row, rows: modifiedRows, quotation});
};
