export function handleItemReturned({row}) {
  if (row?.DateTimeReturned?.Value) {
    const newRow = {...row};
    newRow.DateTimeBusinessEnd.IsReadOnly = true;
    newRow.DateTimeExpectedEnd.IsReadOnly = true;

    return newRow;
  }

  return row;
}
