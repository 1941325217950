export const sortByFilledInRows = ({rows}) => {
  const newRows = rows.slice();
  // sort rows by both AmountRejected.Value and AmountReceived.Value
  return newRows.sort((a, b) => {
    const aAmountRejected = a.AmountRejected.Value;
    const bAmountRejected = b.AmountRejected.Value;
    const aAmountReceived = a.AmountReceived.Value;
    const bAmountReceived = b.AmountReceived.Value;

    if (aAmountRejected === bAmountRejected) {
      return bAmountReceived - aAmountReceived;
    }

    return bAmountRejected - aAmountRejected;
  });
};
