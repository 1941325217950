import {hasDataHookEvent} from "../../../../hooks/hasDataHookEvent.js";
import {processCompositionDefinition} from "../../row/processCompositionDefinition.js";
import {rowsSetMetadataForCompositionRows} from "../../rows/rowsSetMetadataForCompositionRows.js";
import {rowsSetNullValueForMissingProps} from "../../rows/rowsSetNullValueForMissingProps.js";
import {updateRows} from "../../rows/updateRows.js";
import {getExtraTotalValues} from "../getExtraTotalValues.js";
import {rowsDataChanged} from "../rows/rows-process-webhook/rowsDataChanged.js";
import {rowsProcessWebhook} from "../rows/rowsProcessWebhook.js";

export const handleRowsUpdated = async ({rows, vueComponent, oldRows}) => {
  let rowsChanged = true;

  const rentalOrderItemPricesUpdatedDraftWebhookEvent = hasDataHookEvent({
    dataWebHooks: vueComponent.dataWebhooks,
    eventId: "rental.orderitem.prices.updated.draft",
  });

  if (rentalOrderItemPricesUpdatedDraftWebhookEvent) {
    rowsChanged = rowsDataChanged({
      oldRows,
      currentRows: rows,
    });
  }

  let newRows = rows;
  if (rentalOrderItemPricesUpdatedDraftWebhookEvent && rowsChanged) {
    newRows = await rowsProcessWebhook({
      rows: newRows,
      order: vueComponent.order,
    });

    newRows = rowsSetMetadataForCompositionRows({rows: newRows});
    newRows = rowsSetNullValueForMissingProps({
      rows: newRows,
      columns: vueComponent.columns,
    });
    newRows = newRows.map((row) => processCompositionDefinition({row}));
  }

  updateRows({newRows, vueComponent, oldRows});

  vueComponent.extraTotalValues = {
    ...(await getExtraTotalValues({
      rows: vueComponent.rows.filter((row) => !row.rowMeta?.virtual),
      order: vueComponent.order,
      dataWebHooks: vueComponent.dataWebhooks,
    })),
  };
};
