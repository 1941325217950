import {getHighestRankFromRows} from "@/functions/datagrid/rows/getHighestRankFromRows";

export function assignRankingToNewRow({rows, preferredRanking}) {
  let highestRank = 0;
  highestRank = getHighestRankFromRows({rows: rows});

  if (
    preferredRanking !== undefined &&
    preferredRanking !== null &&
    !rows.some((row) => row.Ranking?.Value === preferredRanking)
  ) {
    return preferredRanking;
  }

  return highestRank + 10 - (highestRank % 10);
}
