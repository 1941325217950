import {getEntities} from "@/services/v2/core/railroad-task/filter-option/getEntities";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";

export async function getEntityFilterOptionValues() {
  const {Collection} = await getEntities();

  const uniqueTableNames = [
    ...new Set(Collection.map((entity) => entity.FullTableName)),
  ];
  const translations = await getTranslations();

  return uniqueTableNames.map((fullTableName) => {
    return {
      label: translations[fullTableName.replace("Rental.", "")],
      id: fullTableName,
      children: Collection.filter(
        (entity) => entity.FullTableName === fullTableName,
      ).map((entity) => {
        return {
          label: entity.Description,
          id: entity.EntityID,
        };
      }),
    };
  });
}
