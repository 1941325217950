import {sumValues} from "../../../../../util/math/sumValues";
import {isRelatedRow} from "../../row/isRelatedRow";
import {isDistributionReceiverRow} from "./isDistributionReceiverRow";

export const getValueOfNonReceiverRelatedRows = ({rows, row}) =>
  sumValues(
    rows
      .filter(
        (rowToFilter) =>
          isRelatedRow({rowToMatch: row, rowToCheck: rowToFilter}) &&
          !isDistributionReceiverRow(rowToFilter),
      )
      .map((rowToMap) => rowToMap.TotalExcVAT?.Value ?? 0),
  );
