export const removeIrrelevantPriceConditionRows = ({
  relevantPriceConditionRows,
  rows,
  row,
}) => {
  const orderItemID = row.OrderItemID.Value;
  return rows.filter(
    (orderItemRow) =>
      orderItemRow.RelatedOrderItemID.Value !== orderItemID ||
      relevantPriceConditionRows.includes(orderItemRow.OrderItemID.Value),
  );
};
