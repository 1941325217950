export function processSettingItemSinglePeriodDefault({columns}) {
  const newColumns = columns.slice();
  newColumns.forEach((column) => {
    if (column.Name === "PeriodAmount") {
      column.IsVisible = false;
    }
    if (column.Name === "Period") {
      column.IsVisible = false;
    }
    if (column.Name === "PeriodPrice") {
      column.IsVisible = false;
    }
  });

  return newColumns;
}
