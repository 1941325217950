export const processRowStatusOpen = ({row, openStatus}) => {
  let modifiedRow = {...row};

  if (modifiedRow.Status.Value === openStatus) {
    return {
      ...modifiedRow,
      ItemID: {
        ...modifiedRow.ItemID,
        IsReadOnly: true,
      },
      ItemDescription: {
        ...modifiedRow.ItemDescription,
        IsReadOnly: true,
      },
      TotalExcVAT: {
        ...modifiedRow.TotalExcVAT,
      },
    };
  }

  return modifiedRow;
};
