import {notify} from "../../../../util/notify";

export const serialIDExistenceCheck = ({rows, SerialID}) => {
  const serialIDExists = rows.find(
    (row) => row.SerialID.Value === SerialID && row.SerialID.Value,
  );

  if (serialIDExists) {
    notify({
      message: `SerialID ${SerialID} already exists in the list`,
      type: "warning",
    });
    return true;
  }
  return false;
};
