import {playBehaviorSound} from "../playBehaviorSound.js";
import {createAlertPromise} from "./createAlertPromise";

export const alertPopup = async ({text, icon, dangerMode, buttons, size}) => {
  playBehaviorSound({sound: icon});

  const result = await createAlertPromise({
    text,
    icon,
    dangerMode,
    buttons,
    size,
  });

  return result;
};
