import {getTranslations} from "@/functions/session/localstorage/getTranslations";

export const getTranslatedTitle = (data) => {
  if (!data) {
    return null;
  }
  if (data.Title) {
    return data.Title;
  }
  if (data.RawTitle) {
    return getTranslations()[data.RawTitle];
  }
  return null;
};
