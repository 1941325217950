import React from "react";

export function warehouseButton({dataItem, subject}) {
  if (subject == "Rental.virtual_ItemAvailability") {
    return (
      <button
        style={{marginLeft: "2px"}}
        className="btn btn-sm btn-secondary openItemWarehouseChart"
        data-item-description={dataItem.item.description}
        data-item-id={dataItem.item.itemID}
        data-function={"openWarehouseView"}
      >
        <i className="fas fa-warehouse" style={{pointerEvents: "none"}}></i>
      </button>
    );
  }
  return null;
}
