<template>
  <div class="h-[32.5px]">
    <r-select
      ref="select-field"
      :value="formattedSelectValue"
      :input-id="fieldName"
      :loading="isLoading"
      :read-only="readonly"
      :required="!required"
      :append-to-body="false"
      :disable-dropdown="disableDropdown"
      :clearable="clearable"
      :scanner-mode="scannerMode"
      :show-ref-button="showRefButton"
      :show-create-button="showCreateEntityButton"
      :has-calculated-position="true"
      :options="options"
      label="Text"
      @open="handleDropdownOpened"
      @input="handleInput"
      @search="handleSearch"
      @open-ref="handleRefClick"
      @keyup-enter="handleEnterKeyPress"
      @keyup-backspace="handleBackspacePress"
      @option-selected="handleChange"
      @option-deselected="handleChange"
      @open-create-entity="handleCreateEntityClick"
    />
  </div>
</template>

<script>
import {dropdownOptionsContainsSearchText} from "../../../functions/dropdownOptionsContainsSearchText.js";
import {getFormattedSelectFieldValue} from "../../../functions/fields/select-field/getFormattedSelectFieldValue.js";
import {newOptionsAreEqual} from "../../../functions/fields/select-field/newOptionsAreEqual.js";
import {openNewEntityForm} from "../../../functions/fields/select-field/openNewEntityForm.js";
import {openReference} from "../../../functions/fields/select-field/openReference.js";
import {getOptions} from "../../../functions/fields/select-field/getOptions.js";
import RSelect from "../../elements/RSelect.vue";

export default {
  name: "SelectField",
  components: {
    RSelect,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: false,
      default: () => {},
    },
    getOptionsCallback: {
      type: Function,
      required: false,
      default: null,
    },
    fetchOptions: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      searchText: "",
      inputElement: null,
      fieldWidth: null,
      options: [],
      loading: false,
      searchQueue: [],
      isProcessingSearch: false,
    };
  },
  computed: {
    value() {
      return this.field.Value;
    },
    formattedSelectValue() {
      return getFormattedSelectFieldValue({
        field: this.field,
        options: this.options,
      });
    },
    readonly() {
      return this.field.IsReadOnly;
    },
    required() {
      return this.field.IsRequired;
    },
    clearable() {
      return !!(
        (this.formattedSelectValue?.Value ?? this.formattedSelectValue) &&
        !this.required &&
        !this.readonly &&
        !this.isLoading
      );
    },
    showRefButton() {
      if (this.fieldWidth < 60) return false;
      return !!(
        this.field.Dropdown?.OpenRef &&
        this.field.Dropdown?.TableName &&
        !this.isLoading
      );
    },
    showCreateEntityButton() {
      if (this.fieldWidth < 60) return false;
      return !!(
        this.field?.Dropdown?.ShowCreateEntityButton &&
        this.field.Dropdown?.TableName &&
        !this.isLoading &&
        !this.value &&
        !this.readonly
      );
    },
    scannerMode() {
      return this.field.Dropdown?.ScannerMode;
    },
    isLoading() {
      return this.field.IsLoading || this.loading;
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    disableDropdown() {
      if (this.field.Dropdown?.HideNoOptionsPlaceholder) {
        return (
          !this.options.length ||
          !dropdownOptionsContainsSearchText({
            options: this.options,
            substring: this.searchText,
          })
        );
      }
      return false;
    },
  },
  created() {
    this.options = this.field.Dropdown?.Items ?? [];
  },
  async mounted() {
    const dropdownElement = this.$refs["select-field"].$refs["r-select"].$el;
    this.inputElement = dropdownElement.querySelector(".vs__search");
    this.fieldWidth = dropdownElement.scrollWidth;

    if (this.field.Dropdown?.AutoFocus) {
      return this.inputElement.focus();
    }

    const isDialogField = !!dropdownElement.closest(".vue-portal-target");
    if (this.field.Dropdown?.AutoFetchOptions || isDialogField) {
      return await this.loadOptions();
    }
  },
  methods: {
    async processSearchQueue() {
      if (this.isProcessingSearch || this.searchQueue.length === 0) return;

      this.isProcessingSearch = true;
      this.loading = true;

      try {
        // Process all searches in the queue except the last one
        while (this.searchQueue.length > 1) {
          await this.loadOptions(this.searchQueue.shift());
        }

        // Process the last search
        if (this.searchQueue.length === 1) {
          await this.loadOptions(this.searchQueue[0]);
          this.searchQueue = []; // Clear the queue after processing the last item
        }
      } finally {
        this.loading = false;
        this.isProcessingSearch = false;

        // If there are new searches in the queue, process them
        if (this.searchQueue.length > 0) {
          this.processSearchQueue();
        }
      }
    },
    async loadOptions(searchValue) {
      this.loading = true;
      const options = this.getOptionsCallback
        ? await this.getOptionsCallback()
        : await getOptions({
            field: this.field,
            row: this.row,
            searchValue,
          });

      if (
        !newOptionsAreEqual({newOptions: options, oldOptions: this.options})
      ) {
        this.options = options;
      }

      this.loading = false;
      this.searchText = searchValue;
    },
    handleSearch(value) {
      this.searchQueue.push(value);
      this.$nextTick(this.processSearchQueue);
    },
    handleChange(value) {
      this.$emit("change", value);
    },
    async handleInput(value) {
      if (this.disableDropdown) return;
      if (!value) return this.$emit("input", null);

      const newValue = {
        Label: value?.Text,
        Value: value?.Value,
      };
      this.$emit("input", newValue);
    },
    async handleDropdownOpened() {
      if (!this.readonly) await this.loadOptions();
    },
    handleEnterKeyPress(event) {
      if (event?.target?.value)
        this.$emit("input:search-value", {event, value: event.target?.value});
    },
    handleBackspacePress() {
      if (
        this.value !== null &&
        this.searchText?.length < 1 &&
        this.required === false &&
        this.clearable === true
      )
        this.handleInput(null);
    },
    async handleRefClick() {
      await openReference({
        column: this.field,
        value: this.formattedSelectValue?.Value,
        windowId: this.field.Windowid,
      });
    },
    async handleCreateEntityClick() {
      await openNewEntityForm({
        column: this.field,
        windowId: this.field.Windowid,
      });
    },
  },
};
</script>

<style scoped></style>
