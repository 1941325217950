import {formatDecimal} from "./formatDecimal.js";

export function formatPrice(price) {
  if (
    price === null ||
    price === undefined ||
    typeof price === "string" ||
    typeof price === "object"
  ) {
    return "";
  }

  if (typeof price != "number") {
    throw new Error(`Price must be a number: ${price}`);
  }

  return formatDecimal(price);
}
