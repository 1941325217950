export const flattenKeyObjectsInRow = ({row}) => {
  const normalizedRow = {};
  Object.keys(row).forEach((key) => {
    if (row[key]?.Key) {
      normalizedRow[key] = row[key].Key;
    } else {
      normalizedRow[key] = row[key];
    }
  });
  return normalizedRow;
};
