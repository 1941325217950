import {handleTotalPriceChangeForRows} from "../inbound-orderitem/rows/handleTotalPriceChangeForRows";
import {handleVATCodeIDChangeOnRows} from "../inbound-orderitem/rows/handleVATCodeIDChangeOnRows";
import {handleRankingChange} from "@/functions/datagrid/row/handleRankingChange";
import {handleAmountChange} from "../row/handleAmountChange.js";

export function processColumnsMetadata({columns}) {
  const newColumns = columns.slice();
  newColumns.forEach((newColumn) => {
    newColumn.processValueChangeFunctions = [];
    newColumn.processValueChangeFunctionsForRows = [];

    if (newColumn.Name === "Ranking") {
      newColumn.processValueChangeFunctions.push(handleRankingChange);
    }

    if (newColumn.Name === "Amount") {
      newColumn.processValueChangeFunctions.push(handleAmountChange);
      newColumn.processValueChangeFunctionsForRows.push(
        handleTotalPriceChangeForRows({changedColumnName: "Amount"}),
      );
    }

    if (newColumn.Name === "Price") {
      newColumn.processValueChangeFunctionsForRows.push(
        handleTotalPriceChangeForRows({changedColumnName: "Price"}),
      );
    }

    if (newColumn.Name === "Discount") {
      newColumn.processValueChangeFunctionsForRows.push(
        handleTotalPriceChangeForRows({changedColumnName: "Discount"}),
      );
    }

    if (newColumn.Name === "TotalExcVAT") {
      newColumn.processValueChangeFunctionsForRows.push(
        handleTotalPriceChangeForRows({
          changedColumnName: "TotalExcVAT",
        }),
      );
    }

    if (newColumn.Name === "TotalVAT") {
      newColumn.processValueChangeFunctionsForRows.push(
        handleTotalPriceChangeForRows({
          changedColumnName: "TotalVAT",
        }),
      );
    }

    if (newColumn.Name === "VATCodeID") {
      newColumn.processValueChangeFunctionsForRows.push(
        handleVATCodeIDChangeOnRows,
        handleTotalPriceChangeForRows({changedColumnName: "VATCodeID"}),
      );
    }
  });
  return newColumns;
}
