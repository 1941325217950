import getTextColor from "../../../util/getContrastingTextColorByHex.js";

export const formatTreeSelectOptions = ({options}) => {
  // change Text property to label and Value property to id
  return options.map((option) => {
    const backgroundColor = option.Attributes.Color ?? null;
    let color = null;

    if (backgroundColor) {
      color = getTextColor(option.Attributes.Color);
    }

    return {
      label: option.Text,
      id: option.Value,
      color,
      backgroundColor,
    };
  });
};
