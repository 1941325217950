import {nonActionAxiosInstance} from "../../nonActionAxiosInstance.js";

export async function getClosingDays({warehouse, dateFrom, dateTo}) {
  return await nonActionAxiosInstance.get("/api/v2/closingdays", {
    params: {
      warehouse,
      dateFrom,
      dateTo,
    },
  });
}
