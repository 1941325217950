<template>
  <textarea-field
    :field="field"
    :calculate-remaining-characters-function="
      calculateRemainingCharactersSMSMessage
    "
  @input="handleInput"/>
</template>

<script>
import {calculateRemainingCharactersSMSMessage} from "../../../functions/form/input-field-logic/calculateRemainingCharactersSMSMessage.js";
import TextareaField from "./TextareaField.vue";

export default {
  name: "TextareaSMSField",
  components: {
    TextareaField,
  },
  props: {
    field: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  methods: {
    calculateRemainingCharactersSMSMessage,
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
textarea[readonly] {
  background-color: #eeeeee;
  cursor: not-allowed;
  color: #888;
}

textarea:focus {
  border-bottom: 1px solid #f26522 !important;
}

.textarea-normal {
  background-color: white;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%);
  border: 1px solid #ced4da !important;
  border-radius: 3px;
  padding-right: 5px !important;
  padding-left: 5px !important;
  min-height: 46px !important;
  overflow: hidden !important;
}

.textarea-red {
  background-color: white;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%);
  border: 1px solid #f87171 !important;
  border-radius: 3px;
  padding-right: 5px !important;
  padding-left: 5px !important;
  min-height: 46px !important;
  overflow: hidden !important;
}

.dirty-bottom-border {
  border-bottom-color: #555 !important;
}

.dirty-bottom-border:focus {
  border-bottom: 1px solid #f26522 !important;
}
</style>
