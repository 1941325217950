export function getPrice(value) {
  if (value == null || typeof value === "undefined") {
    return null;
  }

  if (typeof value === "number") return value;

  if (!isNaN(value)) return parseFloat(value);

  if (value.includes(",")) value = value.replace(",", "");

  if (value.includes("€")) value = value.replace("€", "");

  return parseFloat(value);
}
