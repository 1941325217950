<template>
  <ul class="pagination">
    <li
      class="button btn-secondary mx-1 material-icons"
      @click="handlePageChange(1)"
      :class="{'opacity-50 pointer-events-none': page === 1}"
    >
      
    </li>
    <li
      class="button btn-secondary mx-1 material-icons"
      @click="handlePageChange(page - 1)"
      :class="{'opacity-50 pointer-events-none': page === 1}"
    >
      
    </li>
    <li v-if="page > 2" class="page" @click="handlePageChange(page - 2)">
      {{ page - 2 }}
    </li>
    <li v-if="page > 1" class="page" @click="handlePageChange(page - 1)">
      {{ page - 1 }}
    </li>
    <li class="page current mx-1 px-2">{{ page }}</li>
    <li
      v-if="page < pages && count !== 0"
      class="page"
      @click="handlePageChange(page + 1)"
    >
      {{ page + 1 }}
    </li>
    <li
      v-if="page < pages - 1 && count !== 0"
      class="page"
      @click="handlePageChange(page + 2)"
    >
      {{ page + 2 }}
    </li>
    <li
      class="button btn-secondary mx-1 material-icons"
      :class="{'opacity-50 pointer-events-none': page === pages || count === 0}"
      :disabled="page === pages || count === 0"
      @click="handlePageChange(page + 1)"
    >
      
    </li>
    <li
      class="button btn-secondary mx-1 material-icons"
      :class="{'opacity-50 pointer-events-none': page === pages || count === 0}"
      @click="handlePageChange(pages)"
      :disabled="page === pages || count === 0"
    >
      
    </li>
  </ul>
</template>

<script>
export default {
  name: "DatagridPagination",
  props: {
    page: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },

    pageSize: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pages() {
      return Math.ceil(this.count / this.pageSize);
    },
  },
  methods: {
    handlePageChange(page) {
      if (page < 1 || page > this.pages || this.page === page) return;
      this.$emit("page-change", page);
    },
  },
};
</script>
