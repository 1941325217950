export const normalizePickListItemsResponse = ({response}) => {
  const Collection = response.data.Collection.map((row) => {
    let newRow = {...row}; // Copy the original row object
    for (let key in newRow) {
      if (typeof newRow[key] === "object" && newRow[key] !== null) {
        // Checking if the property is an object
        for (let innerKey in newRow[key]) {
          // Accessing keys in the nested object
          newRow[key] = newRow[key][innerKey]; // Set the value of the property to its key value
          break; // Break after setting the first key's value
        }
      }
    }
    newRow.ConditionDescription = row.ConditionID?.Description;
    newRow.ItemDescription = row.ItemID.Description;
    return newRow; // Return the modified row
  });

  return {
    ...response,
    data: {
      ...response.data,
      Collection,
    },
  };
};
