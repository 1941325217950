import {processGlobalActions} from "../../functions/window/processGlobalActions.js";
import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import {newScanRow} from "../datagrid.js";
import {saveTable} from "../datagrid.js";
import {changeRow} from "../datagrid.js";
import {notify} from "../../util/notify.js";

export const handleDatagridService = async ({operation, requestData}) => {
  let result;

  switch (operation) {
    case "newScanRow":
      result = await newScanRow(requestData);
      break;
    case "saveTable":
      result = await saveTable(requestData);
      break;
    case "changeRow":
      result = await changeRow(requestData);
      break;
    default:
      throw new Error(`Invalid operation: ${operation}`);
  }

  if (!result.data || typeof result.data === "string") {
    const translations = getTranslations();
    return notify({
      message: translations.SomethingWentWrongTryAgainLater,
      type: "error",
    });
  }

  if (result.data?.GlobalActions?.length)
    await processGlobalActions({globalActions: result.data.GlobalActions});

  return result;
};
