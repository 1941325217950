import {validateReturnItemRowCheck} from "./validateReturnItemRowCheck.js";

export async function handleReturnAmountChange({row, oldRow}) {
  if (
    !row.SerialID.Value &&
    row.Amount.Value > 0 &&
    oldRow.Amount.Value === 0 &&
    !(await validateReturnItemRowCheck({row}))
  ) {
    return oldRow;
  }

  if (
    row.Amount.Value < 0 ||
    isNaN(row.Amount.Value) ||
    row.Amount.Value === ""
  ) {
    return {
      ...row,
      Amount: {
        Name: "Amount",
        Value: row.SerialID.Value ? 1 : 0,
      },
    };
  }

  if (row.Amount.Value > row.MaxAmountToReturn.Value) {
    return {
      ...row,
      Amount: {
        Name: "Amount",
        Value: row.MaxAmountToReturn.Value,
      },
      Checked: {
        ...row.Checked,
        Value: true,
      },
    };
  }

  return {
    ...row,
    Checked: {
      ...row.Checked,
      Value: true,
    },
  };
}
