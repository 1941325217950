<template>
  <div
    :class="[
      cellClasses,
      {
        '!text-[#888] !cursor-not-allowed': readonly,
        '!text-black !cursor-text': !readonly,
      },
    ]"
    @mousedown="
      $emit('cell-click', {row, column, clickEvent: $event, cell, name})
    "
  >
    <component
      :is="cellComponent"
      v-if="column.IsVisible"
      :value="cell"
      :classes="cellClasses"
      :column="column"
      :cell="cell"
      :columns="columns"
      :name="name"
      :row="row"
      :data-component="`datagrid-cell-${cellComponentName}`"
      @change="handleChange"
      @input="handleChange"
      @value-click="handleValueClick($event)"
    ></component>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    cell() {
      if (!this.value && !this.name.includes("GapFiller")) {
        console.error(
          `DatagridCell: No value (cell) found for column ${
            this.name
          } in row ${JSON.stringify(this.row)}`,
        );
        return {Value: ""};
      }
      return this.value;
    },
    column() {
      const column =
        this.columns.find((column) => column.Name === this.name) ?? {};
      return {...column};
    },
    cellClasses() {
      const classes = {
        hidden: !this.column?.IsVisible ?? false,
        [this.column.Type]: true,
        ...this.cell?.meta?.classes,
        " h-full": true,
      };

      return classes;
    },
    primaryKey() {
      return Object.values(this.columns).find((c) => c.IsPrimaryKey === true)
        ?.Name;
    },
    cellComponentName() {
      const cellFieldName =
        this.value?.Editor ?? this.column.Editor ?? this.column.Type;

      return `${
        cellFieldName.charAt(0).toUpperCase() + cellFieldName.substring(1)
      }`;
    },
    cellComponent() {
      return () =>
        import(`./datagridCellTypes/DatagridCell${this.cellComponentName}.vue`);
    },
    readonly() {
      return this.cell?.IsReadOnly ?? this.column.IsReadOnly === true;
    },
  },
  methods: {
    handleValueClick(event) {
      this.$emit("value-click", {
        row: this.row,
        column: this.column,
        event,
      });
    },
    handleChange(newValue) {
      this.$emit("change", {
        Name: this.name,
        ...newValue,
      });
    },
  },
};
</script>
