import {getTranslations} from "../../../../session/getTranslations";
import {formatDecimal} from "../../../../../util/formatting/formatDecimal";

export const getInboundValueValidationMessage = ({
  totalInboundValue,
  totalExcVat,
}) => {
  const translations = getTranslations();
  const message =
    totalInboundValue > totalExcVat
      ? translations.DistributionErrorTotalCostsHigherThenTotalExcVAT
      : translations.DistributionErrorTotalCostsLowerThenTotalExcVAT;

  return (message ?? "")
    .replace("{TotalInboundValue}", formatDecimal(totalInboundValue))
    .replace("{TotalExcVAT}", formatDecimal(totalExcVat));
};
