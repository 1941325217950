import {advancedReturnItemColumnIsExcluded} from "../return-item/advancedReturnItemColumnIsExcluded.js";
import {advancedOrderItemColumnIsExcluded} from "../order-item/advancedOrderItemColumnIsExcluded.js";

export function advancedColumnIsExcluded({processContext, column, row}) {
  if (Object.keys(processContext)[0] === "order") {
    return advancedOrderItemColumnIsExcluded({column, row});
  }

  if (processContext.context === "ReturnItem") {
    return advancedReturnItemColumnIsExcluded({column, row});
  }

  return false;
}
