export const restoreFiltersValuesFromFilters = ({
  filters,
  filtersToRestore,
}) => {
  if (!filtersToRestore) return filters;
  return filters?.map((filter) => {
    const filterToRestore = filtersToRestore.find(
      (f) => f.Target === filter.Target,
    );
    if (filterToRestore) {
      return {...filter, Selected: filterToRestore?.Selected};
    }
    return filter;
  });
};
