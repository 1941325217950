var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"z-50 bg-white"},[_c('div',{staticClass:"flex justify-end mb-3 mt-1 items-center"},[_c('div',[_c('label',{staticClass:"form-field min-w-[200px]"},[_c('div',{staticClass:"form-label ml-0 pl-0"},[_c('i',{staticClass:"fas fa-clipboard-list"}),_vm._v(" "+_vm._s(_vm.translations["Warehouse"])+": ")]),_c('r-select',{attrs:{"value":_vm.warehouse,"clearable":false,"read-only":true}})],1)])]),_c('div',{staticClass:"border border-dashed border-gray-900/25 rounded-md p-1"},[_c('div',{staticClass:"min-h-[600px] pt-2 m-4 flex"},[_c('div',[_c('div',{staticClass:"text-center flex"},[_c('div',{staticClass:"mr-4"},[_c('p',{attrs:{"title":_vm.itemLabel}},[_vm._v(" "+_vm._s(_vm.translations["ItemID"])+". "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.sliceWithEllipsis(_vm.itemLabel, 35)))])])]),_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.translations["AvailabilityList_SelectedPeriod"])),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.startDate + " - " + _vm.endDate))])])])]),_c('div',{staticClass:"flex justify-center"},_vm._l((_vm.items),function(item,index){return _c('table',{key:index,staticClass:"table table-sm",class:{'!mr-6 min-w-[250px]': index === 0 && _vm.items.length > 1}},[_c('tbody',[_c('tr',{staticClass:"text-right font-bold",staticStyle:{"border-top":"1px solid #dee2e6"}},[_c('td',{class:{
                    'min-w-[80px]': index !== 0,
                    'bg-gray-100': index === 0,
                  },attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(item[0].value)+" ")])]),_vm._l((item.slice(1)),function(property,ranking){return _c('tr',{key:property.title},[(index === 0)?_c('td',{staticClass:"text-nowrap bg-gray-100",class:{'font-bold': ranking === 10}},[_vm._v(" "+_vm._s(property.title)+" ")]):_vm._e(),_c('td',{staticClass:"text-right",class:[
                    _vm.computedValueClass(ranking),
                    {'pl-8 bg-gray-100': index === 0},
                  ]},[_vm._v(" "+_vm._s(property.value ?? 0)+" ")])])})],2)])}),0)])])]),_c('div',{staticClass:"my-1"},[_c('r-button',{staticClass:"m-0 mt-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('close-item-stock-dialog', $event)}}},[_c('i',{staticClass:"fas fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.translations["Back"])+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }