import {setReturnItemFormFieldsWhenValueRemoved} from "./setReturnItemFormFieldsWhenValueRemoved.js";
import {setReturnItemFormFieldsWhenValueChanged} from "./setReturnItemFormFieldsWhenValueChanged.js";
import {setReturnScanboxItems} from "./setReturnScanboxItems.js";
import store from "../../../../state/store.js";

export function processReturnItemValueChange({
  event,
  formFields,
  rows,
  isValueEmpty,
}) {
  const {DefaultSelectedReturnType} = store.state.settings ?? null;
  let newFormFields = {...formFields};

  newFormFields = setReturnScanboxItems({
    formFields: newFormFields,
    rows: rows,
  });

  if (!isValueEmpty)
    return setReturnItemFormFieldsWhenValueChanged({newFormFields, event});

  // When a DefaultSelectedReturnType is selected, you are allowed to scan items without selecting a Value
  if (isValueEmpty && !DefaultSelectedReturnType)
    newFormFields = {
      ...newFormFields,
      Scan: {
        ...newFormFields.Scan,
        IsReadOnly: true,
      },
    };

  if (DefaultSelectedReturnType) return newFormFields;

  if (isValueEmpty)
    return setReturnItemFormFieldsWhenValueRemoved({newFormFields});
}
