import {validateReturnItemRowCheck} from "./validateReturnItemRowCheck.js";
import {sortReturnItemRows} from "./sortReturnItemRows.js";
import {toggleHeaderCheckboxState} from "./toggleHeaderCheckboxState.js";

export async function toggleAllRowsChecked({rows, value, vueComponent}) {
  if (value === false)
    return sortReturnItemRows({
      rows: rows.map((row) => ({
        ...row,
        Checked: {...row.Checked, Value: false},
      })),
    });

  vueComponent.loadingPage = true;
  const iteratedReturnConfirmations = [];
  let checkAllRows = true;

  for (const row of rows) {
    if (
      returnConfirmationHasAlreadyBeenConfirmed({
        iteratedReturnConfirmations,
        row,
      })
    ) {
      await validateReturnItemRowCheck({ignoreReturnConfirmation: true, row});
      continue;
    }

    if (!(await validateReturnItemRowCheck({row}))) {
      checkAllRows = false;
      break;
    }

    iteratedReturnConfirmations.push(row.ReturnConfirmation?.Value);
  }

  vueComponent.loadingPage = false;
  if (!checkAllRows) {
    toggleHeaderCheckboxState({vueComponent, value: false});

    return sortReturnItemRows({
      rows: rows.map((row) => ({
        ...row,
        Checked: {
          ...row.Checked,
          Value:
            row.Checked.Value ||
            iteratedReturnConfirmations.includes(row.ReturnConfirmation?.Value),
        },
      })),
    });
  }

  return sortReturnItemRows({
    rows: rows.map((row) => ({
      ...row,
      Checked: {...row.Checked, Value: true},
    })),
  });
}

const returnConfirmationHasAlreadyBeenConfirmed = ({
  iteratedReturnConfirmations,
  row,
}) => {
  return iteratedReturnConfirmations.some(
    (confirmation) => row.ReturnConfirmation?.Value === confirmation,
  );
};
