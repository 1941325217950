export function processInitialPeriodPrice({row}) {
  const newRow = {...row};

  if (row.Period.Value) {
    const periodPrice = row.PeriodPrices.Value.find((periodPrice) => {
      return periodPrice.PeriodID === row.Period.Value;
    });

    if (periodPrice) {
      row.PeriodPrice.Value = periodPrice.Price;
    }
  }

  return newRow;
}
