import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {filterRowForStockCheck} from "../../row/filterRowForStockCheck.js";
import {getStockForOrderItems} from "../stock/getStockForOrderItems";
import DataLoader from "dataloader";

export async function rowsStockFetch({rows, order}) {
  const stockLoader = new DataLoader((rowsToLoad) =>
    getStockForOrderItems({rowsToLoad, order}),
  );

  return Promise.all(
    rows.map(async (row, index) => {
      if (
        row.rowMeta?.virtual ||
        getRawValueFromDropdownValue(row.ItemType?.Value) === "Free" ||
        row.OrderItemID?.Value?.toString().includes("_") ||
        !filterRowForStockCheck(row)
      ) {
        return row;
      }

      const orderItemStockPrediction = await stockLoader.load(row);

      if (
        !orderItemStockPrediction ||
        orderItemStockPrediction instanceof Error
      ) {
        return row;
      }

      if (orderItemStockPrediction.OrderItemID !== row.OrderItemID.Value) {
        throw new Error(
          `Stock prediction is out of order! ${orderItemStockPrediction.OrderItemID} != ${row.OrderItemID.Value} (index ${index})`,
        );
      }

      return {
        ...row,
        rowMeta: {
          ...row.rowMeta,
          stock: orderItemStockPrediction.ItemStock,
        },
      };
    }),
  );
}
