<template>
  <a
    class="btn btn-link"
    :class="{
      'text-danger cursor-pointer': deletable !== false,
      'text-muted pointer-events-none cursor-not-allowed': deletable === false,
    }"
    @click="deleteRow"
  >
    <i class="fas fa-trash"></i>
  </a>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    columns: {
      type: Array,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deletable() {
      return this.row.rowMeta?.deletable;
    },
  },
  methods: {
    deleteRow() {
      if (this.deletable !== false) {
        this.$emit("action", {name: "deleteRow", row: this.row});
      }
    },
  },
};
</script>

<style scoped>
td {
  white-space: nowrap;
}

input {
  height: 31px;
  box-shadow: 0 !important;
  border: none;
  background-color: transparent;
}
</style>
