import {setRowMetaData} from "./setRowMetaData";

export function setRowsMetaData({rows}) {
  let newRows = rows.slice();

  newRows = newRows.map((row) => {
    return setRowMetaData({row});
  });

  return newRows;
}
