import {setRowValues} from "@/functions/datagrid/order-item/rows/rows-process-webhook/setRowValues";
import {flattenCustomFieldObject} from "@/functions/datagrid/order-item/rows/rows-process-webhook/flattenCustomFieldObject";
import {setRowsCustomPropertiesToCustomPropertyObject} from "@/functions/datagrid/rows/setRowsCustomPropertiesToCustomPropertyObject";

export const rowsProcessWebhookResult = ({oldRows, newRows}) => {
  let virtualRowsSkipped = 0;
  const rowsWithoutCustomFieldsObject = oldRows.map((currentRow, index) => {
    if (currentRow?.rowMeta?.virtual === true) {
      virtualRowsSkipped++;
      return currentRow;
    }

    return setRowValues({
      row: currentRow,
      values: flattenCustomFieldObject({
        row: newRows[index - virtualRowsSkipped],
      }),
    });
  });

  return setRowsCustomPropertiesToCustomPropertyObject({
    rows: rowsWithoutCustomFieldsObject,
  });
};
