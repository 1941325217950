export const createRowsBasedOnQuantity = ({formattedRow}) => {
  let rowsToReturn = [];
  for (let i = 0; i < formattedRow.OrderedQuantity; i++) {
    rowsToReturn.push({
      ...formattedRow,
      OrderedQuantity: 1,
      PickedQuantity: i < formattedRow.PickedQuantity ? 1 : 0,
      Status: i < formattedRow.PickedQuantity ? "Picked" : "Open",
      StatusCode: i < formattedRow.PickedQuantity ? 150 : 100,
      PickListItemID:
        formattedRow.PickListItemIDs[i - 1] ?? formattedRow.PickListItemID,
    });
  }
  return rowsToReturn;
};
