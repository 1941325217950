export const processDatagridDatepickers = ({columns}) => {
  const closingDaysColumns = ["DateTimeExpectedStart", "DateTimeExpectedEnd"];

  return columns.map((column) => {
    return {
      ...column,
      FilterOnOpeningsDay: closingDaysColumns.includes(column.Name),
    };
  });
};
