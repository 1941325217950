export const setFiltersBasedOnViewFilters = ({viewFilters, filters}) => {
  const filteredTable = viewFilters[0].Selected;

  if (!filteredTable)
    return filters.map((filter) => {
      if (filter.Id === "TableName") {
        return {
          ...filter,
          Selected: [],
          readOnly: false,
          Hidden: false,
        };
      }

      return filter;
    });

  return filters.map((filter) => {
    if (filter.Id === "TableName") {
      return {
        ...filter,
        readOnly: true,
        Selected: [filteredTable],
      };
    }
    if (filter.Id === "ResourceID") {
      return {
        ...filter,
        readOnly: true,
        Hidden: true,
        Selected: [],
      };
    }

    return filter;
  });
};
