import {cloneDeep} from "lodash";
import {getColumns} from "../../../../../functions/datagrid/columns/getColumns";
import {processColumnSettings} from "../../../../../functions/datagrid/columns/processColumnSettings";
import {processDatagridDatepickers} from "../../../../../functions/datagrid/columns/processDatagridDatepickers";
import {sortColumns} from "../../../../../functions/datagrid/columns/sortColumns";
import {setInitialDatagridDataToWindow} from "../../../../../functions/datagrid/data/setInitialDatagridDataToWindow";
import {processCustomerDimensions} from "../../../../../functions/datagrid/order-item/dimensions/processCustomerDimensions";
import {getExtraTotalValues} from "../../../../../functions/datagrid/order-item/getExtraTotalValues";
import {processColumnsMetaData} from "../../../../../functions/datagrid/order-item/processColumnsMetaData";
import {processOrderItemAdvancedColumns} from "../../../../../functions/datagrid/order-item/processOrderItemAdvancedColumns";
import {processOrderItemRowMetaData} from "../../../../../functions/datagrid/order-item/row/processOrderItemRowMetaData";
import {physicalItemRowsStockFetch} from "../../../../../functions/datagrid/order-item/rows/physicalItemRowsStockFetch";
import {rowsProcessWebhook} from "../../../../../functions/datagrid/order-item/rows/rowsProcessWebhook";
import {rowsStockFetch} from "../../../../../functions/datagrid/order-item/rows/rowsStockFetch";
import {setItemPickerDialogDates} from "../../../../../functions/datagrid/order-item/setItemPickerDialogDates";
import {convertKeyValueRowsToCellRows} from "../../../../../functions/datagrid/rows/convertKeyValueRowsToCellRows";
import {prepareRowsForRender} from "../../../../../functions/datagrid/rows/prepareRowsForRender";
import {setClientSideUUIDToRows} from "../../../../../functions/datagrid/rows/setClientSideUUIDToRows";
import {setCustomRowsPropertiesFromCustomPropertyObject} from "../../../../../functions/datagrid/rows/setCustomRowsPropertiesFromCustomPropertyObject";
import {sortRows} from "../../../../../functions/datagrid/rows/sortRows";
import {sanitizeServerRows} from "../../../../../functions/datagrid/sanitizeServerRows";
import {getDataWebhooks} from "../../../../../functions/session/localstorage/getDataWebhooks";
import getOrder from "../../../../../services/orders/getOrder";
import getOrderItems from "../../../../../services/orders/getOrderItems";

export const initializeDatagridOrderItem = async ({vueInstance}) => {
  vueInstance.dataWebhooks = getDataWebhooks();
  const rentalOrderItemPricesUpdatedDraftWebhookEvent =
    vueInstance.dataWebhooks.some(
      (webhook) => webhook.EventID === "rental.orderitem.prices.updated.draft",
    );

  vueInstance.orderId = vueInstance.rWindow.criteria[0].OrderID;

  let [orderItemCollection, dgColumns, order] = await Promise.all([
    getOrderItems({
      orderId: vueInstance.orderId,
      params: {
        include: `CompositionItem${
          !rentalOrderItemPricesUpdatedDraftWebhookEvent ? ",PeriodPrices" : ""
        }`,
      },
    }),
    getColumns({
      table: "Rental.OrderItem", //output.Request.Subject,
      prefix: "MultiEdit",
      primaryKey: "OrderID",
      request: vueInstance.rWindow.criteria,
    }),
    getOrder({orderId: vueInstance.orderId}),
  ]);

  vueInstance.order = order;
  vueInstance.itemPickerDialogDates = setItemPickerDialogDates({
    order: vueInstance.order,
  });

  dgColumns = processColumnSettings(dgColumns);
  dgColumns = processOrderItemAdvancedColumns(dgColumns);
  dgColumns = processDatagridDatepickers({columns: dgColumns});
  vueInstance.columns = sortColumns(dgColumns);

  let rows = await Promise.all(
    convertKeyValueRowsToCellRows(orderItemCollection.Collection).map((x) =>
      processOrderItemRowMetaData({row: x, order}),
    ),
  );
  rows = setClientSideUUIDToRows({rows});
  rows = setCustomRowsPropertiesFromCustomPropertyObject({
    rows,
  });

  rows = sortRows(rows);
  const processedData = await processCustomerDimensions({
    columns: vueInstance.columns,
    customerId: vueInstance.order.CustomerID?.Key,
    rows,
  });
  const columns = processedData.columns;
  rows = processedData.rows;

  await sanitizeServerRows({rows, columns});

  vueInstance.columns = columns;
  vueInstance.rows = rows;
  vueInstance.rows = await rowsStockFetch({rows, order}).then(
    async (rows) => await physicalItemRowsStockFetch({rows, order}),
  );

  rows = await vueInstance.loadAndApplyRowDataForPage({
    rows: vueInstance.rows,
    page: vueInstance.page,
    pageSize: vueInstance.pageSize,
    order: vueInstance.order,
  });

  if (rentalOrderItemPricesUpdatedDraftWebhookEvent) {
    rows = await rowsProcessWebhook({rows, order: vueInstance.order});
  }

  rows = prepareRowsForRender({rows, columns: columns});
  vueInstance.rows = rows;
  vueInstance.loaded = true;

  // wait 1 tick for the rows to be updated js
  await new Promise((r) => setTimeout(r, 0));
  vueInstance.columns = [].concat(
    await processColumnsMetaData({
      columns: vueInstance.columns,
      settings: vueInstance.settings,
    }),
  );

  // columns is an array make it into an object. Use column.Name as key
  const columnsObject = vueInstance.columns.reduce((acc, column) => {
    acc[column.Name] = column;
    return acc;
  }, {});

  vueInstance.originalRows = cloneDeep(vueInstance.rows);
  vueInstance.extraTotalValues = await getExtraTotalValues({
    rows: vueInstance.rows.filter((row) => !row.rowMeta?.virtual),
    order: vueInstance.order,
    dataWebHooks: vueInstance.dataWebhooks,
  });

  setInitialDatagridDataToWindow({
    window: vueInstance.rWindow,
    columns: columnsObject,
    rows: vueInstance.rows,
    vueInstance: vueInstance,
  });
};
