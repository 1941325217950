<template>
  <span>
    <r-button
      v-b-modal.modal-1
      :block="block"
      variant="primary"
      class="mb-2 mt-2"
      @click="show = true"
    >
      <div v-if="icon" class="float-left" v-html="icon" />
      {{ title }}</r-button
    >
    <two-factor-setup v-if="show" />
  </span>
</template>

<script>
import TwoFactorSetup from "../../../login/twoFactorSetup/loginTwoFactorSetup.vue";
import RButton from "../../../elements/RButton.vue";

export default {
  components: {
    TwoFactorSetup,
    RButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      show: false,
    };
  },
};
</script>

<style></style>
