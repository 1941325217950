import {getInboundInvoice} from "../../../services/v2/inbound/invoice/getInboundInvoice.js";
import {getInboundInvoiceItems} from "../../../services/v2/inbound/invoice/getInboundInvoiceItems.js";
import {getColumns} from "../columns/getColumns.js";
import {sortColumns} from "../columns/sortColumns.js";
import {processColumnsMetadata} from "../invoice-item/processColumnsMetadata.js";
import {convertKeyValueRowsToCellRows} from "../rows/convertKeyValueRowsToCellRows.js";
import {rowsSetNullValueForMissingProps} from "../rows/rowsSetNullValueForMissingProps.js";
import {setClientSideUUIDToRows} from "../rows/setClientSideUUIDToRows.js";
import {sortRows} from "../rows/sortRows.js";
import {processRowsMetadata} from "./rows/processRowsMetadata.js";
import {processRowsWithInvoiceStatus} from "./rows/processRowsWithInvoiceStatus";
import {getSupplier} from "../../../services/v2/suppliers/getSupplier";
import {getVatCodes} from "../../../services/v2/vat-code/getVatCodes";

export const getInboundInvoiceItemData = async ({
  invoiceId,
  parentWindowCriteria,
  settings,
}) => {
  const [
    invoiceResponse,
    invoiceItemResponse,
    fetchedColumns,
    vatCodesResponse,
  ] = await Promise.all([
    getInboundInvoice({invoiceId}),
    getInboundInvoiceItems({invoiceId, include: ["CompositionItem"]}),
    getColumns({
      table: "Inbound.InvoiceItem",
      prefix: "Multi",
      primaryKey: "InvoiceID",
      criteria: parentWindowCriteria,
    }),
    getVatCodes(),
  ]);

  const invoice = invoiceResponse.data;

  const supplierResponse = await getSupplier({
    supplierID: invoice.SupplierID.Key,
  });

  const rowsConverted = convertKeyValueRowsToCellRows(
    invoiceItemResponse.data.Collection,
  );

  const rowsIdentified = setClientSideUUIDToRows({rows: rowsConverted});

  const rowsMissingPropsNull = rowsSetNullValueForMissingProps({
    rows: rowsIdentified,
    columns: fetchedColumns,
  });

  const metadataProcessedRows = processRowsMetadata({
    rows: rowsMissingPropsNull,
    columns: fetchedColumns,
  });

  const processedRows = processRowsWithInvoiceStatus({
    invoice,
    rows: metadataProcessedRows,
  });

  const sortedColumns = sortColumns(fetchedColumns);
  const sortedRows = sortRows(processedRows);

  const metadataProcessedColumns = processColumnsMetadata({
    columns: sortedColumns,
    settings,
  });

  return {
    invoice,
    rows: sortedRows,
    columns: metadataProcessedColumns,
    supplier: supplierResponse.data,
    vatCodes: vatCodesResponse.data?.Collection ?? [],
  };
};
