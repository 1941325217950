<template>
  <label class="checkbox-wrapper flex items-center cursor-pointer p-0 m-0">
    <input
      v-if="preventDefaultEvent"
      :id="inputId"
      type="checkbox"
      class="!opacity-0 !h-0 !w-0 -z-1"
      :checked="checked"
      @change="handleChange"
      @click.prevent="noop()"
    />
    <input
      v-else
      :id="inputId"
      type="checkbox"
      class="!opacity-0 !h-0 !w-0 -z-1"
      :checked="checked"
      :disabled="isReadonly"
      @change="handleChange"
    />
    <span
      class="relative inline-block w-[15px] h-[15px] checkbox-border checkmark"
      :class="{
        readonly: isReadonly && checked,
        'unchecked-readonly': isReadonly && !checked,
      }"
    >
    </span>
  </label>
</template>

<script>
export default {
  Name: "RCheckbox",
  props: {
    value: {
      type: [Object, Boolean],
      default: null,
      required: false,
    },
    inputId: {
      type: String,
      default: "",
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    checked() {
      return this.value?.Value ?? this.value;
    },
    isReadonly() {
      return this.value?.IsReadOnly || this.readonly;
    },
    preventDefaultEvent() {
      return this.value?.DisableDefaultCheckboxBehaviour;
    },
  },
  methods: {
    handleChange($event) {
      return this.$emit("change", $event.target.checked);
    },
    noop() {
      // No operation (noop) function, does nothing
    },
  },
};
</script>

<style scoped>
.checkmark {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #f26522;
}

.checkbox-wrapper input:checked ~ .checkmark.readonly {
  background-color: rgb(223, 224, 226);
}

.checkbox-wrapper input:focus ~ .checkmark {
  box-shadow: 0px 0px 11px 1px rgba(238, 99, 46, 0.5) !important;
}

.checkbox-wrapper input:focus ~ .checkmark.readonly {
  box-shadow: none !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  left: 5.5px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-border {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.checkbox-wrapper input:checked ~ .checkbox-border {
  border: none !important;
  border-radius: 2px;
}

.checkbox-wrapper input:checked ~ .checkmark.readonly {
  background-color: rgb(223, 224, 226);
}

.checkbox-wrapper input:focus ~ .checkbox-border {
  border: 1px solid rgba(238, 99, 46, 0.3);
  border-radius: 2px;
}

.checkbox-wrapper input:focus ~ .checkmark.readonly {
  box-shadow: none !important;
}

.checkbox-wrapper .unchecked-readonly {
  cursor: not-allowed;
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
  border-radius: 2px;
}

.checkbox-wrapper .readonly {
  cursor: not-allowed;
}
</style>
