export function setReturnScanboxItems({formFields, rows}) {
  let seen = {};
  if (rows.length < 1)
    formFields.Scan = {
      ...formFields.Scan,
      Dropdown: {
        ...formFields.Scan.Dropdown,
        Items: [],
      },
    };

  const uncheckedRows = rows.filter((r) => !r.Checked?.Value);
  const scanItems =
    uncheckedRows
      .map((r) => ({
        Text:
          (r.SerialID?.Value || r.ItemID.Value) +
          " - " +
          r.ItemDescription.Value,
        Value: r.SerialID?.Value ?? r.ItemID.Value,
      }))
      .filter((x) => (seen[x.Value] = !Object.prototype.hasOwnProperty.call(seen, x.Value))) ?? [];

  if (rows.length >= 1)
    formFields.Scan = {
      ...formFields.Scan,
      Dropdown: {
          ...formFields.Scan.Dropdown,
        Items: scanItems,
      },
    };

  return formFields;
}
