import {findMatchingTab} from "./findMatchingTab";
import {updateWindowTabs} from "./updateWindowTabs";

export const setCorrectActiveTab = ({vueInstance}) => {
  if (!vueInstance.activeSubRWindow) return;

  const actionName = vueInstance.activeSubRWindow.request.TableActionName;
  const currentTabs = vueInstance.activeRWindow.tabs;
  const windowId = vueInstance.activeRWindow.id;

  if (!currentTabs) return;

  const updatedTabs = findMatchingTab({
    tabs: currentTabs,
    actionName,
  });

  const hasMatch = updatedTabs.some((tab) => tab.Selected);

  vueInstance.rWindows = updateWindowTabs({
    windows: vueInstance.rWindows,
    windowId,
    tabs: hasMatch ? updatedTabs : currentTabs,
  });
};
