export const removePricesFromRow = (row) => {
  return {
    ...row,
    Price: {...row.Price, Value: null},
    TotalExcVAT: {...row.TotalExcVAT, Value: null},
    TotalVAT: {...row.TotalVAT, Value: null},
    TotalIncVAT: {...row.TotalIncVAT, Value: null},
    PriceCalcReason: {...row.PriceCalcReason, Value: null},
    PriceProjection: {
      ...row.PriceProjection,
      PriceCalculation: null,
    },
  };
};
