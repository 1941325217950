/**
 * Insert sub, get's proxied to insertWindow
 * @param {Object} output - Window output
 * @param {boolean} noRender - should we render the window?
 * @param {boolean} noProcessing - Should we process the output?
 * @returns {Promise} Promise
 * DEPRECATED
 */
export default async function injectSub(
  output,
  noRender = false,
  noProcessing = false,
  mustConfirm = true,
) {
  const window = await this.insertWindow(
    output,
    true,
    noRender,
    noProcessing,
    mustConfirm,
  );

  global.eventBus.emit("afterFocusSub", window ?? this);
  return window;
}
