export function processSerialIdChange({row, oldRow}) {
  let newRow = {...row};
  if (newRow.SerialID.Value) {
    newRow.Amount = {...newRow.Amount, Value: 1, IsReadOnly: true};
    newRow.ConditionID = {...newRow.ConditionID, IsReadOnly: true};
  }

  if (!row.SerialID.Value && oldRow?.SerialID?.Value !== null) {
    newRow.Amount = {...newRow.Amount, IsReadOnly: false};
    newRow.ConditionID = {...newRow.ConditionID, IsReadOnly: false};
  }

  if (!row.SerialID.Value) {
    newRow.SerialID = {...newRow.SerialID, Icon: null};
  }

  return newRow;
}
