import {postPricesUpdatedDraftEvent} from "@/services/v2/orderitems/prices/postPricesUpdatedDraftEvent";
import {createPricesUpdateHookRequestBody} from "./rows-process-webhook/createPricesUpdateHookRequestBody";
import {rowsProcessWebhookResult} from "@/functions/datagrid/rows/rowsProcessWebhookResult";

export const rowsProcessWebhook = async ({rows, order, row}) => {
  if (row?.rowMeta?.virtual === true) return rows;

  const {data} = await postPricesUpdatedDraftEvent({
    context: createPricesUpdateHookRequestBody({context: order, rows}),
    event: "rowsProcessWebhook",
  });
  return rowsProcessWebhookResult({oldRows: rows, newRows: data.Rows});
};
