export function handleServiceCheckboxClick({rows, event}) {
  if (event.cell.IsReadOnly) return rows;

  const selectedRowId = event?.row.ClientSideUUID.Value;

  return rows.map((row) => {
    if (row?.ClientSideUUID?.Value === selectedRowId) {
      return {
        ...row,
        PerformServiceBeforeReturn: {
          ...row.PerformServiceBeforeReturn,
          Value: !event.row.PerformServiceBeforeReturn.Value,
        },
      };
    }

    return row;
  });
}
