import {parseStringToFloat} from "./parseStringToFloat.js";
import {constrainValue} from "./constrainValue.js";

export function parseDecimalInput({value, min, max}) {
  return constrainValue({
    value: parseStringToFloat(value),
    min: min ?? null,
    max: max ?? null,
  });
}
