export function createCompositionMapping(rows) {
  const compositionMapping = {};

  rows.forEach((row) => {
    const compositionValue = row.Composition.Value;
    if (compositionValue) {
      if (!compositionMapping[compositionValue]) {
        compositionMapping[compositionValue] = [];
      }
      compositionMapping[compositionValue].push(row);
    }
  });

  return compositionMapping;
}
