import {getOrderByID} from "../../../services/order.js";
import {formatTaskCardPropertyValue} from "./formatTaskCardPropertyValue.js";
import {getLegacyEnityByTableNameAndPrimaryKey} from "../../../data/getLegacyEnityByTableNameAndPrimaryKey.js";

export const getDataForTaskCard = async ({
  card,
  columns,
  tableName,
  displayedColumns,
}) => {
  if (!displayedColumns.length) return {};
  const primaryKeyColumnName = columns.find((c) => c.IsPrimaryKey).Name;
  const primaryKeyValue = card.Key;
  let windowObject = {};
  let orderObject = {};
  try {
    if (tableName === "Rental.Order") {
      orderObject = await getOrderByID(primaryKeyValue);
    } else {
      windowObject = await getLegacyEnityByTableNameAndPrimaryKey({
        tableName,
        primaryKeyColumnName,
        primaryKey: primaryKeyValue,
      });
    }
  } catch (error) {
    console.error(error);
    return {};
  }

  const entity = orderObject ?? windowObject.Sub.Data.Rows[0] ?? {};

  // return [{title: columns[key].Title, value: entity[key]}];

  return displayedColumns.map((columnName) => {
    const value =
      columnName in entity.CustomFields
        ? entity.CustomFields[columnName]
        : entity[columnName];

    return {
      title: columns.find((col) => col.Name === columnName).Title,
      value: formatTaskCardPropertyValue({
        value: value,
        column: columns.find((col) => col.Name === columnName),
      }),
    };
  });
};
