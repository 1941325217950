import {convertLegacyWindowToRWindow} from "../../../functions/window/convertLegacyWindowToRWindow";
import {correctSubWindowIdOnParentWindow} from "./correctSubWindowIdOnParentWindow";

export const updateWindowOverwriteRows = ({
  vueInstance,
  window,
  updateInitialState,
}) => {
  const existingWindow = vueInstance.rWindows[window.id] ?? {};

  vueInstance.rWindows = {
    ...vueInstance.rWindows,
    [window.id]: {
      ...(existingWindow ?? {}),
      ...convertLegacyWindowToRWindow({
        ...window,
        data: {...existingWindow.data, Rows: null},
        initialData: updateInitialState ? null : existingWindow.initialData,
      }),
    },
  };
  const rWindow = vueInstance.rWindows[window.id];

  if (!rWindow.parentWindowId) return;

  // ensure parent window has the correct subWindowId
  correctSubWindowIdOnParentWindow({
    vueInstance,
    subWindow: window,
  });
};
