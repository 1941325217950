import {fieldContainsExtraCriteria} from "./fieldContainsExtraCriteria.js";

export const formContainsFieldWithExtraCriteria = ({fields}) => {
  if (Array.isArray(fields)) {
    return fields.some((field) => fieldContainsExtraCriteria({field}));
  }

  return Object.values(fields).some((field) =>
    fieldContainsExtraCriteria({field}),
  );
};
