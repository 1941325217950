import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export function processFreeRow({row}) {
  const newRow = {...row};
  const itemType = getRawValueFromDropdownValue(newRow.ItemType.Value);
  const isFreeRow = itemType === "Free";

  if (isFreeRow) {
    newRow.Price.IsReadOnly = false;
    newRow.Discount.IsReadOnly = false;
    newRow.DateTimeExpectedStart.IsReadOnly = true;
    newRow.DateTimeExpectedEnd.IsReadOnly = true;

    newRow.DateTimeBusinessStart.IsReadOnly = true;
    newRow.DateTimeBusinessEnd.IsReadOnly = true;

    newRow.ConditionID.IsReadOnly = true;
    newRow.ThemeGroupID.IsReadOnly = true;
    newRow.SerialID.IsReadOnly = true;
    newRow.SerialID.Value = null;

    newRow.ItemID.Editor = "String";

    newRow.Period.IsReadOnly = true;
    newRow.PeriodPrice.IsReadOnly = true;
    newRow.PeriodAmount.IsReadOnly = true;

    newRow.ItemID.openRef = false;
    newRow.ItemID.IsReadOnly = false;
  }

  if (isFreeRow && !row?.DateTimeReturned?.Value) {
    newRow.ItemDescription.IsReadOnly = false;
  }

  if (isFreeRow && !row?.DateTimeShipped?.Value) {
    newRow.rowMeta = {
      ...newRow.rowMeta,
      deletable: true,
    };
  }

  return newRow;
}
