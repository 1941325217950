<template>
  <div class="bg-gray-100 text-right p-2 min-w-[300px]">
    <div v-if="title" class="text-left font-semibold pb-2">{{ title }}:</div>
    <div v-if="!extraTotals" class="flex justify-between">
      <div class="font-semibold">
        {{ translations["Rental.OrderItem-MultiEdit-TotalExcVat"] }}:
      </div>
      <div>{{ formatPrice(totalExcludingVat) }}</div>
    </div>
    <div v-if="!extraTotals" class="flex justify-between">
      <div class="font-semibold">
        {{ translations["Rental.OrderItem-MultiEdit-Vat"] }}:
      </div>
      <div>{{ formatPrice(totalVat) }}</div>
    </div>
    <div v-if="!extraTotals" class="flex justify-between">
      <div class="font-semibold">
        {{ translations["Rental.OrderItem-MultiEdit-TotalIncVat"] }}:
      </div>
      <div>{{ formatPrice(totalIncludingVat) }}</div>
    </div>
    <div v-if="!extraTotals" class="flex justify-between">
      <div class="font-semibold">
        {{ translations["Rental.OrderItem-MultiEdit-Balance"] }}:
      </div>
      <div>{{ formatPrice(balance) }}</div>
    </div>
    <div
      v-for="(value, key) in extraTotalValues"
      :key="key"
      class="flex justify-between"
    >
      <div class="font-semibold">{{ translations[key] || key }}:</div>
      <div>{{ formatPrice(convertToLocalCurrency(value, rows[0])) }}</div>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import {formatPrice} from "@/util/formatting/formatPrice";
import {sumValues} from "@/util/math/sumValues";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";

export default {
  name: "DatagridOrderItemFooterPriceSum",
  inject: ["rateCallback"],
  props: {
    title: {
      type: String,
      default: "",
    },
    rows: {
      type: Array,
      required: true,
    },
    totalPaid: {
      type: Number,
      required: false,
      default: 0,
    },
    useRate: {
      type: Boolean,
      required: false,
      default: true,
    },
    extraTotalValues: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    extraTotals() {
      return Object.keys(this.extraTotalValues).length > 0;
    },
    balance() {
      const balance = new Decimal(this.totalIncludingVat).minus(this.totalPaid);

      return balance.toNumber();
    },
    totalExcludingVat() {
      return sumValues(
        this.rows.map((x) =>
          this.convertToLocalCurrency(x.TotalExcVAT.Value, x),
        ),
      );
    },
    totalIncludingVat() {
      return sumValues(
        this.rows.map((x) =>
          this.convertToLocalCurrency(x.TotalIncVAT.Value, x),
        ),
      );
    },
    totalVat() {
      return this.totalIncludingVat - this.totalExcludingVat;
    },
    translations() {
      return getTranslations();
    },
  },
  methods: {
    formatPrice,
    convertToLocalCurrency(num, row) {
      let rate = 1;

      if (this.useRate) {
        rate = this.rateCallback(row, false);
      }
      return new Decimal(num || 0).times(rate).toNumber();
    },
  },
};
</script>

<style scoped></style>
