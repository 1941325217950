import {sortReturnItemRows} from "./sortReturnItemRows.js";
import {rowWasChecked} from "./rowWasChecked.js";
import {updateRows} from "../rows/updateRows.js";

export const handleRowsUpdated = async ({rows, oldRows, vueComponent}) => {
  updateRows({newRows: rows, oldRows: oldRows, vueComponent: vueComponent});

  if (rowWasChecked({rows, oldRows})) {
    setTimeout(() => {
      vueComponent.rows = sortReturnItemRows({
        rows: rows,
      });
    }, 110);
  }
};
