/**
 * Reloads related windows based on provided Subject
 * @param data {Object} ReloadRelatedWindowsArguments
 * @param data.Subject {String} Subject of the window to reload
 * @returns {Promise<void>}
 */
export default function (data) {
  global.session.updateRelatedWindows(
    global.session.activeWindow,
    data.Subject,
  );
}
