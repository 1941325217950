import {isRelevantPriceCondition} from "./apply-price-conditions/isRelevantPriceCondition";
import {applyPriceCondition} from "./apply-price-conditions/applyPriceCondition";
import {isStackingPriceCondition} from "./apply-price-conditions/isStackingPriceCondition";
import {removeIrrelevantPriceConditionRows} from "./apply-price-conditions/removeIrrelevantPriceConditionRows";
import {removePriceConditionsFromRow} from "./apply-price-conditions/removePriceConditionsFromRow";
import {getUpdatedRowFromRows} from "./apply-price-conditions/getUpdatedRowFromRows";
import {isCostRow} from "./apply-price-conditions/isCostRow";
import {getRows} from "../getRows";

export const applyPriceConditions =
  ({changedColumnName}) =>
  async ({
    row,
    priceConditions,
    rows,
    warehouseId,
    columns,
    inboundOrder,
    inboundItems,
    vatCodes,
    supplier,
    costDistributionType,
  }) => {
    if (!priceConditions || priceConditions.length === 0) {
      return rows;
    }

    const getRowsWrapper = ({scannedRow, rows}) =>
      getRows({
        scannedRow,
        rows,
        priceConditions,
        columns,
        inboundOrder,
        inboundItems,
        warehouseId,
        vatCodes,
        supplier,
        costDistributionType,
      });

    const itemID = row.ItemID.Value;
    const itemContentID = row.ItemContentID?.Value;
    const amount = row.Amount?.Value ?? 0;

    let {itemPrice, modifiedRows} = removePriceConditionsFromRow({
      row,
      rows,
      changedColumnName,
      inboundItems,
      priceConditions,
    });

    let modifiedRow = getUpdatedRowFromRows({rows: modifiedRows, row});

    let hasAppliedNonStackingPriceCondition = false;
    let relevantPriceConditionOrderItemIDs = [];
    for (const priceCondition of priceConditions) {
      if (
        isRelevantPriceCondition({
          priceCondition,
          amount,
          warehouseId,
          itemID,
          itemContentID,
          currentPrice: modifiedRow.PurchasePricePerUnit.Value,
          hasAppliedNonStackingPriceCondition,
          changedColumnName,
          itemType: modifiedRow.ItemType?.Value,
          isCostRow: isCostRow({row: modifiedRow}),
        })
      ) {
        const {rows: updatedRows, priceConditionOrderItemIDs} =
          await applyPriceCondition({
            row: modifiedRow,
            priceCondition,
            rows: modifiedRows,
            itemPrice,
            getRowsWrapper,
          });
        modifiedRows = updatedRows;
        modifiedRow = getUpdatedRowFromRows({rows: modifiedRows, row});
        relevantPriceConditionOrderItemIDs.push(...priceConditionOrderItemIDs);
        if (!isStackingPriceCondition({priceCondition})) {
          hasAppliedNonStackingPriceCondition = true;
        }
      }
    }

    return removeIrrelevantPriceConditionRows({
      relevantPriceConditionRows: relevantPriceConditionOrderItemIDs,
      rows: modifiedRows,
      row: modifiedRow,
    });
  };
