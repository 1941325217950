export const setAllSerialRowsPickQuantityReadOnly = ({rows}) => {
  return rows.map((row) => {
    if (row.SerialID?.Value) {
      return {
        ...row,
        PickedQuantity: {
          ...row.PickedQuantity,
          IsReadOnly: true,
        },
      };
    }
    return row;
  });
};
