import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {setSalesPrice} from "./setSalesPrice";
import {processSalesRowMetaData} from "@/functions/datagrid/order-item/row/processSalesRowMetaData";

export async function processSalesRow({row}) {
  let newRow = {...row};
  const itemType = getRawValueFromDropdownValue(newRow.ItemType.Value);

  const isSalesRow = itemType === "Sales";

  if (isSalesRow) {
    newRow = await setSalesPrice({row: newRow});
  }

  newRow = processSalesRowMetaData({row: newRow});

  return newRow;
}
