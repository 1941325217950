import {compositionGroupGetTotalExcVATSum} from "../rows/rowsGenerateVirtualCompositionRows/compositionGroupGetTotalExcVATSum";

export const processVirtualRowTotalExcVatSum = ({row, rows}) => {
  if (!rows.length) return row;
  const composition = row.Composition.Value;

  const compositionRows = rows.filter(
    (innerRow) =>
      innerRow.Composition.Value === composition && !innerRow.rowMeta.virtual,
  );

  if (!compositionRows.length) return row;

  const totalExcVatSum = compositionGroupGetTotalExcVATSum({
    row,
    rows: compositionRows,
  });

  return {
    ...row,
    TotalExcVAT: {
      ...row.TotalExcVAT,
      Value: totalExcVatSum,
    },
  };
};
