import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import {alertPopup} from "../../interface/alertPopup/alertPopup.js";
import {axiosInstance} from "../axiosInstance.js";
import store from "../../../state/store";

export async function saveButtonClick(calculateRowValuesInput) {
  const translations = getTranslations();

  const result = await axiosInstance.post(
    `/Admin/WebServices/RentWebServices.asmx/SaveButtonClick`,
    calculateRowValuesInput,
    {
      windowId: global.session.activeWindow.id,
      params: {
        Warehouse: store.state.activeWarehouse,
      },
    },
  );

  if (
    typeof result?.data !== "object" ||
    result.data?.ContainsAnyWarnings ||
    result.data?.ContainsAnyErrors
  ) {
    if (!result?.data?.Events?.[0]?.[0] === "swal-message") {
      await alertPopup({
        icon: "error",
        text: translations.SaveFailed,
      });
    }

    return null;
  } else {
    return result.data;
  }
}
