import {getPeriodBasedEndDate} from "../../getPeriodBasedEndDate";
import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {getActiveWarehouse} from "../../../../util/getActiveWarehouse.js";
import {getSettings} from "@/util/getSettings";
import dayjs from "dayjs";

export async function fetchPeriodBasedExpectedEndDate({row, quotation}) {
  const newRow = {...row};

  const {PeriodSelection} = getSettings();

  const defaultPeriodSelection = PeriodSelection === "Default";
  const AllowItemSinglePeriodPriceCalculation =
    PeriodSelection === "AllowItemSinglePeriodPriceCalculation";

  if (
    newRow.PeriodAmount.Value > 0 &&
    getRawValueFromDropdownValue(newRow.Period.Value) &&
    !AllowItemSinglePeriodPriceCalculation &&
    !defaultPeriodSelection
  ) {
    const params = {
      WarehouseID: getActiveWarehouse(),
      CustomerID: quotation.CustomerID,
    };

    const endDate = await getPeriodBasedEndDate({
      period: row.Period.Value,
      periodAmount: row.PeriodAmount.Value,
      startDate: dayjs(row.DateTimeExpectedStart.Value, "YYYY-MM-DD").format(
        "YYYY-MM-DD",
      ),
      itemId: getRawValueFromDropdownValue(row.ItemID.Value),
      itemType: getRawValueFromDropdownValue(row.ItemType.Value),
      params,
    });

    newRow.DateTimeExpectedEnd.Value = endDate;
  }

  return newRow;
}
