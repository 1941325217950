import {openWindow} from "../services/admin/webservices/corewebservices/openWindow.js";

export const getLegacyEnityByTableNameAndPrimaryKey = async ({
  tableName,
  primaryKeyColumnName,
  primaryKey,
}) => {
  return openWindow({
    request: {
      Criteria: [{[primaryKeyColumnName]: primaryKey}],
      Prefix: "Single",
      Subject: tableName,
    },
  });
};
