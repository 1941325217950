export function assignValuesToRowsProps({rows}) {
  return rows.map((row) => {
    const newRow = {};
    Object.keys(row).forEach((key) => {
      if (row[key]?.Value) {
        newRow[key] = row[key].Value;
      }

      if (!row[key]?.Value) {
        newRow[key] = null;
      }
    });
    return newRow;
  });
}
