export const handleTabSelected = ({actionName, vueInstance}) => {
  const tabs = vueInstance.rWindows[vueInstance.activeWindowId].tabs;
  const updatedTabs = tabs.map((tab) => {
    if (tab.ActionName === actionName) {
      return {
        ...tab,
        Selected: true,
      };
    }
    return {
      ...tab,
      Selected: false,
    };
  });

  if (!updatedTabs.some((tab) => tab.Selected)) {
    return;
  }

  vueInstance.rWindows = {
    ...vueInstance.rWindows,
    [vueInstance.activeWindowId]: {
      ...vueInstance.rWindows[vueInstance.activeWindowId],
      tabs: updatedTabs,
    },
  };
};
