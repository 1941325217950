import {isRelatedRow} from "../../row/isRelatedRow";
import {isDistributionReceiverRow} from "./isDistributionReceiverRow";

export const hasReceiverRelation = ({row, rows}) => {
  if (!row.RelatedOrderItemID?.Value) {
    return false;
  }
  const relatedRow = rows.find((relatedRow) =>
    isRelatedRow({rowToMatch: relatedRow, rowToCheck: row}),
  );
  if (!relatedRow) {
    return false;
  }

  return isDistributionReceiverRow(relatedRow);
};
