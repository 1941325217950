import {getPrice} from "@/services/rental/item/getPrice";
import {calculateRowTotalIncVat} from "../../row/calculateRowTotalIncVat";
import {calculateRowTotalVat} from "../../row/calculateRowTotalVat";
import dayjs from "dayjs";

export async function getRowPrices({order, rowValues}) {
  const {
    DateTimeRentalStart,
    DateTimeRentalEnd,
    DateTimeBusinessStart,
    DateTimeBusinessEnd,
  } = rowValues;

  const convertedRowValues = {...rowValues};

  if (convertedRowValues.Composition === "") {
    convertedRowValues.Composition = null;
  }

  const startDate = dayjs(DateTimeRentalStart ?? DateTimeBusinessStart).format(
    "YYYY-MM-DDTHH:mm:ss",
  );
  const endDate = dayjs(DateTimeRentalEnd ?? DateTimeBusinessEnd).format(
    "YYYY-MM-DDTHH:mm:ss",
  );

  const priceResult = await getPrice({
    startDate,
    endDate,
    itemId: rowValues.ItemID,
    customerId: order.CustomerID?.Key,
    orderId: order.OrderID,
    orderItemId: rowValues.OrderItemID,
    contractId: order.ContractID?.Key,
    discount: rowValues.Discount,
  });

  const priceCalculation = priceResult?.PriceCalculation?.[0];
  const periods = priceCalculation?.Periods[0];
  const discount = periods?.Discount ?? rowValues.Discount;

  const price = priceResult.PriceNett;
  const totalExcVat = price * rowValues.Amount;
  const totalIncVat = calculateRowTotalIncVat({price, vatPercentage: priceResult?.VATPercentage, amount: rowValues.Amount});
  const totalVat = calculateRowTotalVat({totalIncVat, totalExcVat});

  return {
    Discount: discount,
    Price: price,
    TotalExcVAT: totalExcVat,
    TotalVAT: totalVat,
    TotalIncVAT: totalIncVat,
    VATPercentage: priceResult.VATPercentage ?? 0,
    PriceCalcReason: priceResult.PriceCalcReason,
    PriceProjection: {
      ...rowValues.PriceProjection,
      PriceCalculation: priceResult.PriceCalculation ?? [],
    },
  };
}
