import {detectCustomPropertyChanges} from "./detectCustomPropertyChanges.js";
import {rowsHaveValueChanges} from "./rows/rowsHaveValueChanges.js";

export const detectDatagridDataChanges = ({newRows, originalRows, columns}) => {
  const detectedCustPropChanges = detectCustomPropertyChanges({
    newRows,
    originalRows,
  });
  const detectedRowsChanges = rowsHaveValueChanges({
    newRows,
    originalRows,
    columns,
  });

  return detectedCustPropChanges || detectedRowsChanges;
};
