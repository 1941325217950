import {setValuesInRow} from "./setValuesInRow";
import Decimal from "decimal.js";

export const setPricePerUnitFromTotalExcVAT = ({row}) => {
  // TODO: Reintroduce Itemtype on Inbound OrderItems
  // if (row.ItemType.Value === "RentalPeriod") {
  //   return row;
  // }

  const newPurchasePricePerUnit = new Decimal(row.TotalExcVAT?.Value ?? 0)
    .dividedBy(row.Amount?.Value ?? 1)
    .times(100)
    .round()
    .dividedBy(100)
    .toNumber();
  return setValuesInRow({
    row,
    values: {PurchasePricePerUnit: newPurchasePricePerUnit},
  });
};
