export const processRowWeights = ({row}) => {
  const newRow = {...row};

  const weight =
    row.Weight?.Value ??
    row.PhysicalItem?.Value?.Weight ??
    row.Item?.Value?.Weight;

  const shippingWeight =
    row.ShippingWeight?.Value ??
    row.PhysicalItem?.Value?.ShippingWeight ??
    row.Item?.Value?.ShippingWeight;

  newRow.Weight = {
    ...newRow.Weight,
    Value: weight,
  };

  newRow.ShippingWeight = {
    ...newRow.ShippingWeight,
    Value: shippingWeight,
  };

  return newRow;
};
