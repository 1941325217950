import {getTranslations} from "../../session/localstorage/getTranslations.js";
import {getEntityFilterOptionValues} from "../../table/core-rail-road-task/filters/getEntityFilterOptionValues.js";
import {getRentalResourceFilterOptionValues} from "../../table/core-rail-road-task/filters/getRentalResourceFilterOptionValues.js";
import {getResourceFilterOptionValues} from "../../table/core-rail-road-task/filters/getResourceFilterOptionValues.js";

const translations = getTranslations();

export const generateTaskFilters = ({filters, initialValues}) => {
  filters.forEach((filter) => {
    if (filter.Target === "Key") {
      filter.loadOptions = getEntityFilterOptionValues;
      filter.loadOptionsOnCreated = true;
    }
    if (filter.Target === "ResourceID") {
      filter.loadOptions = getResourceFilterOptionValues;
      filter.loadOptionsOnCreated = true;
    }
    if (filter.Target === "Resources") {
      filter.loadOptions = getRentalResourceFilterOptionValues;
      filter.loadOptionsOnCreated = true;
      filter.Target = "filter[Resource][ResourceID]";
    }
    if (filter.Target === "TableName") {
      filter.Options = [
        {Key: "Rental.Quotation", Title: translations["Quotation"]},
        {Key: "Rental.Reservation", Title: translations["Reservation"]},
        {Key: "Rental.Order", Title: translations["Order"]},
        {Key: "Rental.Invoice", Title: translations["Invoice"]},
        {Key: "Rental.Service", Title: translations["Service"]},
        {Key: "Rental.InboundOrder", Title: translations["InboundOrder"]},
        {Key: "Rental.InboundInvoice", Title: translations["InboundInvoice"]},
      ];
    }
    if (filter.Target === "Status" || filter.Target === "status") {
      filter.Options = [
        {Key: "Open", Title: translations["Open"]},
        {Key: "Closed", Title: translations["Closed"]},
        {Key: "Cancelled", Title: translations["Cancelled"]},
        {Key: "InProgress", Title: translations["InProgress"]},
      ];

      if (initialValues) {
        filter.Selected = ["Open", "InProgress"];
      }
    }
    if (filter.Target === "DateTimeExecuteStart") {
      filter.Target = "DateTimeExecuteStart";
      filter.Type = "Date";
      filter.Suffix = ">=";
      filter.Ranking = 0;
      filter.Selected = null;
    }
    if (filter.Target === "DateTimeExecuteEnd") {
      filter.Target = "DateTimeExecuteEnd";
      filter.Type = "Date";
      filter.Suffix = "<=";
      filter.Ranking = 10;
      filter.Selected = null;
    }
  });

  return filters;
};
