<template>
  <div v-if="loaded" class="flex justify-end mb-1.5">
    <datagrid-page-size
      :page-size="pageSize"
      :page-sizes="[5, 10, 25, 100]"
      @change="$emit('page-size-change', $event)"
    />
  </div>
</template>

<script>
import DatagridPageSize from "../datagridFilters/DatagridPageSize.vue";

export default {
  name: "DatagridHeaderPartialDelivery",
  components: {DatagridPageSize},
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
