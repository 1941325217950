export function setReturnItemFormFieldsWhenValueRemoved({newFormFields}) {
  return {
    ...newFormFields,
    Type: {
      ...newFormFields.Type,
      IsReadOnly: false,
    },
    Value: {
      ...newFormFields.Value,
      Value: null,
    },
    Scan: {
      ...newFormFields.Scan,
      Value: null,
    },
  };
}
