export function isPriceCalculationDataMissing({
  row,
  defaultPeriodSelection,
  allowItemSinglePeriodCalculateEndDate,
  ItemSinglePeriodEnforced,
}) {
  if (
    allowItemSinglePeriodCalculateEndDate &&
    (!row.Period.Value || row.PeriodAmount.Value === null)
  ) {
    return true;
  }

  if (
    ItemSinglePeriodEnforced &&
    (!row.Period.Value || !row.PeriodPrice.Value || row.PeriodAmount === null)
  ) {
    return true;
  }

  if (
    defaultPeriodSelection &&
    (!row.DateTimeBusinessEnd.Value || !row.DateTimeBusinessStart.Value)
  ) {
    return true;
  }

  return false;
}
