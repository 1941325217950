import {closePickList} from "../../../../services/v2/picklist/closePickList";
import {alertPopup} from "../../../../interface/alertPopup/alertPopup.js";
import {notify} from "../../../../util/notify";

export const checkPickStatus = async ({
  rows,
  pickListId,
  windowId,
  translations,
}) => {
  const allRowsPicked = rows.every(
    (row) => row.PickedQuantity.Value === row.OrderedQuantity.Value,
  );

  if (allRowsPicked) {
    notify({
      message: translations["PickListClosed"],
      type: "success",
    });

    await closePickList({pickListId});
    global.session.windows[windowId].dispose();
    try {
      const windowsToRefresh = Object.values(global.session.windows).filter(
        (win) =>
          win.output.Request.Subject === "Rental.PickList" ||
          (win.output.Request.Subject === "Rental.Order" &&
            win.output.Request.Prefix === "Single"),
      );
      windowsToRefresh.forEach((win) => win.reset());
    } catch (error) {
      console.error(error);
    }
    return;
  }

  const closeAnyway = await alertPopup({
    icon: "warning",
    text: translations["ClosePickListQuestion"],
    buttons: {
      no: {
        text: "No",
        value: false,
        className: "btn-secondary",
        variant: "secondary",
      },
      yes: {text: "Yes", value: true, variant: "primary"},
    },
  });

  if (closeAnyway) {
    await closePickList({pickListId});
  }
  global.session.windows[windowId].dispose();
  try {
    const windowsToRefresh = Object.values(global.session.windows).filter(
      (win) =>
        win.output.Request.Subject === "Rental.PickList" ||
        (win.output.Request.Subject === "Rental.Order" &&
          win.output.Request.Prefix === "Single"),
    );

    windowsToRefresh.forEach((win) => win.reset());
  } catch (error) {
    console.error(error);
  }
};
