export const getTotalWeightFromRows = ({rows}) => {
  return rows.reduce(
    (acc, row) => {
      if (row.Weight) {
        const weight = parseFloat(row.Weight?.Value) || 0;
        const amount = parseFloat(row.Amount?.Value) || 0;
        acc.ItemsWeight += weight * amount;
      }
      if (row.ShippingWeight) {
        const weight = parseFloat(row.ShippingWeight?.Value) || 0;
        const amount = parseFloat(row.Amount?.Value) || 0;
        acc.ShippingWeight += weight * amount;
      }
      return acc;
    },
    {ItemsWeight: 0, ShippingWeight: 0},
  );
};
