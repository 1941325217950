<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <div ref="datagrid">
      <datagrid-standalone
        v-if="loaded && columns.length"
        :rows="rows"
        :actions="actions"
        :columns="columns"
        :window-id="rWindow.id"
        :user-metadata-key="userMetadataKey"
        :page="page"
        :page-size="pageSize"
        :process-context="{invoice, vatCodes, supplier}"
        :skeleton-rows="addItemQueue.length"
        @loading="loading = $event"
        @click-row="handleRowClick"
        @rows-updated="handleRowsUpdated"
        @page-change="handlePageChange({page: $event})"
      >
        <template #header>
          <datagrid-header-inbound-invoice-item
            :page-size="pageSize"
            :loaded="loaded"
            :show-scanner="showScanner"
            :invoice="invoice"
            :settings="settings"
            @addItem="addItem"
            @handlePageSizeChange="handlePageSizeChange"
          />
        </template>
        <template #footer>
          <datagrid-inbound-invoice-item-footer
            :rows="rows"
            :loading-page="loadingPage"
            :page="page"
            :page-size="pageSize"
            :currency="currency"
            :active-row="activeRow"
            @page-change="handlePageChange({page: $event})"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
import {setInitialDatagridDataToWindow} from "../../../functions/datagrid/data/setInitialDatagridDataToWindow.js";
import {convertCellRowsToKeyValueRows} from "../../../functions/datagrid/rows/convertCellRowsToKeyValueRows";
import {confirmDiscardUnsavedChanges} from "../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import fetchInboundItemScannerData from "../../../functions/datagrid/inbound-invoice-item/fetchInboundItemScannerData";
import {getInboundInvoiceItemData} from "../../../functions/datagrid/inbound-invoice-item/getInboundInvoiceItemData.js";
import {processInvoiceDiscrepancy} from "../../../functions/datagrid/inbound-invoice-item/rows/processInvoiceDiscrepancy";
import {detectDatagridDataChanges} from "../../../functions/datagrid/detectDatagridDataChanges.js";
import {convertRowToliRowDataRow} from "../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {putInboundInvoiceItems} from "../../../services/v2/inbound/invoice/putInboundInvoiceItems";
import {readonlyInboundInvoice} from "../../../functions/datagrid/inbound-invoice-item/readonlyInboundInvoice";
import {getLabelFromScannedRow} from "../../../functions/datagrid/inbound-orderitem/getLabelFromScannedRow";
import {getRowFromScannedItem} from "../../../functions/datagrid/inbound-invoice-item/getRowFromScannedItem.js";
import {getTypeFromScannedRow} from "../../../functions/datagrid/inbound-orderitem/getTypeFromScannedRow";
import {integrateRowsChanges} from "../../../functions/datagrid/rows/integrateRowsChanges.js";
import {loadRowDataForPage} from "../../../functions/datagrid/inbound-invoice-item/row/loadRowDataForPage.js";
import {mapActionComponent} from "../../../functions/datagrid/mapActionComponent";
import {handleJobExecution} from "../../../functions/datagrid/actions/handleJobExecution.js";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";
import {getUserId} from "../../../functions/session/getUserId";
import {cloneDeep} from "lodash";
import {sortRows} from "../../../functions/datagrid/rows/sortRows.js";
import {notify} from "../../../util/notify.js";

import DatagridHeaderInboundInvoiceItem from "../datagridHeaders/DatagridHeaderInboundInvoiceItem.vue";
import DatagridInboundInvoiceItemFooter from "../datagridFooters/DatagridInboundInvoiceItemFooter.vue";
import DatagridStandalone from "../DatagridStandalone.vue";

export default {
  name: "DatagridInboundInvoiceItem",
  components: {
    DatagridHeaderInboundInvoiceItem,
    DatagridInboundInvoiceItemFooter,
    DatagridStandalone,
  },
  provide() {
    const vm = this;

    const rate = {};
    Object.defineProperty(rate, "rate", {
      enumerable: true,
      get: () => this.rate,
    });

    return {
      rate,
      translations: this.translations,
      rateCallback(row, followSetting = true) {
        if (vm.selectedCurrencyId === vm.defaultCurrencyId && followSetting)
          return 1;
        return row?.Rate?.Value ?? 1;
      },
    };
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    loading: false,
    loadingPage: false,
    criteria: [],
    rows: [],
    originalRows: [],
    columns: {},
    page: 1,
    pageSize: 10,
    invoice: {},
    supplier: {},
    vatCodes: [],
    activeRow: null,
    addItemQueue: [],
    translations: getTranslations(),
    defaultCurrencyId: global.session?.defaultCurrencyId,
    actions: [{type: "Delete"}].map(mapActionComponent),
  }),
  computed: {
    showScanner() {
      return !readonlyInboundInvoice({invoice: this.invoice});
    },
    rate() {
      return this.rows[0]?.Rate?.Value ?? 1;
    },
    currency() {
      return (
        this.invoice.CurrencyID?.Description ?? this.defaultCurrencyId ?? null
      );
    },
    selectedCurrencyId() {
      return (
        this.rWindow.request.Data?.ClientCurrency ?? this.defaultCurrencyId
      );
    },
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Inbound.InvoiceItem-Multi`;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.criteria = this.rWindow.criteria ?? this.rParentWindow.criteria;
    const invoiceId = this.criteria[0].InvoiceID;

    const {columns, rows, invoice, supplier, vatCodes} =
      await getInboundInvoiceItemData({
        invoiceId,
        parentWindowCriteria: this.rParentWindow.criteria ?? this.criteria,
        settings: this.settings,
      });

    processInvoiceDiscrepancy(rows);

    this.columns = columns;
    this.rows = rows;
    this.invoice = invoice;
    this.supplier = supplier;
    this.vatCodes = vatCodes;
    this.originalRows = cloneDeep(this.rows);

    if (readonlyInboundInvoice({invoice: this.invoice})) {
      this.actions = [];
    }

    const columnsObject = this.columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {});

    setInitialDatagridDataToWindow({
      window: this.rWindow,
      columns: columnsObject,
      rows: this.rows,
      vueInstance: this,
    });

    this.loaded = true;
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async addItem(item) {
      const datagridElement =
        this.$refs.datagrid.querySelector(".datagrid-table");
      delete item.value;

      this.addItemQueue.push(item);

      if (this.addItemQueue.length > 1) return;

      while (this.addItemQueue.length > 0) {
        const queuedItem = this.addItemQueue[0];

        const newRow = await getRowFromScannedItem({
          item: queuedItem,
          rows: this.rows ?? [],
          invoice: this.invoice,
          columns: this.columns,
          supplier: this.supplier,
          vatCodes: this.vatCodes,
        });

        this.updateRows(this.rows.concat(newRow));
        this.addItemQueue.shift();
      }

      this.navigateToLastPage();
      this.updateWindowData();
      this.scrollToBottom(datagridElement);
    },
    async save(job) {
      try {
        if (!this.loaded) return false;
        this.$store.state.loading = true;

        const currentDirtyState = this.rWindow.data.dirty;
        this.$emit("data-change", {
          windowId: this.rWindow.id,
          newData: {
            ...this.rWindow.data,
            dirty: false,
          },
        });
        const rows = convertCellRowsToKeyValueRows({rows: this.rows});

        const response = await putInboundInvoiceItems({
          items: rows.filter((row) => !row.rowMeta?.virtual),
          invoiceId: this.invoice.InvoiceID,
        });

        if (response.status === 200) {
          notify({
            message: this.translations["SaveSuccessful"],
            type: "success",
          });
        } else {
          this.$emit("data-change", {
            windowId: this.rWindow.id,
            newData: {
              ...this.rWindow.data,
              dirty: currentDirtyState,
            },
          });
          notify({
            message:
              response?.data?.Error?.Message ?? this.translations["SaveFailed"],
            type: "warning",
          });
        }
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);

        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }

        this.$store.state.loading = true;
        const {columns, rows, invoice, supplier, vatCodes} =
          await getInboundInvoiceItemData({
            invoiceId: this.invoice.InvoiceID,
            parentWindowCriteria: this.rParentWindow.criteria ?? this.criteria,
            settings: this.settings,
          });

        processInvoiceDiscrepancy(rows);

        this.columns = columns;
        this.rows = rows;
        this.invoice = invoice;
        this.supplier = supplier;
        this.vatCodes = vatCodes;
        this.originalRows = cloneDeep(this.rows);

        if (readonlyInboundInvoice({invoice: this.invoice})) {
          this.actions = [];
        }

        const columnsObject = this.columns.reduce((acc, column) => {
          acc[column.Name] = column;
          return acc;
        }, {});

        setInitialDatagridDataToWindow({
          window: this.rWindow,
          columns: columnsObject,
          rows: this.rows,
          vueInstance: this,
        });
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    sortRows(job) {
      this.rows = sortRows(this.rows);
      notify({message: this.translations["RowsSorted"], type: "success"});
      this.$emit("job-completed", job);
      return false;
    },
    handleRowsUpdated({rows}) {
      this.rows = rows;
      this.updateWindowData();
    },
    async loadAndApplyRowDataForPage() {
      const changedRowsObject = await loadRowDataForPage({
        rows: this.rows,
        page: this.page,
        pageSize: this.pageSize,
      });

      return integrateRowsChanges({
        originalRows: this.rows,
        changedRows: changedRowsObject,
      });
    },
    updateRows(newRows) {
      newRows.forEach((newRow) => {
        const existingRow = this.rows.find(
          (row) => row.ClientSideUUID.Value === newRow.ClientSideUUID.Value,
        );
        if (existingRow) {
          // Update properties of the existing row
          Object.assign(existingRow, newRow);
        } else {
          // Add new row if it does not exist yet
          this.rows.push(newRow);
        }
      });
    },
    updateWindowData() {
      const columnsObject = this.columns.reduce((acc, column) => {
        acc[column.Name] = column;
        return acc;
      }, {});

      const windowData = {
        ...this.rWindow.data,
        Columns: columnsObject,
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },

    handleRowClick(row) {
      this.updateActiveRow(row);
    },
    updateActiveRow(row) {
      this.activeRow = row;
    },
    async handlePageChange({page}) {
      this.page = page;
      this.rows = await this.loadAndApplyRowDataForPage({
        rows: this.rows,
        page,
        pageSize: this.pageSize,
      });
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    navigateToPage({page}) {
      this.page = page;
    },
    navigateToLastPage() {
      const rowCount = this.rows.filter(
        (row) => !row.rowMeta?.compositionItem,
      ).length;
      this.page = Math.ceil(rowCount / this.pageSize);
    },
    scrollToBottom(element) {
      element.scrollTop = element.scrollHeight;
    },
    getTypeFromScannedRow,
    getLabelFromScannedRow,
    fetchInboundItemScannerData,
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
