import {processUseSinglePeriodPriceDifference} from "../../row/processUseSinglePeriodPriceDifference";
import {getSettings} from "../../../../util/getSettings";
import {calculateCalculatedPriceGetPrice} from "./calculateCalculatedPriceGetPrice";
import {getActiveWarehouse} from "../../../../util/getActiveWarehouse.js";

export async function calculateCalculatedPrice({order, row}) {
  const {PeriodSelection} = getSettings();
  const defaultPeriodSelection = PeriodSelection === "Default";

  const trueStartDate =
    row.DateTimeRentalStart?.Value ?? row.DateTimeBusinessStart?.Value;
  const trueEndDate =
    row.DateTimeRentalEnd?.Value ?? row.DateTimeBusinessEnd?.Value;

  if (
    trueStartDate === row.DateTimeBusinessStart?.Value &&
    trueEndDate === row.DateTimeBusinessEnd?.Value
  ) {
    return {
      ...row,
      CalculatedPrice: {
        ...row.CalculatedPrice,
        Value: row.TotalExcVAT.Value,
      },
    };
  }

  if (
    (row.Period.Value && row.PeriodPrice.Value && !defaultPeriodSelection) ||
    row.HasPeriodPricesForCalculation?.Value
  ) {
    const params = {
      WarehouseID: getActiveWarehouse(),
      CustomerID: order.CustomerID?.Key,
      OrderID: order.OrderID,
    };

    row = await processUseSinglePeriodPriceDifference({
      row,
      params,
      startDate: row.DateTimeBusinessStart.Value,
      endDate: row.DateTimeBusinessEnd.Value,
    });
    return row;
  }

  row = await calculateCalculatedPriceGetPrice({
    row,
    order,
  });

  return row;
}
