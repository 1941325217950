<template>
  <div>
    <slot name="header"> </slot>
    <div
      ref="topScrollbar"
      class="h-[20px] mb-2 overflow-x-scroll overflow-y-hidden"
      @scroll="syncScroll('top')"
    >
      <!-- This empty div is for the top scrollbar -->
      <div class="scroll-content" :style="{width: scrollWidth + 'px'}"></div>
    </div>
    <div
      ref="scrollContainer"
      class="min-h-screen flex overflow-x-scroll scroll-container cursor-grab pb-1"
      @mousedown="startDrag"
      @mousemove="onDrag"
      @mouseup="endDrag"
      @mouseleave="endDrag"
      @scroll="syncScroll('main')"
    >
      <kanban-lane
        v-for="lane in visibleLanes"
        :key="JSON.stringify(lane)"
        :lane="lane"
        :context="context"
        :cards="getLaneCards({lane, cards})"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import KanbanLane from "./KanbanLane.vue";

const translations = getTranslations();

export default {
  name: "RKanban",
  components: {KanbanLane},
  props: {
    context: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    lanes: {
      type: Array,
      required: false,
      default: () => [
        {
          Title: translations["StatusOpen"],
          StatusCode: "100",
          Ranking: 10,
          Color: "#FFC100",
          Status: "Reference to card property",
        },
        {
          Title: translations["InProgress"],
          StatusCode: "300",
          Ranking: 20,
          Color: "#FF8A08",
          Status: "Reference to card property",
        },
        {
          Title: translations["Cancelled"],
          StatusCode: "999",
          Ranking: "#151515",
          Color: "black",
          Status: "Reference to card property",
        },
        {
          Title: translations["Closed"],
          StatusCode: "200",
          Ranking: 40,
          Color: "#C40C0C",
          Status: "Reference to card property",
        },
      ],
    },
    cards: {
      type: Array,
      required: false,
      default: () => [{}],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    getLaneCardsCallback: {
      type: [Function, null],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      scrollWidth: 0,
    };
  },
  computed: {
    visibleLanes() {
      return this.lanes.filter((lane) => lane.Visible !== false);
    },
  },
  watch: {
    lanes() {
      this.updateScrollWidth();
    },
  },
  mounted() {
    this.updateScrollWidth();
    window.addEventListener("resize", this.updateScrollWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScrollWidth);
  },
  methods: {
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.pageX - this.$refs.scrollContainer.offsetLeft;
      this.scrollLeft = this.$refs.scrollContainer.scrollLeft;
    },
    onDrag(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.scrollContainer.offsetLeft;
      const walk = (x - this.startX) * 1; // Multiply by 2 for faster scrolling
      this.$refs.scrollContainer.scrollLeft = this.scrollLeft - walk;
      this.$refs.scrollContainer.classList.add("select-none");
    },
    endDrag() {
      this.isDragging = false;
      this.$refs.scrollContainer.classList.remove("select-none");
      this.updateScrollWidth();
    },
    getLaneCards({lane, cards}) {
      if (this.getLaneCardsCallback) {
        return this.getLaneCardsCallback({lane, cards});
      }
      return cards.filter((card) => card.StatusCode === lane.StatusCode);
    },
    syncScroll(source) {
      if (source === "main") {
        this.$refs.topScrollbar.scrollLeft =
          this.$refs.scrollContainer.scrollLeft;
      } else if (source === "top") {
        this.$refs.scrollContainer.scrollLeft =
          this.$refs.topScrollbar.scrollLeft;
      }
    },
    updateScrollWidth() {
      this.scrollWidth = this.$refs.scrollContainer.scrollWidth;
    },
  },
};
</script>
