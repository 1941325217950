import {getRowValues} from "./getRowValues";
import {flattenKeyObjectsInRow} from "../../../../parsing/flattenKeysInRowCollection";
import {setCustomFieldsObject} from "./setCustomFieldsObject";

export const createPricesUpdateHookRequestBody = ({context, rows}) => {
  const nonVirtualRows = rows.filter((x) => !x?.rowMeta?.virtual !== false);
  return {
    Context: setCustomFieldsObject({
      row: flattenKeyObjectsInRow({
        row: context,
      }),
    }),
    Rows: nonVirtualRows.map((row) => {
      return setCustomFieldsObject({
        row: flattenKeyObjectsInRow({
          row: getRowValues({
            row,
          }),
        }),
      });
    }),
  };
};
