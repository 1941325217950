import {setRowItemDescription} from "../../../functions/datagrid/inbound-invoice-item/row/setRowItemDescription";

import {normalizeRows} from "../../normalizers/normalizeRows";

export const normalizeGetInvoiceItems = async ({result}) => {
  const descriptionMappedRows = result.data.Collection.map((row) =>
    setRowItemDescription({row}),
  );
  const normalizedRows = normalizeRows({rows: descriptionMappedRows});
  return {
    ...result,
    data: {
      ...result.data,
      Collection: normalizedRows,
    },
  };
};
