import getMetadata from "@/services/metadata/getMetaData";

export async function getColumns({
  table,
  prefix,
  primaryKey,
  criteria,
  filterDTOProperties,
}) {
  const metadata = await getMetadata({
    table,
    prefix,
    primaryKey,
    criteria,
    filterDTOProperties,
  });

  if (
    metadata &&
    Object.values(metadata)?.length > 0 &&
    !Object.values(metadata)[0]?.Message
  ) {
    return Object.values(metadata);
  } else {
    return [];
  }
}
