import {findUniqueCompositionRows} from "./composition/findUniqueCompositionRows.js";
import {replaceCompositionValue} from "./composition/replaceCompositionValue.js";

export const setCorrectCompositionValue = ({row, rows}) => {
  const uniqueCompositionRows = findUniqueCompositionRows({
    rows,
    targetValue: row.CompositionID.Value,
  });

  row.Composition.Value = replaceCompositionValue({
    value: row.Composition.Value,
    count: uniqueCompositionRows.length,
  });

  row.CompositionRanking.Value = uniqueCompositionRows.length;

  return row;
};
