import $ from "jquery";
import availabilityList from "../../../views/elements/availabilityList.html";
import "bootstrap4-notify";
import Combobox from "../../interface/combobox.class";
import Formatter from "../../model/formatter";
import modalBodyLoadAnimation from "../../../views/content/modals/body/modalBodyLoadAnimation.html";
import modalItemAvailability from "../../../views/content/modals/modalItemAvailability.html";
import visRow from "../../../views/elements/itemAvailabilityVisRow.html";
import minMax from "dayjs/plugin/minMax";
import Vue from "../../interface/vue";
import ItemAvailabilityPlanner from "../../components/elements/ItemAvailabilityPlanner.vue";
import store from "../../../state/store";

import dayjs from "dayjs";

/**
 * Writes the contents of an item availability screen to a specified element
 *
 * @export
 * @class ModalItemAvailability
 */
export default class ModalItemAvailability {
  /**
   *Creates an instance of ModalItemAvailability.
   * @param {object} modalBody
   * @param {string} category
   * @param {object} vueModalComponent
   * @memberof ModalItemAvailability
   */
  constructor(modalBody, category, vueModalComponent) {
    this.modalBody = modalBody; // The modal we'll be writing our content in
    this.modalTitle = window.translations.OrderPlannerTitle;
    this.vueModalComponent = vueModalComponent;

    // The data used to populate this chart
    this.requestData = {};
    this.requestDataSubject = null;
    // The amount of records we can display
    this.dataCount = 0;

    // Contains the last active "main" page data, used for restoring the view when going out of a secondary page
    this.cachedSegmentData = {};

    this.pageIndex = 1;
    this.secondaryPageIndex = 1;
    this.pageSize = 10;
    this.pageCount = 10; // Generated based of recordCount
    this.searchCriteria = "";
    this.categoryID = "";

    // Item ID to get serialItems based on
    this.itemID = null;
    this.itemDescription = null;

    // Search criteria
    this.categoryID = null;
    this.searchCriteria = null;
    this.startDate = null;
    this.endDate = null;

    // So uhh I had to recreate the window, because otherwise we have issue with no having translation get it? kthxbai
    this.window = window;

    // Define combobox configurations for our filtering
    this.comboboxData = {
      CategoryID: Combobox.new(null, {
        name: "CategoryID",
        nullable: true,
        tableName: "Rental.Category",
        columnName: "CategoryID",
        readOnly: !!category?.oldValue,
      }),
    };

    // Set categoryCombobox data if id exists
    if (category.oldValue) {
      this.categoryID = category.oldValue;
      this.comboboxData.CategoryID.setInitialValues(
        category.oldText,
        this.categoryID,
      );
    }

    // this.itemSelectEvent = itemSelectEvent
  }
  /**
   * Caches all dom objects that are referenced more then once for performance
   *
   * @memberof ModalItemAvailability
   * @returns {void}
   */
  cacheDom() {
    this.$modalBody = $(this.modalBody);
    this.$chartWrapper = this.$modalBody.find("#chartWrapper");
    this.$previousPageButton = this.$modalBody.find(
      "[data-pagination-previous]",
    );
    this.$nextPageButton = this.$modalBody.find("[data-pagination-next]");
    this.$categorySelector = this.$modalBody.find("#categorySelector");
  }

  /**
   * Sets the title of the modal
   *
   * @param {string} title
   * @memberof ModalItemAvailability
   */
  setTitle(title) {
    this.vueModalComponent.$children[0].setModalTitle(title);
  }

  /**
   * Main function of the availability screen, it triggers all the methods in the correct order
   *
   * @param {boolean} loadAnimation Show load animation or not
   * @param {string} requestSubject The subject to render based on
   * @param {bool} restoreFromCache Should we restore the latest cached "main" page?
   * @memberof ModalItemAvailability
   * @returns {void}
   */
  async render({
    loadAnimation = false,
    requestSubject = "Rental.virtual_ItemAvailability",
    restoreFromCache = false,
  }) {
    this.setTitle(window.session.translations.ModalDayPlannerTitle);
    // If true show load animation
    if (loadAnimation) {
      this.modalBody.html(modalBodyLoadAnimation(window.translations));
    }

    await this.getVisChartData({
      requestSubject: requestSubject,
      restoreFromCache: restoreFromCache,
    });
    this.modalBody.html(modalItemAvailability(this));
    if (this.itemID) {
      this.setTitle(this.itemID);
    } else {
      this.setTitle(window.session.translations.ModalDayPlannerTitle);
    }
    this.cacheDom();
    await this.renderVisChart({visChartWrapper: this.$chartWrapper});
    this.bindEvents();
  }

  /**
   *	Binds events to the dom (mostly with JQuery)
   *
   * @memberof ModalItemAvailability
   * @returns {void}
   */
  bindEvents() {
    this.$modalBody.off();
    this.$modalBody.on(
      "submit",
      "form[name='availabilitySearch']",
      async () => {
        let values = this.getFormData(event.target);
        let oldCategoryText = this.comboboxData.CategoryID.oldText;

        if (values.CategoryID != "") {
          this.categoryID = values.CategoryID;
        } else {
          this.categoryID = null;
        }

        // Recreate ComboBox from scratch
        delete this.comboboxData;
        this.comboboxData = {
          CategoryID: Combobox.new(null, {
            name: "CategoryID",
            nullable: true,
            tableName: "Rental.Category",
            columnName: "CategoryID",
          }),
        };
        this.comboboxData.CategoryID.setInitialValues(
          oldCategoryText,
          this.categoryID,
        );

        this.searchCriteria = values.SearchCriteria;
        this.startDate = values.StartDate;
        this.endDate = values.StartDate;

        this.pageIndex = 1;
        await this.render({loadAnimation: false});

        // this.$categorySelector.find(":input").each(() => {
        // 	$(this).val(self.CategoryID)
        // })
      },
    );

    this.$modalBody.on("click", "#closeDetailChart", () => {
      this.itemID = null;
      this.itemDescription = null;
      this.secondaryPageIndex = 1;
      this.requestDataSubject = null;
      this.render({loadAnimation: true, restoreFromCache: true});
    });

    // Navigating to a new Item page
    this.$modalBody.on("click", "[data-pagination-page]", (event) => {
      this.navigatePage(event);
    });

    // Navigating to a new serial Item page
    this.$modalBody.on(
      "click",
      "[data-navigate=SerialItemVisChart]",
      (event) => {
        this.navigatePage(event);
      },
    );

    // Button open serial item overview
    this.$modalBody.on("click", "[data-open-SerialAvailability]", (event) => {
      this.addSerialToOrder(event);
    });

    // Button open warehouse item overview
    this.$modalBody.on(
      "click",
      "[data-open-ItemWarehouseAvailability]",
      (event) => {
        this.changeRenderView({
          event,
          subject: "Rental.virtual_ItemWarehouseAvailability",
        });
      },
    );

    // Button open detail item overview
    this.$modalBody.on(
      "click",
      "[data-open-ItemDetailAvailability]",
      (event) => {
        this.changeRenderView({
          event,
          subject: "Rental.virtual_ItemDetailAvailability",
        });
      },
    );
  }

  /**
   * Changes the view to a new subject data
   *
   * @param {object} event Jquery object containing data required for informing selected item
   * @param {string} subject The subject we'll be getting our data based on
   * @memberof ModalItemAvailability
   * @returns {void}
   */
  changeRenderView({event, subject}) {
    this.itemID = $(event.target).data("itemId");
    this.itemDescription = $(event.target).data("itemDescription");
    this.requestDataSubject = subject;
    this.render({loadAnimation: true, requestSubject: subject});
  }

  /**
   * Add serial item to order based on Jquery element object
   * @param {object} event The jquery object passed on containing serial id
   * @memberof ModalItemAvailability
   * @returns {void}
   */
  addSerialToOrder(event) {
    this.itemID = $(event.target).data("itemId");
    this.itemDescription = $(event.target).data("itemDescription");
    this.requestDataSubject = "Rental.virtual_SerialAvailability";
    this.render({
      loadAnimation: true,
      requestSubject: "Rental.virtual_SerialAvailability",
    });
  }

  /**
   * Navigate to page
   * @param {object} event Jquery object of the clicked button
   * @memberof ModalItemAvailability
   * @returns {void}
   */
  async navigatePage(event) {
    let buttonHTML = $(event.target).html();
    if (this.requestDataSubject !== null) {
      this.secondaryPageIndex = $(event.target).data("pagination-page");
    } else {
      this.pageIndex = $(event.target).data("pagination-page");
    }
    $(event.target).html(
      "<i class='fas fa-spinner fa-spin'></i> " + buttonHTML,
    );
    await this.render({loadAnimation: false});
    $(event.target).html(buttonHTML);
  }

  /**
   * Get's the format data
   *
   * @param {*} $form Jquery form object
   * @returns {array} values
   * @memberof ModalItemAvailability
   */
  getFormData($form) {
    let values = {};
    $.each($($form).serializeArray(), (i, field) => {
      values[field.name] = field.value;
    });

    return values;
  }

  /**
   * Restore old modal content by making the initial modal body visible again
   *
   * @memberof ModalItemAvailability
   * @returns {void}
   */
  restoreModalContent() {
    $("#modalItemAvailabilityBody").find(".container-fluid:eq(1)").remove();
    $("#modalItemAvailabilityBody")
      .find("#modalItemAvailabilityInitialBody")
      .show();
  }

  /**
   *  Get's the selected CategoryID in the modal
   * 	@returns {String} The selected CategoryID
   */
  getSearchCriteria() {
    let categoryID = $("#categorySelector").find("input").value();
    return categoryID;
  }

  /**
   * Get visChart data and draw it in the existing modal
   * @param {string} requestSubject Request Subject based on requested data
   * @param {bool} restoreFromCache Should we restore from cache yes or no
   * @memberof ModalItemAvailability
   * @returns {void}
   */
  async getVisChartData({requestSubject, restoreFromCache}) {
    let requestUrl = "/Admin/WebServices/CoreWebServices.asmx/OpenWindow";

    let activeWindow = window.session.activeWindow;
    if (activeWindow.sub && activeWindow.sub.window) {
      activeWindow = activeWindow.sub.window;
    }

    // Get all DateTimeExpectedEnds, filter out ones which are not entered for example with a Sales/use article.
    let expectedEnds = $(this.element)
      .find("[name='DateTimeExpectedEnd[]']")
      .toArray()
      .map((dateField) => $(dateField).text())
      .filter((dateValue) => dateValue !== "");
    let momentDates = [];

    if (activeWindow.output.Data.DateTimeExpectedEnd) {
      momentDates.push(
        dayjs(
          Formatter.unixMilisecondsToDateString(
            activeWindow.output.Data.DateTimeExpectedEnd,
          ),
        ),
      );
    }

    // Convert all dates into dayjs objects and push them into an array
    expectedEnds.forEach((date) =>
      momentDates.push(dayjs(Formatter.unixMilisecondsToDateString(date))),
    );

    // use the dayjs.max() function to get the biggest date.
    dayjs.extend(minMax);
    let maxDate = dayjs.max(momentDates);
    maxDate = dayjs(maxDate).add(1, "year");

    let rentalStarts = $(this.element)
      .find("[name='DateTimeExpectedStart[]']")
      .toArray()
      .map((dateField) => $(dateField).text())
      .filter((dateValue) => dateValue !== "");
    let startMomentDates = [];

    if (activeWindow.output.Data.DateTimeExpectedStart) {
      startMomentDates.push(
        dayjs(
          Formatter.unixMilisecondsToDateString(
            activeWindow.output.Data.DateTimeExpectedStart,
          ),
        ),
      );
    }

    // Convert all dates into moment objects and push them into an array
    rentalStarts.forEach((date) =>
      startMomentDates.push(dayjs(Formatter.unixMilisecondsToDateString(date))),
    );

    // use the dayjs.max() function to get the biggest date.
    let minDate = dayjs();

    if (startMomentDates.length > 0) {
      minDate = dayjs.min(startMomentDates);
    }

    // Validate dates to prevent errors, and format them for the server when valid
    minDate.isValid() ? minDate.format("YYYY-MM-DD") : (minDate = null);
    maxDate.isValid() ? maxDate.format("YYYY-MM-DD") : (maxDate = null);

    // Define request data send to the server
    let request = {
      Subject: this.requestDataSubject
        ? this.requestDataSubject
        : requestSubject,
      Prefix: "View",
      Criteria: [],
    };

    // Provide request criteria data
    request.Criteria = [
      {
        startDate: minDate,
        endDate: maxDate,
        categoryID: this.categoryID,
        searchCriteria: this.searchCriteria,
        itemID: this.itemID,
      },
    ];

    // Provide data required for getting the required data from the server
    request.Data = {
      pageNumber: this.requestDataSubject
        ? this.secondaryPageIndex
        : this.pageIndex,
      pageSize: this.pageSize,
    };

    // If we're not loading main view data use alternative page index so we remember which page the user initially was at
    if (requestSubject != "Rental.virtual_ItemAvailability") {
      request.Data.pageNumber = this.secondaryPageIndex;
    }

    // Restore cache if we're going back to the main view
    if (restoreFromCache) {
      this.requestData = this.cachedSegmentData;
    } else {
      this.requestData = await window.session.request(requestUrl, {
        request: request,
      });
    }

    // Cache page if we're not looking at a "secondary" page
    if (this.requestDataSubject === null) {
      this.cachedSegmentData = this.requestData;
    }

    // Set the latest page & data count
    this.pageCount = this.requestData.Data.pageCount;
    this.dataCount = this.requestData.Data.count;
  }
  /**
   * Render the vis Chart
   *
   * @param {string} [startDate=null] Start date
   * @param {string} [endDate=null] End date
   * @param {string} visChartWrapper Dom to HTML element
   * @memberof ModalItemAvailability
   * @returns {void};
   */
  renderVisChart({endDate = null, visChartWrapper}) {
    const items = [];
    const groups = [];

    let distinctedSegments = this.removeDuplicates(
      this.requestData.Data.segments,
      "segmentGroup",
    );

    distinctedSegments.forEach((dataItem) => {
      // Contains an ItemID than show serials.
      let isItemID = false;
      if (this.itemID) {
        isItemID = true;
      }
      if (this.requestData.Title === "Rental.virtual_ItemDetailAvailability") {
        dataItem.segmentGroupDescription =
          window.session.translations[
            "AvailabilityList_" + dataItem.segmentGroupDescription
          ];
      }
      const htmlString = visRow({
        dataItem,
        isItemID,
        subject: this.requestData.Title,
      });

      groups.push({
        id: dataItem.segmentGroup,
        content: htmlString,
      });
    });

    // Output the requested data segments into the right object
    this.requestData.Data.segments.forEach(function addItems(segment, index) {
      if (this.requestData.Title === "Rental.virtual_ItemDetailAvailability") {
        segment.segmentGroupDescription =
          window.session.translations[
            "AvailabilityList_" + segment.segmentGroupDescription
          ];
      }
      const item = {
        id: index,
        group: segment.segmentGroup,
        title: this.constructTitle(segment),
        className: this.setClassName(
          segment.label,
          segment.segmentGroup.toLowerCase(),
        ),
        label: segment.segmentGroupDescription,
        criteria: segment.criteria,
        content: this.formatAmount(segment.label),
        start: Formatter.unixMilisecondsToDateObject(segment.start),
        end: Formatter.unixMilisecondsToDateObject(segment.end),
      };
      items.push(item);
    }, this);

    endDate = dayjs(
      Formatter.unixMilisecondsToDateString(this.requestData.Data.endDate),
    ).isBefore(dayjs())
      ? dayjs().add(1, "days")
      : dayjs(
          Formatter.unixMilisecondsToDateString(this.requestData.Data.endDate),
        );
    endDate = dayjs().add(7, "days");

    // Set timeline options
    let options = {
      zoomMin: 3600000, // minimum zoom level is 1 day
      min: dayjs().subtract(1, "year").format("YYYY-MM-DD"), // The minimum visible date
      start: Formatter.unixMilisecondsToDateString(
        this.requestData.Data.startDate,
      ),
      // The initial visible startdate of the timeline
      end: endDate.toDate(), // The initial visible enddate of the timeline
      max: dayjs().add(1, "years").toDate(), // The maximum visible date
      locale: window.session.language,
      stack: false, // do not stack the items, group them together on 1 row,
      orientation: "both",
    };

    // Cleanup old chart
    $(visChartWrapper).html("");

    new Vue({
      el: visChartWrapper.get(0),
      store,
      render: (h) =>
        h(ItemAvailabilityPlanner, {
          props: {
            items,
            groups,
            options,
          },
        }),
    });
  }

  removeDuplicates(originalArray, objKey) {
    let trimmedArray = [];
    let values = [];
    let value;

    for (let i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    return trimmedArray;
  }

  /**
   * Get the ID of a group.
   * @param {Array} filteredGroups The array to search in.
   * @param {String} category The category to get the ID from.
   * @returns {Int} Returns the ID of the found group
   */
  getGroupID(filteredGroups, category) {
    let groupRow = filteredGroups.find(
      (filteredElement) => filteredElement.content === category,
    );
    return groupRow.id;
  }

  /**
   * If the amount is smaller than 1, set className to red so a red background color is applied. Else, a green class is returned.
   * @param {int} amount The amount to base the className on.
   * @returns {string} The classname to apply.
   */
  setClassName(amount, segmentGroup) {
    let className = "";
    if (Number(amount) < 0) {
      className = "red";
    } else {
      className = "green";
    }
    className = className + " vis-bar-" + segmentGroup;
    return className;
  }

  /**
   * Format the string amount (which is a decimal) to an int, so we get no decimals added.
   * Then, return the int casted to a string because this is what vis.js expects as content.
   * @param {int} amount The amount to parse.
   * @return {string} The amount formatted as a String.
   */
  formatAmount(amount) {
    let intAmount = Formatter.parsers.Int(amount);
    return Formatter.parsers.String(intAmount);
  }

  /**
   * Construct the title for the given segment.
   * @param {object} segment The segment object to get data from.
   * @returns {string} The title for the given segment as s String. Contains HTML generated from the availabilityList.html (Nunjucks)
   */
  constructTitle(segment) {
    let itemID = segment.criteria.itemID;
    let startDate = Formatter.unixMilisecondsToDateString(segment.start);

    let endDate = dayjs(Formatter.unixMilisecondsToDateString(segment.end))
      .subtract(1, "days")
      .format("DD-MM-YYYY");

    let html = availabilityList({
      availabilityList: segment.details,
      itemID,
      startDate,
      endDate,
      translations: this.window.session.translations,
    });
    return html;
  }
}
