<template>
  <div class="mr-1 flex flex-wrap grow gap-1">
    <div
      v-for="(filter, index) in filters
        .filter((f) => f.Hidden !== true)
        .sort((a, b) => a.Ranking - b.Ranking)"
      :key="filter.target"
    >
      <div class="form-label ml-0" style="padding-left: 0">
        {{ filter.title }}
      </div>
      <template>
        <table-form-search-bar-filter-options-date
          v-if="filter.Type === 'Date'"
          :key="filter.Target"
          :filter="filter"
          @filter-change="$emit('filter-change', $event)"
        />

        <table-form-search-bar-filter-options-list
          v-if="filter.Type === 'List'"
          :key="filter.Target"
          :filter="filter"
          :index="index"
          @filter-change="$emit('filter-change', $event)"
        />
      </template>
    </div>
  </div>
</template>
<script>
import TableFormSearchBarFilterOptionsList from "./FormFilterBarFilterOptionsList.vue";
import TableFormSearchBarFilterOptionsDate from "./FormFilterBarFilterOptionsDate.vue";

export default {
  name: "TableFormSearchBarOptions",
  components: {
    TableFormSearchBarFilterOptionsList,
    TableFormSearchBarFilterOptionsDate,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
