import {isKeyMissing} from "./addMissingKeysToRow/isKeyMissing";
import {nullValueObjectForKey} from "./addMissingKeysToRow/nullValueObjectForKey";

export function addMissingKeysToRow({row, columns}) {
  return columns.reduce((acc, column) => {
    return {
      ...acc,
      ...(isKeyMissing({row: acc, columnName: column.Name})
        ? nullValueObjectForKey({key: column.Name})
        : {}),
    };
  }, row);
}
