export const processLanesVisibility = ({lanes, viewFilters}) => {
  if (!viewFilters[0].Selected) {
    return lanes;
  }

  return lanes?.map((lane) => ({
    ...lane,
    Visible: viewFilters
      .find((viewFilter) => viewFilter.Target === "VisibleLanes")
      .Selected.includes(lane.StatusCode),
  }));
};
