<template>
  <div
    class="py-2 text-right"
    :class="{
      'border-x-0 border-b-0 !border-t !border-solid !border-[#eeeeee]':
        fieldType === 'LabelSelector',
    }"
  >
    <label v-if="!field.IsReadOnly" class="font-black text-[#626262]">{{
      field.Title
    }}</label>
    <component
      :is="fieldComponent"
      class="ml-[5px] !text-wrap"
      :field="field"
      :fields="fields"
      @field-change="$emit('field-change', $event)"
    >
    </component>
  </div>
</template>

<script>
import {capitalizeFirstCharInString} from "../../../src/interface/capitalizeFirstCharInString.js";

export default {
  name: "Field",
  props: {
    field: {
      required: true,
      type: Object,
    },
    fields: {
      required: true,
      type: Array,
    },
  },
  computed: {
    fieldType() {
      const fieldName = this.field.Editor ?? this.field.Type;
      return `${capitalizeFirstCharInString({string: fieldName})}${
        this.field.IsReadOnly ? "ReadOnly" : ""
      }`;
    },
    fieldComponent() {
      return () => import(`./Field${this.fieldType}.vue`);
    },
  },
};
</script>

<style></style>
