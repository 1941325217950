<template>
  <div class="login cover v-center">
    <div class="container">
      <div class="box pull-center">
        <form @submit.prevent="submit">
          <div class="logo">
            <span
              :class="[
                'logo-icon',
                'infodatek-icon',
                'infodatek-icon-' + (session.product || '').toLowerCase(),
              ]"
            ></span
            ><br />
            <span class="logo-text">{{ session.product }}</span>
          </div>

          <div v-if="error" class="error-box">
            {{ error }}
          </div>

          <div>
            <p>{{ translations.ForgotPasswordInfo }}</p>

            <div class="form-element">
              <input
                v-model="email"
                type="text"
                name="email"
                :placeholder="translations.EmailAddress"
              />
            </div>

            <div class="form-element">
              <button
                class="button button-menu half login-screen-return"
                @click="showLogin"
              >
                {{ translations.Cancel }}
              </button>
              <button
                class="button button-default half password-request-button"
                type="submit"
              >
                {{ translations.Submit }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import getSessions from "../../services/authentication/getSessions";

import postCreateSession from "../../services/authentication/postCreateSession.js";
import {setPersistedSessionId} from "../../functions/session/localstorage/setPersistedSessionId";
import getWelcomeInfo from "../../services/authentication/getWelcomeInfo.js";
import {login} from "../../functions/session/login.js";

import postRequestResetPassword from "../../services/authentication/postRequestResetPassword";

export default {
  name: "LoginRequestResetPassword",
  props: {
    session: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      error: null,
      email: "",
      successMessage: "",
      activeSessions: [],
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  created: async function () {
    await this.fetchSessionList();
  },
  methods: {
    showLogin() {
      this.$emit("showComponent", "loginPage");
    },

    async fetchSessionList() {
      const response = await getSessions();

      if (response.status == 200) {
        this.$data.activeSessions = response.data;
      }
    },
    async doSomething() {
      const sessionResult = await postCreateSession();

      await this.$store.commit("setSessionId", {
        sessionId: sessionResult.data.SessionId,
      });

      setPersistedSessionId(sessionResult.data.SessionId);

      const result = await getWelcomeInfo();

      await login({
        session: this.$props.session,
        result: result.data,
        serviceName: "GetWelcomeInfo",
      });

      this.$data.component = "loginSessionList";
    },
    async submit() {
      const response = await postRequestResetPassword({
        email: this.$data.email,
      });

      this.$data.error = response.data;
    },
  },
};
</script>
