import {handleAmountChange} from "../row/handleAmountChange";
import {handleDateTimeDesiredDeliveryChange} from "./row/handleDateTimeDesiredDeliveryChange";
import {handleDateTimeOrderedChange} from "./row/handleDateTimeOrderedChange";
import {handleRankingChange} from "../row/handleRankingChange";
import {applyPriceConditions} from "./rows/applyPriceConditions";
import {sortRowsByRanking} from "../row/sortByRanking";
import {handleTotalPriceChangeForRows} from "./rows/handleTotalPriceChangeForRows";
import {handleVATCodeIDChangeOnRows} from "./rows/handleVATCodeIDChangeOnRows";
import {distributeCosts} from "./rows/distributeCosts";
import {validateInboundValue} from "./rows/validateInboundValue";
import {processInboundValueReadonly} from "./row/processInboundValueReadonly";
import {setPricePerUnitFromTotalExcVAT} from "./row/setPricePerUnitFromTotalExcVAT";

export const processColumnsMetaData = ({columns}) => {
  const newColumns = columns.slice();

  newColumns.forEach((newColumn) => {
    newColumn.processValueChangeFunctions = [];
    newColumn.processValueChangeFunctionsForRows = [];

    // TODO: Reintroduce Itemtype on Inbound OrderItems
    // if (newColumn.Name === "ItemType") {
    //   newColumn.processValueChangeFunctions.push(handleItemTypeChange);
    //   newColumn.processValueChangeFunctionsForRows.push(
    //     applyPriceConditions({changedColumnName: "ItemType"}),
    //     handleTotalPriceChangeForRows({changedColumnName: "ItemType"}),
    //     sortRowsByRanking,
    //   );
    // }

    if (newColumn.Name === "Ranking") {
      newColumn.processValueChangeFunctions.push(handleRankingChange);
      newColumn.processValueChangeFunctionsForRows.push(sortRowsByRanking);
    }

    if (newColumn.Name === "Amount") {
      newColumn.processValueChangeFunctions.push(handleAmountChange);
      newColumn.processValueChangeFunctionsForRows.push(
        applyPriceConditions({changedColumnName: "Amount"}),
        handleTotalPriceChangeForRows({changedColumnName: "Amount"}),
        distributeCosts,
        validateInboundValue,
        sortRowsByRanking,
      );
    }

    if (newColumn.Name === "PurchasePricePerUnit") {
      newColumn.processValueChangeFunctionsForRows.push(
        applyPriceConditions({changedColumnName: "PurchasePricePerUnit"}),
        handleTotalPriceChangeForRows({
          changedColumnName: "PurchasePricePerUnit",
        }),
        distributeCosts,
        validateInboundValue,
        sortRowsByRanking,
      );
    }

    if (newColumn.Name === "TotalExcVAT") {
      newColumn.processValueChangeFunctions.push(
        setPricePerUnitFromTotalExcVAT,
      );
      newColumn.processValueChangeFunctionsForRows.push(
        handleTotalPriceChangeForRows({
          changedColumnName: "TotalExcVAT",
        }),
        distributeCosts,
        validateInboundValue,
      );
    }

    if (newColumn.Name === "TotalVAT") {
      newColumn.processValueChangeFunctionsForRows.push(
        handleTotalPriceChangeForRows({
          changedColumnName: "TotalVAT",
        }),
      );
    }

    if (newColumn.Name === "DateTimeOrdered") {
      newColumn.processValueChangeFunctions.push(handleDateTimeOrderedChange);
    }

    if (newColumn.Name === "VATCodeID") {
      newColumn.processValueChangeFunctionsForRows.push(
        handleVATCodeIDChangeOnRows,
        handleTotalPriceChangeForRows({changedColumnName: "VATCodeID"}),
      );
    }

    if (newColumn.Name === "DateTimeDesiredDelivery") {
      newColumn.processValueChangeFunctions.push(
        handleDateTimeDesiredDeliveryChange,
      );
    }

    if (newColumn.Name === "InboundValue") {
      newColumn.processValueChangeFunctionsForRows.push(validateInboundValue);
    }

    if (newColumn.Name === "CostAccountID") {
      newColumn.processValueChangeFunctions.push(processInboundValueReadonly);
      newColumn.processValueChangeFunctionsForRows.push(
        distributeCosts,
        validateInboundValue,
      );
    }
  });

  return newColumns;
};
