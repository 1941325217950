import {createPricesUpdateHookRequestBody} from "../../order-item/rows/rows-process-webhook/createPricesUpdateHookRequestBody";
import {postPricesUpdatedDraftEvent} from "../../../../services/v2/rental/quotationItem/prices/postPricesUpdatedDraftEvent";
import {rowsProcessWebhookResult} from "@/functions/datagrid/rows/rowsProcessWebhookResult";

export const rowsProcessWebhook = async ({rows, quotation, row}) => {
  if (row?.rowMeta?.virtual === true) return rows;

  const {data} = await postPricesUpdatedDraftEvent({
    context: createPricesUpdateHookRequestBody({context: quotation, rows}),
    event: "rowsProcessWebhook",
  });

  return rowsProcessWebhookResult({oldRows: rows, newRows: data.Rows});
};
