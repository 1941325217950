export const rowsSetDefaultAmountRejected = ({rows}) => {
  const newRows = rows.slice();
  newRows.forEach((row) => {
    if (row.AmountRejected.Value === null) {
      row.AmountRejected.Value = 0;
    }
  });

  return newRows;
};
