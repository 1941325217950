import {generateLaneFilters} from "./generateLaneFilters.js";

export const processEntityFilters = (viewFilters, lanes) => {
  viewFilters = generateLaneFilters({viewFilters, lanes});
  if (
    viewFilters.find((viewFilter) => viewFilter.Target === "Entity").Selected
  ) {
    viewFilters = generateLaneFilters({viewFilters, lanes});
  }
  return viewFilters;
};
