import {nonActionAxiosInstance} from "../nonActionAxiosInstance.js";

export async function getItemStockDialogItems({
  itemId,
  startDate,
  warehouseId,
}) {
  const result = await nonActionAxiosInstance.get(
    `/api/v2/screen/stock/details/conditions`,
    {
      params: {
        itemID: itemId,
        warehouse: warehouseId,
        date: startDate,
      },
    },
  );

  return result.data;
}
