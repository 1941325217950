export const correctSubWindowIdOnParentWindow = ({vueInstance, subWindow}) => {
  const rWindows = vueInstance.rWindows;
  const parentWindow =
    rWindows[subWindow?.parent?.id ?? subWindow.parentWindowId];

  if (!parentWindow) {
    console.error("Parent window not found", {subWindow});
    return;
  }

  vueInstance.rWindows = {
    ...rWindows,
    [parentWindow.id]: {
      ...parentWindow,
      subWindowId: subWindow.id,
    },
  };
};
