<template>
  <div
    v-if="resources.length"
    class="h-8 flex flex-row-reverse"
    :class="classes"
  >
    <div
      v-for="(resource, index) in resources"
      :key="index"
      :style="calculateStyle(index)"
      class="rounded-t-md w-6 h-6"
    >
      <kanban-card-resource :resource="resource" :is-sub-task="isSubTask" />
    </div>
  </div>
</template>

<script>
import KanbanCardResource from "../../kanban/order-kanban/OrderKanbanCardResource.vue";

export default {
  name: "FormSidebarRailroadItemResources",
  components: {
    KanbanCardResource,
  },
  props: {
    resources: {
      type: Array,
      default: function () {
        return [];
      },
    },
    isSubTask: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    classes() {
      return {
        "px-1.5 bg-[#eee] rounded-t-md items-center": this.isSubTask,
        "ml-2": !this.isSubTask,
      };
    },
  },
  methods: {
    calculateStyle(index) {
      if (index === this.resources.length - 1) {
        return {
          "border-radius": "6px 6px 0px 0px",
        };
      }
      if (this.isSubTask) {
        return {
          "margin-left": "-9px",
          "border-radius": "6px 6px 0px 0px",
        };
      }
      return {
        "margin-left": "-15.5px",
      };
    },
  },
};
</script>

<style></style>
