export function getExpectedDeliveryDaysFromRow({row, inboundItems}) {
  const itemId = row?.ItemID?.Value;

  for (const item of inboundItems) {
    if (item.ItemID === itemId) {
      return item.ExpectedDeliveryDays;
    }
  }
  return null;
}
