import {getExpectedDeliveryDay} from "@/functions/datagrid/inbound-orderitem/cell/getExpectedDeliveryDay";
import {assignRankingToNewRow} from "@/functions/datagrid/row/assignRankingToNewRow";
import dayjs from "dayjs";
import {v4 as uuidv4} from "uuid";

export function assignScannedItemValuesToCorrectRowValues({
  newRow,
  rows,
  inboundOrder,
}) {
  newRow["Ranking"].Value = assignRankingToNewRow({
    rows: rows,
    preferredRanking: newRow.Ranking.Value,
  });

  newRow["OrderItemID"].Value = newRow["OrderItemID"]?.Value ?? uuidv4();

  newRow["OrderID"].Value = inboundOrder.OrderID;

  newRow["ItemDescription"].Value = newRow["Description"].Value;

  // TODO: Reintroduce Itemtype on Inbound OrderItems
  // newRow["ItemType"].Value = newRow["ItemType"]?.Value ?? "Sales";

  if (!newRow["RelatedOrderItemID"]) {
    newRow["RelatedOrderItemID"] = {Name: "RelatedOrderItemID", Value: null};
  }

  newRow["DateTimeOrdered"].Value = dayjs().format("YYYY-MM-DD");

  newRow["DateTimeDesiredDelivery"].Value = getExpectedDeliveryDay(
    newRow["ExpectedDeliveryDays"]?.Value,
  );

  newRow["PurchasePricePerUnit"].Value = newRow["UnitPrice"].Value;

  newRow["Amount"].Value = newRow["Amount"]?.Value ?? 1;

  newRow["TotalExcVAT"].Value =
    newRow["PurchasePricePerUnit"].Value * newRow["Amount"].Value;

  newRow["Status"].Value = "Open";

  newRow["Notes"].Value = null;
  return newRow;
}
