export const setRowItemDescription = ({row}) => {
  if (!row.ItemID) {
    return row;
  }
  const {Key, Description} = row.ItemID;
  const itemDescription = Description?.replace(`${Key} - `, "");

  return {
    ...row,
    ItemDescription: itemDescription,
  };
};
