<template>
  <div>
    <textarea
      :id="fieldName"
      v-model="value"
      class="w-full editable-text textarea-normal"
      :class="{
        'textarea-red': remainingCharacters < 0,
      }"
      type="text"
      :maxlength="maxCharacters"
      :readonly="readonly"
      :disabled="readonly"
      :title="value"
      @change="handleChange"
      @keyup="handleFocus"
      @focus="handleFocus"
      @blur="handleBlur"
    />

    <div
      v-if="showRemainingCharacters"
      class="text-xs absolute right-7"
      :class="{
        'text-danger': remainingCharacters <= 0,
        'text-warning': remainingCharacters <= 10 && remainingCharacters > 0,
      }"
    >
      {{ remainingCharacters + " " + translations.RemainingCharacters }}
    </div>
  </div>
</template>

<script>
import {getSettings} from "../../../util/getSettings.js";

export default {
  name: "TextareaField",
  inject: ["translations"],
  props: {
    field: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    calculateRemainingCharactersFunction: {
      type: [Function, null],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      originalValue: "",
      value: this.field.Value,
      isFocused: false,
    };
  },
  computed: {
    maxCharacters() {
      return this.field.Length;
    },
    remainingCharacters() {
      return this.calculateRemainingCharactersFunction
        ? this.calculateRemainingCharactersFunction({
            value: this.value,
            maxCharacters: this.maxCharacters,
          })
        : null;
    },
    showRemainingCharacters() {
      return (
        this.remainingCharacters &&
        ((this.maxCharacters && this.isFocused) || this.remainingCharacters < 0)
      );
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    readonly() {
      return this.field.IsReadOnly;
    },
  },
  created() {
    this.originalValue = this.field.Value;
    this.settings = getSettings();
  },
  methods: {
    handleBlur() {
      this.isFocused = false;
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleChange(event) {
      this.$emit("input", {Value: event.target.value});
    },
  },
};
</script>

<style scoped>
textarea[readonly] {
  background-color: #eeeeee;
  cursor: not-allowed;
  color: #888;
}

textarea:focus {
  border-bottom: 1px solid #f26522 !important;
}

.textarea-normal {
  background-color: white;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%);
  border: 1px solid #ced4da !important;
  border-radius: 3px;
  padding-right: 5px !important;
  padding-left: 5px !important;
  min-height: 46px !important;
  overflow: hidden !important;
}

.textarea-red {
  background-color: white;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%);
  border: 1px solid #f87171 !important;
  border-radius: 3px;
  padding-right: 5px !important;
  padding-left: 5px !important;
  min-height: 46px !important;
  overflow: hidden !important;
}
</style>
