import {processTotalExcVat} from "../processTotalExcVat";
import {calculateCalculatedPrice} from "../calculateCalculatedPrice";
import {calculatePriceDifference} from "../calculatePriceDifference";

export const rowAmountChangeWrapper = async ({row, rows, order}) => {
  let modifiedRow = {...row};
  modifiedRow = await processTotalExcVat({row: modifiedRow, rows, order});
  modifiedRow = await calculateCalculatedPrice({row: modifiedRow, rows, order});
  modifiedRow = await calculatePriceDifference({row: modifiedRow, rows, order});

  return modifiedRow;
};
