import dayjs from "dayjs";

export const setItemPickerDialogEndDate = ({order}) => {
  if (
    !order.DateTimeExpectedEnd &&
    dayjs(order.DateTimeExpectedStart) < dayjs()
  ) {
    return dayjs().add(1, "months").format("YYYY-MM-DD");
  }

  if (!order.DateTimeExpectedEnd) {
    return dayjs(order.DateTimeExpectedStart).add(1, "months");
  }

  if (order.DateTimeExpectedEnd && dayjs(order.DateTimeExpectedEnd) < dayjs()) {
    return dayjs().add(1, "months");
  }

  if (order.DateTimeExpectedEnd && dayjs(order.DateTimeExpectedEnd) > dayjs()) {
    return dayjs(order.DateTimeExpectedEnd).add(1, "months");
  }

  if (
    !order.DateTimeExpectedEnd &&
    dayjs(order.DateTimeExpectedStart < dayjs())
  ) {
    return dayjs().add(1, "months");
  }

  if (
    !order.DateTimeExpectedEnd &&
    dayjs(order.DateTimeExpectedStart) > dayjs()
  ) {
    return dayjs(order.DateTimeExpectedStart).add(1, "months");
  }
};
