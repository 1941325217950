export const downloadBase64 = ({base64, filename, setDefaultPrefix}) => {
  let base64String = `data:application/octet-stream;base64,${base64}`;
  if (setDefaultPrefix === false) base64String = base64;

  let blob = fetch(base64String)
    .then((res) => res.blob())
    .then((blob) => {
      // Create an object URL for the blob
      let url = window.URL.createObjectURL(blob);

      // Create a new anchor element
      let a = document.createElement("a");

      // Set the href and download attributes for the anchor element
      a.href = url;
      a.download = filename || "download";

      // Append the anchor element to the parent element
      document.body.appendChild(a);

      // Simulate a click on the anchor element
      a.click();

      // Remove the anchor element after the download is initiated
      document.body.removeChild(a);

      // At the end, revoke the object URL to free up memory
      setTimeout(function () {
        URL.revokeObjectURL(url);
      }, 100);
    });
};
