import {getCostsToDistribute} from "./getCostsToDistribute";
import {isDistributionReceiverRow} from "./isDistributionReceiverRow";
import Decimal from "decimal.js";
import {setValuesInRow} from "../../row/setValuesInRow";
import {correctRoundingError} from "./correctRoundingError";
import {getTotalExcVatIncludingNonReceiverRelatedRows} from "./getTotalExcVatIncludingNonReceiverRelatedRows";

export const distributeCostsLinear = ({rows}) => {
  const costsToDistribute = getCostsToDistribute({rows});
  const numberOfReceiverRows = rows.filter(isDistributionReceiverRow).length;

  const costPerRow = new Decimal(costsToDistribute)
    .dividedBy(numberOfReceiverRows)
    .times(100)
    .floor()
    .dividedBy(100)
    .toNumber();

  const updatedRows = rows.map((row) => {
    let value = 0;
    if (isDistributionReceiverRow(row)) {
      value = new Decimal(
        getTotalExcVatIncludingNonReceiverRelatedRows({row, rows}),
      )
        .plus(costPerRow)
        .toNumber();
    }
    return setValuesInRow({row, values: {InboundValue: value}});
  });

  return correctRoundingError({rows: updatedRows});
};
