import {getComboboxItems} from "@/services/mainRadComboBox";

export async function getCategoryDropdownItems() {
  const serverDropdownItems = await getComboboxItems({
    columnName: "CategoryID",
    tableName: "Rental.Category",
    prefix: null,
  });

  const parentCategories = serverDropdownItems.filter(
    (item) => item.Attributes.ParentCategoryID === undefined,
  );

  function childCategories(parentCategory) {
    return serverDropdownItems.filter(
      (item) =>
        parseInt(item.Attributes.ParentCategoryID) ===
        parseInt(parentCategory.Value),
    );
  }

  return parentCategories.map((item) => ({
    id: item.Value,
    label: item.Text,
    children: childCategories(item).map((child) => ({
      id: child.Value,
      label: child.Text,
    })),
  }));
}
