<template>
  <div>
    <div class="return-item-form m-2">
      <row-form
        :fields="formFields"
        :order-by="'Ranking'"
        @change="$emit('form-input', $event)"
        @input="handleScanSearch($event)"
      />
    </div>
    <div class="flex flex-row justify-end mb-2">
      <datagrid-page-size
        :page-size="pageSize"
        @change="$emit('page-size-change', $event)"
      />
    </div>
  </div>
</template>

<script>
import DatagridPageSize from "../datagridFilters/DatagridPageSize.vue";
import RowForm from "../../form/RowForm.vue";

export default {
  name: "DatagridHeaderReturnItem",
  components: {RowForm, DatagridPageSize},
  props: {
    pageSize: {
      type: Number,
      default: 10,
    },
    rows: {
      type: Array,
      required: false,
      default: () => [],
    },
    formFields: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    handleScanSearch(event) {
      this.$emit("form-input", {
        Name: event.Name,
        KeyboardEvent: event.$event.event,
      });
    },
  },
};
</script>
