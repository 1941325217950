export const convertUndeliveredOrderItemsToRows = async ({
  undeliveredOrderItems,
}) => {
  const rows = [];
  // loop through undeliveredOrderItems and set the row object to [key]{Name: [key],Value: [value]}
  for (const undeliveredOrderItem of undeliveredOrderItems) {
    const row = {};
    for (const key in undeliveredOrderItem) {
      row[key] = {
        Name: key,
        Value: undeliveredOrderItem[key],
      };
    }
    rows.push(row);
  }
  return rows;
};
