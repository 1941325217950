import translation from "../../../util/translation.js";
import {getItem} from "../../../services/v2/items/getItem.js";

export async function setSalesItemTypeDropdownItems({row}) {
  const originalItem = await getItem(row.ItemID.Value?.Key ?? row.ItemID.Value);
  const originalItemType = originalItem.ItemType;

  if (originalItemType !== "Sales") {
    return [
      {
        Text: translation("ItemTypeSales"),
        Value: "Sales",
      },
      {
        Text: translation(`ItemType${originalItemType}`),
        Value: originalItemType,
      },
    ];
  } else {
    return [
      {
        Text: translation("ItemTypeSales"),
        Value: "Sales",
      },
    ];
  }
}
