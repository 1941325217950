export function processPeriodPrice({row}) {
  const newRow = {...row};
  if (newRow.Period.Value) {
    const periodPrice = newRow.PeriodPrices?.Value?.find((periodPrice) => {
      return periodPrice.PeriodID === newRow.Period.Value;
    });
    if (periodPrice) {
      newRow.PeriodPrice.Value = periodPrice.Price;
    }
  }
  return newRow;
}
