import {getRawValueFromDropdownValue} from "../../../util/parsing/getRawValueFromDropdownValue.js";
import {setSaleableItemTypeMetaData} from "./setSaleableItemTypeMetaData.js";
import {setSalesItemTypeDropdownItems} from "./setSalesItemTypeDropdownItems.js";
import translation from "../../../util/translation.js";

export async function setRowItemTypeMetaData({row}) {
  const itemType = getRawValueFromDropdownValue(
    row.ItemType.Value?.Key ?? row?.ItemType?.Value,
  );
  const saleableItemTypes = ["RentalPeriod", "RentalBoth"];
  const nonSaleableItemTypes = [
    "RentalCounter",
    "Deposit",
    "Free",
    "DepositReturn",
  ];

  row.ItemType.IsReadOnly = false;
  row.ItemType.Value = {
    Key: itemType,
    Description: translation(`ItemType${itemType}`),
  };

  if (saleableItemTypes.includes(itemType)) {
    row = setSaleableItemTypeMetaData({row, itemType});
  }

  if (nonSaleableItemTypes.includes(itemType)) {
    row.ItemType.IsReadOnly = true;
  }

  if (itemType === "Sales") {
    row.ItemType.getOptionsCallback = setSalesItemTypeDropdownItems;
  }

  return row;
}
