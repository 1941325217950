export const handleJobCompleted = ({job, vueInstance, windowId}) => {
  // Add the job to the list of jobs
  const jobs =
    vueInstance.rWindows[windowId].jobs.filter((jb) => jb.id !== job.id) ?? [];

  vueInstance.rWindows = {
    ...vueInstance.rWindows,
    [windowId]: {
      ...vueInstance.rWindows[windowId],
      jobs,
    },
  };
};
