export const getColumnOffsetClassFromColumn = ({ColumnOffSet}) => {
  const parseOffset = (offset) => {
    const parsedValue = parseInt(offset);
    return !isNaN(parsedValue) && parsedValue > 0
      ? Math.min(parsedValue, 12)
      : 0;
  };

  const formatColumnClass = (offset) => (offset > 0 ? `col-md-${offset}` : "");

  return formatColumnClass(parseOffset(ColumnOffSet));
};
