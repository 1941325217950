export function processRowForReturn({row, formFields}) {
  let processedRow = {...row};

  if (formFields.BusinessHourEnd) {
    processedRow = {
      ...processedRow,
      BusinessHourEnd: {
        ...formFields.BusinessHourEnd,
        Value:
          formFields.BusinessHourEnd.Value?.value ??
          formFields.BusinessHourEnd.Value,
      },
    };
  }

  if (formFields.Location) {
    processedRow = {
      ...processedRow,
      LocationID: {
        ...formFields.Location,
        Value: formFields.Location.Value?.LocationID,
      },
      ReturnToLocationID: {
        ...formFields.Location,
        Value: formFields.Location.Value?.LocationID,
      },
    };
  }

  return {
    ...processedRow,
    NotesOnReturnHeader: {
      ...formFields.NotesOnReturnHeader,
      Value: formFields.Notes.Value,
    },
    DateTimeReturned: {
      ...formFields.DateTimeReturned,
      Value: formFields.DateTimeReturned.Value,
    },
    ChangeRentalEndDate: {
      ...formFields.ChangeRentalEndDate,
      Value: formFields.ChangeRentalEndDate.Value,
    },
  };
}
