import {api} from "@/services/api";
import store from "../../../state/store";

export async function getSerialInfo(calculateRowValuesInput) {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    },
  );

  const result = await api.post(
    `/Admin/WebServices/RentWebServices.asmx/GetSerialInfo`,
    {allowUnspecifiedSerialItems: true, ...calculateRowValuesInput},
    {
      windowId: global.session.activeWindow.id,
      params: {
        Warehouse: store.state.activeWarehouse,
      },
      baseURL: "",
    },
  );

  return result.data;
}
