import {setAndForceMinDates} from "@/functions/datagrid/order-item/row/setAndForceMinDates";
import {setPeriodDropdownItems} from "@/functions/datagrid/order-item/row/setPeriodDropdownItems";
import {processFreeRow} from "@/functions/datagrid/quotation-item/row/processFreeRow";
import {processItemType} from "@/functions/datagrid/quotation-item/row/processItemType";
import {processInitialSalesRow} from "@/functions/datagrid/quotation-item/row/processInitialSalesRow";
import {processPopupPriceCalculations} from "@/functions/datagrid/order-item/row/processPopupPriceCalculations";
import {setRowItemTypeMetaData} from "../../row/setRowItemTypeMetaData.js";
import {processRowDropdownCells} from "../../row/processRowDropdownCells.js";
import {processSerialRow} from "../../row/processSerialRow.js";
import {processRowWeights} from "../../row/processRowWeights.js";
import {processRowCellEditors} from "../../row/processRowCellEditors.js";

export async function processQuotationItemRowMetaData({row, quotation}) {
  let newRow = {...row};
  newRow = setAndForceMinDates({row: newRow});

  const ensureColumnsExist = ["Period", "PeriodPrice", "PeriodAmount"];

  for (const column of ensureColumnsExist) {
    if (!newRow[column]) {
      newRow[column] = {Value: null};
    }
  }

  newRow = await setRowItemTypeMetaData({row: newRow});
  newRow = setPeriodDropdownItems({row: newRow});

  newRow = processPopupPriceCalculations({row: newRow});
  newRow = await processItemType({row: newRow, quotation});

  newRow = processFreeRow({row: newRow});
  newRow = processSerialRow({row: newRow});
  newRow = processRowDropdownCells({row: newRow});
  newRow = processInitialSalesRow({row: newRow});
  newRow = processRowCellEditors({row: newRow});
  newRow = processRowWeights({row: newRow});

  return newRow;
}
