import Decimal from "decimal.js";
import {cloneDeep} from "lodash";
import {calculateRowTotalIncVat} from "../../row/calculateRowTotalIncVat";
import {calculateRowTotalVat} from "../../row/calculateRowTotalVat";
import {calculateRowTotalExcVat} from "../../row/calculateRowTotalExcVat";

export const processTotalExcVat = ({row}) => {
  if (row.rowMeta?.virtual) return row;
  const newRow = cloneDeep(row);

  const counterPrice = new Decimal(
    parseFloat(row.CounterPrice?.Value ?? 0),
  ).toNumber();

  let newPrice = Decimal(parseFloat(newRow.Price.Value ?? 0)).add(counterPrice);

  if (newRow.Discount.Value) {
    newPrice = newPrice
      .dividedBy(100)
      .times(100 + (parseFloat(newRow.Discount.Value) ?? 0));
  }

  newRow.TotalIncVAT = {
    ...newRow.TotalIncVAT,
    Value: calculateRowTotalIncVat({price: newPrice, vatPercentage: newRow.VATPercentage.Value, amount: row.Amount.Value})
  };

  newRow.TotalExcVAT = {
    ...newRow.TotalExcVAT,
    Value: calculateRowTotalExcVat({price: newPrice, amount: newRow.Amount.Value})
  };

  newRow.TotalVAT = {
    ...newRow.TotalVAT,
    Value: calculateRowTotalVat({totalIncVat: newRow.TotalIncVAT.Value, totalExcVat: newRow.TotalExcVAT.Value})
  };

  return newRow;
};
