import {getExtraCriteriaValues} from "./getExtraCriteriaValues.js";
import {getWindowFromWindowId} from "../../window/getWindowFromWindowId.js";
import {getActiveWindow} from "../../window/getActiveWindow.js";

export const openReference = async ({column, value, windowId}) => {
  const dropdown = column.Dropdown;
  const window =
    getWindowFromWindowId({windowId, session: global.session}) ??
    getActiveWindow();

  if (!window) return;

  let input = {
    Subject: dropdown.TableName,
    Prefix: value ? "Single" : "Multi",
    Criteria: [{}],
  };

  if (value) input.Criteria[0][dropdown.ColumnName] = value;

  if (dropdown?.ExtraCriteria) {
    input.Criteria[0] = {
      ...input.Criteria[0],
      ...getExtraCriteriaValues({column, window}),
    };
  }

  document.dispatchEvent(
    new KeyboardEvent("keydown", {
      key: "Escape",
      keyCode: 27,
      which: 27,
      bubbles: true,
      cancelable: true,
    }),
  );

  await window.session.openWindow(input);
};
