<template>
  <div
    v-if="diagramData !== null"
    class="col-xs-12 col-12 chart-timeline px-[15px] py-0"
  >
    <div>
      <h5 class="title">
        <strong>{{ diagramData.title }}</strong>
      </h5>
    </div>
    <r-row class="mx-[-15px]">
      <r-col
        v-for="filter of diagramData.filters"
        :key="filter.type"
        class="px-[15px]"
      >
        <div class="flex justify-between">
          <div class="form-field">
            <div class="form-label flex" title="Reservation no.">
              <div class="overflow-hidden text-ellipsis">
                {{ translations.Search }}
              </div>
            </div>
            <div class="form-input">
              <input
                v-model="searchString"
                class="form-control min-h-[31px]"
                type="search"
              />
            </div>
          </div>
          <div class="form-field">
            <div class="form-label flex">
              <div class="overflow-hidden text-ellipsis">
                {{ translations.ChartStartDate }}
              </div>
            </div>
            <div class="form-input">
              <date-field
                :field="currentStartDate"
                :placeholder="currentStartDate.Value"
                :append-to-body="true"
                @input="handleDatePick"
              >
              </date-field>
            </div>
          </div>
          <div class="form-field mr-auto">
            <div class="form-label flex">
              <div class="overflow-hidden text-ellipsis">
                {{ translations.IndicationAvailableOnReturnDate }}
              </div>
            </div>
            <div class="form-input">
              <datagrid-cell-boolean
                :value="indicationAvailableOnReturnDate"
                :column="{IsReadonly: false}"
                @input="handleIndicationAvailableOnReturnDate"
              />
            </div>
          </div>
          <div class="form-field">
            <div class="form-label flex" title="Reservation no.">
              <div class="overflow-hidden text-ellipsis">
                {{ translations.Labels }}
              </div>
            </div>
            <div class="form-input">
              <v-select
                v-model="labelFilter"
                multiple
                class="filter-selector"
                :options="filter.options || []"
                :placeholder="`${translations.Select} ${filter.description}`"
                label="description"
              >
                <template #option="option">
                  <span>{{ option.description }}</span>
                </template>
                <div slot="no-options">{{ translations.NoRowsFound }}</div>
              </v-select>
            </div>
          </div>
        </div>
      </r-col>
    </r-row>

    <timeline
      v-if="pageGroups.length > 0 && items.length > 0 && unavailable === false"
      v-show="visible"
      ref="timeline"
      :items="items"
      :groups="pageGroups"
      :options="options"
      style="min-height: 600px; position: relative"
      @itemover="onHover"
    />

    <div
      v-if="searchString === ''"
      class="table-footer clearfix sticky-div stick-to-bottom"
    >
      <ul class="pagination">
        <li
          v-if="page > 2"
          class="button button-menu material-icons"
          @click="page = 1"
        >
          
        </li>
        <li v-else class="button button-menu material-icons" disabled></li>

        <li
          v-if="page > 1"
          class="button button-menu material-icons"
          @click="page--"
        >
          
        </li>
        <li v-else class="button button-menu material-icons" disabled></li>

        <li v-if="page - 1 > 1" class="page" @click="page = page - 2">
          {{ page - 2 }}
        </li>
        <li v-if="page > 1" class="page" @click="page--">
          {{ page - 1 }}
        </li>
        <li class="page current">{{ page }}</li>
        <li v-if="groups.length > page * count" class="page" @click="page++">
          {{ page + 1 }}
        </li>
        <li
          v-if="groups.length > (page + 1) * count"
          class="page"
          @click="page = page + 2"
        >
          {{ page + 2 }}
        </li>

        <li
          v-if="groups.length > page * count"
          class="button button-menu material-icons"
          @click="page++"
        >
          
        </li>

        <li v-else class="button button-menu material-icons ml-1" disabled>
          
        </li>

        <li
          v-if="page !== Math.ceil(groups.length / count)"
          class="button button-menu material-icons"
          @click="page = Math.ceil(groups.length / count)"
        >
          
        </li>
        <li v-else class="button button-menu material-icons" disabled></li>
      </ul>

      <div class="fake-button button button-menu no-hover no-action">
        <span>{{ translations.total }} {{ groups.length }}</span>
      </div>

      <div class="fake-button button button-menu no-hover no-action">
        <span data-select-count="data-select-count"
          >{{ page * (count - 1) }} / {{ groups.length }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import {Timeline} from "vue2vis";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import vSelect from "vue-select";
import {generateStockMessage} from "../../functions/stock-timeline/generateSegmentStockMessage";
import {getOrderByID} from "../../services/order";
import {getStockItemMovementItemCounts} from "../../services/item";
import chartTimelineOrderPopup from "./timeline/chartTimelineOrderPopup.html";
import chartTimelineItemPopup from "./timeline/chartTimelineItemPopup.html";
import moment from "moment";
import {axiosInstance} from "../../services/axiosInstance";
import DateField from "../form/filters/FormFilterBarFilterOptionsDateField.vue";
import {getTranslations} from "../../functions/session/localstorage/getTranslations";
import RRow from "../elements/RRow.vue";
import RCol from "../elements/RCol.vue";
import DatagridCellBoolean from "../datagrid/datagridCellTypes/DatagridCellBoolean.vue";

import("dayjs/locale/de");
import("dayjs/locale/fr");
import("dayjs/locale/nl");

dayjs.extend(advancedFormat);

export default {
  name: "ChartTimeline",
  components: {
    Timeline,
    "v-select": vSelect,
    DateField,
    RRow,
    RCol,
    DatagridCellBoolean,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      page: 1,
      searchString: "",
      indicationAvailableOnReturnDate: {
        Value: false,
      },
      visible: true,
      count: 9,
      diagramData: null,
      unavailable: true,
      currentStartDate: {Value: dayjs().format("YYYY-MM-DD")},
      groups: [],
      items: [],
      labelFilter: [],
      options: {
        height: "600px",
        showCurrentTime: true,
        showMinorLabels: true,
        orientation: "both",
        editable: false,
        locale: this.$store.state.language,
        start: new Date(),
        end: dayjs().add(30, "day").toDate(),
        stack: false,
        autoResize: true,
        margin: {
          item: {
            horizontal: -1,
          }, // minimal margin between items
          axis: 40, // minimal margin between items and the axis
        },
      },
    };
  },
  computed: {
    pageGroups() {
      let groups = this.groups;

      if (this.searchString) {
        groups = groups.filter((group) => {
          return group.content
            ?.toLowerCase()
            .includes(this.searchString.toLowerCase());
        });
      }

      // get the first 10 groups skip 10 * (page - 1)
      if (this.page === 1) {
        return groups.slice(0, this.count);
      }

      return groups.slice(this.count * (this.page - 1), this.count * this.page);
    },
    warehouseID: function () {
      return this.$store.state.activeWarehouse;
    },
    translations() {
      return getTranslations();
    },
  },
  watch: {
    searchString() {
      setTimeout(() => {
        this.$refs.timeline?.redraw();
      }, 1);
    },
    page() {
      //wait 300ms for the timeline to render

      setTimeout(() => {
        this.$refs.timeline?.redraw();
      }, 1);
    },
    labelFilter: async function (labelFilter) {
      await this.getVisData();
      await this.setVisData();
    },
  },
  async created() {
    moment.locale(this.options.locale);
    this.options.moment = function (date) {
      return moment(date);
    };

    if (this.endpoint) {
      await this.getVisData();
      await this.setVisData();
      this.options = this.getVisOptions();

      // wait 1 second for the timeline to render
      setTimeout(() => {
        this.unavailable = false;
      }, 1000);
    }
  },
  methods: {
    handleDatePick(value) {
      this.currentStartDate = {
        ...this.currentStartDate,
        Value: value,
      };
      this.reloadTimeline();
    },
    handleIndicationAvailableOnReturnDate(value) {
      this.indicationAvailableOnReturnDate = value;

      this.reloadTimeline();
    },
    async reloadTimeline() {
      if (this.endpoint) {
        this.options = this.getVisOptions();
        await this.getVisData();
        await this.setVisData();

        // wait 1 second for the timeline to render
        setTimeout(() => {
          this.unavailable = false;
        }, 1000);
      }
    },
    getVisData: async function () {
      let params = {};

      if (this.labelFilter !== {}) {
        let labels = "";
        for (const label of this.labelFilter) {
          if (labels != "") {
            labels = labels + ",";
          }
          labels = labels + label.value;
        }
        params.labels = labels;
      }
      params.warehouse = this.$store.state.activeWarehouse;
      params.indicationAvailableOnReturnDate =
        this.indicationAvailableOnReturnDate.Value;

      params = {
        ...params,
        startDate: this.currentStartDate.Value,
        period: "day",
        periodQuantity: 90,
      };

      const url = this.endpoint.split("?")[0];

      const result = await axiosInstance.get(url, {
        params,
      });

      this.diagramData = result.data;
    },
    generateOrderSegmentTitle: async function (item) {
      if (!item.criteria.OrderID) return "";
      const orderData = await getOrderByID(item.criteria.OrderID);

      // Ensure we don't render an object for the delivery method but rather it's description.
      if (orderData.DeliveryID) {
        orderData.DeliveryID = orderData.DeliveryID.Description;
      }

      return chartTimelineOrderPopup({
        order: orderData,
        translations: this.translations,
        dayjs,
      });
    },
    onHover: async function ({item}) {
      const targetItem = this.items.find((visItem) => visItem.id === item);
      if (targetItem.isLoaded) {
        return;
      }

      targetItem.title = await this.generateSegmentTitle(targetItem.item);
      // eslint-disable-next-line no-undef
      $(global.session.activeWindow.element)
        .find(".vis-tooltip")
        .html(targetItem.title);
      targetItem.isLoaded = true;
    },
    generateItemSegmentTitle: async function (item) {
      const itemData = await getStockItemMovementItemCounts({
        itemID: item.group,
        startDate: item.start,
        warehouseID: this.warehouseID,
        indicationAvailableOnReturnDate:
          this.indicationAvailableOnReturnDate.Value,
      });
      return chartTimelineItemPopup({
        item: itemData,
        formattedStartDate: dayjs(item.start).format("DD-MM-YYYY"),
        formattedEndDate: dayjs(item.end).format("DD-MM-YYYY"),
        translations: this.translations,
      });
    },
    generateSegmentTitle: async function (item) {
      if (item.criteria && item.criteria.OrderID)
        return await this.generateOrderSegmentTitle(item);
      if (item.subject === "Stock")
        return await this.generateItemSegmentTitle(item);
    },
    setVisData: async function () {
      const items = [];
      let segmentCount = 0;
      for (const item of this.diagramData.segments) {
        const startDate = dayjs(item.start);
        const endDate = dayjs(item.end);
        const stockMessage = generateStockMessage({
          item,
          startDate,
          endDate,
        });

        let cssClass = "";
        const stockAmount = item.details || item.value;
        const stockAmountNumber = Number(stockAmount);
        switch (true) {
          case stockAmountNumber < 0:
            cssClass += "vis-stockNegative vis-stockNegative-border";
            break;
          case stockAmountNumber === 0:
            cssClass += "vis-stockNeutral vis-stockNeutral-border";
            break;
          case stockAmountNumber > 0:
            cssClass += "vis-stockPositive vis-stockPositive-border";
            break;
        }

        items.push({
          id: segmentCount,
          group: item.group,
          start: startDate.toDate(),
          end: endDate.toDate(),
          title: chartTimelineItemPopup({
            translations: this.translations,
          }),
          isLoaded: false,
          content: stockMessage,
          className: `${cssClass} segment-${item.subject}`,
          item,
        });
        segmentCount++;
      }
      this.items = items;
      this.groups = this.diagramData.groups;
    },
    getVisOptions() {
      return {
        ...this.options,
        start: dayjs(this.currentStartDate.Value).toDate(),
        end: dayjs(this.currentStartDate.Value).add(90, "day").toDate(),
        min: dayjs(this.currentStartDate.Value).subtract(1, "day").toDate(),
        max: dayjs(this.currentStartDate.Value).add(90, "day").toDate(),
      };
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import "../../../node_modules/vue2vis/dist/vue2vis.css";
@import "../../../node_modules/vue-select/src/scss/vue-select";

body {
  background-color: #f2f2f2;

  h1 {
    margin-top: 0;
    font-size: 20.33px;
    color: #2c2c2c;
  }
}
.chart-timeline {
  .vis-timeline {
    position: unset;
  }
}
.filter-selector {
  width: 300px;
}

.vis-timeline .vis-item.segment-Stock .column.text-center {
  z-index: 262;
  -webkit-transition: all 0.3s ease-in-out;
}

.vis-timeline .vis-item .column.text-center {
  width: 150px;
  position: absolute;
}

.vis-timeline .vis-item:hover .column.text-center {
  -webkit-transition: all 0.3s ease-in-out;
}

.vis-timeline {
  border: none !important;
  .vis-panel {
    border: none;
  }
  .vis-item {
    -webkit-transition: all 0.3s ease-in-out;
    max-height: 100% !important;
    font-weight: 600;

    .vis-item-content {
      white-space: nowrap;
      word-break: break-all;
      overflow-wrap: break-word;
    }

    .time {
      font-weight: 400;
    }
  }

  .vis-item.retrieval {
    background-color: #d89d08 !important;
  }

  .vis-item.outstanding {
    background-color: blue !important;
  }
  .vis-item .vis-item-content {
    white-space: normal;
  }
}
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.pancake-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 28px;
  overflow: hidden;
}

.column {
  flex: 1 1 0px; /*  Stretching: */
  flex: 1 1 auto; /*  No stretching: */
  margin: 5px;
}

.stock {
  width: 100%;
}
</style>
