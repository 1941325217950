import {getTranslations} from "../../session/localstorage/getTranslations.js";

export function processColumnMetadata({columns}) {
  const translations = getTranslations();
  const newColumns = columns.slice();

  newColumns.map((column) => {
    if (column.Name === "Key") {
      column.IsVisible = true;
    }

    if (column.Name === "Description") {
      column.Ranking = 540;
    }

    return column;
  });

  newColumns.push({
    Name: "Title",
    Title: translations["Customer"],
    IsVisible: true,
    Ranking: 20,
    Type: "String",
  });

  const listFilters = ["Key", "Resources", "ResourceID", "TableName", "Status"];
  const dateFilters = ["DateTimeExecuteStart", "DateTimeExecuteEnd"];

  for (const column of newColumns) {
    if (listFilters.includes(column.Name)) {
      column.AllowFiltering = true;
    }
    if (dateFilters.includes(column.Name)) {
      column.AllowFiltering = true;
      column.Type = "Date";
    }
  }

  return newColumns;
}
