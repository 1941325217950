export function bundleRowsWithCriteria({rows}) {
  const saveRows = rows.map((row) => {
    return {
      Criteria: {},
      Values: {
        ...row,
      },
    };
  });

  return saveRows;
}
