<template>
  <div class="h-[31.5px]">
    <r-date-picker
      :value="value"
      :readonly="readonly"
      :input-id="fieldName"
      :filter-on-openings-day="filterOnOpeningsDay"
      :warehouse-id="closingDaysWarehouseId"
      :min-date="minDate"
      :max-date="maxDate"
      @input="handleInput($event)"
    />
  </div>
</template>

<script>
import {getWarehouseIdFromFormRow} from "../../../functions/form/getWarehouseIdFromFormRow.js";
import {formatDate} from "../../../util/formatting/formatDate";
import customParseFormat from "dayjs/plugin/customParseFormat";
import RDatePicker from "../../elements/RDatePicker.vue";
import dayjs from "dayjs";
dayjs.extend(customParseFormat);

export default {
  name: "DateField",
  components: {RDatePicker},
  props: {
    field: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    value() {
      return formatDate(this.field.Value);
    },
    filterOnOpeningsDay() {
      return (
        this.fieldName === "DateTimeExpectedStart" ||
        this.fieldName === "DateTimeExpectedEnd"
      );
    },
    closingDaysWarehouseId() {
      return getWarehouseIdFromFormRow();
    },
    minDate() {
      return this.field.MinDate ?? null;
    },
    maxDate() {
      return this.field.MaxDate ?? null;
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    readonly() {
      return this.field.IsReadOnly;
    },
  },
  methods: {
    handleInput(newDate) {
      this.$emit("input", {Value: newDate});
    },
  },
};
</script>
