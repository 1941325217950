export const processFormPostbackChanges = ({newData, changes}) => {
  let newRow = {...newData.Rows?.[0]};
  if (!newRow || !changes) return newData;

  Object.entries(changes).forEach(([key, value]) => {
    newRow[key] = value;
  });

  newData.Rows[0] = newRow;
  return newData;
};
