import {processRowsItemSerialStock} from "../row/processRowsItemSerialStock.js";
import {filterRowForStockCheck} from "../../row/filterRowForStockCheck.js";

export async function rowsStockSerialFetch({rows, order}) {
  const newRows = rows.slice();
  return Promise.all(
    newRows.map(async (row) => {
      if (filterRowForStockCheck(row)) {
        return processRowsItemSerialStock({row, rows, order});
      } else return row;
    }),
  );
}
