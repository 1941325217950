export const clearSerialIdWhenPickedQuantityIsZero = ({row}) => {
  if (row.PickedQuantity.Value === 0) {
    return {
      ...row,
      SerialID: {
        ...row.SerialID,
        Value: null,
      },
      StatusCode: {
        ...row.StatusCode,
        Value: 100,
      },
    };
  }
  return row;
};
