import {handleReturnAmountChange} from "./handleReturnAmountChange.js";
import {handleCounterValueChange} from "./handleCounterValueChange.js";

export function processColumnsMetaData({columns}) {
  const newColumns = columns.slice();
  newColumns.forEach((newColumn) => {
    newColumn.processValueChangeFunctions = [];
    newColumn.processValueChangeFunctionsForRows = [];

    if (newColumn.Name === "Amount") {
      newColumn.processValueChangeFunctions.push(handleReturnAmountChange);
    }

    if (newColumn.Name === "Counter") {
      newColumn.processValueChangeFunctions.push(handleCounterValueChange);
    }
  });

  return newColumns;
}
