<template>
  <div v-if="!headless" class="row tab-wrapper">
    <div class="col-xs-12 col-md-9">
      <tabs :tabs="session.tabs" />
    </div>
    <div class="col-xs-12 col-md-3">
      <div class="row">
        <div class="col-xs-12 col-lg-6">
          <div class="form-group mb-1">
            <warehouse-selector class="mt-3" />
          </div>
        </div>
        <div class="col-xs-12 col-lg-6">
          <div class="form-group">
            <quick-action-search class="mt-3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuickActionSearch from "./QuickActionSearch.vue";
import tabs from "./tabs/tabs.vue";
import WarehouseSelector from "./WarehouseSelector.vue";

export default {
  components: {QuickActionSearch, WarehouseSelector, tabs},
  props: {
    session: {
      required: true,
      type: Object,
    },
    headless: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
