import {api} from "@/services/api";
import store from "../../../state/store";
import {axiosInstance} from "../axiosInstance";

const makeRequest = ({
  instance,
  Uri,
  RequestBody,
  RequestParams,
  RequestHeaders,
}) =>
  instance.post(Uri, RequestBody, {
    params: {
      Warehouse: store.state.activeWarehouse,
      ...RequestParams,
    },
    headers: {
      ...RequestHeaders,
    },
  });

const postRequest = async ({
  Uri,
  RequestBody,
  RequestParams,
  RequestHeaders,
}) => {
  const instance = Uri.includes("Admin/WebServices") ? axiosInstance : api;
  const {data} = await makeRequest({
    instance,
    Uri,
    RequestBody,
    RequestParams,
    RequestHeaders,
  });

  return data;
};

export default postRequest;
