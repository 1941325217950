export const getCostAccountWithoutPriceConditions = ({
  row,
  inboundItems,
  priceConditions,
}) => {
  const itemID = row.ItemID.Value;

  if (itemID) {
    const hasCostAccountPriceConditions = priceConditions.some(
      (priceCondition) =>
        (!priceCondition.ItemID?.Key ||
          priceCondition.ItemID?.Key === itemID) &&
        priceCondition.CostAccountID?.Key,
    );
    // only reset the CostAccountID when there are price conditions that could've modified it. To prevent resetting user changes when all price conditions are irrelevant
    if (hasCostAccountPriceConditions) {
      const item = inboundItems.find(
        (inboundItem) => inboundItem.ItemID === itemID,
      );

      if (item?.CostAccountID?.Key) {
        return item.CostAccountID.Key;
      }
    }
  }

  return row.CostAccountID?.Value ?? null;
};
