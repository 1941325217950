export function loadComponents(contextRequire, regex) {
  const result = {};

  for (const key of contextRequire.keys()) {
    const regexMatch = regex.exec(key);

    if (!regexMatch) continue;

    const [, cleanName] = regexMatch;
    const component = contextRequire(key).default;

    result[cleanName] = component;
  }

  return result;
}
