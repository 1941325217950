import {convertKeyValueRowToCellRow} from "../row/convertKeyValueRowToCellRow";

export function convertKeyValueRowsToCellRows(rows) {
  const convertedRows = [];

  for (const row of rows) {
    convertedRows.push(convertKeyValueRowToCellRow(row));
  }

  return convertedRows;
}
