export const isRelevantPriceCondition = ({
  priceCondition,
  amount,
  currentPrice,
  warehouseId,
  itemID,
  itemContentID,
  hasAppliedNonStackingPriceCondition,
  changedColumnName,
  itemType,
  isCostRow,
}) => {
  if (!priceCondition) {
    return false;
  }

  if (isCostRow) {
    return false;
  }

  if (
    (priceCondition.WarehouseID?.Key ?? null) !== null &&
    priceCondition.WarehouseID.Key !== warehouseId
  ) {
    return false;
  }
  if (
    priceCondition.MinItemQty !== null &&
    amount < priceCondition.MinItemQty
  ) {
    return false;
  }

  if (
    (priceCondition.ItemID?.Key ?? null) !== null &&
    priceCondition.ItemID.Key !== itemID
  ) {
    return false;
  }

  if (
    (priceCondition.ItemContentID?.Key ?? null) !== null &&
    priceCondition.ItemContentID.Key !== itemContentID
  ) {
    return false;
  }

  if (
    priceCondition.MinTotalPrice !== null &&
    currentPrice * amount < priceCondition.MinTotalPrice
  ) {
    return false;
  }

  if (
    priceCondition.MaxTotalPrice !== null &&
    currentPrice * amount > priceCondition.MaxTotalPrice
  ) {
    return false;
  }

  // TODO: Reintroduce Itemtype on Inbound OrderItems
  // if (
  //   priceCondition.ItemType &&
  //   priceCondition.ItemType !== "All" &&
  //   priceCondition.ItemType !== itemType
  // ) {
  //   return false;
  // }

  if (
    priceCondition.ActionType === "ModifyRow" &&
    priceCondition.PriceType &&
    priceCondition.PriceType !== "None" &&
    (hasAppliedNonStackingPriceCondition ||
      changedColumnName === "PurchasePricePerUnit")
  ) {
    return false;
  }

  return true;
};
