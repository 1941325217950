<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <div ref="datagrid">
      <datagrid-standalone
        v-if="loaded && columns.length"
        :rows="rows"
        :actions="actions"
        :columns="columns"
        :window-id="rWindow.id"
        :user-metadata-key="userMetadataKey"
        :page="page"
        :page-size="pageSize"
        :skeleton-rows="addItemQueue.length"
        @loading="loading = $event"
        @click-row="handleRowClick"
        @cell-icon-click="handleCellIconClick($event)"
        @page-change="handlePageChange({page: $event})"
        @rows-updated="handleRowsUpdated"
      >
        <template #header>
          <datagrid-header-stock-transfer-item
            :loaded="loaded"
            :scan-amount="scanAmount"
            :settings="settings"
            :page-size="pageSize"
            @item-select="addItem"
            @page-size-change="handlePageSizeChange"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
import {convertDatagridRowsToDatagridScanRows} from "../../../functions/datagrid/stock-transfer-item/convertDatagridRowsToDatagridScanRows";
import {rowsSetNullValueForMissingProps} from "../../../functions/datagrid/rows/rowsSetNullValueForMissingProps";
import {setInitialDatagridDataToWindow} from "../../../functions/datagrid/data/setInitialDatagridDataToWindow.js";
import {confirmDiscardUnsavedChanges} from "../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {applyItemDescriptionToRows} from "../../../functions/datagrid/stock-transfer-item/applyItemDescriptionToRows";
import {detectDatagridDataChanges} from "../../../functions/datagrid/detectDatagridDataChanges.js";
import {convertRowToliRowDataRow} from "../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {setClientSideUUIDToRows} from "../../../functions/datagrid/rows/setClientSideUUIDToRows";
import {processColumnsMetaData} from "../../../functions/datagrid/stock-transfer-item/processColumnsMetaData";
import {assignValuesToRowProps} from "../../../functions/datagrid/stock-transfer-item/assignValuesToRowProps";
import {processColumnSettings} from "../../../functions/datagrid/columns/processColumnSettings";
import {handleDatagridService} from "../../../services/table/handleDatagridService.js";
import {integrateRowsChanges} from "../../../functions/datagrid/rows/integrateRowsChanges";
import {loadRowDataForPage} from "../../../functions/datagrid/stock-transfer-item/loadRowDataForPage";
import {handleJobExecution} from "../../../functions/datagrid/actions/handleJobExecution.js";
import {removePropFromRows} from "../../../functions/datagrid/stock-transfer-item/removePropFromRows";
import {mapActionComponent} from "../../../functions/datagrid/mapActionComponent";
import {assignDeletedRows} from "../../../functions/datagrid/stock-transfer-item/assignDeletedRows";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";
import {sortColumns} from "../../../functions/datagrid/columns/sortColumns";
import {addNewRows} from "../../../functions/datagrid/stock-transfer-item/addNewRows";
import {getColumns} from "../../../functions/datagrid/columns/getColumns";
import {getUserId} from "../../../functions/session/getUserId";
import {cloneDeep} from "lodash";

import DatagridHeaderStockTransferItem from "../datagridHeaders/DatagridHeaderStockTransferItem.vue";
import DatagridStandalone from "../DatagridStandalone.vue";

export default {
  name: "DatagridStockTransferItem",
  components: {
    DatagridHeaderStockTransferItem,
    DatagridStandalone,
  },
  provide() {
    return {
      translations: this.translations,
    };
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      loadingPage: false,
      criteria: [],
      rows: [],
      initialRows: [],
      originalRows: [],
      columns: {},
      page: 1,
      pageSize: 50,
      stockTransferId: null,
      window: {},
      activeRow: null,
      selectedItemId: null,
      addItemQueue: [],
      loading: false,
      scanAmount: 1,
      translations: getTranslations(),
      actions: [{type: "Delete"}].map(mapActionComponent),
    };
  },
  computed: {
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Warehouse.StockTransferItem-Multi`;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.criteria = this.rWindow.criteria ?? this.rParentWindow.criteria;
    this.stockTransferId = this.criteria[0].StockTransferID;

    let rows = this.rWindow.data.Rows;
    rows = applyItemDescriptionToRows({rows});

    let columns = await getColumns({
      table: "Warehouse.StockTransferItem",
      prefix: "MultiEdit",
      primaryKey: "StockTransferID",
      request: this.criteria,
    });

    columns = processColumnSettings(columns);
    columns = sortColumns(columns);

    rows = setClientSideUUIDToRows({rows});
    rows = rowsSetNullValueForMissingProps({rows, columns});

    this.rows = rows;
    rows = await this.loadAndApplyRowDataForPage();

    await new Promise((r) => setTimeout(r, 0));
    this.columns = [].concat(
      await processColumnsMetaData({
        columns,
        settings: this.settings,
      }),
    );

    this.rows = rows;
    this.originalRows = cloneDeep(this.rows);
    this.initialRows = assignValuesToRowProps({rows: this.rows});

    const columnsObject = this.columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {});

    setInitialDatagridDataToWindow({
      window: this.rWindow,
      columns: columnsObject,
      rows: this.rows,
      vueInstance: this,
    });

    this.loaded = true;
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async addItem(item) {
      const datagridElement =
        this.$refs.datagrid.querySelector(".datagrid-table");

      this.addItemQueue.push(item);
      if (this.addItemQueue.length > 1) {
        return;
      }

      let rows = cloneDeep(this.rows);

      const requestDataRows = removePropFromRows({
        prop: "Description",
        rows: rows,
      });

      const requestData = {
        subject: this.rWindow.subject,
        criteria: this.criteria,
        search: item.value,
        rows: requestDataRows,
        windowId: this.rWindow.id,
      };

      while (this.addItemQueue.length > 0) {
        const queuedItem = this.addItemQueue[0];
        const newRows = await addNewRows({
          item: queuedItem,
          rows: this.rows,
          scanAmount: this.scanAmount,
          requestData: requestData,
        });

        this.updateRows(newRows);
        this.updateWindowData();
        this.addItemQueue.shift();
      }

      this.rows = rowsSetNullValueForMissingProps({
        rows: this.rows,
        columns: this.columns,
      });

      this.navigateToLastPage();
      this.scrollToBottom(datagridElement);
    },
    async save(job) {
      try {
        this.$store.state.loading = true;
        document.activeElement.blur();

        while (this.loading || this.loadingPage) {
          await new Promise((r) => setTimeout(r, 0));
        }

        const valueAssignedRows = assignValuesToRowProps({rows: this.rows});

        const deletedAssignedRows = assignDeletedRows({
          initialRows: this.initialRows,
          newRows: valueAssignedRows,
        });

        const requestData = {
          data: [
            ...convertDatagridRowsToDatagridScanRows({
              rows: deletedAssignedRows,
            }),
          ],
          subject: "Warehouse.StockTransferItem",
          windowId: this.rWindow.id,
        };

        const currentDirtyState = this.rWindow.data.dirty;
        this.$emit("data-change", {
          windowId: this.rWindow.id,
          newData: {
            ...this.rWindow.data,
            dirty: false,
          },
        });

        const result = await handleDatagridService({
          operation: "saveTable",
          requestData,
        });

        if (result.status === 500)
          this.$emit("data-change", {
            windowId: this.rWindow.id,
            newData: {
              ...this.rWindow.data,
              dirty: currentDirtyState,
            },
          });
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);

        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }

        this.$store.state.loading = true;

        let rows = this.originalRows;
        rows = applyItemDescriptionToRows({rows});

        let columns = await getColumns({
          table: "Warehouse.StockTransferItem",
          prefix: "MultiEdit",
          primaryKey: "StockTransferID",
          request: this.criteria,
        });

        columns = processColumnSettings(columns);
        columns = sortColumns(columns);

        rows = setClientSideUUIDToRows({rows});
        rows = rowsSetNullValueForMissingProps({rows, columns});

        this.rows = rows;
        rows = await this.loadAndApplyRowDataForPage();

        await new Promise((r) => setTimeout(r, 0));
        this.columns = [].concat(
          await processColumnsMetaData({
            columns,
            settings: this.settings,
          }),
        );

        this.rows = rows;
        this.originalRows = cloneDeep(this.rows);
        this.initialRows = assignValuesToRowProps({rows: this.rows});

        const columnsObject = this.columns.reduce((acc, column) => {
          acc[column.Name] = column;
          return acc;
        }, {});

        setInitialDatagridDataToWindow({
          window: this.rWindow,
          columns: columnsObject,
          rows: this.rows,
          vueInstance: this,
        });
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async loadAndApplyRowDataForPage() {
      for (const row of this.rows) {
        row.SerialID.IsReadOnly = false;
        row.Description.IsReadOnly = true;
      }

      const changedRowsObject = await loadRowDataForPage({
        rows: this.rows,
        page: this.page,
        pageSize: this.pageSize,
      });

      return integrateRowsChanges({
        originalRows: this.rows,
        changedRows: changedRowsObject,
      });
    },
    updateRows(newRows) {
      newRows.forEach((newRow) => {
        const existingRow = this.rows.find(
          (row) => row.ClientSideUUID.Value === newRow.ClientSideUUID.Value,
        );
        if (existingRow) {
          // Update properties of the existing row
          Object.assign(existingRow, newRow);
        } else {
          // Add new row if it does not exist yet
          this.rows.push(newRow);
        }
      });
    },
    async handlePageChange({page}) {
      this.page = page;
      this.rows = await this.loadAndApplyRowDataForPage({
        rows: this.rows,
        page,
        pageSize: this.pageSize,
      });
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    scrollToBottom(element) {
      element.scrollTop = element.scrollHeight;
    },
    navigateToLastPage() {
      this.page = Math.ceil(this.rows.length / this.pageSize);
    },
    navigateToPage({page}) {
      this.page = page;
    },
    handleCellIconClick({row, cell}) {
      if (cell.Name === "Amount") {
        this.selectedItemId = row.ItemID.Value;
        setTimeout(() => {
          this.selectedItemId = null;
        }, 300);
      }
    },
    handleRowsUpdated({rows}) {
      this.rows = rows;
      this.updateWindowData();
    },
    updateWindowData() {
      const columnsObject = this.columns.reduce((acc, column) => {
        acc[column.Name] = column;
        return acc;
      }, {});

      const windowData = {
        ...this.rWindow.data,
        Columns: columnsObject,
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },
    handleRowClick(row) {
      this.updateActiveRow(row);
    },
    updateActiveRow(row) {
      this.activeRow = row;
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
