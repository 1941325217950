import store from "../../../../state/store.js";

export function sortReturnItemRows({rows}) {
  const {SortCheckedRows} = store.state.settings ?? null;
  const newRows = [...rows];

  const isAscending = SortCheckedRows === "Upwards";

  return newRows.sort((a, b) => {
    if (a.Checked.Value !== b.Checked.Value) {
      return isAscending
        ? a.Checked.Value
          ? -1
          : 1
        : a.Checked.Value
        ? 1
        : -1;
    }

    if (a.Amount.Value > 1 && b.Amount.Value > 1) {
      return isAscending
        ? b.Amount.Value - a.Amount.Value
        : a.Amount.Value - b.Amount.Value;
    } else if (a.Amount.Value > 1) {
      return isAscending ? -1 : 1;
    } else if (b.Amount.Value > 1) {
      return isAscending ? 1 : -1;
    }

    return 0;
  });
}
