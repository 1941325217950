export const handleNewJob = async ({job, vueInstance, windowId}) => {
  // Add the job to the list of jobs
  const jobs = vueInstance.rWindows[windowId]?.jobs ?? [];
  vueInstance.rWindows = {
    ...vueInstance.rWindows,
    [windowId]: {
      ...vueInstance.rWindows[windowId],
      jobs: [...jobs, job],
    },
  };
  // wait 1 tick
  await vueInstance.$nextTick();

  global.eventBus.emit(`new-job-${windowId}`, job);
};
