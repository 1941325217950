import {setClientSideUUIDToRow} from "@/functions/datagrid/row/setClientSideUUIDToRow";
import {convertKeyValueRowToCellRow} from "../row/convertKeyValueRowToCellRow";
import {processRowMetadata} from "./row/processRowMetadata";
import {processRowPrices} from "./row/processRowPrices.js";
import {setCorrectRankingToRow} from "../row/setCorrectRankingToRow";
import {setDefaultVatCodeID} from "../inbound-orderitem/rows/setDefaultVatCodeID";
import {handleVATCodeIDChangeOnRow} from "../inbound-orderitem/rows/handleVatCodeIDChangeOnRow";
import {processInvoiceDiscrepancy} from "@/functions/datagrid/inbound-invoice-item/rows/processInvoiceDiscrepancy";

export const getRowFromScannedItem = async ({
  item,
  rows,
  columns,
  supplier,
  vatCodes,
}) => {
  const descriptionMappedRow = {
    ...item,
    ItemDescription: item.Description,
  };
  const priceMappedRow = {
    ...descriptionMappedRow,
    Price: descriptionMappedRow.UnitPrice,
  };

  const amountMappedRow = {
    ...priceMappedRow,
    Amount: 1,
  };

  const cellRow = convertKeyValueRowToCellRow(amountMappedRow);

  const identifiedRow = setClientSideUUIDToRow({row: cellRow});

  const vatCodeRow = setDefaultVatCodeID({row: identifiedRow, supplier});

  const metadataProcessedRow = processRowMetadata({
    row: vatCodeRow,
    rows,
    columns,
  });

  const vatPercentageRow = handleVATCodeIDChangeOnRow({
    row: metadataProcessedRow,
    vatCodes,
  });

  const priceProcessedRow = processRowPrices({row: vatPercentageRow});

  const invoiceDiscrepancyProcessedRow = processInvoiceDiscrepancy([
    priceProcessedRow,
  ])[0];

  return setCorrectRankingToRow({row: invoiceDiscrepancyProcessedRow, rows});
};
