import {api} from "../../api";
import {normalizeGetInvoiceItems} from "@/services/v2/invoices/normalizeGetInvoiceItems";

export const getInvoiceItems = async ({invoiceId, include}) => {
  const result = await api.get(`/v2/invoices/${invoiceId}/items`, {
    windowId: global.session.activeWindow.id,
    params: {
      include: include.join(','),
    }
  });

  return normalizeGetInvoiceItems({result});
};
