import {sumValues} from "../../../../util/math/sumValues";
import {setValuesInRow} from "../row/setValuesInRow";
import Decimal from "decimal.js";

export const correctRowInboundValue = ({row, rows}) => {
  const totalExcVat = sumValues(rows.map((row) => row.TotalExcVAT?.Value ?? 0));
  const totalInboundValue = sumValues(
    rows.map((row) => row.InboundValue?.Value ?? 0),
  );

  if (totalExcVat === totalInboundValue) {
    return rows;
  }

  const correctedValue = new Decimal(totalExcVat)
    .minus(totalInboundValue)
    .plus(row.InboundValue?.Value ?? 0)
    .toNumber();

  return rows.map((rowToUpdate) => {
    if (rowToUpdate.ClientSideUUID.Value !== row.ClientSideUUID.Value) {
      return rowToUpdate;
    }
    return setValuesInRow({
      row: rowToUpdate,
      values: {InboundValue: correctedValue},
    });
  });
};
