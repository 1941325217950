import {getActiveWindow} from "../window/getActiveWindow.js";

export const processLegacyWindowForm = ({rWindowData}) => {
  const window = getActiveWindow();

  const newRow = rWindowData.Rows[0];
  const newColumns = rWindowData.Columns;
  const legacyRow = window.output?.Table?.Rows[0];

  if (!newRow || !legacyRow) return;

  legacyRow.forEach((cell) => {
    cell.NewValue = newRow[cell.Column.Name];
    cell.Column = {
      ...cell.Column,
      IsReadOnly: newColumns[cell.Column.Name].IsReadOnly,
    };
    cell.IsDirty = newColumns[cell.Column.Name]?.IsDirty;
  });
};
