import {cloneDeep} from "lodash";
import {convertRowToliRowDataRow} from "../order-item/row/convertRowToliRowDataRow";

export const setInitialDatagridDataToWindow = ({
  window,
  vueInstance,
  columns,
  rows,
}) => {
  const windowData = {
    ...window.data,
    Columns: columns,
    Rows: rows.map(convertRowToliRowDataRow),
  };
  vueInstance.$emit("data-change", {
    windowId: window.id,
    newData: windowData,
  });
  vueInstance.$emit("initial-data-change", {
    windowId: window.id,
    newData: cloneDeep(windowData),
  });
};
