
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/window.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent")?runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent")),"element")),"offsetLeft") + 20 || 0:0);
frame.set("xpos", t_1, true);
if(frame.topLevel) {
context.setVariable("xpos", t_1);
}
if(frame.topLevel) {
context.addExport("xpos", t_1);
}
output += "\n";
var t_2;
t_2 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent")?runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent")),"element")),"offsetTop") + 20 || 0:0);
frame.set("ypos", t_2, true);
if(frame.topLevel) {
context.setVariable("ypos", t_2);
}
if(frame.topLevel) {
context.addExport("ypos", t_2);
}
output += "\n<div class=\"window ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent")?"child":" parent"), env.opts.autoescape);
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent")),"sub")),"dummy")?" dummy":""), env.opts.autoescape);
output += "\"\n\tdata-window-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"id"), env.opts.autoescape);
output += "\" \n\t\n\tstyle=\"left:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "xpos"), env.opts.autoescape);
output += ";top:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ypos"), env.opts.autoescape);
output += "; z-index:";
output += runtime.suppressValue((!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent")?runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"tabs")),"length"):"1"), env.opts.autoescape);
output += "0; \">\n\n\n\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"empty")) {
output += "\n\t\t<div class=\"window-body w-100\">\n\t\t\t<div class=\"scroll-container\">\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"col-12  mr-3\">\n\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Request")),"Prefix") == "Multi" || runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"Type") == "returnview") {
output += "\n\t\t\t\t\t\t\t<div class=\"row no-gutters multi-row\">\n\t\t\t\t\t\t\t\t<div class=\"col col-xs-12 ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "childrenHtml")),"length") > 0?"col-sm-6 col-md-4":""), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t<div class=\"window-content mb-0\">\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "body")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t<div class=\"overlay";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"renderOptions")),"overlay")?" hide":""), env.opts.autoescape);
output += "\"></div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "childrenHtml")),"length") > 0) {
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"col col-xs-12 col-md-8 col-sm-6 panel left-border\">\n\t\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_5 = runtime.contextOrFrameLookup(context, frame, "childrenHtml");
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("childHtml", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
output += "\n\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("safe").call(context, t_6), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Request")),"Prefix")) {
output += "\n\t\t\t\t\t\t\t<div class=\"window-content\">\n\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "body")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t<div class=\"overlay";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"renderOptions")),"overlay")?" hide":""), env.opts.autoescape);
output += "\"></div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t";
frame = frame.push();
var t_9 = runtime.contextOrFrameLookup(context, frame, "childrenHtml");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("childHtml", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("safe").call(context, t_10), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t<div class=\"window-content\">\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "body")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t<div class=\"overlay";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"renderOptions")),"overlay")?" hide":""), env.opts.autoescape);
output += "\"></div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t";
frame = frame.push();
var t_13 = runtime.contextOrFrameLookup(context, frame, "childrenHtml");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("childHtml", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("safe").call(context, t_14), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t\t\t\t";
;
}
;
}
output += "\n\t\t\t\t\t</div>\n\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"error")) {
output += "\n\t\t\t\t\t\t<div class=\"cover dim v-center\">\n\t\t\t\t\t\t\t<div class=\"msg-box-holder\">\n\t\t\t\t\t\t\t\t<div class=\"msg-box error\">\n\t\t\t\t\t\t\t\t\t<img src=\"/App_Themes/images/error.svg\">\n\t\t\t\t\t\t\t\t\t<h1 class=\"msg-header\">\n\t\t\t\t\t\t\t\t\t\t<span class=\"msg-header-text\">Error</span></h1>\n\t\t\t\t\t\t\t\t\t<p class=\"msg-text\">";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"error")),"data") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"error")),"data")),"Data") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"error")),"data")),"Data")),"Translation")?runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"error")),"data")),"Data")),"Translation"):"Er is een error opgetreden bij het opvragen van de gegevens."), env.opts.autoescape);
output += "</p>\n\n\t\t\t\t\t\t\t\t\t<div class=\"msg-actions\">\n\t\t\t\t\t\t\t\t\t\t<button class=\"button button-default left\" data-window-event=\"reload\">Opnieuw proberen</button>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t<div class=\"upload-info-box cover dim v-center hide\">\n\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t<span>Upload file</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\n\n\t\t</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/window.html", ctx);
          }
        
          return nunjucks.render("views/elements/window.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/window.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        