export function setExpectedDeliveryDate({itemDays, supplierDays}) {
  //If both the item and supplier have expected delivery dates, the item gets priority
  if (itemDays !== null) {
    return itemDays;
  }
  if (supplierDays !== null) {
    return supplierDays;
  }
  return null;
}
