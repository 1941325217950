import {getRawValueFromDropdownValue} from "../../../../util/parsing/getRawValueFromDropdownValue.js";
import {processUseSinglePeriodPriceDifference} from "../../row/processUseSinglePeriodPriceDifference";
import {processUseSinglePeriodPrice} from "../../row/processUseSinglePeriodPrice";
import {getActiveWarehouse} from "../../../../util/getActiveWarehouse.js";

export async function processUseSinglePeriodPriceChange({row, order}) {
  let newRow = {...row};
  const priceStartDate =
    row.DateTimeRentalStart?.Value ?? row.DateTimeBusinessStart.Value;
  const priceEndDate =
    row.DateTimeRentalEnd?.Value ?? row.DateTimeBusinessEnd.Value;

  const periodAmount = row.PeriodAmount.Value;
  const period = row.Period.Value;

  if (!periodAmount || !period) return newRow;

  const params = {
    WarehouseID: getActiveWarehouse(),
    CustomerID: getRawValueFromDropdownValue(order.CustomerID),
    OrderID: order.OrderID,
  };

  newRow = await processUseSinglePeriodPrice({
    row,
    params,
    periodAmount,
    startDate: priceStartDate,
    endDate: priceEndDate,
  });

  newRow = await processUseSinglePeriodPriceDifference({
    row,
    params,
    periodAmount,
    startDate: row.DateTimeBusinessStart.Value,
    endDate: row.DateTimeBusinessEnd.Value,
  });

  return newRow;
}
