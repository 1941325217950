import {setValueInRow} from "../row/setValueInRow";

export function handleVATCodeIDChangeOnRow({row, vatCodes}) {
  const vatCode = vatCodes?.find(
    (vatCode) => vatCode.VATCodeID === row?.VATCodeID?.Value,
  );

  const vatPercentage =
    vatCode?.Percentage ??
    parseFloat(row?.VATCodeID?.SelectedOption?.Attributes?.Percentage);

  return setValueInRow({
    row,
    columnName: "VATPercentage",
    value: vatPercentage,
  });
}
