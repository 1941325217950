import store from "@/../state/store";
export function addDimensionValueToRow({row, dimension}) {
  const newRow = {...row};
  if (!newRow[dimension.DimensionID.Description]) {
    const Items = [];

    const value =
      newRow.Dimensions?.Value[`DimensionID-${dimension.DimensionID.Key}`];

    for (const dimensionObject of dimension.DimensionObjects) {
      Items.push({
        Value: dimensionObject.value,
        Text: dimensionObject.label,
      });
    }

    newRow[`DimensionID-${dimension.DimensionID.Key}`] = {
      Value: value ?? null,
      Name: dimension.DimensionID.Description,
      Dropdown: {Items},
    };
  }
  return newRow;
}
