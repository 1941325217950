<template>
  <div>
    <a
      class="btn-secondary btn-sm btn headless-link"
      :href="href.replace('&Headless=true', '')"
      target="__blank"
    >
      {{ translations.OpenInRentMagic }}
    </a>
    <canvas-save-button
      :subject="subject"
      :window="mainWindow"
      :translations="translations"
    />
  </div>
</template>
<script>
import CanvasSaveButton from "./CanvasSaveButton.vue";

export default {
  components: {CanvasSaveButton},
  props: {
    subject: {
      type: String,
      required: true,
    },
    mainWindow: {
      type: Object,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
  },
  computed: {
    href() {
      return window.location.href;
    },
  },
};
</script>
