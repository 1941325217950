<template>
  <div class="w-full h-full">
    <date-range-picker
      ref="date-range-picker"
      :date-range="calendarDate"
      :min-date="minDate"
      :max-date="maxDate"
      :time-picker="timePicker"
      :append-to-body="true"
      :calculate-position="withPopper"
      opens="center"
      :show-dropdowns="true"
      :auto-apply="true"
      :ranges="false"
      :single-date-picker="true"
      :locale-data="getDateRangePickerDataFormat()"
      :date-format="dateFormat"
      @update="handleCalendarInput($event)"
      @change-month="updateClosingDays($event)"
    />

    <div class="flex relative w-full h-full">
      <input
        :id="inputId"
        :value="value"
        class="w-full h-full"
        :class="[
          customStyle,
          {
            'bg-[#eeeeee] text-[#888] cursor-not-allowed': readonly,
          },
        ]"
        maxlength="10"
        :readonly="readonly"
        :title="value"
        @change="handleFieldInput"
      />
      <r-button
        v-if="!readonly"
        class="absolute -right-1.5 z-10"
        variant="link"
        @mousedown="togglePicker()"
        ><i class="fas fa-calendar-alt text-dark"></i
      ></r-button>
    </div>
  </div>
</template>

<script>
import {getDateRangePickerDataFormat} from "../../functions/fields/date-field/getDateRangePickerDataFormat.js";
import {parseValueToDate} from "../../functions/date/parseValueToDate.js";
import {getClosingDays} from "../../services/v2/closingdays/getClosingDays.js";
import {createPopper} from "@popperjs/core";
import DateRangePicker from "../calendar/DateRangePicker.vue";
import RButton from "./RButton.vue";
import dayjs from "dayjs";
import {generateID} from "../../util/generateID.js";

export default {
  name: "RDatePicker",
  components: {RButton, DateRangePicker},
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    inputId: {
      type: String,
      required: false,
      default: "",
    },
    minDate: {
      type: String,
      required: false,
      default: null,
    },
    maxDate: {
      type: String,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    warehouseId: {
      type: String,
      required: false,
      default: null,
    },
    filterOnOpeningsDay: {
      type: Boolean,
      required: false,
      default: false,
    },
    timePicker: {
      type: Boolean,
      required: false,
      default: false,
    },
    customStyle: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      closingDays: [],
      fetchedMonthsWithClosingDays: [],
      name: generateID(3),
    };
  },
  computed: {
    calendarDate() {
      return this.value
        ? {startDate: dayjs(this.value, "DD-MM-YYYY").toDate()}
        : {startDate: null};
    },
  },
  watch: {
    value: {
      handler(value) {
        this.updateCalendarView(value);
      },
      deep: true,
    },
  },
  created() {
    this.updateClosingDays(this.calendarDate?.startDate);
  },
  methods: {
    handleFieldInput(event) {
      let newDate = event?.target?.value ?? event;

      if (dayjs.isDayjs(newDate)) {
        newDate = dayjs(event).format("DD-MM-YYYY");
      }
      let parsedDate = parseValueToDate(newDate);

      if (!this.isValidDate(parsedDate)) {
        parsedDate = null;
      }

      this.$emit("input", parsedDate);
    },
    handleCalendarInput(event) {
      const newDate = dayjs(event.startDate).format("YYYY-MM-DD");
      this.$emit("input", newDate);
    },
    updateCalendarView(date) {
      if (dayjs(date, "DD-MM-YYYY").isValid())
        this.$refs["date-range-picker"].monthDate = dayjs(
          date,
          "DD-MM-YYYY",
        ).toDate();
    },
    togglePicker() {
      this.$refs["date-range-picker"].openPicker();
    },
    async updateClosingDays(date) {
      if (!this.filterOnOpeningsDay) return;

      if (!date) date = new Date();

      if (
        this.fetchedMonthsWithClosingDays.includes(
          dayjs(date).format("YYYY-MM-DD"),
        )
      )
        return;

      const start = dayjs(date).subtract("2", "month").format("YYYY-MM-DD");
      const end = dayjs(date).add("2", "month").format("YYYY-MM-DD");

      const closingDays = await getClosingDays({
        warehouse: this.warehouseId,
        dateFrom: start,
        dateTo: end,
      });

      this.closingDays = this.closingDays.concat(closingDays.data);
      this.fetchedMonthsWithClosingDays.push(dayjs(date).format("YYYY-MM-DD"));
    },
    isValidDate(date) {
      return (
        dayjs(date, ["YYYY-MM-DD", "DD-MM-YYYY"]).format("DD-MM-YYYY") !==
        "Invalid Date"
      );
    },
    dateFormat(classes, date) {
      if (this.closingDays.length > 0) {
        for (const disabledDate of this.closingDays) {
          if (dayjs(disabledDate).isSame(dayjs(date), "day")) {
            classes.disabled = true;
          }
        }
      }
      return classes;
    },
    getDateRangePickerDataFormat,
    withPopper(dropdownList, component, {width}) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "auto",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 32],
            },
          },
          {
            name: "flip",
            options: {
              allowedAutoPlacements: ["bottom"],
            },
          },
        ],
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },
  },
};
</script>
