var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-field"},[_c('div',{staticClass:"flex items-center h-full"},[_c('div',{staticClass:"max-h-full shrink-0",on:{"click":_vm.handleFieldClick}},[_c('upload-icon',{class:{
          'fill-gray-600 hover:fill-black cursor-pointer my-2 h-1': true,
        }})],1),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.onFilePicked,"click":_vm.handleFieldClick}}),_c('div',{staticClass:"h-[80%] w-[1px] mx-1 bg-gray-300 shrink-0"}),(!_vm.fileName)?_c('div',{staticClass:"text-gray-500 cursor-pointer shrink-0",on:{"click":_vm.handleFieldClick}},[_vm._v(" "+_vm._s(_vm.translations.FileUploadButtonText)+"... ")]):_vm._e(),(_vm.fileName)?_c('div',{staticClass:"text-gray-600 pr-2 flex w-full min-w-0",attrs:{"title":_vm.fileName}},[_c('div',{staticClass:"cursor-pointer truncate flex-1 min-w-0",attrs:{"title":_vm.fileName},on:{"click":function($event){$event.preventDefault();return _vm.downloadBase64({
            base64: _vm.base64File,
            filename: _vm.fileName,
            setDefaultPrefix: false,
          })}}},[_c('download-icon',{staticClass:"fill-gray-600 hover:fill-black h-1 shrink-0 inline"}),_c('span',{staticClass:"text-black truncate",attrs:{"href":_vm.base64File,"download":_vm.fileName}},[_vm._v(" "+_vm._s(_vm.fileName)+" ")])],1),_c('div',{staticClass:"ml-2 flex items-center shrink-0",on:{"click":function($event){$event.preventDefault();return _vm.handleInput(null)}}},[_c('i',{staticClass:"fas fa-times cursor-pointer"})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }