import {getNotificationRowAddedMessage} from "../getNotificationRowAddedMessage.js";
import {getItemDescriptionFromScanRow} from "@/functions/datagrid/stock-transfer-item/getItemDescriptionFromScanRow";
import {checkScanDuplicateSerialItem} from "@/functions/datagrid/stock-transfer-item/checkScanDuplicateSerialItem";
import {setClientSideUUIDToRow} from "@/functions/datagrid/row/setClientSideUUIDToRow";
import {handleDatagridService} from "../../../services/table/handleDatagridService.js";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";
import {notify} from "@/util/notify";

import {v4 as uuidv4} from "uuid";
import dayjs from "dayjs";

export async function addNewRows({rows, scanAmount, requestData}) {
  const translations = getTranslations();
  let scannedRows = [];

  try {
    const result = await handleDatagridService({
      operation: "newScanRow",
      requestData: requestData,
    });

    if (!result.data) throw new Error();

    scannedRows =
      result.data.ComponentActions[0]?.Data?.Rows ??
      result.data.ComponentActions[0]?.Data?.Row;

    if (scannedRows === undefined) return rows;

    const isDoubleSerial = checkScanDuplicateSerialItem({
      oldRows: rows,
      newRows: scannedRows,
    });

    if (isDoubleSerial) {
      await notify({
        message: translations.AdminDesktop_AlertDoubleSerial,
        type: "danger",
      });

      return rows;
    }
  } catch (error) {
    console.error(error);
    return rows;
  }

  if (!Array.isArray(scannedRows)) {
    scannedRows = [
      {
        ...scannedRows,
      },
    ];
  }

  for (let row of scannedRows) {
    row.Description = {
      Value: getItemDescriptionFromScanRow({row}),
      IsReadOnly: true,
      Editor: "String",
    };

    row.DateTimeScheduledReceiving.Value = dayjs(
      row.DateTimeScheduledReceiving.Value,
    ).format("YYYY-MM-DDTHH:MM:ss");

    row.DateTimeScheduledShipment.Value = dayjs(
      row.DateTimeScheduledShipment.Value,
    ).format("YYYY-MM-DDTHH:MM:ss");

    row = setClientSideUUIDToRow({row});

    row.Amount.Value = (scanAmount ?? 1) * row.Amount.Value;
    row.StockTransferItemID.Value = `NEW_${uuidv4()}`;
    row.SerialID.IsReadOnly = false;

    rows.push(row);
  }

  if (scannedRows.length > 0)
    notify({
      message: getNotificationRowAddedMessage({scannedRows}),
      type: "success",
    });

  return rows;
}
