import {getRWindowById} from "../../functions/window/getRWindowById.js";

/**
 * Check if dirty values exists
 * @returns {boolean} True if the window is dirty
 */
export default function isDirty() {
  const formWindow = this.sub?.window ?? this;

  if (!formWindow || formWindow.output.Request.Prefix === "Multi") return false;

  const newRow = formWindow.output?.Table?.Rows[0];

  const rWindow = getRWindowById({windowId: formWindow.id});
  if (rWindow?.vueComponent) return rWindow.data.dirty ?? false;

  return newRow ? newRow.find((cell) => cell.IsDirty) : false;
}
