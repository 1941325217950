import store from "../../state/store";
import {nonActionAxiosInstance} from "./nonActionAxiosInstance";

export async function newWindow({subject, actionName, criteria, prefix}) {
  const Criteria = criteria || [{"WarehouseID 0=": ["VERHUUR01 - Terneuzen"]}];
  const Prefix = prefix || "Multi";
  const TableToSelect = criteria ? null : "Rental.vw_WarehouseItem";
  const Data = criteria
    ? null
    : {
        Search: [],
        Filters: {},
        SortOn: null,
        SortReverse: false,
        SelectedTabActionName: null,
        PageNumber: 1,
        PageSize: 100,
        ShowCurrencyToggle: false,
        ToggleCurrency: false,
        ClientCurrency: null,
        OldClientCurrency: null,
        ForeignCurrency: null,
      };

  const data = {
    selectionInfo: {
      ActionName: actionName,
      Arguments: {},
      Data: {
        Changes: [],
        Selection: [],
      },

      Request: {
        Data,
        Criteria,
        IsSubWindow: false,
        Subject: subject,
        TableToSelect,
        ParentSubject: "",
        LinkedSubject: null,
        Prefix,
        RunDesktopHooks: true,
        RequestType: 3,
      },
    },
  };

  const result = await nonActionAxiosInstance.post(
    "/Admin/WebServices/CoreWebServices.asmx/OpenNew",
    data,
    {
      params: {
        warehouse: store.state.activeWarehouse,
      },
    },
  );
  return result;
}
