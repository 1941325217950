export const processRowCellEditors = ({row}) => {
  if (row.UseSerials?.Value)
    return {
      ...row,
      Amount: {
        ...row.Amount,
        Editor: "Int",
      },
    };

  return row;
};
