import translation from "../../../util/translation.js";

export function setSaleableItemTypeMetaData({row, itemType}) {
  row.ItemType.Dropdown = {
    Items: [
      {
        Text: translation("ItemTypeSales"),
        Value: "Sales",
      },
      {
        Text: itemType.Text ?? translation(`ItemType${itemType}`),
        Value: itemType,
      },
    ],
  };

  return row;
}
