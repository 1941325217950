import {getTranslations} from "../../session/localstorage/getTranslations.js";

export const getDateRangePickerDataFormat = () => {
  const translations = getTranslations();

  return {
    direction: "ltr",
    format: "dd-mm-yyyy",
    separator: " - ",
    changed: false,
    applyLabel: "Apply",
    cancelLabel: "Cancel",
    weekLabel: "W",
    customRangeLabel: "Custom Range",
    daysOfWeek: [
      translations["calendar-sun"],
      translations["calendar-mon"],
      translations["calendar-tue"],
      translations["calendar-wed"],
      translations["calendar-thu"],
      translations["calendar-fri"],
      translations["calendar-sat"],
    ],
    monthNames: [
      translations["calendar-jan"],
      translations["calendar-feb"],
      translations["calendar-mar"],
      translations["calendar-apr"],
      translations["calendar-may"],
      translations["calendar-jun"],
      translations["calendar-jul"],
      translations["calendar-aug"],
      translations["calendar-sep"],
      translations["calendar-oct"],
      translations["calendar-nov"],
      translations["calendar-dec"],
    ],
    firstDay: 0,
  };
};
