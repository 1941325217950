import Decimal from "decimal.js";
import {calculateTotalPrice} from "./process-row-prices/calculateTotalPrice";
import {calculateTotalVAT} from "./process-row-prices/calculateTotalVAT";

const calculateTotalIncVAT = (totalPrice, totalVAT) => {
  return new Decimal(totalPrice).plus(totalVAT).toFixed(3);
};

export const processRowPrices = ({row}) => {
  if (row.rowMeta?.virtual) return {...row};

  const totalPrice = calculateTotalPrice(row);

  const totalVAT = calculateTotalVAT(row, totalPrice);
  const totalIncVAT = calculateTotalIncVAT(totalPrice, totalVAT);

  return {
    ...row,
    TotalVAT: {
      ...row.TotalVAT,
      Value: totalVAT,
    },
    TotalIncVAT: {
      ...row.TotalIncVAT,
      Value: totalIncVAT,
    },
    TotalExcVAT: {
      ...row.TotalExcVAT,
      Value: totalPrice.toNumber(),
    },
  };
};
