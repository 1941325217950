<template>
  <div class="max-w-md">
    <p class="ml-1">{{ componentData.message }}</p>
    <r-button
      variant="secondary"
      :override-classes="'border-solid border-[1px] border-transparent mb-2'"
      @click="handleCorrectRowClick"
    >
      {{ translations.ButtonCorrectRowInboundValue }} ({{ correctionAmount }})
    </r-button>
    <r-button
      v-if="showDistributeButton"
      variant="secondary"
      :override-classes="'border-solid border-[1px] border-transparent mb-2'"
      @click="handleDistributeClick"
    >
      {{ translations.ButtonDistributeInboundValue }}
    </r-button>
  </div>
</template>

<script>
import RButton from "../../elements/RButton.vue";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations";
import {formatDecimal} from "../../../util/formatting/formatDecimal";

export default {
  name: "DatagridInboundOrderItemInboundValuePopup",
  components: {RButton},
  props: {
    row: {
      type: Object,
      required: true,
    },
    componentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      translations: {},
    };
  },

  computed: {
    showDistributeButton() {
      return (
        (this.componentData.costDistributionType ?? "Disable") !== "Disable"
      );
    },
    correctionAmount() {
      const valueAsString = formatDecimal(
        this.componentData.correctionAmount ?? 0,
      );
      if (this.componentData.correctionAmount > 0) {
        return "+" + valueAsString;
      }
      return valueAsString;
    },
  },
  created() {
    this.translations = getTranslations();
  },
  methods: {
    handleDistributeClick() {
      this.$emit("icon-event", {action: "Distribute", row: this.row});
    },
    handleCorrectRowClick() {
      this.$emit("icon-event", {action: "CorrectRow", row: this.row});
    },
  },
};
</script>

<style scoped></style>
