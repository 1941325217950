export const getInitialFilters = ({translations}) => {
  return [
    {
      Type: "List",
      Target: "Key",
      title: translations["Key"],
      placeholder: "#",
      Selected: [],
      Options: [],
      loadOptionsOnCreated: true,
    },
    {
      Type: "List",
      Target: "TableName",
      Id: "TableName",
      title: translations["TableName"],
      placeholder: translations["TableName"],
      Selected: [],
      Options: [
        {Key: "Rental.Quotation", Title: translations["Rental.Quotation"]},
        {Key: "Rental.Reservation", Title: translations["Rental.Reservation"]},
        {Key: "Rental.Order", Title: translations["Rental.Order"]},
        {Key: "Rental.Invoice", Title: translations["Rental.Invoice"]},
        {Key: "Rental.Service", Title: translations["Rental.Service"]},
        {
          Key: "Inbound.Order",
          Title: translations["Inbound.Order"],
        },
        {
          Key: "Inbound.Invoice",
          Title: translations["Inbound.Invoice"],
        },
      ],
    },
    {
      Type: "List",
      Target: "ResourceID",
      Id: "ResourceID",
      title: translations["ResourceID"],
      placeholder: translations["ResourceID"],
      Selected: [],
      Options: [],
      loadOptionsOnCreated: true,
      disableBranchNodes: false,
    },
    {
      Type: "List",
      Target: "status",
      title: translations["Status"],
      placeholder: "Status",
      Selected: [],

      Options: [
        {Key: "Open", Title: translations["StatusOpen"]},
        {Key: "Closed", Title: translations["StatusClosed"]},
        {Key: "Cancelled", Title: translations["StatusCancelled"]},
        {Key: "InProgress", Title: translations["InProgress"]},
      ],
    },
    {
      Type: "Date",
      Target: "DateTimeExecuteStart",
      title: translations["DateTimeExecuteStart"],
      placeholder: translations["DateTimeExecuteStart"],
      Selected: null,
      Options: [],
      Suffix: ">=",
      Ranking: 0,
    },
    {
      Type: "Date",
      Target: "DateTimeExecuteEnd",
      title: translations["DateTimeExecuteEnd"],
      placeholder: translations["DateTimeExecuteEnd"],
      Selected: null,
      Options: [],
      Suffix: "<=",
      Ranking: 10,
    },
    {
      Type: "List",
      title: translations["Resources"],
      Target: "Resources",
      placeholder: translations["Resources"],
      Selected: [],
      Options: [],
      loadOptionsOnCreated: true,
    },
  ];
};
