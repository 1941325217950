import {setRowsCustomPropertiesToCustomPropertyObject} from "../../rows/setRowsCustomPropertiesToCustomPropertyObject.js";
import {rowsProcessWebhook} from "./rowsProcessWebhook";
import {hasDataHookEvent} from "../../../../hooks/hasDataHookEvent";

export const applyRentalQuotationItemPricesUpdatedDraftHook = async ({
  rows,
  row,
  quotation,
  dataWebHooks,
}) => {
  if (
    !hasDataHookEvent({
      dataWebHooks,
      eventId: "rental.quotationitem.prices.updated.draft",
    })
  ) {
    return rows;
  }
  let newRows = await rowsProcessWebhook({rows, quotation, row});
  return setRowsCustomPropertiesToCustomPropertyObject({
    rows: newRows,
  });
};
