export function checkServerErrorNotification({result}) {
  if (result?.GlobalActions[0]?.Data?.Type === "danger") {
    return "error";
  }

  if (result?.Data?.Type === "danger") {
    return "error";
  }

  return result;
}
