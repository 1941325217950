import {rowsStockFetch} from "@/functions/datagrid/order-item/rows/rowsStockFetch";
import {rowsProcessPopupStockPredictions} from "@/functions/datagrid/order-item/rows/rowsProcessPopupStockPredictions";
import {rowsVirtualResumTotalExcVat} from "@/functions/datagrid/order-item/rows/rowsVirtualResumTotalExcVat";
import {rowsVirtualResumCalculatedPrice} from "@/functions/datagrid/order-item/rows/rowsVirtualResumCalculatedPrice";
import {rowSetCorrectAmountForVirtualCompositionAfterAmountChangeRow} from "@/functions/datagrid/rows/rowsSetCorrectAmountForVirtualCompositionAfterAmountChangeRow";
import {physicalItemRowsStockFetch} from "../physicalItemRowsStockFetch.js";

export const rowsAmountChangeWrapper = async ({row, rows, order}) => {
  let modifiedRows = rows.slice();

  modifiedRows = await rowsStockFetch({rows: modifiedRows, order});
  modifiedRows = await physicalItemRowsStockFetch({rows: modifiedRows, order});
  modifiedRows = await rowsProcessPopupStockPredictions({
    row,
    rows: modifiedRows,
    order,
  });
  modifiedRows = await rowsVirtualResumTotalExcVat({
    row,
    rows: modifiedRows,
    order,
  });
  modifiedRows = await rowsVirtualResumCalculatedPrice({
    row,
    rows: modifiedRows,
    order,
  });
  modifiedRows = rowSetCorrectAmountForVirtualCompositionAfterAmountChangeRow({
    row,
    rows: modifiedRows,
  });

  return modifiedRows;
};
