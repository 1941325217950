export function processPeriodPriceChange({row}) {
  const newRow = {...row};
  const {PeriodPrice, PeriodPrices, Period} = newRow;

  const newPeriodPrice = PeriodPrice.Value;

  if (!newPeriodPrice || newPeriodPrice === 0) {
    return {
      ...row,
      Price: {
        ...row.Price,
        Value: 0,
      },
    };
  }

  if (Period.Value) {
    const periodPrice =
      PeriodPrices?.Value?.find((pp) => pp.PeriodID === Period.Value) ?? {};
    periodPrice.Price = newPeriodPrice;
  }

  return newRow;
}
