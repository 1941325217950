<template>
    <tr class="whitespace-nowrap border-top">
        <td v-for="columnIndex in columnCount" :key="columnIndex" class="border" style="height:34px">
            <div class="line"></div>
        </td>
    </tr>
</template>

<script>
export default {
  name: "DatagridRow",
  props: {
    columnCount: {
        type: Number,
        required: true,
    },
  }
};
</script>

<style scoped lang="scss">

$base-color: #F4F4F4;
$shine-color: rgba(229,229,229,0.8);
$animation-duration: 2.0s;
$avatar-offset: 52 + 16;

$base-dark-color: #eeeeee;
$shine-dark-color: rgba(255, 255, 255, 0.8);

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 600px;
}

@mixin background-gradient-dark {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-dark-color 40px, $base-dark-color 80px);
  background-size: 600px;
}


.line-dark {
  @include background-gradient-dark;
}
.line{
    width: 90%;
    height: 16px;
    max-width:100%;
    padding: 0 15px;
    border-radius: 7px;
    margin: auto;
    
    @include background-gradient;
    animation: shine-lines $animation-duration infinite ease-out;
}

 
@keyframes shine-lines{
    0% { background-position: -100px;}
    40%, 100% {background-position: 140px;}
}
</style>