<template>
  <div class="flex mt-2 justify-between items-center">
    <span v-if="startDate" class="text-sm datetime text-gray-600">
      {{ formattedStartDate }}
    </span>
    <span v-else class="text-sm datetime text-gray-600">
      {{ $store.getters.translations.ToDetermine }}
    </span>
    <span
      v-if="endDate"
      :class="{
        'datetime-overdue': overdue,
        'text-white bg-primary': due,
      }"
      class="text-sm datetime"
    >
      {{ formattedEndDate }}
    </span>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "KanbanCardPlannedDate",
  props: {
    startDate: {
      type: String,
      required: false,
      default: null,
    },
    endDate: {
      type: String,
      required: false,
      default: null,
    },
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedStartDate() {
      const startDate = dayjs(this.startDate);
      const endDate = dayjs(this.endDate);

      if (startDate.format("HH:mm") === endDate.format("HH:mm")) {
        return `${startDate.format("DD-MM-YYYY")}`;
      } else {
        return `${startDate.format("DD-MM-YYYY HH:mm")}`;
      }
    },
    formattedEndDate() {
      const startDate = dayjs(this.startDate);
      const endDate = dayjs(this.endDate);

      if (startDate.format("HH:mm") === endDate.format("HH:mm")) {
        return `${endDate.format("DD-MM-YYYY")}`;
      } else {
        return `${endDate.format("DD-MM-YYYY HH:mm")}`;
      }
    },
    due() {
      // check if start and the end date are on the same day
      const today = dayjs();
      const endDate = dayjs(this.endDate);

      if (today.format("DD-MM-YYYY") === endDate.format("DD-MM-YYYY")) {
        return true;
      }
      return false;
    },
    overdue() {
      if (this.due) return false;
      return dayjs().isAfter(this.endDate);
    },
  },
};
</script>

<style scoped>
.datetime-overdue {
  background-color: #f95151 !important;
  color: #ffffff !important;
}
.datetime {
  background-color: #dedede;
  border-radius: 19px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 600;
  color: #343a40;
  font-family: "Lucida Sans Unicode", sans-serif;
  font-size: 12.1px;
}
</style>
