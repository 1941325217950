<template>
  <div class="relative">
    <div
      v-if="guides.length > 1"
      class="ml-3 w-1 h-full position-absolute bg-gray-300 border-t-white border-t-8 !z-[-1]"
    ></div>
    <form-sidebar-railroad-item
      v-for="(railroadItem, index) in guides"
      :key="index"
      :r-window="rWindow"
      :railroad-item="railroadItem"
      :index="index"
    />
  </div>
</template>

<script>
import FormSidebarRailroadItem from "./FormSidebarRailroadItem.vue";

export default {
  name: "FormSidebarRailroad",
  components: {FormSidebarRailroadItem},
  props: {
    guides: {
      type: Array,
      default: function () {
        return [];
      },
    },
    rWindow: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
