<template>
  <div class="flex justify-between">
    <div v-if="settings.ShowScanAmountField" class="form-field pr-1">
      <div class="btn-group">
        <button class="btn btn-secondary input-group-append">
          {{ translations.Amount }}
        </button>
      </div>
    </div>
    <div class="w-full mr-1">
      <item-scanner
        :disabled="!loaded"
        entity="Invoices"
        class="mb-2"
        @select="$emit('item-select', $event)"
      />
    </div>

    <datagrid-page-size
      class="ml-1"
      :page-size="pageSize"
      :page-sizes="[5, 10, 25, 50, 100]"
      @change="$emit('page-size-change', $event)"
    />
  </div>
</template>

<script>
import DatagridPageSize from "../datagridFilters/DatagridPageSize.vue";
import ItemScanner from "@/components/item/ItemScanner.vue";

export default {
  name: "DatagridHeaderInvoiceItem",
  components: {
    DatagridPageSize,
    ItemScanner,
  },
  inject: ["translations"],
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
};
</script>
