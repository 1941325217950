import Decimal from "decimal.js";

export const calculateTotalPrice = (row, counterPrice = 0) => {
  if (!row.Price?.Value || !row.Amount?.Value) {
    return Decimal(0);
  }
  return Decimal(parseFloat(row.Price?.Value))
    .add(counterPrice)
    .times(row.Amount.Value);
};
