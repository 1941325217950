import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {handleItemTypeSalesRowChange} from "../../row/handleItemTypeSalesRowChange.js";

export async function processItemType({row, oldRow}) {
  const itemType = getRawValueFromDropdownValue(row.ItemType.Value);
  let newRow = {...row};

  if (itemType === "Sales") {
    newRow.Price = {
      ...newRow.Price,
      IsReadOnly: false,
      Icon: null,
    };
    newRow.DateTimeExpectedEnd = {
      ...newRow.DateTimeExpectedEnd,
      Value: null,
      OldValue: newRow.DateTimeExpectedEnd.Value,
      IsReadOnly: true,
    };
    newRow.DateTimeBusinessEnd = {
      ...newRow.DateTimeBusinessEnd,
      Value: null,
      IsReadOnly: true,
    };
  } else {
    newRow.Price.IsReadOnly = true;
  }

  newRow = handleItemTypeSalesRowChange({newRow, oldRow});

  const processOnCounter = ["RentalCounter", "RentalPeriod", "RentalBoth"];
  if (!processOnCounter.includes(itemType)) {
    newRow.ExpectedCounter = {
      ...newRow.ExpectedCounter,
      IsVisible: false,
      IsReadOnly: true,
    };
  }

  return newRow;
}
