import selectTabOnActiveWindow from "../../actions/selectTabOnActiveWindow.js";
import {dirtyCheckWithPrompt} from "@/interface/window/dirty";
import store from "../../../state/store";
import Window from "@/model/window";

/**
 * Insert sub window or create new window
 * @param {Object} output - Window output
 * @param {boolean} inSub - Insert as sub window?
 * @param {boolean} noRender - should we render the window?
 * @param {boolean} noProcessing - Should we process the output?
 * @returns {Promise} Promise
 */
export default async function insertWindow(
  output,
  inSub = false,
  noRender = false,
  noProcessing = false,
  mustConfirm = true,
  actionName = null,
) {
  if (mustConfirm !== false && inSub === true) {
    if ((await dirtyCheckWithPrompt(this)) === false) return;
  }

  if (actionName) selectTabOnActiveWindow({ActionName: actionName});

  let window = new Window(this.session, inSub ? this : null);
  window.input = output.Request;

  // process data if neccessairy
  if (!noProcessing) {
    await window.process(output);
  }

  // we have done all the loading already
  window.loading = false;

  // where should we place the new window

  if (inSub) {
    // into self
    this.sub?.window?.dispose(false);

    this.sub = {
      window: window,
    };
  } else {
    // as new tab
    //this.session.tabs.unshift(window)
    window.focus();
  }

  if (!noRender) {
    await window.render();
  }

  global.eventBus.emit("afterInsert", window);

  return window;
  store.commit("updateWindow");
}
