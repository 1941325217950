export const setLanesCallbackFunction = ({viewFilters}) => {
  if (!viewFilters[0].Selected) return null;

  const filterCards = ({lane, cards}) =>
    cards.filter(
      (card) =>
        (card.ResourceID === lane.StatusCode ||
          card.StatusCode === lane.StatusCode) &&
        card.StatusCode !== "200",
    );

  return filterCards;
};
