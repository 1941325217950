export function checkScanDuplicateSerialItem({oldRows, newRows}) {
  let newRow = "";

  if (Array.isArray(newRows)) {
    newRow = newRows[0];
  } else {
    newRow = newRows;
  }

  if (!newRow?.SerialID?.Value) {
    return false;
  }

  return oldRows.some(
    (row) =>
      row.SerialID.Value === newRow.SerialID.Value &&
      row.ClientSideUUID?.Value !== newRow.ClientSideUUID?.Value,
  );
}
