import {getScanBoxItems} from "@/services/item";

export async function scanItem({searchValue, warehouseId, params}) {
  const requestData = await getScanBoxItems({searchValue, params});

  const searchResults = [];

  for (const searchResult of requestData) {
    searchResults.push({
      label: searchResult.Text,
      value: searchResult.Value,
      type: searchResult.Attributes.Type,
      stock: searchResult.Attributes.Stock,
      warehouseId: warehouseId,
    });
  }

  return searchResults[0];
}
