<template>
  <li
    v-if="show"
    class="page"
    @click="$emit('changePage', page > 0 ? page : 1)"
  >
    {{ page }}
  </li>
</template>

<script>
export default {
  name: "PaginationPageButton",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    show() {
      return this.page > 0 && this.page <= this.pageCount;
    },
  },
};
</script>

<style scoped></style>
