export function setPeriodDropdownItems({row}) {
  if (row.PeriodPrices.Value?.length) {
    const newRow = {...row};
    newRow.Period.Dropdown = {
      Items: newRow.PeriodPrices.Value.map((periodPrice) => {
        return {
          Text: periodPrice.PeriodID,
          Value: periodPrice.PeriodID,
        };
      }),
    };
    return newRow;
  }
  return row;
}
