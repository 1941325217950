<template>
  <a class="btn btn-link" @click="collapseCompositionRows">
    <i v-if="toggle" class="fas fa-chevron-down"></i>
    <i v-else class="fas fa-chevron-up"></i>
  </a>
</template>

<script>
export default {
  data() {
    return {
      toggle: {
        type: Boolean,
        default: false,
      },
    };
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    columns: {
      type: Array,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  methods: {
    collapseCompositionRows() {
      this.toggle = !this.toggle;
      if (this.deletable !== false) {
        this.$emit("action", {name: "collapseCompositionRows", row: this.row});
      }
    },
  },
};
</script>
