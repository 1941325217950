export const setSerialPickedQuantityReadOnlyWhenZero = ({row}) => {
  if (row.PickedQuantity.Value === 0 && row.UseSerials.Value) {
    return {
      ...row,
      PickedQuantity: {
        ...row.PickedQuantity,
        IsReadOnly: true,
      },
    };
  }
  return row;
};
