<template>
  <div class="flex justify-end mb-2">
    <div v-if="loaded && showScanner" class="w-full">
      <datagrid-scanner
        :params="{
          invoiceId: invoice.InvoiceID,
          supplierId: invoice.SupplierID.Key,
        }"
        :filter-by="
          (option, label, search) => {
            return (
              option.ItemID.toLowerCase().includes(search.toLowerCase()) ||
              option.Description.toLowerCase().includes(search.toLowerCase())
            );
          }
        "
        :fetch-data-function="fetchInboundItemScannerData"
        :settings="settings"
        label="ItemID"
        @select="$emit('addItem', $event)"
      >
        <template #select-option="{option, searchValue}">
          <item-scanner-label
            :option="option"
            :get-type="getTypeFromScannedRow"
            :get-label="getLabelFromScannedRow"
            :search-value="searchValue"
          />
        </template>
      </datagrid-scanner>
    </div>
    <datagrid-page-size
      class="ml-1"
      :page-size="pageSize"
      @change="$emit('handlePageSizeChange', $event)"
    />
  </div>
</template>

<script>
import fetchInboundItemScannerData from "../../../functions/datagrid/inbound-invoice-item/fetchInboundItemScannerData.js";
import {getLabelFromScannedRow} from "../../../functions/datagrid/inbound-orderitem/getLabelFromScannedRow.js";
import {getTypeFromScannedRow} from "../../../functions/datagrid/inbound-orderitem/getTypeFromScannedRow.js";

import DatagridPageSize from "../datagridFilters/DatagridPageSize.vue";
import ItemScannerLabel from "../../item/ItemScannerLabel.vue";
import DatagridScanner from "../DatagridScanner.vue";

export default {
  components: {
    DatagridPageSize,
    ItemScannerLabel,
    DatagridScanner,
  },
  props: {
    pageSize: {
      type: Number,
      required: true,
    },
    loaded: {
      type: Boolean,
      required: true,
    },
    showScanner: {
      type: Boolean,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getLabelFromScannedRow,
    getTypeFromScannedRow,
    fetchInboundItemScannerData,
  },
};
</script>
