import {generateNewRowFromScanItem} from "@/functions/datagrid/quotation-item/row/generateNewRowFromScanItem";
import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export async function handleItemIdChange({row, oldRow, quotation}) {
  const itemType = getRawValueFromDropdownValue(
    row.ItemType.Value?.Key || row.ItemType?.Value,
  );
  if (itemType === "Free") {
    return;
  }

  row.SerialID = {Value: ""};
  const item = {
    type: "Item",
    value: row.ItemID.Value,
  };

  if (row.ItemID.OriginalValue === row.ItemID.Value) return row;

  const [newRow, ...extraRows] = await generateNewRowFromScanItem({
    item,
    ranking: row.Ranking.Value,
    oldRow: oldRow,
    quotation,
  });

  if (extraRows.length) {
    throw new Error(
      `Unexpected extra rows from scanned ItemID: ${row.ItemID.Value}`,
    );
  }

  row = {...newRow};
  return row;
}
