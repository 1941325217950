import {getTranslations} from "@/functions/session/localstorage/getTranslations";

export function processRowDeletable({row}) {
  const newRow = {...row};
  const translations = getTranslations();

  if (
    newRow.Status.Value == translations.StatusOpen ||
    newRow.Status.Value == translations.StatusCancelled
  ) {
    newRow.rowMeta = {
      ...newRow.rowMeta,
      deletable: true,
    };
    return newRow;
  } else {
    newRow.rowMeta = {
      ...newRow.rowMeta,
      deletable: false,
    };

    return newRow;
  }
}
