import {setValuesInRow} from "../row/setValuesInRow";
import {isSameRow} from "../row/isSameRow";

export const setValuesForRowInRows = ({row, rows, values}) =>
  rows.map((rowToUpdate) => {
    if (isSameRow({a: rowToUpdate, b: row})) {
      return setValuesInRow({row, values});
    }
    return rowToUpdate;
  });
