export function deleteDanglingVirtualCompositionRows({rows}) {
  return rows.filter(
    (row) =>
      row.rowMeta?.virtual !== true ||
      (row.rowMeta?.virtual === true &&
        rows
          .filter((r) => r.rowMeta?.virtual !== true)
          .some((r) => r.Composition.Value === row.Composition.Value)),
  );
}
