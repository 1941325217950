<template>
  <div>
    <div v-if="!priceMessage">
      <datagrid-popup-price-calculation
        v-for="priceCalculation in priceCalculations"
        :key="priceCalculation.StartDate"
        :price-calculation="priceCalculation"
        :rate="rate"
      />
    </div>
    <div v-else>
      {{ priceMessage }}
    </div>
  </div>
</template>

<script>
import DatagridPopupPriceCalculation from "@/components/datagrid/datagridPopups/DatagridPopupPriceCalculation";

export default {
  name: "DatagridPopupPriceCalculations",
  components: {DatagridPopupPriceCalculation},
  inject: ["rateCallback", "translations"],
  props: {
    row: {
      type: Object,
      required: true,
    },
    rateCallback: {
      type: Function,
    },
  },
  data() {
    return {
      rate: this.rateCallback ? this.rateCallback(this.row) : 1,
    };
  },
  computed: {
    priceMessage() {
      return this.row.Price.IconMessage;
    },
    priceCalculations() {
      return this.row.PriceProjection?.Value?.PriceCalculation ?? [];
    },
  },
};
</script>
