import {generateTaskDescription} from "./generateTaskDescription";
import {generateTaskTitle} from "./generateTaskTitle";

export const createCard = ({task, primaryKeyName, translations}) => ({
  ...task,
  Subject: task.TableName,
  Disabled: false,
  Criteria: {
    Key: task.Key + Math.random(),
  },
  Fields: {},
  Status: task.Status,
  TaskID: task.TaskID,
  Title: generateTaskTitle({task}),
  Entity: task.EntityDescription,
  Description: generateTaskDescription({task}),
  Breadcrumb: `${translations[task.TableName] ?? task.TableName} > ${
    task.Key
  } > ${translations[task.ResourceID] ?? task.ResourceID}`,
  StartDate: task.DateTimeExecuteStart,
  EndDate: task.DateTimeExecuteEnd,
  Ranking: task.Ranking,
  ClickActions: [
    {
      Function: "openWindow",
      Data: {
        Subject: task.TableName,
        Prefix: "Single",
        Criteria: [{[primaryKeyName]: task.Key}],
      },
    },
  ],
  Resources: task.Resources,
});
