<template>
  <div>
    <slot>
      <div class="fake-button button button-menu no-hover no-action">
        <span v-if="!loading">{{ rows.length }}</span>
        <span v-if="loading"><i class="fas fa-spinner fa-spin"></i></span>
      </div>

      <span v-if="maxRows && rows.length >= maxRows"
        ><small>{{
          translations.TableFooterMessageToManyRows.replace(
            "{rows}",
            rows.length,
          ).replace("{maxRows}", maxRows)
        }}</small>
      </span>
    </slot>
  </div>
</template>

<script>
import {getTranslations} from "@/functions/session/localstorage/getTranslations";
export default {
  name: "DatagridFooter",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    maxRows: {
      type: Number,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    translations() {
      return getTranslations();
    },
  },
};
</script>

<style scoped></style>
