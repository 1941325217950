<template>
  <div>
    <loginPage
      :is="loginPage"
      v-if="component == 'loginPage'"
      :session="session"
      :initial-error-message="error"
      @setLoginToken="setLoginToken"
      @setBearerToken="setBearerToken"
      @showComponent="showComponent"
    />
    <login-request-reset-password
      :is="loginRequestResetPassword"
      v-if="component == 'loginRequestResetPassword'"
      :session="session"
      @showComponent="showComponent"
    />
    <login-reset-password
      :is="loginResetPassword"
      v-if="component == 'loginResetPassword'"
      :session="session"
      @showComponent="showComponent"
    />
    <login-saml
      :is="loginSaml"
      v-if="component == 'loginSaml'"
      :session="session"
      @setBearerToken="setBearerToken"
      @showComponent="showComponent"
    />
    <login-m-f-a
      :is="loginMFA"
      v-if="component == 'loginMFA'"
      :session="session"
      :login-token="loginToken"
      @setBearerToken="setBearerToken"
      @showComponent="showComponent"
    />
    <login-after-backup-code
      :is="loginAfterBackupCode"
      v-if="component == 'loginAfterBackupCode'"
      :session="session"
      @showComponent="showComponent"
    />
    <login-two-factor-setup
      :is="loginTwoFactorSetup"
      v-if="component == 'loginTwoFactorSetup'"
      :login-token="loginToken"
      @showComponent="showComponent"
      @sendTotpData="handleSendTotpData"
      @setBearerToken="setBearerToken"
    />
    <login-session-list
      :is="loginSessionList"
      v-if="component == 'loginSessionList'"
      :session="session"
      @setSessionId="setSessionId"
    />
  </div>
</template>

<script>
import loginRequestResetPassword from "./loginRequestResetPassword.vue";
import loginAfterBackupCode from "./loginAfterBackupCode.vue";
import loginTwoFactorSetup from "./twoFactorSetup/loginTwoFactorSetup.vue";
import loginResetPassword from "./loginResetPassword.vue";
import loginSessionList from "./loginSessionList.vue";
import loginSaml from "./loginSaml.vue";
import loginMFA from "./loginMFA.vue";
import loginPage from "./loginPage.vue";

import {setPersistedSessionId} from "../../functions/session/localstorage/setPersistedSessionId.js";
import {setPersistedToken} from "../../functions/session/localstorage/setPersistedToken.js";
import getWelcomeInfo from "../../services/authentication/getWelcomeInfo.js";
import {login} from "../../functions/session/login.js";

export default {
  name: "LoginRouter",
  components: {
    loginRequestResetPassword,
    loginAfterBackupCode,
    loginTwoFactorSetup,
    loginResetPassword,
    loginSessionList,
    loginSaml,
    loginMFA,
  },
  props: {
    session: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      error: null,
      translations: this.session.translations,
      loginToken: null,
      component: "loginPage",
      totpData: null,
    };
  },
  computed: {
    loginSessionList() {
      return loginSessionList;
    },
    loginTwoFactorSetup() {
      return loginTwoFactorSetup;
    },
    loginMFA() {
      return loginMFA;
    },
    loginAfterBackupCode() {
      return loginAfterBackupCode;
    },
    loginSaml() {
      return loginSaml;
    },
    loginRequestResetPassword() {
      return loginRequestResetPassword;
    },
    loginResetPassword() {
      return loginResetPassword;
    },
    loginPage() {
      return loginPage;
    },
  },
  created: function () {
    const url = location.hash.substring(1);

    if (url) {
      if (url.includes("userid")) {
        this.showComponent("loginSaml");
      } else if (url.includes("userResetCode")) {
        this.showComponent("loginResetPassword");
      }
    }

    if (this.$props.session.err?.data?.Error?.Message) {
      this.$data.error = this.$props.session.err.data.Error.Message;
    } else {
      this.$data.error = this.$props.session.err;
    }

    window.session.err = null;
  },
  methods: {
    handleSendTotpData(event) {
      this.totpData = event;
    },
    setLoginToken(loginToken) {
      this.$data.loginToken = loginToken;
    },
    async setBearerToken(bearerToken) {
      await this.$store.commit("setToken", {
        token: bearerToken,
      });

      setPersistedToken(bearerToken);
    },
    async setSessionId(sessionId) {
      await this.$store.commit("setSessionId", {
        sessionId: sessionId,
      });

      setPersistedSessionId(sessionId);

      await this.finishLogin();
    },
    async finishLogin() {
      const result = await getWelcomeInfo();

      await login({
        session: this.$props.session,
        result: result.data,
        serviceName: "GetWelcomeInfo",
      });

      window.location.reload();
    },
    showComponent(component, error) {
      this.$data.component = component;
      this.$data.error = error;
    },
  },
};
</script>
