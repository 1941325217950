import {getTranslations} from "../../session/localstorage/getTranslations.js";
import {getResourceFilterOptionValues} from "../../table/core-rail-road-task/filters/getResourceFilterOptionValues.js";

const translations = getTranslations();

export const generateLanes = async ({viewFilters}) => {
  const defaultLanes = [
    {
      Title: translations["StatusOpen"],
      StatusCode: "100",
      Ranking: 10,
      Color: "#F5BB00",
      Status: "Reference to card property",
    },
    {
      Title: translations["InProgress"],
      StatusCode: "300",
      Ranking: 20,
      Color: "#36A7FF",
      Status: "Reference to card property",
    },
    {
      Title: translations["Cancelled"],
      StatusCode: "999",
      Ranking: 30,
      Color: "#C56BD0",
      Status: "Reference to card property",
    },
    {
      Title: translations["Closed"],
      StatusCode: "200",
      Ranking: 40,
      Color: "#6BC950",
      Status: "Reference to card property",
    },
  ];

  if (!viewFilters[0].Selected) {
    return defaultLanes;
  }

  const selectedEntity = viewFilters[0].Selected;
  const tableTaskStatuses = await getResourceFilterOptionValues();
  const selectedEntityTaskStatuses = tableTaskStatuses.filter(
    (status) => status.id === selectedEntity,
  )[0]?.children;

  return selectedEntityTaskStatuses?.map((status) => ({
    Title: status.label,
    StatusCode: status.id,
    Ranking: status.index, // assuming index is a property of status
    Color: status.color ?? "grey",
    Status: status.id,
    Table: selectedEntity,
  }));
};
