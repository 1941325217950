<template>
  <div>
    <button
      :data-window-event="`action:OpenHistoryOverview|Core.RecordVersion:${table.FullTableName}:../../Admin/WebServices/CoreWebServices.asmx:InvokeEntityMethod`"
      data-window-target="active"
      action="OpenHistoryOverview:Core.RecordVersion"
      type="button"
      class="text-dark btn-icon btn-link mt-1 mr-2"
    >
      <div class="float-left">
        <i class="fas fa-history"></i>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "CanvasHistoryButton",
  props: {
    table: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
