import {rowHasValueChanges} from "../row/rowHasValueChanges.js";

export function rowsHaveValueChanges({newRows, originalRows, columns}) {
  return newRows.some((newRow) => {
    const originalRow = originalRows.find(
      (originalRow) =>
        originalRow.ClientSideUUID.Value === newRow.ClientSideUUID.Value,
    );

    return rowHasValueChanges({
      newRow: newRow,
      originalRow: originalRow,
      columns,
    });
  });
}
