import {getPermissionTables} from "../../../services/v2/core/getPermissionTables";

export const getInitialViewFilters = async ({translations}) => {
  const {data} = await getPermissionTables();

  return [
    {
      Type: "List",
      Target: "Entity",
      placeholder: translations["LimitViewTo"],
      title: translations["Entity"],
      Selected: null,
      Multiple: false,
      Options: [
        {Key: "Rental.Quotation", Title: translations["Quotation"]},
        {Key: "Rental.Reservation", Title: translations["Reservation"]},
        {Key: "Rental.Order", Title: translations["Order"]},
        {Key: "Rental.Invoice", Title: translations["Invoice"]},
        {Key: "Rental.Service", Title: translations["Service"]},
        {
          Key: "Inbound.Order",
          Title: translations["InboundOrder"],
        },
        {
          Key: "Inbound.Invoice",
          Title: translations["InboundInvoice"],
        },
      ].filter((option) => data.includes(option.Key)),
    },
  ];
};
