import {setValuesForRowInRows} from "../setValuesForRowInRows";

export const updatePriceConditionCostRow = ({
  row,
  price,
  rows,
  existingRow,
  priceCondition,
}) => {
  let valuesToUpdate = {
    PurchasePricePerUnit: price,
  };

  if (priceCondition.AmountType === "Follow") {
    valuesToUpdate.Amount = row.Amount.Value;
  }

  return {
    rows: setValuesForRowInRows({
      rows,
      row: existingRow,
      values: valuesToUpdate,
    }),
    priceConditionOrderItemIDs: [existingRow.OrderItemID?.Value],
  };
};
