import {processRowMetadata} from "../row/processRowMetadata";

export const processRowsMetadata = ({rows, columns}) => {
  const processedRows = [];

  for (const row of rows.slice()) {
    processedRows.push(processRowMetadata({row, columns}));
  }

  return processedRows;
};
