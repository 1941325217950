import {getTranslations} from "../../session/localstorage/getTranslations.js";

const translations = getTranslations();

export const generateLaneFilters = ({viewFilters, lanes}) => {
  if (!viewFilters[0].Selected) {
    return viewFilters.filter((filter) => filter.Target !== "VisibleLanes");
  }

  const options = lanes?.map((lane) => ({
    Title: lane.Title,
    Key: lane.Status,
  }));

  const changedViewFilters = viewFilters.filter(
    (filter) => filter.Target !== "VisibleLanes",
  );

  changedViewFilters.push({
    Type: "List",
    Target: "VisibleLanes",
    title: translations["ExcelVisibleColumns"],
    colSpan: 2,
    placeholder: `${translations["ExcelVisibleColumns"]}...`,
    Selected: options?.map((option) => option.Key),
    Multiple: true,
    Options: options,
  });

  return changedViewFilters;
};
