import openWindow from "../../../../actions/openWindow.js";
import translation from "../../../../util/translation.js";

export const openItemWindowHelper = ({row, cell}) => {
  const ItemID =
    cell.Name === "PhysicalItemID"
      ? row.PhysicalItemID.Value
      : row.ItemID.Value;

  openWindow({
    Subject: "Rental.virtual_ItemWarehouseAvailability",
    Prefix: "View",
    Criteria: [
      {
        ItemID: ItemID,
        StartDate: row.DateTimeExpectedStart.Value ?? null,
        EndDate: row.DateTimeExpectedEnd.Value ?? null,
      },
    ],
    customTitle: `${translation("ItemAvailability")}: ${ItemID}`,
  });
};
