import {isRelatedRow} from "../../row/isRelatedRow";
import {updatePriceConditionItemRow} from "./updatePriceConditionItemRow";
import {addPriceConditionItemRow} from "./addPriceConditionItemRow";

export const updateRowsWithSpecificItemPriceCondition = async ({
  rows,
  row,
  priceCondition,
  getRowsWrapper,
}) => {
  const existingRow = rows.find(
    (existingRow) =>
      isRelatedRow({rowToCheck: existingRow, rowToMatch: row}) &&
      existingRow.PriceConditionID.Value === priceCondition.PriceConditionID,
  );

  if (existingRow) {
    return updatePriceConditionItemRow({
      row,
      rows,
      existingRow,
      priceCondition,
    });
  }

  return await addPriceConditionItemRow({
    priceCondition,
    row,
    rows,
    getRowsWrapper,
  });
};
