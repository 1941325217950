<template>
  <div class="mb-2 flex">
    <div class="w-full"></div>
    <div class="max-w-[100px]">
      <datagrid-page-size
        :page-size="pageSize"
        @change="$emit('page-size-change', $event)"
      />
    </div>
  </div>
</template>
<script>
import DatagridPageSize from "../datagridFilters/DatagridPageSize.vue";
export default {
  name: "DatagridHeader",
  components: {
    DatagridPageSize,
  },
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
  },
};
</script>
