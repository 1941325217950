<template>
  <div class="flex flex-col md:flex-row justify-between">
    <div
      v-for="filter in filters"
      :key="filter.name"
      class="mr-1 w-full md:w-[30%]"
    >
      <datagrid-filter
        :options="filter.options"
        :selected-options="filter.selectedValues"
        :filter-name="filter.name"
        :filter-title="filter.title"
        :disable-branch-nodes="false"
        @input="handleFilterChange"
      />
    </div>

    <datagrid-page-size
      class="ml-1"
      :page-size="pageSize"
      @change="$emit('page-size-change', $event)"
    />
  </div>
</template>
<script>
import DatagridPageSize from "../datagridFilters/DatagridPageSize.vue";
import DatagridFilter from "@/components/datagrid/datagridFilters/DatagridFilter.vue";
import "vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "DatagridHeaderInboundDelivery",
  components: {
    DatagridFilter,
    DatagridPageSize,
  },
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleFilterChange({name, value}) {
      this.$emit("filter-change", {[name]: value});
    },
  },
};
</script>
