import {processPopupStockPrediction} from "../row/processPopupStockPrediction";

export function rowsProcessPopupStockPredictions({rows}) {
  const modifiedRows = rows.slice();
  for (const index in modifiedRows) {
    modifiedRows[index] = processPopupStockPrediction({
      row: modifiedRows[index],
      modifiedRows,
    });
  }

  return modifiedRows;
}
