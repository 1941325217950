<template>
  <div
    v-if="!guidesCompleted"
    class="flex flex-col min-w-0 break-words bg-white rounded-lg transition-all duration-300 ease-in-out card-shadow p-[17.5px]"
    :title="translations['GlobalGuides-Title']"
  >
    <h4
      class="mb-[10px] pb-[7px] border-b-2 border-t-0 border-x-0 border-solid border-[#eee] ml-[8px] text-[1.5rem]"
    >
      {{ translations["GlobalGuides-Title"] }}
    </h4>
    <r-row class="">
      <r-col
        v-if="guides.length"
        :lg="4"
        class="relative w-full min-h-[1px] px-[15px] md:max-w-1/3 md:shrink-0 md:grow-0 md:basis-1/3"
      >
        <knowledge-base-item
          v-for="guide in guides"
          :key="guide.Message"
          :item="guide"
          :active="guide.Message === activeGuideTitle"
          @click.native="changeActiveItem(guide.Message)"
        />
      </r-col>
      <r-col
        v-if="activeItem"
        :lg="8"
        class="relative w-full min-h-[1px] px-[15px] md:max-w-2/3 md:shrink-0 md:grow-0 md:basis-2/3"
      >
        <div
          class="mb-3"
          v-html="translations[`Global-Guide-${activeItem.Message}-Message`]"
        />
        <r-button
          v-if="activeItem.Action"
          class="ml-0 primary"
          :data-menu-id="activeItem.Action"
          >{{ translations[`Global-Guide-${activeItem.Message}-ActionTitle`] }}
        </r-button>
      </r-col>
    </r-row>
  </div>
</template>

<script>
import KnowledgeBaseItem from "./KnowledgebaseItem.vue";
import openWindow from "../../../functions/window/openWindow";
import {getGlobalGuides} from "../../../services/guide";
import RRow from "../../elements/RRow.vue";
import RCol from "../../elements/RCol.vue";
import RButton from "../../elements/RButton.vue";

export default {
  components: {
    KnowledgeBaseItem,
    RButton,
    RRow,
    RCol,
  },
  data() {
    return {
      guides: [],
      loading: false,
      items: [],
      activeGuideTitle: "",
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    activeItem() {
      if (this.guides.length < 1) return null;
      return this.getItemByTitle(this.activeGuideTitle);
    },
    template() {
      return this.$store.state.window?.templateName;
    },
    guidesCompleted() {
      if (this.guides.filter((guide) => guide.Completed !== true).length) {
        return false;
      }
      return true;
    },
  },
  watch: {
    template() {
      if (this.template === "startup") {
        this.fetchGuides();
      }
    },
  },
  async created() {
    await this.fetchGuides();
    this.activeGuideTitle = this.guides[0].Message;
  },
  methods: {
    async fetchGuides() {
      this.loading = true;

      const result = await getGlobalGuides({
        subject: "",
        criteria: null,
      });

      if (result.status == 200) {
        this.guides = result.data.sort((a, b) => {
          a.Completed - b.Completed;
        });
      }

      this.loading = false;
    },
    getItemByTitle(title) {
      return this.guides.find((guide) => guide.Message === title);
    },
    changeActiveItem(title) {
      this.activeGuideTitle = title;
    },
    triggerAction(subject) {
      openWindow({subject, actionName: "New"});
    },
  },
};
</script>

<style>
.card-shadow {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.startup .card-title {
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 10px;
  padding-bottom: 7px;
}
</style>
