import {formatRowToKeyValuePairs} from "../../formatRowToKeyValuePairs";
import {createRowsBasedOnQuantity} from "./format-row/createRowsBasedOnQuantity";

export const formatRow = ({row}) => {
  const formattedRow = formatRowToKeyValuePairs(row);

  if (formattedRow.OrderedQuantity > 1) {
    return createRowsBasedOnQuantity({formattedRow});
  } else {
    return [formattedRow];
  }
};
