import {api} from "../../api";

export const fetchVisStockData = async ({
  categoryId,
  warehouseId,
  orderId,
  startDate,
  periodQuantity,
}) => {
  return await api.get(`/v2/screen/stock`, {
    params: {
      warehouse: warehouseId,
      startDate,
      periodQuantity,
      CategoryID: categoryId,
      OrderID: orderId,
      indicationAvailableOnReturnDate: false,
      period: "day",
      labels: null,
    },
  });
};
