<template>
  <div class="min-w-[100px]">
    <select-field :field="field" @change="$emit('change', $event)" />
  </div>
</template>
<script>
import SelectField from "@/components/form/fields/SelectField";

export default {
  name: "DatagridPageSize",
  components: {
    SelectField,
  },
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 25],
    },
  },
  computed: {
    field() {
      return {
        Options: this.pageSizes,
        Value: this.pageSize,
        IsRequired: true,
      };
    },
  },
};
</script>
