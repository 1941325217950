export const handleExistingRow = ({existingRow, setPickedQuantityMax, row}) => {
  const totalQuantity = existingRow.Quantity.Value + 1;
  const orderedQuantityValue = existingRow.OrderedQuantity
    ? existingRow.OrderedQuantity.Value + 1
    : 1;

  const pickListItemIDs = existingRow.PickListItemIDs?.Value ?? [];
  pickListItemIDs.push(row.PickListItemID.Value);

  let pickedQuantity = existingRow.PickedQuantity.Value;

  if (row.StatusCode.Value === "150" || setPickedQuantityMax === true) {
    pickedQuantity += 1;
  }

  return {
    ...existingRow,
    Quantity: {Value: totalQuantity},
    PickListItemIDs: {
      Value: pickListItemIDs,
    },
    OrderedQuantity: {Value: orderedQuantityValue},
    PickedQuantity: {Value: pickedQuantity},
  };
};
