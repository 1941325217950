import {getColumns} from "../datagrid/columns/getColumns";

export const getColumnsPerEntity = async ({task, columnsPerEntity}) => {
  if (!columnsPerEntity[task.TableName]) {
    columnsPerEntity[task.TableName] = await getColumns({
      table: task.TableName,
      prefix: "Single",
      filterDTOProperties: true,
    });
  }
  return columnsPerEntity;
};
