<template>
  <div :class="{headless: headless}">
    <div v-if="headless" class="float-right">
      <canvas-buttons-headless
        :main-window="window"
        :translations="translations"
        :subject="subject"
        @new-job="$emit('new-job', $event)"
      />
    </div>
    <div v-if="!headless" class="float-right">
      <canvas-buttons
        :bulkedit="bulkedit"
        :sub-window="subWindow"
        :change-tracking-enabled="changeTrackingEnabled"
        :current-table="currentTable"
        :active-r-sub-window="activeRSubWindow"
        :window="window"
        :active-r-window="activeRWindow"
        @new-job="$emit('new-job', $event)"
      />
    </div>
    <canvas-title
      :title="title"
      :sub-title="subTitle"
      :window="window"
      :sub-window="subWindow"
    />
    <hr />
  </div>
</template>

<script>
import CanvasButtons from "./buttons/CanvasButtons.vue";
import CanvasButtonsHeadless from "./buttons/CanvasButtonsHeadless.vue";
import CanvasTitle from "./CanvasTitle.vue";

export default {
  name: "CanvasHeader",
  components: {
    CanvasButtonsHeadless,
    CanvasButtons,
    CanvasTitle,
  },
  inject: ["translations"],
  props: {
    window: {
      type: Object,
      required: true,
    },
    activeRWindow: {
      type: Object,
      required: true,
    },
    activeRSubWindow: {
      type: [Object, null],
      required: false,
      default: () => null,
    },
  },
  computed: {
    currentTable() {
      const tables = this.$store.getters["tables/tables"];
      const window = this.subWindow?.id ? this.subWindow : this.window;
      const tableName = window?.output?.Request?.Subject;
      const table = tables.find((t) => t.FullTableName === tableName);

      return table ?? null;
    },
    changeTrackingEnabled() {
      if (this.currentTable) {
        return this.currentTable?.EnableChangeTracking ?? false;
      }
      return false;
    },
    title() {
      if (!this.window.output || this.loading) {
        return this.translations.translations.LoadingWindow;
      }

      // Cache the counter check
      const counter = this.window.counter;
      const titleSuffix = counter && counter !== 0 ? ` (${counter})` : "";

      return counter !== 0
        ? `${
            this.window.customTitle ||
            this.window.output.Title ||
            this.translations.translations.LoadingWindow
          }${titleSuffix}`
        : "";
    },
    subTitle() {
      if (window.output) {
        return window.output.SubTitle;
      }
      return "";
    },
    subject() {
      return (
        this.subWindow?.output?.Request.Subject ??
        this.window?.output?.Request.Subject ??
        null
      );
    },
    bulkedit: function () {
      if (this.window.bulkedit) return true;
      if (this.subWindow && this.subWindow.bulkedit) return true;
      return false;
    },
    headless() {
      return this.$store.state.headless;
    },
    subWindow: function () {
      return this.$store.getters.subWindow;
    },
  },
};
</script>

<style scoped></style>
