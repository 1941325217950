import Decimal from "decimal.js";

export function calculatePriceDifference({row}) {
  const newRow = {...row};
  if (newRow.CalculatedPrice.Value) {
    newRow.PriceDifference.Value = new Decimal(newRow.CalculatedPrice.Value)
      .minus(newRow.TotalExcVAT.Value)
      .toNumber();
  }

  return newRow;
}
