export function addMissingPropsToRow(row) {
  if (!row.PeriodPrice) {
    row.PeriodPrice = {
      Value: null,
    };
  }

  if (!row.DateTimeRentalEnd) {
    row.DateTimeRentalEnd = {
      Value: null,
    };
  }

  if (!row.PriceProjection) {
    row.PriceProjection = {
      Value: null,
    };
  }

  return row;
}
