import {nonActionAxiosInstance} from "../nonActionAxiosInstance.js";
import store from "../../../state/store.js";

export async function getReturnHours({businessDay}) {
  const result = await nonActionAxiosInstance.get(
    `/api/v2/warehouse/current/businessdays/${businessDay}/returnhours`,
    {
      params: {
        Warehouse: store.state.activeWarehouse,
      },
    },
  );

  return result.data;
}
