export function setResourceIdBadgeClasses({cell}) {
  const badgeClasses = {
    // RailRoadDeliver: "badge-primary",
    // RailRoadEditOrderItems: "badge-primary",
    // RailRoadOrderCreated: "badge-secondary",
    // RailRoadPick: "badge-primary",
    // RailRoadReturn: "badge-primary",
  };

  const cellWithBadgeClasses = {...cell};

  cellWithBadgeClasses.badgeClasses = badgeClasses[cellWithBadgeClasses.Value];

  return cellWithBadgeClasses;
}
