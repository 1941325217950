export function setRowRentalEndValue({row}) {
  const newRow = {...row};
  if (row.DateTimeReportedEnd.Value) {
    newRow.DateTimeRentalEnd.Value = row.DateTimeReportedEnd.Value;
  } else if (row.DateTimeBusinessEnd.Value) {
    newRow.DateTimeRentalEnd.Value = row.DateTimeBusinessEnd.Value;
  }

  return newRow;
}
