<template>
  <div class="col-xs-12 col-sm-12 col-md-4 col-xl-2 textual">
    <div
      :class="{
        card: true,
        'card-chart': true,
        'h-100': true,
        unavailable,
      }"
    >
      <div class="card-body" :v-if="diagramData.data">
        <div class="card-entry">
          <r-button
            class="float-right text-grey mb-0 mt-[-10px] mx-[4px] mr-[-6px]"
            variant="link"
            :title="diagramData.title"
          >
            <i class="fas fa-question"></i>
          </r-button>
          <r-button
            v-if="diagramData.detailUrl"
            class="float-right text-grey mb-0 mt-[-10px] mx-[4px] mr-[-6px]"
            variant="link"
            @click="openWindow(diagramData.detailUrl)"
          >
            <i class="fas fa-list"></i>
          </r-button>

          <h5 class="title">
            <strong>{{ diagramData.title }}</strong>
          </h5>
        </div>

        <div
          class="h-100 d-flex"
          style="align-items: center; justify-content: center"
        >
          <p
            class="mt-a"
            :title="formatValue(diagramData.data, false)"
            :class="'text-' + getTextStyle(getPrice(diagramData.data))"
          >
            {{ formatValue(diagramData.data, true) }}
          </p>
        </div>
      </div>
      <div class="overlay">
        <div class="text">{{ $store.getters.translations.NoDataFound }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatNumber} from "../../util/formatting/shortTypedPrice";
import {getTextStyle} from "../../util/formatting/getTextStyle";
import {getPrice} from "../../util/parsing/getPrice";
import RButton from "../elements/RButton.vue";

import {api} from "../../services/api";
import {axiosInstance} from "../../services/axiosInstance";

export default {
  components: {
    RButton,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      unavailable: false,
      diagramData: {},
      id: `tooltip-${Date.now()}`,
    };
  },
  created: async function () {
    if (this.endpoint) {
      const result = await axiosInstance.get(this.endpoint);

      this.diagramData = result.data;

      // If unavailable or necessary data is not available show data not available

      if (this.diagramData.unavailable || !this.diagramData.data) {
        this.unavailable = true;
        return;
      }
    }
  },
  methods: {
    formatValue: function (value, round) {
      if (!this.diagramData.isNumeric) {
        return value;
      }

      if (typeof value === "undefined") return "";
      if (typeof value === "number") return value;
      if (value.includes("€")) return `€ ${formatNumber(value, round)}`;
      if (value.includes(","))
        return formatNumber(value.replace(",", ""), round);
      return formatNumber(value, round);
    },
    getPrice: function (value) {
      if (!this.diagramData.isNumeric) {
        return value;
      }

      return getPrice(value);
    },
    getTextStyle: function (value) {
      return getTextStyle(value, this.diagramData.conditionalFormatting);
    },
    openWindow: async function (url) {
      const result = await api.get(url);

      window.session.openWindow(result.data);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  text-align: center;
  font-size: 44px;
  font-weight: bold;
  color: #f26522;
}
</style>
