
export function getTextStyle(
  value,
  conditionalFormatting,
) {
  let style = "default";

  if (conditionalFormatting) {
    if (value > conditionalFormatting.Treshold) {
      style = conditionalFormatting.GreatherThan.Value;
    } else if (value < conditionalFormatting.Treshold) {
      style = conditionalFormatting.LessThan.Value;
    } else {
      style = conditionalFormatting.Equal.Value;
    }
  }

  if (!style) {
    style = "default";
  }

  return style;
}
