import dayjs from "dayjs";
export function getDateBadgeClasses(DateTimeExecuteStart) {
  const dayjsDate = dayjs(DateTimeExecuteStart);

  // if same date as today
  if (dayjsDate.isSame(dayjs(), "day")) {
    return "badge-warning";
  }

  if (dayjsDate.isBefore(dayjs())) {
    return "badge-danger";
  }
}
