<template>
  <div class="w-full px-[15px] mx-auto startup">
    <r-row class="mx-[-15px]">
      <r-col :md="12" :xl="3">
        <startup-quick-links />
      </r-col>
      <r-col :md="12" :xl="6" class="h-fit">
        <knowledgebase />
      </r-col>
    </r-row>
  </div>
</template>

<script>
import Knowledgebase from "../interface/knowledgebase/Knowledgebase.vue";
import StartupQuickLinks from "./StartupQuickLinks.vue";
import RRow from "../elements/RRow.vue";
import RCol from "../elements/RCol.vue";

export default {
  components: {
    Knowledgebase,
    StartupQuickLinks,
    RRow,
    RCol,
  },
};
</script>

<style scoped lang="scss"></style>
