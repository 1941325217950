import {getCounterPrice} from "@/services/v2/rental/item/getCounterPrice";
import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export async function fetchCounterPrice({row, quotation}) {
  const newRow = {...row};

  const {TransactionTypeID} = {...quotation}
  const itemType = getRawValueFromDropdownValue(row.ItemType);
  if (itemType === "Free") return;

  const startDate = row.DateTimeBusinessStart.Value;
  const endDate = row.DateTimeBusinessEnd.Value;
  
  const itemId = getRawValueFromDropdownValue(row.ItemID.Value);
  const counter = row.Counter?.Value ?? row.ExpectedCounter.Value;

  const counterPrices = await getCounterPrice({
    itemId,
    counter,
    startDate,
    endDate,
    TransactionTypeID,
  });

  newRow.CounterPrice = {
    Value: counterPrices.PriceNett,
  };

  return newRow;
}
