import {isDistributionReceiverRow} from "../rows/distribute-costs/isDistributionReceiverRow";

export const processInboundValueReadonly = ({row, columns}) => {
  const column = columns.find((column) => column.Name === "InboundValue");
  if (!column || column.IsReadOnly) {
    return row;
  }

  const isReceiver = isDistributionReceiverRow(row);
  if (isReceiver) {
    return {
      ...row,
      InboundValue: {
        ...row.InboundValue,
        IsReadOnly: undefined,
      },
    };
  } else {
    return {
      ...row,
      InboundValue: {
        ...row.InboundValue,
        IsReadOnly: true,
        Value: 0,
      },
    };
  }
};
