import {getResources} from "@/services/v2/core/railroad-task/filter-option/getResources";

import {getTranslations} from "@/functions/session/localstorage/getTranslations";

export async function getResourceFilterOptionValues() {
  const {Collection} = await getResources();
  const translations = getTranslations();
  const options = [];

  const uniqueTableNames = Collection.reduce((acc, resource) => {
    if (!acc.includes(resource.TableName) && resource.TableName) {
      acc.push(resource.TableName);
    }
    return acc;
  }, []);

  for (const tableName of uniqueTableNames) {
    const resources = Collection.filter(
      (resource) => resource.TableName === tableName,
    )
      .sort((a, b) => a.Ranking - b.Ranking)
      .map((resource) => ({
        label: resource.Description,
        id: resource.ResourceID,
        color: resource.Color,
      }));

    const tableDescription =
      translations[tableName] ?? translations[tableName.split(".")[1]];
    options.push({
      label: tableDescription,
      id: tableName,
      children: resources,
    });
  }

  return options;
}
