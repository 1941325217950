<template>
  <div class="mt-1.5 mb-2.5">
    <r-checkbox
      :size="'lg'"
      :value="value"
      :input-id="fieldName"
      :readonly="readonly"
      @change="handleChange"
    />
  </div>
</template>

<script>
import RCheckbox from "../../elements/RCheckbox.vue";

export default {
  name: "CheckboxField",
  components: {RCheckbox},
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    value() {
      return this.field.Value;
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    readonly() {
      return this.field.IsReadOnly;
    },
  },
  methods: {
    handleChange(newValue) {
      this.$emit("input", {Value: newValue});
    },
  },
};
</script>
