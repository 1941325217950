import {getExpectedDeliveryDaysFromRow} from "./getExpectedDeliveryDaysFromRow";
import {getExpectedDeliveryDaysFromSupplier} from "../getExpectedDeliveryDaysFromSupplier";
import {setExpectedDeliveryDate} from "@/functions/datagrid/inbound-orderitem/setExpectedDeliveryDate";
import {setRowDateTimeDesiredDelivery} from "@/functions/datagrid/inbound-orderitem/setRowDateTimeDesiredDelivery";

export const handleDateTimeDesiredDeliveryChange = async ({
  row,
  inboundItems,
}) => {
  const {DateTimeDesiredDelivery, DateTimeOrdered} = row;

  let itemExpectedDeliveryDays = getExpectedDeliveryDaysFromRow({
    row,
    inboundItems,
  });
  let supplierExpectedDeliveryDays = await getExpectedDeliveryDaysFromSupplier({
    row: row,
  });

  let expectedDeliveryDays = setExpectedDeliveryDate({
    itemDays: itemExpectedDeliveryDays,
    supplierDays: supplierExpectedDeliveryDays,
  });

  const dateDesired = new Date(DateTimeDesiredDelivery.Value);
  dateDesired.setHours(0, 0, 0, 0);

  const dateOrdered = new Date(DateTimeOrdered.Value);
  dateOrdered.setHours(0, 0, 0, 0);

  const differenceInDays = (dateDesired - dateOrdered) / (1000 * 60 * 60 * 24);

  return setRowDateTimeDesiredDelivery({
    row: row,
    differenceInDays,
    expectedDeliveryDays,
    itemExpectedDeliveryDays,
  });
};
