import {api} from "../../../api";
import store from "../../../../../state/store";
export async function getPrice({
  itemId,
  quotationItemID,
  startDate,
  endDate,
  customerId,
  transactionTypeId,
  contractId,
}) {
  const result = await api.get(
    `/v2/rental/item/${encodeURIComponent(itemId)}/price/${encodeURIComponent(
      startDate,
    )}/${encodeURIComponent(endDate)}`,
    {
      windowId: global.session?.activeWindow.id ?? "",
      params: {
        QuotationItemID: quotationItemID,
        WarehouseID: store.state.activeWarehouse,
        CustomerID: customerId,
        TransactionTypeID: transactionTypeId,
        ContractID: contractId,
      },
    },
  );

  return result.data;
}
