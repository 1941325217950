import {getSettings} from "../../../../util/getSettings.js";

export const clearPeriodPricesOnPeriodClearWhenPeriodUseOptional = ({
  row,
  oldRow,
}) => {
  const {PeriodSelection} = getSettings();
  const allowItemSinglePeriodPriceCalculation =
    PeriodSelection === "AllowItemSinglePeriodPriceCalculation";

  if (!allowItemSinglePeriodPriceCalculation) return row;
  if (oldRow.Period.Value === null) return row;
  if (row.Period.Value === null) {
    return {
      ...row,
      PeriodPrices: {
        ...row.PeriodPrices,
        Value: [],
      },
      PeriodPrice: {
        ...row.PeriodPrice,
        Value: null,
      },
    };
  }
  return row;
};
