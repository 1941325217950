<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3" />
    </div>
    <div ref="datagrid">
      <datagrid-standalone
        :rows="rows"
        :columns="columns"
        :page="page"
        :page-size="pageSize"
        @page-change="page = $event"
        @rows-updated="handleRowsUpdated"
      >
        <template #header>
          <datagrid-header-pick-list-item
            :page-size="pageSize"
            :loaded="loaded"
            :rows="rows"
            @scan="handleScan"
            @page-size-change="handlePageSizeChange"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
import {confirmDiscardUnsavedChanges} from "../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {convertRowToliRowDataRow} from "../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {serialIDExistenceCheck} from "../../../functions/datagrid/pick-list-item/scan/serialIDExistenceCheck";
import {detectDatagridDataChanges} from "../../../functions/datagrid/detectDatagridDataChanges.js";
import {handleJobExecution} from "../../../functions/datagrid/actions/handleJobExecution.js";
import {findRowAndUpdate} from "../../../functions/datagrid/pick-list-item/scan/findRowAndUpdate";
import {scanPickListItem} from "../../../functions/datagrid/data/scanPickListItem";
import {scanErrorHandler} from "../../../functions/datagrid/pick-list-item/scan/scanErrorHandler";
import {checkPickStatus} from "../../../functions/datagrid/pick-list-item/save/checkPickStatus.js";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";
import {setPicklistData} from "../../../functions/datagrid/pick-list-item/setPicklistData.js";
import {patchItems} from "../../../functions/datagrid/pick-list-item/save/patchItems.js";
import {formatRow} from "../../../functions/datagrid/pick-list-item/save/formatRow.js";
import {cloneDeep} from "lodash";
import DatagridHeaderPickListItem from "../datagridHeaders/DatagridHeaderPickListItem.vue";
import DatagridStandalone from "../DatagridStandalone.vue";

export default {
  name: "DatagridPickListItem",
  components: {
    DatagridHeaderPickListItem,
    DatagridStandalone,
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pickListItems: [],
      pickListId: null,
      criteria: [],
      rows: [],
      originalRows: [],
      columns: [],
      loaded: false,
      loading: false,
      loadingPage: false,
      page: 1,
      pageSize: 100,
      translations: getTranslations(),
    };
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.criteria = this.rWindow.criteria;
    this.pickListId = this.criteria[0].PickListID;

    const {rows, columns} = await setPicklistData({
      pickListId: this.pickListId,
      criteria: this.criteria,
      settings: this.settings,
    });
    this.rows = rows;
    this.columns = columns;
    this.originalRows = cloneDeep(this.rows);

    this.loaded = true;
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async save(job) {
      try {
        if (!this.loaded) return false;
        this.$store.state.loading = true;
        const currentDirtyState = this.rWindow.data.dirty;
        this.$emit("data-change", {
          windowId: this.rWindow.id,
          newData: {
            ...this.rWindow.data,
            dirty: false,
          },
        });

        const formattedRows = this.rows.flatMap((row) => formatRow({row}));

        const result = await patchItems(formattedRows);
        if (result !== "Error") {
          await checkPickStatus({
            rows: this.rows,
            pickListId: this.pickListId,
            windowId: this.rWindow.id,
            translations: this.translations,
          });

          const {rows, columns} = await setPicklistData({
            pickListId: this.pickListId,
            criteria: this.criteria,
            settings: this.settings,
          });

          this.rows = rows;
          this.columns = columns;
          this.originalRows = cloneDeep(this.rows);
        } else {
          this.$emit("data-change", {
            windowId: this.rWindow.id,
            newData: {
              ...this.rWindow.data,
              dirty: currentDirtyState,
            },
          });
        }
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);

        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }
        this.$store.state.loading = true;

        const {rows, columns} = await setPicklistData({
          pickListId: this.pickListId,
          criteria: this.criteria,
          settings: this.settings,
        });

        this.rows = rows;
        this.columns = columns;
        this.originalRows = cloneDeep(this.rows);
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async handleScan(scanData) {
      if (scanErrorHandler({scanData})) return;

      const {ItemID, SerialID} = scanData[0];

      if (serialIDExistenceCheck({rows: this.rows, SerialID})) return;

      this.rows = findRowAndUpdate({rows: this.rows, ItemID, SerialID});

      this.updateWindowData();
      this.navigateToLastPage();
    },
    handleRowsUpdated({rows}) {
      this.rows = rows;
      this.updateWindowData();
    },
    updateWindowData() {
      const columnsObject = this.columns.reduce((acc, column) => {
        acc[column.Name] = column;
        return acc;
      }, {});

      const windowData = {
        ...this.rWindow.data,
        Columns: columnsObject,
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    navigateToLastPage() {
      const rowCount = this.rows.filter(
        (row) => !row.rowMeta?.compositionItem,
      ).length;
      this.page = Math.ceil(rowCount / this.pageSize);
    },
    navigateToPage({page}) {
      this.page = page;
    },
    scanPickListItem,
    setPicklistData,
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>

<style scoped></style>
