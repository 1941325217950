export const getFormattedSelectFieldValue = ({field, options}) => {
  const option = options?.find(
    (item) => item.Value?.toString() === field.Value?.toString(),
  );

  if (!option && field.Label && field.Value)
    return {
      Text: field.Label,
      Value: field.Value,
      SubDescription: field.SubDescription ?? "",
    };

  if (!option) return field.Value;

  return option;
};