import {processFormPostbackMetadata} from "./processFormPostbackMetadata.js";
import {processFormPostbackChanges} from "./processFormPostbackChanges.js";

export const processFormPostbackResult = ({result, windowData}) => {
  if (!result?.data?.Events) return windowData;
  let newData = {...windowData};

  if (result.data.Events[0]?.[0] === "update-window") {
    newData = result?.data?.Events?.[0]?.[1]?.Data;
    newData = processFormPostbackMetadata({newData});
  }

  if (result.data.Events[0]?.[0] === "apply-changes") {
    const changes = result.data.Events[0]?.[1];
    newData = processFormPostbackChanges({newData, changes});
  }

  return newData;
};
