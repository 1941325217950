import {filterCustomRowsProperties} from "./rows/filterCustomRowsProperties.js";
import {hasDifferences} from "./rows/updateRows/hasDifferences.js";
import {convertRowToliRowDataRow} from "./order-item/row/convertRowToliRowDataRow.js";

export const detectCustomPropertyChanges = ({newRows, originalRows}) => {
  const newRowsCustomProperties = filterCustomRowsProperties({rows: newRows});
  const originalRowsCustomProperties = filterCustomRowsProperties({
    rows: originalRows,
  });

  const newRowsValueMap = newRowsCustomProperties.map(convertRowToliRowDataRow);
  const originalRowsValueMap = originalRowsCustomProperties.map(
    convertRowToliRowDataRow,
  );

  return hasDifferences({
    obj1: originalRowsValueMap,
    obj2: newRowsValueMap,
  });
};
