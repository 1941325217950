import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";

export function handleItemDescriptionChange({row, oldRow}) {
  const newDescription = row.ItemDescription.Value;
  const oldDescription = oldRow.ItemDescription.Value;
  const itemType = getRawValueFromDropdownValue(row.ItemType.Value);

  if (newDescription !== oldDescription) {
    row.ItemType.Value = itemType;
  }
}
