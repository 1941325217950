import store from "../../state/store";
import Hook from "../hook";
import Sidebar from "../components/form/formSidebar/FormSidebar.vue";

const initializeVueInstance = ({element, eventWindow}) => {
  const sidebarElements = element?.querySelectorAll("[sidebar]") ?? [];
  if (sidebarElements.length) {
    new window.vue({
      el: sidebarElements[0],
      store,
      render: (h) =>
        h(Sidebar, {
          props: {
            windowId: eventWindow.id,
            window: eventWindow,
          },
        }),
    });
  }
};

class RailroadHook extends Hook {
  async afterRender(eventWindow) {
    initializeVueInstance({element: eventWindow.element, eventWindow});
  }

  async afterFocus(eventWindow) {
    initializeVueInstance({element: eventWindow.element, eventWindow});
  }
}

export default new RailroadHook();
