import {getActiveWarehouse} from "../../../util/getActiveWarehouse.js";
import openWindow from "../../../actions/openWindow.js";

export const openInboundPutAway = ({deliveryItems}) => {
  const orderIdArray = [...new Set(deliveryItems.map((row) => row.OrderID))];

  openWindow({
    Subject: "Inbound.vw_PutAway",
    Prefix: "Multi",
    Headless: false,
    Criteria: [{OrderID: orderIdArray}, {WarehouseID: getActiveWarehouse()}],
  });
};
