import {api} from "@/services/api";
import store from "../../../../../state/store";


export async function getCounterPrice({
  itemId,
  counter,
  customerId,
  startDate,
  endDate,
  TransactionTypeID,
}){
  const result = await api.get(
    `/v2/rental/item/${itemId}/counter-price/${counter}`,
    {
      windowId: global.session.activeWindow.id,
      params: {
        WarehouseID: store.state.activeWarehouse,
        CustomerID: customerId,
        startDate,
        endDate,
        TransactionTypeID: TransactionTypeID
      },
    },
  );

  return result.data;
}
