export const setCustomFieldsObject = ({row}) => {
  if (row["CustomFields"]) {
    return row;
  }

  const customFields = {};
  Object.keys(row).forEach((columnName) => {
    if (columnName.startsWith("CUST_")) {
      customFields[columnName] = row[columnName];
    }
  });

  return {...row, CustomFields: customFields};
};
