var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"select-scan-label"},[(_vm.type)?_c('i',{class:{
        'fas fa-tag pl-1': _vm.type === 'ItemSerial',
        'fas fa-cube': _vm.type === 'Item' || _vm.type === 'RentalItem',
        'fas fa-cubes': _vm.type === 'Composition' || _vm.type === 'ContentItem',
      }}):_vm._e(),_c('span',{class:{
        italic: _vm.type === 'Composition',
        'pl-1 serial': _vm.type === 'ItemSerial',
      },domProps:{"innerHTML":_vm._s(_vm.highlightSearchCharacters(_vm.label))}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }