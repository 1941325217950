export const handleCheckboxCheck = ({rows, row}) => {
  return rows.map((r) => {
    if (r.ClientSideUUID.Value === row.ClientSideUUID.Value) {
      r.Checked.Value = !r.Checked.Value;

      if (r.Checked.Value) r.DeliveryAmount.Value = r.Amount.Value;
      if (!r.Checked.Value) r.DeliveryAmount.Value = 0;

      return r;
    }
    return r;
  });
};
