import {processSettingItemSinglePeriodDefault} from "@/functions/datagrid/columns/processSettingItemSinglePeriodDefault.js";
import {processSettingAllowItemSinglePeriodPriceCalculation} from "@/functions/datagrid/columns/processSettingAllowItemSinglePeriodPriceCalculation";
import {processSettingItemSinglePeriodEnforced} from "@/functions/datagrid/columns/processSettingItemSinglePeriodEnforced";
import {processSettingAllowItemSinglePeriodCalculateEndDate} from "@/functions/datagrid/columns/processSettingAllowItemSinglePeriodCalculateEndDate";
import {getSettings} from "@/util/getSettings";

export function processColumnSettings(columns) {
  let newColumns = columns.slice();
  const {PeriodSelection} = getSettings();

  const defaultPeriodSelection = PeriodSelection === "Default";
  const allowItemSinglePeriodPriceCalculation =
    PeriodSelection === "AllowItemSinglePeriodPriceCalculation";
  const ItemSinglePeriodEnforced =
    PeriodSelection === "ItemSinglePeriodEnforced";
  const ItemSinglePeriodCalculateEndDateEnforced =
    PeriodSelection === "ItemSinglePeriodCalculateEndDateEnforced";

  if (defaultPeriodSelection) {
    newColumns = processSettingItemSinglePeriodDefault({
      columns: newColumns,
    });
  }

  if (allowItemSinglePeriodPriceCalculation) {
    newColumns = processSettingAllowItemSinglePeriodPriceCalculation({
      columns: newColumns,
    });
  }

  if (ItemSinglePeriodEnforced) {
    newColumns = processSettingItemSinglePeriodEnforced({
      columns: newColumns,
    });
  }

  if (ItemSinglePeriodCalculateEndDateEnforced) {
    newColumns = processSettingAllowItemSinglePeriodCalculateEndDate({
      columns: newColumns,
    });
  }

  return newColumns;
}
