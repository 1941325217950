export const mergeValueOrValueObject = ({oldValue, newValue, cell}) => {
  if (typeof oldValue == "object" && cell.Name !== "rowMeta") {
    return {
      ...oldValue,
      ...(newValue && typeof newValue == "object"
        ? cell ?? newValue
        : cell ?? {Value: newValue ?? null}),
    };
  }

  return newValue;
};
