import {checkScanDuplicateSerialItem} from "@/functions/datagrid/stock-transfer-item/checkScanDuplicateSerialItem";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";
import {notify} from "@/util/notify";

export async function handleSerialIdChange({row, rows, oldRow}) {
  const translations = getTranslations();

  const isDoubleSerial = checkScanDuplicateSerialItem({
    oldRows: rows,
    newRows: row,
  });

  if (isDoubleSerial) {
    await notify({
      message: translations.AdminDesktop_AlertDoubleSerial,
      type: "danger",
    });

    return oldRow;
  }

  if (row.SerialID.Value) {
    row.Amount.IsReadOnly = true;
  } else {
    row.Amount.IsReadOnly = false;
  }

  return row;
}
