import {setValuesForRowInRows} from "../setValuesForRowInRows";

export const updatePriceConditionItemRow = ({
  row,
  rows,
  existingRow,
  priceCondition,
}) => {
  const updatedRows =
    priceCondition.AmountType !== "Follow"
      ? rows
      : setValuesForRowInRows({
          rows,
          row: existingRow,
          values: {Amount: row.Amount.Value},
        });

  return {
    rows: updatedRows,
    priceConditionOrderItemIDs: [existingRow.OrderItemID?.Value],
  };
};
