export const getHighestRankFromRows = ({rows}) => {
  if (!rows.length || !rows.some((row) => row.Ranking)) {
    return 0;
  }

  const highestRankingRow = rows.reduce((prev, current) =>
    prev.Ranking.Value > current.Ranking.Value ? prev : current,
  );

  return highestRankingRow.Ranking.Value;
};
