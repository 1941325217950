import {processChangedFieldExtraCriteria} from "./processChangedFieldExtraCriteria.js";
import {processLinkedFieldsExtraCriteria} from "./processLinkedFieldsExtraCriteria.js";
import {fieldContainsExtraCriteria} from "../fieldContainsExtraCriteria.js";

export const processFormExtraCriteria = async ({changedField, windowData}) => {
  const newData = {...windowData};

  const changedFieldHasExtraCriteria = fieldContainsExtraCriteria({
    field: changedField,
  });

  if (changedFieldHasExtraCriteria) {
    const {newRow, newColumns} = await processChangedFieldExtraCriteria({
      changedField,
      columns: newData.Columns,
      row: newData.Rows[0],
    });

    newData.Columns = {...newColumns};
    newData.Rows[0] = {...newRow};
  }

  const {newRow, newColumns} = await processLinkedFieldsExtraCriteria({
    changedField,
    columns: newData.Columns,
    row: newData.Rows[0],
  });

  return {
    ...newData,
    Rows: [{...newRow}],
    Columns: {...newColumns},
  };
};
