import {notify} from "@/util/notify";
import store from "../../../../state/store";
import {assignRankingToNewRow} from "@/functions/datagrid/row/assignRankingToNewRow";

export function handleRankingChange({row, rows, oldRow}) {
  const ranking = row.Ranking.Value;
  const oldRanking = oldRow.Ranking.Value;
  const sameRanking = rows.filter((r) => r.Ranking.Value === ranking);

  if (sameRanking.length > 1) {
    const newRow = {...row};
    newRow.Ranking.Value = oldRanking;

    try {
      notify({
        message: store.state.translations.RowWithRankingAlreadyExists.replace(
          "{ranking}",
          ranking,
        ),
        type: "warning",
      });
    } catch (e) {
      console.error(e);
    }

    return newRow;
  }

  if (ranking === null || ranking === 0) {
    const newRow = {...row};
    oldRanking >= 1
      ? (newRow.Ranking.Value = oldRanking)
      : (newRow.Ranking.Value = assignRankingToNewRow({rows: rows}));

    return newRow;
  }

  return row;
}
