<template>
  <div>
    <div
      class="flex justify-between bg-gray-100 text-right px-2 pt-2 mr-2 min-w-[275px] max-h-[40px]"
    >
      <div class="text-left font-semibold">
        {{ translations.TotalItemWeight }}:
      </div>
      <div class="flex">
        {{ totalItemsWeight }}
        <div class="font-semibold ml-0.5">KG</div>
      </div>
    </div>
    <div
      class="flex justify-between bg-gray-100 text-right px-2 mr-2 min-w-[250px] max-h-[40px]"
    >
      <div class="text-left font-semibold pb-2">
        {{ translations.TotalShippingWeight }}:
      </div>
      <div class="flex">
        {{ totalShippingWeight }}
        <div class="font-semibold ml-0.5">KG</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getTranslations} from "@/functions/session/localstorage/getTranslations";
import {formatDecimal} from "../../../util/formatting/formatDecimal.js";

export default {
  name: "DatagridFooterWeightSum",
  props: {
    totalWeights: {
      type: Object,
      required: true,
    },
  },
  computed: {
    translations() {
      return getTranslations();
    },
    totalItemsWeight() {
      return formatDecimal(this.totalWeights.ItemsWeight);
    },
    totalShippingWeight() {
      return formatDecimal(this.totalWeights.ShippingWeight);
    },
  },
};
</script>

<style scoped></style>
