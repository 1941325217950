export const setCompositionDescriptionToItemDescriptionForVirtualRow = ({
  row,
}) => {
  if (!row.rowMeta?.virtual) return row;
  if (
    row.ItemDescription.Value &&
    row.ItemDescription.Value !== row.Composition.Value
  ) {
    return row;
  }

  return {
    ...row,
    ItemDescription: {
      ...row.ItemDescription,
      Value: row.CompositionDescription.Value,
    },
  };
};
