<template>
  <div>
    <tooltip :message="message" :sub-message="subMessage">
      <div
        class="has-tooltip cursor-pointer"
        data-tooltip-target="tooltip-default"
      >
        <i :class="`fas ${icon} text-${iconColor}`"></i>
      </div>
      <template v-if="component" #tooltip-body>
        <component
          :is="component"
          :cell="cell"
          :row="row"
          :rows="rows"
          :component-data="componentData"
          :process-context="processContext"
          @icon-event="$emit('icon-event', $event)"
        ></component>
      </template>
    </tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/util/Tooltip.vue";

export default {
  name: "DatagridCellIcon",
  components: {Tooltip},
  props: {
    message: {
      type: String,
      required: false,
    },
    subMessage: {
      type: String,
      required: false,
      default: null,
    },
    cell: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    component: {
      type: Object,
      required: false,
    },
    componentData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    processContext: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
