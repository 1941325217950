import Hook from "@/hook";
import store from "../../../state/store";

/**
 * Focus self
 * @see {@link Session.activeWindow}
 * @returns {Promise} Promise
 */
export default async function focus(window) {
  const targetWindow = window ?? this;
  // prevent if we are already the active window
  if (
    targetWindow.session.activeWindow &&
    targetWindow.session.activeWindow.id === targetWindow.id
  ) {
    return null;
  }

  // hookable
  const focus = await Hook.run("focus", targetWindow, () => {
    this.session.activeWindow = targetWindow;
  });
  store.commit("updateWindow");

  const tabID = targetWindow.id;
  let tabs = targetWindow.session.tabs;

  tabs = targetWindow.session.tabs;

  if (!tabs.find((tab) => tab.id === tabID)) {
    tabs.unshift(targetWindow);
  }

  targetWindow.session.tabs = tabs;

  store.commit("setActiveWindowID", targetWindow.id);
  store.commit("updateWindow");

  global.eventBus.emit("afterFocus", targetWindow);
  return focus;
}
