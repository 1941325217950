import {getRentalPeriodBasedPricesFromRow} from "@/functions/datagrid/row/getRentalPeriodBasedPricesFromRow";
import Decimal from "decimal.js";
export async function processUseSinglePeriodPriceDifference({
  row,
  startDate,
  endDate,
  periodAmount,
  params,
}) {
  const calculatedPrices = await getRentalPeriodBasedPricesFromRow({
    row,
    params,
    startDate,
    endDate,
    periodAmount,
  });

  const discount = new Decimal(parseFloat(row.Discount?.Value ?? 0)).toNumber();

  const counterPrice = new Decimal(
    parseFloat(row.CounterPrice?.Value ?? 0),
  ).toNumber();

  let newCalculatedPrice = calculatedPrices[0].PriceGross;

  newCalculatedPrice = new Decimal(newCalculatedPrice).add(counterPrice);
  newCalculatedPrice = newCalculatedPrice
    .times(row.Amount.Value)
    .dividedBy(100)
    .times(100 + discount)
    .toDecimalPlaces(4);

  row.CalculatedPrice.Value = newCalculatedPrice.toNumber();

  return row;
}
