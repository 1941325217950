export function processCompositionDefinition({row}) {
  const newRow = {...row};

  if (
    !newRow.CompositionDefinition?.Value?.QuantityPolicy ||
    newRow?.rowMeta?.virtual === true
  )
    return row;

  if (
    newRow.CompositionDefinition.Value.QuantityPolicy === "AdjustableUpwards"
  ) {
    newRow.Amount = {
      ...newRow.Amount,
      MinNumber: newRow.Amount.Value,
    };
  }

  if (
    newRow.CompositionDefinition.Value.QuantityPolicy === "AdjustableDownwards"
  ) {
    newRow.Amount = {
      ...newRow.Amount,
      MaxNumber: newRow.Amount.Value,
    };
  }

  if (
    newRow.CompositionDefinition.Value.QuantityPolicy ===
      "AdjustableDownwards" &&
    newRow?.rowMeta?.virtual === true
  ) {
    newRow.Amount = {
      ...newRow.Amount,
      IsReadOnly: true,
    };
  }

  if (newRow.CompositionDefinition.Value.QuantityPolicy === "NotAdjustable") {
    newRow.Amount = {
      ...newRow.Amount,
      IsReadOnly: true,
    };
  }

  return newRow;
}
