import {setValuesInRow} from "../row/setValuesInRow";

export const setDefaultVatCodeID = ({row, supplier}) => {
  if (!row.VATCodeID?.Value) {
    const vatCodeId =
      row.DefaultVATCodeID?.Value?.Key ??
      row.DefaultVATCodeID?.Value ??
      supplier?.DefaultVATCodeID?.Key;
    return setValuesInRow({row, values: {VATCodeID: vatCodeId}});
  }
  return row;
};
