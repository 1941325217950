<template>
  <tr
    v-if="showAdvanced"
    :class="{'bg-white': odd, 'bg-gray-100': !odd, hidden: isHidden}"
    class="whitespace-nowrap border"
  >
    <td colspan="100%">
      <row-form
        class="mt-2"
        :row="row"
        :fields="fields"
        @change="processChange"
      />
    </td>
  </tr>
</template>

<script>
import {setAdvancedColumnFields} from "../../functions/datagrid/columns/setAdvancedColumnFields.js";
import mapValues from "lodash/mapValues";
import RowForm from "@/components/form/RowForm";

export default {
  name: "DatagridRowAdvancedFields",
  components: {RowForm},
  props: {
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    processContext: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    row() {
      return this.value;
    },
    fields() {
      // Loop through the columns and set the advanced fields for each row
      const fields = setAdvancedColumnFields({
        processContext: this.processContext,
        columns: this.columns,
        row: this.row,
      });

      return mapValues(fields, (field, key) => ({
        ...field,
        Value: this.row[key].Value,
        Dropdown: this.row[key].Dropdown ?? field.Dropdown,
        IsReadOnly: this.row[key].IsReadOnly ?? field.IsReadOnly,
      }));
    },
    rowMeta() {
      return this.row.rowMeta ?? null;
    },
    showAdvanced() {
      return this.rowMeta?.showAdvanced === true;
    },
    isHidden() {
      return this.row.rowMeta?.classes?.hidden;
    },
    odd() {
      return this.index % 2 === 0;
    },
    filteredColumns() {
      return this.columns.filter((column) => !column.ShowUnder);
    },
  },
  methods: {
    processChange(valueObject) {
      this.$emit("change", valueObject);
    },
  },
};
</script>

<style scoped>
tr {
  font-size: 15.4px;
}
</style>
