export function checkReturnableRow({rows, event}) {
  const selectedRowId = event?.row.ClientSideUUID.Value;

  return rows.map((row) => {
    if (row?.ClientSideUUID?.Value === selectedRowId) {
      return {
        ...row,
        Checked: {
          ...row.Checked,
          Value: !row.Checked.Value,
        },
      };
    }

    return row;
  });
}
