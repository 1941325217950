import {rowsVirtualResumTotalExcVat} from "@/functions/datagrid/order-item/rows/rowsVirtualResumTotalExcVat";
import {rowSetCorrectAmountForVirtualCompositionAfterAmountChangeRow} from "@/functions/datagrid/rows/rowsSetCorrectAmountForVirtualCompositionAfterAmountChangeRow";

export const rowsAmountChangeWrapper = async ({row, rows, quotation}) => {
  let modifiedRows = rows.slice();

  modifiedRows = await rowsVirtualResumTotalExcVat({
    row,
    rows: modifiedRows,
  });

  modifiedRows = rowSetCorrectAmountForVirtualCompositionAfterAmountChangeRow({
    row,
    rows: modifiedRows,
  });

  return modifiedRows;
};
