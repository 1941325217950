import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {getPriceBulk} from "@/services/v2/rental/item/getPriceBulk";
import dayjs from "dayjs";

export async function getRentalPeriodBasedPricesFromRow({
  startDate,
  endDate,
  row,
  periodAmount,
  periodPrices,
  params,
}) {
  if (row.Period?.Value || row.HasPeriodPricesForCalculation?.Value) {
    const period = row.Period.Value;
    const periodPrice = row.PeriodPrice.Value;

    const periodStartDate =
      startDate ??
      row.DateTimeRentalStart?.Value ??
      row.DateTimeBusinessStart.Value;

    const periodEndDate =
      endDate ?? row.DateTimeRentalEnd?.Value ?? row.DateTimeBusinessEnd.Value;

    const rentalPeriods = [
      {
        ItemID: getRawValueFromDropdownValue(row.ItemID.Value),
        ItemType: getRawValueFromDropdownValue(row.ItemType.Value),
        StartDate: periodStartDate
          ? dayjs(periodStartDate, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        EndDate: periodEndDate
          ? dayjs(periodEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        PeriodPrices: [
          {
            Period: period,
            Price: periodPrice ?? 0,
          },
        ],
        Amount: periodAmount,
        Period: period,
      },
    ];

    rentalPeriods[0].PeriodPrices =
      periodPrices ?? row.PeriodPrices?.Value?.length
        ? row.PeriodPrices.Value
        : rentalPeriods[0].PeriodPrices;

    return await getPriceBulk({rentalPeriods, params});
  }
}
