import {setResourceIdBadgeClasses} from "./setResourceIdBadgeClasses";
import {setDateTimeBadgeClasses} from "./setDateTimeBadgeClasses";
import {translateCellValueAndForceToString} from "../cell/translateCellValueAndForceToString";
export function setRowMetaData({row}) {
  const newRow = {...row};

  newRow.ResourceID = setResourceIdBadgeClasses({cell: newRow.ResourceID});
  newRow.DateTimeExecuteEnd = setDateTimeBadgeClasses({
    cell: newRow.DateTimeExecuteEnd,
  });

  newRow.ResourceID = translateCellValueAndForceToString({
    cell: newRow.ResourceID,
  });

  // newRow.TableName = translateCellValueAndForceToString({
  //   cell: newRow.TableName,
  // });

  return newRow;
}
