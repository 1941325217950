export const setRowsSerialColumnReadOnlyForNonSerialRows = ({rows}) => {
  return rows.map((row) => {
    if (row.UseSerials.Value === false) {
      return {
        ...row,
        SerialID: {
          ...row.SerialID,
          IsReadOnly: true,
        },
      };
    }
    return row;
  });
};
