<template>
  <div class="table-view v-table" :class="{unsortable: true}">
    <table-form
      :search-values="searchValues"
      :filters="filters"
      :disable-search="disableSearch"
      @remove-search="$emit('remove-search', $event)"
      @search="$emit('search', $event)"
      @filter-change="$emit('filter-change', $event)"
    />
    <div
      v-if="filteredRows.length === 0 && !loading"
      class="w-full text-center m-5"
    >
      <p class="font-bold">{{ translations["NoRowsFound"] }}</p>

      <p class="text-gray">
        {{ translations["NoRowsFoundSubDescription"] }}
      </p>
    </div>

    <compoment
      :is="tableRowsWrapperComponent"
      :rows="filteredRows"
      :columns="columns"
    />
    <table-footer
      class="mt-2"
      :max-rows="maxRows"
      :rows="rows"
      :loading="loading"
    />
  </div>
</template>
<script>
import {getCellText} from "./tableCellTypes/getTextRenderer";
import TableForm from "./TableForm.vue";
import TableFooter from "./TableFooter.vue";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";

const translations = getTranslations();

export default {
  name: "VTable",
  components: {
    TableFooter,
    TableForm,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    maxRows: {
      type: Number,
      required: false,
    },
    tableRowsWrapperComponent: {
      type: Function,
      required: true,
    },
    searchValues: {
      type: Array,
      default: () => [],
    },
    clientSideFiltering: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      translations,
    };
  },
  computed: {
    filteredRows() {
      if (!this.clientSideFiltering) return this.rows;

      return [
        (rows) =>
          this.searchFilteredRows({rows, searchValues: this.searchValues}),
        (rows) => this.filterValueFilteredRows({rows, filters: this.filters}),
      ].reduce((rows, filter) => filter(rows), this.rows);
    },
  },
  methods: {
    searchFilteredRows({rows, searchValues}) {
      if (searchValues.length) {
        return rows.filter((row) => {
          return Object.keys(row).some((columnName) => {
            const cell = row[columnName];

            return searchValues.every((searchValue) => {
              const column = this.columns.find((x) => x.Name == columnName);

              const strValue = getCellText({cell, column});

              if (!strValue) return false;

              return strValue.toLowerCase().includes(searchValue.toLowerCase());
            });
          });
        });
      }
      return rows;
    },
    filterValueFilteredRows({rows, filters}) {
      let filteredRows = rows.slice();
      filters.forEach((filter) => {
        if (!filter.Selected.length) return rows;
        filteredRows = filteredRows.filter((row) => {
          return filter.Selected.some((selected) => {
            return row[filter.Target].Value === selected;
          });
        });
      });

      return filteredRows;
    },
  },
};
</script>
<style lang="scss">
.v-table {
  .vue-treeselect--has-value .vue-treeselect__multi-value {
    margin-bottom: 0px;
  }

  .vue-treeselect__multi-value-item {
    margin: 2px;
  }
  .vue-treeselect__multi-value-item-container {
    padding-top: 0px;
    width: 100%;
  }
  span.vue-treeselect__multi-value-label {
    width: 100%;
  }
}
</style>
