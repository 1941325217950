export function handleCheckboxStateChange({rows}) {
  const newRows = [...rows];

  return newRows.sort((a, b) => {
    if (a.Checked.Value === b.Checked.Value) {
      return 0;
    }

    return a.Checked.Value ? -1 : 1;
  });
}
