<template>
  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-2 radialbar">
    <div
      :class="{
        card: true,
        'card-chart': true,
        'h-100': true,
        unavailable,
      }"
    >
      <div class="card-body">
        <r-button
          class="float-right text-grey mb-0 mt-[-10px] mx-[4px] mr-[-6px]"
          variant="link"
          :title="diagramData.title"
        >
          <i class="fas fa-question"></i>
        </r-button>
        <r-button
          v-if="diagramData.detailUrl"
          class="float-right text-grey mb-0 mt-[-10px] mx-[4px] mr-[-6px]"
          variant="link"
          @click="openWindow(diagramData.detailUrl)"
        >
          <i class="fas fa-list"></i>
        </r-button>
        <h5 class="title">
          <strong>{{ diagramData.title }}</strong>
        </h5>
        <div
          :class="
            'text-' +
            getTextStyle(
              diagramData.data != null
                ? (getPrice(diagramData.data.Value) * 100) /
                    getPrice(diagramData.data.Total)
                : null,
            )
          "
        >
          <apexchart
            v-if="diagramSettings.series.length > 0"
            :type="diagramSettings.chartOptions.chart.type"
            :options="diagramSettings.chartOptions"
            :series="diagramSettings.series"
          ></apexchart>
        </div>
        <div
          v-if="diagramData.data"
          class="radialContext"
          :title="
            formatValue(diagramData.data.Value, false) +
            ' / ' +
            formatValue(diagramData.data.Total, false)
          "
        >
          {{ formatValue(diagramData.data.Value, true) }} /
          {{ formatValue(diagramData.data.Total, true) }}
        </div>
      </div>

      <div class="overlay">
        <div class="text">{{ $store.getters.translations.NoDataFound }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {formatNumber} from "../../util/formatting/shortTypedPrice";
import {getTextStyle} from "../../util/formatting/getTextStyle";
import {getPrice} from "../../util/parsing/getPrice";
import RButton from "../elements/RButton.vue";

import {api} from "../../services/api";
import {axiosInstance} from "../../services/axiosInstance";

export default {
  components: {
    apexchart: VueApexCharts,
    RButton,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      unavailable: false,
      diagramData: {},
      diagramSettings: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: "radialBar",
            offsetY: -10,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,

              dataLabels: {
                name: {
                  fontSize: "16px",
                  color: undefined,
                  offsetY: -20,
                },
                value: {
                  offsetY: -10,
                  fontSize: "22px",
                  color: "inherit",
                  formatter: function (val) {
                    return `${formatNumber(val)} %`;
                  },
                },
              },
            },
          },
          labels: [""],
        },
      },
    };
  },
  themedOptions: function () {
    const options = this.diagramSettings.chartOptions;

    options.theme = {
      monochrome: {
        enabled: true,
        color: "#f26522",
        shadeTo: "light",
        shadeIntensity: 0.85,
      },
    };

    return options;
  },
  created: async function () {
    if (this.endpoint) {
      const result = await axiosInstance.get(this.endpoint);

      this.diagramData = result.data;

      // If unavailable or necessary data is not available show data not available
      if (
        Object.keys(this.diagramData).length === 0 ||
        !this.diagramData.data ||
        this.diagramData.unavailable
      ) {
        this.diagramSettings.series.push(0);
        this.unavailable = true;
        return;
      }

      const percentage = this.valueTotalToPercentage(
        this.diagramData.data.Value,
        this.diagramData.data.Total,
      );

      this.diagramSettings.series.push(percentage);

      this.diagramSettings.chartOptions.theme = {
        monochrome: {
          enabled: true,
          color: "#f26522",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      };

      this.unavailable = false;
    }
  },
  methods: {
    valueTotalToPercentage: function (value, total) {
      // If total is 0, percentage is 0
      if (total === 0 || value === 0) {
        return 0;
      }
      return Math.round(value / (total / 100));
    },
    formatValue: function (value, round) {
      if (typeof value === "undefined") return "";
      if (typeof value === "number")
        return formatNumber(value.toString(), round);
      if (value.includes("€")) return `€ ${formatNumber(value, round)}`;
      if (value.includes(","))
        return formatNumber(value.replace(",", ""), round);
      return formatNumber(value, round);
    },
    getPrice: function (value) {
      return getPrice(value);
    },
    getTextStyle: function (value) {
      return getTextStyle(value, this.diagramData.conditionalFormatting);
    },
    openWindow: async function (url) {
      const result = await api.get(url);

      window.session.openWindow(result.data);
    },
  },
};
</script>

<style lang="scss">
.radialContext {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .radialTextBox {
    flex: 1 1 30%; /*  Stretching: */
    flex: 0 1 30%; /*  No stretching: */
    margin: 5px;
    font-size: 15.4px;
  }
}
</style>
