import store from "../../../state/store";

/**
 * Open reference
 * @param {string} columnName - Name of column
 * @param {*} value - Value of cell
 * @returns {Promise} To create window
 */
export default async function openref(columnName, value) {
  let col = null;

  if (this.output.Request.Subject === "Rental.virtual_QuickRent") {
    col = this.output.serverData.QuickRentColumns[columnName];
  }

  if (col == null) {
    col = this.output.Table.Columns.filter((c) => c.Name === columnName).pop();
  }

  if (col == null) {
    return;
  }

  let foreign = col.Dropdown;

  // construct input
  let input = {};
  input.Subject = foreign.TableName;
  input.Prefix = "Multi";
  input.Criteria = [{}];

  // Go to foreign field of a value is selected,
  // otherwise, go to table
  if (value) {
    input.Prefix = "Single";
    input.Criteria[0][foreign.ColumnName] = value;
  }

  // Add extra keys to criteria
  if (
    foreign.ExtraKeys != null &&
    this.output.Data != null &&
    this.output.Data.Rows != null &&
    this.output.Data.Rows[0] != null
  ) {
    let currentDataRow = this.output.FullTable.Rows[0];
    for (let primaryKey of foreign.ExtraKeys) {
      let filteredRow = currentDataRow.filter(
        (x) => x.Column.Name == primaryKey,
      );
      if (filteredRow.length > 0) {
        let primaryKeyCell = filteredRow[0];
        input.Criteria[0][primaryKey] = primaryKeyCell.IsDirty
          ? primaryKeyCell.NewValue
          : primaryKeyCell.Value;
      }
    }
  }

  if (foreign?.ExtraCriteria) {
    const row = this.output.Data.Rows[0];
    for (let criteria of foreign.ExtraCriteria) {
      if (criteria.OverrideValueFromRow) {
        input.Criteria[0][criteria.PrimaryKeyName] =
          row[criteria.FormColumnName];
      } else {
        input.Criteria[0][criteria.PrimaryKeyName] = criteria.Value;
      }
    }
  }

  // create window
  await this.session.openWindow(input);
  store.commit("updateWindow");
}
