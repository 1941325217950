<template>
  <decimal-field
    :field="field"
    :decimal-places="computedDecimalPlaces"
    @input="$emit('input', $event)"
  />
</template>

<script>
import DecimalField from "./DecimalField.vue";

export default {
  name: "MoneyField",
  components: {DecimalField},
  props: {
    field: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    computedDecimalPlaces() {
      return this.field.NumericScale ?? 2;
    },
  },
};
</script>

<style></style>
