export const processRowWarehouseIdChange = async ({row}) => {
  let newRow = {...row};

  newRow.SerialID.Value = null;
  newRow.SerialID.Icon = "";
  newRow.SerialID.IconColor = "";
  newRow.SerialID.IconMessage = "";
  newRow.SerialID.IconSubMessage = "";

  return newRow;
};
