<template>
  <div class="row no-gutters">
    <div
      v-if="settings.SimpleInterface && !settings.HideFormSections"
      class="col-xs-12 col-md-12 col-lg-3 col-xl-3 pb-4 pb-lg-0"
    >
      <div class="font-semibold text-[18.7px] m-2 p-0">
        {{ section.Title }}
      </div>
      <div class="text-[14.3px] m-2 p-0">{{ section.Description }}</div>
    </div>

    <div
      :class="{
        'col-xs-12 col-md-12 col-lg-12 col-xl-12':
          settings.ShowActionBar && settings.HideFormSections,
        'col-xs-12 col-md-12 col-lg-9 col-xl-9': !(
          settings.ShowActionBar && settings.HideFormSections
        ),
      }"
    >
      <div
        v-if="!settings.HideFormSections && !settings.SimpleInterface"
        class="ml-3"
      >
        <div v-if="section.Title !== '' && section.Description !== ''">
          <div class="font-semibold text-[18.7px] m-2 p-0">
            {{ section.Title }}
          </div>
          <div class="text-[14.3px] m-2 p-0">{{ section.Description }}</div>
        </div>
      </div>

      <div class="row">
        <template v-for="column in sortedSectionColumns">
          <div
            :key="column.Name"
            :class="getColumnWidthClassFromColumn(column)"
          >
            <form-field-col
              :key="column.Name"
              :field-name="column.Name"
              :form-fields="formFields"
              @input="handleInput"
              @field-change="$emit('field-change', $event)"
            />
          </div>
          <div
            v-if="column.IsVisible"
            :key="`${column.Name}-offset`"
            :class="getColumnOffsetClassFromColumn(column)"
          ></div>
        </template>
      </div>
    </div>

    <div v-if="!settings.HideFormSections" class="col-12">
      <hr class="w-100 mt-4 mb-4 pb-0 mb-md-5" />
    </div>
  </div>
</template>

<script>
import {getColumnOffsetClassFromColumn} from "../../../functions/form/getColumnOffsetClassFromColumn";
import {getColumnWidthClassFromColumn} from "../../../functions/form/getColumnWidthClassFromColumn";
import FormFieldCol from "./FormFieldCol.vue";

export default {
  name: "FormSection",
  components: {FormFieldCol},
  props: {
    section: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    values: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    formFields: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortedSectionColumns() {
      return [...this.section.Columns].sort((a, b) => a.Ranking - b.Ranking);
    },
  },
  methods: {
    getColumnWidthClassFromColumn,
    getColumnOffsetClassFromColumn,
    handleInput(value) {
      this.$emit("field-change", {field: value, formFields: this.formFields});
    },
  },
};
</script>
