import {api} from "@/services/api";

export const saveInboundOrderItems = async ({rows, orderId}) => {
  const result = await api.put(`v2/inbound/order/${orderId}/items`, rows);

  if (result.status === 400 || result?.data?.Error) {
    return false;
  }

  return true;
};
