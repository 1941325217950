import {distributeCostsLinear} from "./distribute-costs/distributeCostsLinear";
import {distributeCostsWeighed} from "./distribute-costs/distributeCostsWeighed";
import {distributeCostsForRelatedRows} from "./distribute-costs/distributeCostsForRelatedRows";

export const distributeCosts = ({rows, costDistributionType, row}) => {
  if (costDistributionType === "Linear") {
    return distributeCostsLinear({rows});
  }

  if (costDistributionType === "Weighed") {
    return distributeCostsWeighed({rows});
  }

  if (costDistributionType === "Disable") {
    return distributeCostsForRelatedRows({rows, row});
  }

  return rows;
};
