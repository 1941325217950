export const renumberRows = ({rows}) => {
  // Sort rows based on the 'Value' property of the 'Ranking' object
  rows.sort((a, b) => {
    if (a.Ranking && a.Ranking.Value && b.Ranking && b.Ranking.Value) {
      return a.Ranking.Value - b.Ranking.Value;
    } else {
      return 0;
    }
  });

  // Iterate through each row in the sorted rows array
  for (let i = 0; i < rows.length; i++) {
    // Check if the row object has a Ranking property
    if (rows[i].hasOwnProperty("Ranking")) {
      // Check if the Ranking object has a Value property
      if (rows[i].Ranking.hasOwnProperty("Value")) {
        // Get the single-digit value of the current ranking
        let singleDigitValue = rows[i].Ranking.Value % 10;

        // Set the Value property of the Ranking object to the (index + 1) * 10, plus the single-digit value
        rows[i].Ranking.Value = (i + 1) * 10 + singleDigitValue;
      } else {
        console.error(
          `Row ${i} does not have a Value property in its Ranking object.`,
        );
      }
    } else {
      console.error(`Row ${i} does not have a Ranking property.`);
    }
  }

  return rows;
};
