import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import {alertPopup} from "../alertPopup/alertPopup.js";

export const confirmDiscardUnsavedChanges = async () => {
  const translations = getTranslations();

  const alertObject = {
    text: translations.WindowWarningOnClose,
    icon: "warning",
    dangerMode: true,
    buttons: [
      {
        text: translations.Cancel,
        value: null,
        variant: "secondary",
      },
      {
        text: "Ok",
        value: true,
        variant: "primary",
      },
    ],
  };

  return await alertPopup(alertObject);
};
