import {setRowsCustomPropertiesToCustomPropertyObject} from "./setRowsCustomPropertiesToCustomPropertyObject";
import {rowsGenerateVirtualCompositionRows} from "./rowsGenerateVirtualCompositionRows";
import {rowsSetMetadataForCompositionRows} from "./rowsSetMetadataForCompositionRows";
import {normalizeRowCompositionIDProperty} from "../row/normalizeRowCompositionIDProperty";
import {rowsSetNullValueForMissingProps} from "./rowsSetNullValueForMissingProps";
import {processCompositionDefinition} from "../row/processCompositionDefinition";
import {processTotalExcVat} from "../order-item/row/processTotalExcVat.js";

export const prepareRowsForRender = ({rows, columns}) => {
  rows = rowsSetMetadataForCompositionRows({rows});
  rows = rowsGenerateVirtualCompositionRows({rows});
  rows = rowsSetNullValueForMissingProps({rows, columns});
  rows = rows.map((row) => normalizeRowCompositionIDProperty({row}));
  rows = rows.map((row) => processCompositionDefinition({row}));
  rows = rows.map((row) => processTotalExcVat({row}));
  return setRowsCustomPropertiesToCustomPropertyObject({rows});
};
