import {saveButtonClick} from "@/services/rent-web-services/saveButtonClick";
import {convertRowToliRowDataRow} from "@/functions/datagrid/order-item/row/convertRowToliRowDataRow";

export async function saveOrderItems({orderId, rows, columns}) {
  const liRowData = rows.map(convertRowToliRowDataRow);

  for (const index in columns) {
    const column = columns[index];
    if (column.Type === "Decimal") {
      liRowData.forEach((row) => {
        row[column.Name] = parseFloat(row[column.Name]);
      });
    }
    if (column.Type === "Money") {
      liRowData.forEach((row) => {
        row[column.Name] = parseFloat(row[column.Name]);
      });
    }
  }

  for (const row of liRowData) {
    const dimensions = getDimensionsFromRow({row});
    if (dimensions.length) {
      row.Dimensions = dimensions;
      continue;
    }

    delete row.Dimensions;
  }

  const calculateRowValuesInput = {
    headerData: {
      RentalType: "",
      CategoryID: "",
      DefaultPeriodID: null,
    },
    liRowData,
    request: {
      IsSubWindow: false,
      HideEmptyPlaceHolder: true,
      HideDefaultFilters: false,
      Subject: "Rental.virtual_QuickRent",
      TableToSelect: null,
      ParentSubject: "",
      TitleResourceID: "HandOut",
      SubTitleResourceID: null,
      LinkedSubject: null,
      Prefix: "Multi",
      Criteria: [
        {
          OrderID: orderId,
          IsReplacement: false,
          Target: "Rental.Order",
        },
      ],
      EntityTranslationLanguage: null,
      TableValuedFunctionParameters: [],
      Data: {
        Search: [],
        Filters: {},
        SortOn: null,
        SortReverse: false,
        SelectedTabActionName: null,
        PageNumber: 1,
        PageSize: null,
        ShowCurrencyToggle: false,
        ToggleCurrency: false,
        ClientCurrency: "EUR",
        ClientCurrencySymbol: "€",
        OldClientCurrency: null,
        ForeignCurrency: "EUR",
      },
      PreviousPostBackValues: null,
      PostBackValues: null,
      RunDesktopHooks: true,
    },
  };

  return await saveButtonClick(calculateRowValuesInput);
}

function getDimensionsFromRow({row}) {
  const dimensions = [];
  for (const key in row) {
    if (key.startsWith("DimensionID") && row[key] !== null) {
      const DimensionObjectID = row[key];

      if (DimensionObjectID.Value === null) {
        continue;
      }

      dimensions.push({DimensionObjectID});
    }
  }

  return dimensions;
}
